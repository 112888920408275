import { get } from 'lodash';

import {
  additionalExternalValuesMapping,
  WATCHTOWER_ADDON_NAME,
  WATCHTOWER_READABLE_NAME,
} from './constants';
import {
  AlertConfigData,
  AlertProperty,
  CountryPermissionsData,
  DropdownOptions,
  EmailDomainsData,
  UnusualApplicationTimesData,
  WatchtowerConfig,
  WatchtowerData,
} from './types';
import AddonConfigModel from 'models/AddonConfigModel';
import { FEATURE_FLAGS } from 'conf';

export const getNewVersionAttribute = (
  addonConfig,
  data: WatchtowerConfig
): { attributes: { config: WatchtowerConfig } } => {
  const attributes: any = {
    config: data,
  };

  if (addonConfig.isPersisted) {
    attributes.addon_config_id = addonConfig.id;
  } else {
    attributes.name = WATCHTOWER_READABLE_NAME;
    attributes.addon_module_name = WATCHTOWER_ADDON_NAME;
  }

  return attributes;
};

const watchTowerDataToDynamicFieldKeyMap = {
  'blocked_geo_ips': 'countryPermissions',
  'credit_score_decrease': 'creditScoreDecreaseThreshold',
  'email_age': 'emailAge',
  'unusual_application_times': 'unusualApplicationTimes',
  'email_domains': 'emailDomains',
};

export const getDynamicFieldsData = (
  alertName: string,
  data: WatchtowerData
): { [key: string]: any } => {
  const alertObject = { name: alertName };
  const alertDataKey = watchTowerDataToDynamicFieldKeyMap[alertName];

  switch (alertName) {
    case 'blocked_geo_ips': {
      const { blacklist_countries, whitelist_countries } = data[alertDataKey];
      return {
        ...alertObject,
        blacklist_countries: blacklist_countries.filter(
          (country) => country !== 'non_au_nz'
        ),
        whitelist_countries,
      };
    }
    case 'unusual_application_times':
      return {
        ...alertObject,
        ...data[alertDataKey],
      };
    case 'email_age':
      return {
        ...alertObject,
        age: data[alertDataKey],
      };
    case 'credit_score_decrease':
      return {
        ...alertObject,
        threshold: data[alertDataKey],
      };
    case 'email_domains':
      const { domain_list, entity_types } = data[alertDataKey];

      return {
        ...alertObject,
        domain_list,
        entity_types,
      };
    default:
      return alertObject;
  }
};

export const generateNewPayload = (data: WatchtowerData): WatchtowerConfig => {
  return {
    community_opt_in: false,
    external_alerts: {
      alerts_enabled: data.externalAlerts.reduce((alertData, alertName) => {
        const additionalMapping = additionalExternalValuesMapping[alertName];
        if (additionalMapping) {
          alertData = [
            ...alertData,
            ...additionalMapping.map((additionalMappingAlertName) => ({
              name: additionalMappingAlertName,
            })),
          ];
        } else {
          alertData.push(getDynamicFieldsData(alertName, data));
        }

        return alertData;
      }, [] as AlertProperty[]),
      contacts: data.externalAlertContacts,
    } as AlertConfigData,
    internal_alerts: {
      alerts_enabled: data.internalAlerts.map((alertName) =>
        getDynamicFieldsData(alertName, data)
      ),
      contacts: data.internalAlertContacts,
      application_status_exclusions: data.exclusions,
    } as AlertConfigData,
  };
};

export const getAlertsNameArray = (configData: AlertProperty[]): string[] => {
  if (!Array.isArray(configData)) return [];

  return configData.reduce((alertsNameArray: string[], alert) => {
    let alertHasMapping = false;

    for (const alertName in additionalExternalValuesMapping) {
      const alertMapValues = additionalExternalValuesMapping[alertName];
      if (alertMapValues.includes(alert.name)) {
        if (!alertsNameArray.includes(alertName)) {
          alertsNameArray.push(alertName);
        }
        alertHasMapping = true;
        return alertsNameArray;
      }
    }

    if (!alertHasMapping) {
      alertsNameArray.push(alert.name);
    }

    return alertsNameArray;
  }, []);
};

export const getContactOptions = (allUsers): DropdownOptions[] => {
  return allUsers.reduce(
    (userEmails: DropdownOptions[], currentUser): DropdownOptions[] => {
      if (!currentUser.roleTypes.includes('standard')) {
        const { email } = currentUser;
        userEmails.push({ brief: email, label: email, value: email });
      }
      return userEmails;
    },
    []
  );
};

export const getAlertValuesFromOption = (
  options: DropdownOptions[]
): AlertProperty[] => {
  return options.map((option) => ({ name: option.value }));
};

const getAlertConfigFromName = (configName, currentConfig) => {
  const internalAlerts = get(
    currentConfig,
    'internal_alerts.alerts_enabled',
    []
  );
  return internalAlerts.find((config) => config.name === configName) || {};
};

export const getPermissionDefaultData = (
  currentConfig: any
): CountryPermissionsData => {
  const permissionData = getAlertConfigFromName(
    'blocked_geo_ips',
    currentConfig
  );
  const { blacklist_countries, whitelist_countries } = permissionData;
  return {
    ...(blacklist_countries
      ? { blacklist_countries }
      : { blacklist_countries: ['KP'] }),
    ...(whitelist_countries ? { whitelist_countries } : {}),
  };
};

export const getEmailAgeDefaultData = (currentConfig): number => {
  const emailAge = getAlertConfigFromName('email_age', currentConfig);
  return emailAge.age ? emailAge.age : 0;
};

export const getEmailDomainsDefaultData = (currentConfig): EmailDomainsData => {
  const emailDomains = getAlertConfigFromName('email_domains', currentConfig);
  const { domain_list, entity_types } = emailDomains;
  return {
    domain_list: domain_list ? domain_list : [],
    entity_types: entity_types ? entity_types : [],
  };
};

export const getUnusualApplicationTimesDefaultData = (
  currentConfig,
  userTimezone: string
): UnusualApplicationTimesData => {
  const unusualApplicationTimeData = getAlertConfigFromName(
    'unusual_application_times',
    currentConfig
  );
  const { end_time, start_time, timezone } = unusualApplicationTimeData;
  return {
    end_time: end_time || '',
    start_time: start_time || '',
    timezone: timezone || userTimezone,
  };
};

export const isUnusualApplicationTimeEnabled = (config) => {
  const internalAlerts = get(config, 'internal_alerts.alerts_enabled', []);
  return internalAlerts.some(
    (alert) => alert.name === 'unusual_application_times'
  );
};

export const updateTimezone = (config, newTimezone) => {
  const newConfig = { ...config };
  const internalAlerts = get(newConfig, 'internal_alerts.alerts_enabled', []);
  const unusualApplicationTimeIndex = internalAlerts.findIndex(
    (alert) => alert.name === 'unusual_application_times'
  );
  if (unusualApplicationTimeIndex < 0) return;
  const unusualApplicationTime = internalAlerts[unusualApplicationTimeIndex];

  newConfig.internal_alerts.alerts_enabled[unusualApplicationTimeIndex] = {
    ...unusualApplicationTime,
    timezone: newTimezone,
  };

  return newConfig;
};
// {
//   "countryPermissions": {
//       "blacklist_countries": [
//           "KP"
//       ]
//   },
//   "creditScoreDecreaseThreshold": 0,
//   "emailAge": 0,
//   "externalAlertContacts": [],
//   "externalAlerts": [
//       "company_persons_changed",
//       "credit_score_decrease",
//       "company_deregistered",
//       "au_director_mismatch",
//       "au_gst_registered",
//       "company_insolvency",
//       "nz_company_liquidation",
//       "au_liquidation_recievership",
//       "nz_company_receivership",
//       "company_voluntary_administration"
//   ],
//   "internalAlertContacts": [],
//   "internalAlerts": [
//       "application_velocity",
//       "director_liquidated_company",
//       "email_age",
//       "blocked_geo_ips",
//       "identical_company_numbers",
//       "identical_directors",
//       "identical_emails",
//       "identical_identifications",
//       "identical_ip_addresses",
//       "repeat_application",
//       "unusual_application_times"
//   ],
//   "unusualApplicationTimes": {
//       "end_time": "",
//       "start_time": "",
//       "timezone": "Pacific/Auckland"
//   }
// }

export const watchtowerAddonHasEmptyFields = (
  values: WatchtowerData
): boolean => {
  const {
    externalAlerts,
    externalAlertContacts,
    internalAlerts,
    internalAlertContacts,
  } = values;

  if (externalAlerts.length) {
    if (!externalAlertContacts.length) return true;
  }

  if (internalAlerts.length) {
    const {
      unusualApplicationTimes,
      countryPermissions,
      emailAge,
      emailDomains,
    } = values;

    const validEndTimeInput =
      unusualApplicationTimes.end_time &&
      unusualApplicationTimes.end_time.length === 5;
    const validStartTimeInput =
      unusualApplicationTimes.start_time &&
      unusualApplicationTimes.start_time.length === 5;

    const emptyUnusualApplicationTimes =
      internalAlerts.includes('unusual_application_times') &&
      !(validEndTimeInput && validStartTimeInput);

    const emptyBlockedGeoIps =
      internalAlerts.includes('blocked_geo_ips') &&
      !(
        countryPermissions.blacklist_countries.length > 1 ||
        !!countryPermissions.whitelist_countries
      );

    const emptyEmailAge =
      internalAlerts.includes('email_age') && emailAge === 0;

    const emptyEmailDomains =
      FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER_EMAIL_DOMAINS
        ? internalAlerts.includes('email_domains') &&
          emailDomains &&
          (!emailDomains.domain_list.length ||
            !emailDomains.entity_types.length)
        : false;

    if (
      !internalAlertContacts.length ||
      emptyUnusualApplicationTimes ||
      emptyBlockedGeoIps ||
      emptyEmailAge ||
      emptyEmailDomains
    )
      return true;
  }

  return false;
};

export const updateWatchtowerConfigTimezone = (
  currentUser: any,
  timezone: string
) => {
  const currentUserEntityId = currentUser.current_entity.id;

  if (!!timezone) {
    AddonConfigModel.fetchAddonConfigByAddonType({
      addonType: 'watchtower_module',
      entityId: currentUserEntityId,
      accessToken: undefined,
    }).then((response) => {
      const addonResponse = response[0] || {};
      const watchtowerConfig = addonResponse.latestVersion;
      const currentConfig = watchtowerConfig.attributes.config;
      // Only do an update if unusual_application_time config is enabled
      if (!isUnusualApplicationTimeEnabled(currentConfig)) {
        return;
      } else {
        const newVersion = addonResponse.generateNewVersion();
        const payload = updateTimezone(currentConfig, timezone);
        const attributes = getNewVersionAttribute(addonResponse, payload);
        newVersion.setAttributes(attributes);
        newVersion.save({
          addonConfig: addonResponse,
          currentUser,
          onSuccessCallback: () => {},
          onErrorCallback: () => {},
        });
      }
    });
  }
};
