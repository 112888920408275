import { get } from 'lodash';
import {
  lookupAddress,
  selectAddress,
  setAddress,
} from 'modules/identity/actions';
import TextInput from 'modules/shared/components/inputs/TextInput';
import BackAndNextButtons from 'modules/shared/components/widgets/interactive/BackAndNextButtons';
import GuarantorConfirmModal from 'modules/shared/components/widgets/static/GuarantorConfirmModal';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isPresent from 'utils/isPresent';
import { isSanitizedStringEqual } from 'utils/sanitizeName';

import { updateAuthorisation, updateAuthorisationSignature } from '../actions';
import styles from './css/Section.css';
import { WatchtowerBlockMessage } from 'modules/consumer-onboarding/components/review/WatchtowerBlockMessage';
import { FEATURE_FLAGS } from 'conf';

const isNewTermsAndConditionsEnabled = FEATURE_FLAGS.FEATURE_FLAG_UPDATED_TNCS;

const retrieveNameComponents = ({ authorisation, identity }) => {
  if (isPresent(identity.firstName) && isPresent(identity.lastName)) {
    return { firstName: identity.firstName, lastName: identity.lastName };
  }

  return {
    firstName: authorisation.firstName,
    lastName: authorisation.lastName,
  };
};

class Confirm extends Component {
  constructor() {
    super();
    this.state = {
      error: {},
      first_name: '',
      isGuarantorConfirmModalVisible: false,
      last_name: '',
    };
  }

  handleConfirm() {
    const { params, toNextSection, dispatch } = this.props;
    this.hideGuarantorConfirmModal();
    dispatch(
      updateAuthorisation(
        params.authorisation_id,
        () => {
          dispatch(
            updateAuthorisationSignature(params.authorisation_id, () =>
              toNextSection()
            )
          );
        },
        { check_for_blocked_ip: true }
      )
    );
  }

  onNextClick() {
    const { agreedToTerms, validateTerms, isGuarantor } = this.props;
    const allValid = [];
    const fields = defaults.fields.standard;
    fields.forEach((field) =>
      allValid.push(this.fieldCheck(field, this.state[field]))
    );
    if (!agreedToTerms) {
      validateTerms();
    }
    if (allValid.every((flag) => flag) && agreedToTerms) {
      if (isGuarantor) {
        this.showGuarantorConfirmModal();
      } else {
        this.handleConfirm();
      }
    }
  }

  fieldCheck(key, value) {
    const validationResult = this.valid(key, value);
    const { error } = this.state;
    if (validationResult) {
      error[key] = '';
    } else {
      error[key] = this.errorMessage(key);
    }
    this.setState({ error });
    return validationResult;
  }

  errorMessage(key) {
    const { identity, referenceFirstName, referenceLastName } = this.props;
    const { first_name, last_name } = this.state;
    switch (key) {
      case 'first_name':
        if (isPresent(first_name) && first_name !== referenceFirstName) {
          return 'Incorrect first name';
        }

        return 'You must input first name';
      case 'last_name':
        if (isPresent(last_name) && last_name !== referenceLastName) {
          return 'Incorrect last name';
        }

        return 'You must input last name';

      case 'dob':
        if (identity[key]) return 'Please select a valid date of birth';
        return 'Please select date of birth';
      case 'personal_address':
        if (!identity.address || !identity.address.full_address)
          return 'Please input personal address';
        return 'Please select a valid address';
    }
  }

  valid(key, value) {
    const { referenceFirstName, referenceLastName } = this.props;
    const { identity } = this.props;

    switch (key) {
      case 'first_name':
        return (
          Boolean(value) && isSanitizedStringEqual(referenceFirstName, value)
        );
      case 'last_name':
        return (
          Boolean(value) && isSanitizedStringEqual(referenceLastName, value)
        );
      case 'dob': {
        const dob = moment(value, 'DD/MM/YYYY');
        const yearOfBirth = dob.year();
        const thisYear = new Date().getFullYear();

        // user must be less then 100 years old and over 18
        return (
          Boolean(value) &&
          thisYear - 100 <= yearOfBirth &&
          yearOfBirth <= thisYear - 18
        );
      }
      case 'personal_address':
        return Boolean(identity.address_api_id);
    }
  }

  handleAddressChange(value) {
    const { dispatch } = this.props;
    dispatch(setAddress({ full_address: value }));
    if (value.length > 0) {
      dispatch(lookupAddress(event.target.value));
    }
  }

  handleAddressClick(value) {
    const { dispatch } = this.props;
    dispatch(selectAddress(value));
  }

  showGuarantorConfirmModal = () => {
    this.setState({ isGuarantorConfirmModalVisible: true });
  };

  hideGuarantorConfirmModal = () => {
    this.setState({ isGuarantorConfirmModalVisible: false });
  };

  renderAcknowledge() {
    const {
      hasCardholder,
      hasGuarantor,
      hasPaperless,
      supplierLegalName,
      consumerName,
      isTrustApplication,
      trustApplicantName,
      corporateTrusteeName,
    } = this.props;
    const items = [];
    items.push(`The information provided is true and correct.`);
    if (hasGuarantor) {
      items.push(
        `In electronically signing the Guarantee, you should assume that this
        is a liability you will be called upon to honour, and you should
        satisfy yourself that you have the financial means to meet this
        liability, and are willing to do so. If you are not comfortable with
        that assumption, you should not consent to the Guarantee.`
      );
    }
    if (hasCardholder) {
      items.push(
        `Once you use the ${supplierLegalName} card you are bound by the
        conditions of use set out in the ${supplierLegalName} Terms and
        Conditions (as amended form time to time).`
      );
    }
    if (hasPaperless) {
      items.push(
        `I authorise ${supplierLegalName}, until further notice in writing to
        debit my/our account.`
      );
      items.push(
        `I acknowledge and accept that the bank accepts the direct debit
        authority based upon the conditions found in the Terms & Conditions.`
      );
    }
    return (
      <div>
        <p>{`By electronically signing this section you confirm and acknowledge that: ${items.join(
          ' '
        )}`}</p>

        {isNewTermsAndConditionsEnabled && hasGuarantor && (
          <p className="mt-3">
            {isTrustApplication
              ? `I am signing on behalf of ${
                  corporateTrusteeName || trustApplicantName
                } in my capacity as director and/or shareholder or trustee and also in my personal capacity as guarantor, guaranteeing the performance of the obligations of ${trustApplicantName} under the Terms of the Guarantee.`
              : `I am signing on behalf of ${consumerName} in my capacity as director and/or shareholder, and also in my personal capacity as guarantor, guaranteeing the performance of the obligations of ${consumerName} under the Terms of the Guarantee.`}
          </p>
        )}
      </div>
    );
  }

  renderAcknowledge_depcrecated() {
    const { hasCardholder, hasGuarantor, hasPaperless, supplierLegalName } =
      this.props;
    const items = [];
    items.push(<li> The information provided is true and correct.</li>);
    if (hasGuarantor) {
      items.push(
        <li>
          In electronically signing the Guarantee, you should assume that this
          is a liability you will be called upon to honor, and you should
          satisfy yourself that you have the financial means to meet this
          liability, and are willing to do so. If you are not comfortable with
          that assumption, you should not consent to the Guarantee.
        </li>
      );
    }
    if (hasCardholder) {
      items.push(
        <li>
          Once you use the {supplierLegalName} card you are bound by the
          conditions of use set out in the {supplierLegalName} Terms and
          Conditions (as amended form time to time).
        </li>
      );
    }
    if (hasPaperless) {
      items.push(
        <li>
          I authorise {supplierLegalName}, until further notice in writing to
          debit my/our account.
        </li>
      );
      items.push(
        <li>
          I acknowledge and accept that the bank accepts the direct debit
          authority based upon the conditions found in the Terms & Conditions.
        </li>
      );
    }
    return (
      <div>
        <p>By signing this section you confirm and acknowledge that:</p>
        <ul>{items}</ul>
      </div>
    );
  }

  renderSignatureFields() {
    const { identity } = this.props;
    const { error, first_name, last_name } = this.state;
    return (
      <div className={styles.row}>
        <div className={styles.half_width}>
          <TextInput
            id="first_name"
            name="first_name"
            handleChange={(event) =>
              this.setState({ first_name: event.target.value })
            }
            handleBlur={(event) =>
              this.fieldCheck('firstName', event.target.value)
            }
            label="First name"
            error={error.first_name}
            value={first_name}
            required={true}
          />
        </div>
        <div className={styles.half_width}>
          <TextInput
            id="last_name"
            name="last_name"
            handleChange={(event) =>
              this.setState({ last_name: event.target.value })
            }
            handleBlur={(event) =>
              this.fieldCheck('lastName', event.target.value)
            }
            label="Last name"
            error={error.last_name}
            value={last_name}
            required={true}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      toPreviousSection,
      updating,
      supplierLegalName,
      renderWithoutSection,
      isBlockedByWatchtower,
    } = this.props;

    const ConfirmComponent = (
      <React.Fragment>
        <div className={`${styles.row} mb-4`}>
          <div className={styles.panel}>
            <PanelTitle text={defaults.title} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.content}>
            {isNewTermsAndConditionsEnabled
              ? this.renderAcknowledge()
              : this.renderAcknowledge_depcrecated()}
          </div>
        </div>
        <p className="mb-2">
          If you agree please fill out your first and last name in the fields
          below and press confirm.
        </p>
        {this.renderSignatureFields()}
      </React.Fragment>
    );

    return (
      <div>
        {renderWithoutSection ? (
          ConfirmComponent
        ) : (
          <div className={styles.container}>
            <section className={styles.section}>{ConfirmComponent}</section>
          </div>
        )}

        <BackAndNextButtons
          onNextClick={this.onNextClick.bind(this)}
          onBackClick={toPreviousSection}
          updating={updating}
          disableNext={updating}
        />
        {isBlockedByWatchtower && <WatchtowerBlockMessage />}
        {this.state.isGuarantorConfirmModalVisible && (
          <GuarantorConfirmModal
            onClose={this.hideGuarantorConfirmModal}
            onConfirm={this.handleConfirm.bind(this)}
            supplierLegalName={supplierLegalName}
          />
        )}
      </div>
    );
  }
}

const defaults = {
  fields: {
    extra: ['first_name', 'last_name', 'dob', 'personal_address'],
    standard: ['first_name', 'last_name'],
  },
  title: 'Electronic signature',
};

export default connect((state) => {
  const nameComponents = retrieveNameComponents({
    authorisation: state.authorisation || {},
    identity: state.identity,
  });

  const isGuarantor = get(
    state,
    'authorisation.data.authorisation.attributes.is_guarantor',
    false
  );

  return {
    identity: state.identity,
    isGuarantor,
    referenceFirstName: nameComponents.firstName,
    referenceLastName: nameComponents.lastName,
    supplierLegalName: state.authorisation.supplierLegalName,
    updating: state.authorisation.updating,
    isBlockedByWatchtower: state.authorisation.isBlockedByWatchtower,
  };
})(Confirm);
