import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import useCollapsibleContentState from 'modules/shared/hooks/useCollapsibleContentState';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const sortVersionNumberDescending = (a, b) => {
  if (a.version_number > b.version_number) {
    return -1;
  }
  if (a.version_number < b.version_number) {
    return 1;
  }
  return 0;
};

const ApplicationVersionLink = ({
  applicationVersions,
  currentApplicationId,
  styles,
}) => {
  const sortedApplicationVersion = applicationVersions.sort(
    sortVersionNumberDescending
  );

  return sortedApplicationVersion.map((version) => {
    const { version_number, id, change_reason, inaccessible } = version;
    const baseString = `Version ${version_number}`;
    const changeReason = change_reason ? `: ${change_reason}` : '';
    const isCurrent = id === currentApplicationId;
    const isCurrentString = isCurrent ? ' (current)' : '';
    const displayString = `${baseString}${changeReason}`;

    return (
      <div key={id}>
        {isCurrent || inaccessible ? (
          <span className={isCurrent ? styles.header_version : ''}>
            {displayString + isCurrentString}
          </span>
        ) : (
          <a href={`/dashboard/applications/${id}/`}>{displayString}</a>
        )}
      </div>
    );
  });
};

const SidebarApplicationVersions = (props) => {
  const { applicationVersions, styles, currentApplicationId } = props;
  const { isOpen, onToggleIsOpen } = useCollapsibleContentState();
  const ArrowIcon = isOpen ? ArrowDropUpIcon : ArrowDropDownIcon;

  return (
    <div className={styles.sidebar_menu}>
      <div className={styles.sidebar_menu_header} onClick={onToggleIsOpen}>
        <div className="is-flex is-justify-content-space-between is-align-items-center">
          <div>Application version</div>
          <ArrowIcon color="primary" />
        </div>
      </div>

      <Collapse in={isOpen}>
        <ApplicationVersionLink
          styles={styles}
          applicationVersions={applicationVersions || []}
          currentApplicationId={currentApplicationId}
        />
      </Collapse>
    </div>
  );
};

export default SidebarApplicationVersions;
