import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import React, { Fragment } from 'react';

function IdentificationNumber(props) {
  const { signature } = props;

  let label = 'Identification number';

  if (signature.identificationType === 'driver_licence') {
    label = 'Drivers licence no.';
  } else if (signature.identificationType === 'passport') {
    label = 'Passport no.';
  }

  return (
    <LabeledContent label={label} content={signature.identificationNumber} />
  );
}

function IdentificationOtherDetails(props) {
  const { signature } = props;

  if (signature.identificationType === 'driver_licence') {
    return (
      <LabeledContent
        label="Drivers licence version"
        content={signature.identificationVersion}
      />
    );
  }

  if (signature.identificationType === 'passport') {
    return (
      <LabeledContent
        label="Passport expiry date"
        content={signature.identificationExpiryDate}
      />
    );
  }

  return null;
}

const hasAgreedToAuthorise = (applicantAuthorisation) => {
  if (
    applicantAuthorisation.status === 'in_progress' ||
    applicantAuthorisation.status === 'not_started'
  ) {
    return 'Incomplete';
  }

  return 'Yes';
};

const NeedShowApplicantDetail = (application) =>
  application.isIdentificationCheckRequired ||
  (application.isCreditCheckRequired &&
    application.applicantAuthorisation.isGuarantor);

export default function AuthorisedApplicant(props) {
  const { application } = props;
  const signature = application.applicantSignature || {};

  return (
    <FixedContent header="Authorised applicant">
      <GridContent>
        <div>
          <LabeledContent label="Name" content={signature.fullName} />

          {NeedShowApplicantDetail(application) && (
            <Fragment>
              <LabeledContent
                label="Physical address"
                content={signature.fullResidentialAddress}
              />
              <IdentificationNumber signature={signature} />
            </Fragment>
          )}
          <LabeledContent
            label="Agreed to authorise"
            content={hasAgreedToAuthorise(application.applicantAuthorisation)}
          />
        </div>
        <div>
          <LabeledContent
            label="Email"
            content={application.consumerContactEmail}
          />
          {NeedShowApplicantDetail(application) && (
            <Fragment>
              <LabeledContent
                label="Date of birth"
                content={signature.formattedDOB}
              />
              <IdentificationOtherDetails signature={signature} />
            </Fragment>
          )}
        </div>
      </GridContent>
    </FixedContent>
  );
}
