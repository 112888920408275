/* Import libraries */
/* Import components */
import Clickable from 'modules/shared/components/widgets/interactive/Clickable';
import React from 'react';

/* Import CSS */
import styles from '../css/DashboardContainer.css';

const SubMenuItem = (props) => {
  const {
    title,
    id,
    target,
    css_style,
    visible,
    hiddenxs,
    tip_name,
    tip_css_style,
    alertCount,
    alertLabel,
  } = props;

  if (visible === false) {
    return null;
  }
  let style = styles.menu_link;

  if (css_style) {
    style = styles[css_style];
  }

  if (hiddenxs) {
    style = `${style} ${styles.hidden_xs}`;
  }

  return (
    <Clickable
      key={id}
      target={target}
      tip_name={tip_name}
      tip_css_style={tip_css_style}
    >
      <div className={style} id={id}>
        {alertCount ? (
          <span className={styles.subtitle_alert}>
            {title}
            <span>{` (${alertCount} ${
              alertLabel ? alertLabel : 'new alerts'
            })`}</span>
          </span>
        ) : (
          title
        )}
      </div>
    </Clickable>
  );
};

export default SubMenuItem;
