import React from 'react';
import styles from './report-styles.css';

const ReportHeadline = ({ characteristic, reportType }) => {
  const reportDataPrefixMap = {
    individual: 'NX',
    personal: 'NY',
  };
  const reportDataPrefix = reportDataPrefixMap[reportType];

  let adverseOnFile =
      characteristic.find((obj) => obj['@id'] === `${reportDataPrefix}7601`) ||
      {},
    insolvenciesAndActions =
      characteristic.find((obj) => obj['@id'] === 'NP8510') || {},
    creditEnquiries =
      characteristic.find((obj) => obj['@id'] === `${reportDataPrefix}8006`) ||
      {},
    defaults =
      characteristic.find((obj) => obj['@id'] === `${reportDataPrefix}7514`) ||
      {},
    currentDirectorships =
      characteristic.find((obj) => obj['@id'] === 'NP8500') || {},
    businessHeld = characteristic.find((obj) => obj['@id'] === 'NP8508') || {},
    prevDirectorships =
      characteristic.find((obj) => obj['@id'] === 'NP8509') || {},
    disqDirectorships =
      characteristic.find((obj) => obj['@id'] === 'NP8503') || {};

  const adverseFileValueMapping = { N: 'No', Y: 'Yes' };
  const adverseOnFileValue =
    adverseFileValueMapping[adverseOnFile.value] || adverseOnFile.value;
  const businessRelationshipsCount =
    parseInt(currentDirectorships.value) +
    parseInt(prevDirectorships.value) +
    parseInt(disqDirectorships.value) +
    parseInt(businessHeld.value);

  return (
    <div>
      <div className={styles.table + ' ' + styles.bg_space}>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_3}>Adverse on file</div>
          <div className={styles.td + ' ' + styles.col_3}>
            {!adverseOnFile || adverseOnFile.value < 0
              ? '-'
              : adverseOnFileValue}
          </div>
          <div className={styles.td + ' ' + styles.col_3}>
            Insolvencies & Actions
          </div>
          <div className={styles.td + ' ' + styles.col_3}>
            {!insolvenciesAndActions || insolvenciesAndActions.value < 0
              ? '-'
              : insolvenciesAndActions.value}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_3}>Credit enquiries</div>
          <div className={styles.td + ' ' + styles.col_3}>
            {!creditEnquiries || creditEnquiries.value < 0
              ? '-'
              : creditEnquiries.value}
          </div>
          <div className={styles.td + ' ' + styles.col_3}>
            Business relationships
          </div>
          <div className={styles.td + ' ' + styles.col_3}>
            {!businessRelationshipsCount || businessRelationshipsCount < 0
              ? 0
              : businessRelationshipsCount}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_3}>Defaults</div>
          <div className={styles.td + ' ' + styles.col_3}>
            {!defaults || defaults.value < 0 ? '-' : defaults.value}
          </div>
          <div className={styles.td + ' ' + styles.col_3}>
            Disq. directorships
          </div>
          <div className={styles.td + ' ' + styles.col_3}>
            {!disqDirectorships || disqDirectorships.value < 0
              ? 0
              : disqDirectorships.value}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportHeadline;
