import api from 'api';
import axios from 'axios';
import { pdfjs } from 'react-pdf';

export const getBase64 = async (file, maxPageNum = 0) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = async () => {
      if (file.type === 'application/pdf' && maxPageNum !== 0) {
        await pdfjs
          .getDocument({ data: atob(reader.result.split(',')[1]) })
          .promise.then(({ _pdfInfo }) => {
            if (_pdfInfo.numPages > maxPageNum) {
              return reject(
                `Number of page should not be more than ${maxPageNum} page.`
              );
            }
          });
      }

      return resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const getPdfImage = async (file) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);

  return new Promise((resolve, reject) => {
    reader.onload = async () => {
      await pdfjs.getDocument(reader.result).promise.then((pdf) => {
        pdf.getPage(1).then(function(page) {
          const scale = 1.5;
          const viewport = page.getViewport({ scale });

          const canvas = document.createElement('canvas');

          // Prepare canvas using PDF page dimensions
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Render PDF page into canvas context
          const renderContext = { canvasContext: context, viewport };

          const renderTask = page.render(renderContext);
          renderTask.promise.then(function() {
            return resolve(canvas.toDataURL());
          });
        });
      });
    };
    reader.onerror = (error) => reject(error);
  });
};

const kilo = 1024;
export const mb2Bytes = (mb) => mb * kilo * kilo;

export const getPresignedUrl = async ({
  currentUser,
  authorisationID,
  attachmentType,
  maxPageNum,
  resourceType,
  file,
}) => {
  const presignedUrlApi = api('presigned_url', currentUser.access_token);

  const presignedUrlResponse = await presignedUrlApi.get({
    attachment_type: attachmentType,
    mime_type: file.type,
    resource_id: authorisationID,
    resource_type: resourceType,
  });

  const { data, error } = presignedUrlResponse;

  if (error) {
    throw new Error(error);
  }

  return data;
};

export const storeFileToS3 = async (url, file, setProgress, callback) => {
  const base64File = await getBase64(file);
  const base64String = base64File.replace('data:', '').replace(/^.+,/, '');
  const bufferData = Buffer.from(base64String, 'base64');

  const config = {
    headers: {
      'Content-Encoding': 'base64',
      'Content-Type': file.type,
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setProgress(percentCompleted);
    },
  };

  const response = await axios.put(url, bufferData, config);
  const { error } = response;

  if (error) {
    if (callback) callback(response);
    throw new Error(error);
  }

  if (callback) callback(response);
  return response;
};

export const updateFilename = async ({
  apiType,
  authorisationID,
  currentUser,
  attachments,
  identityType,
  region,
}) => {
  const updateApi = api(apiType, currentUser.access_token);

  const params = {
    attachments,
    id: authorisationID,
    identity_type: identityType || null,
    ...(region ? { region } : {}),
  };

  const response = await updateApi.updateFilename(authorisationID, params);
  const { error } = response;

  if (error) {
    throw new Error(error);
  }

  return response.data;
};

export const toPreviewObj = (file) => {
  let fileString = file;

  if (!file) {
    return null;
  }

  if (typeof file !== 'string') {
    if (!file.url) {
      return null;
    }

    fileString = file.url;
  }

  return { preview: fileString };
};
