import Form from 'modules/consumer-invite/v2/ConsumerInvite/Modal/Form';
import {
  ContentWrapper,
  ModalCloseIcon,
  ModalWrapper,
} from 'modules/consumer-invite/v2/ConsumerInvite/Modal/style';
import SendSuccessMessage from 'modules/consumer-invite/v2/ConsumerInvite/SendSuccessMessage';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import React, { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import getAccountTypeName from 'utils/getAccountTypeName';

const Modal = (props): ReactElement => {
  const { applicationType, dispatch, onCloseModal } = props;
  const [email, setEmail] = useState('');
  const [isInviteSent, setIsInviteSent] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onSuccessSendCallback = (email) => {
    setEmail(email);
    setIsInviteSent(true);
  };

  const onErrorCallback = (error) => {
    console.error(error);
    setHasError(true);
  };

  let title = `Send application - ${getAccountTypeName(applicationType)}`;
  let content = (
    <Form
      applicationType={applicationType}
      dispatch={dispatch}
      onErrorCallback={onErrorCallback}
      onSuccessSendCallback={onSuccessSendCallback}
    />
  );

  if (isInviteSent) {
    title = 'Application sent!';
    content = (
      <div className="has-text-centered">
        <SendSuccessMessage email={email} />
      </div>
    );
  }

  if (hasError) {
    title = 'Something went wrong';
    content = (
      <div className="has-text-centered">
        Please refresh the page and try again.
      </div>
    );
  }

  return (
    <ModalWrapper>
      <SquareModal title={title} size="small">
        <ModalCloseIcon className="icon is-clickable" onClick={onCloseModal}>
          <i className="fas fa-times-circle fa-lg"></i>
        </ModalCloseIcon>
        <ContentWrapper>{content}</ContentWrapper>
      </SquareModal>
    </ModalWrapper>
  );
};

export default connect()(Modal);
