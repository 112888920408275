export const PEOPLE_HEADER = {
  company: 'Director',
  partnership: 'Partner',
  personal: 'Individual',
  sole_trader: 'Sole trader',
  trust: 'Trustee',
  corporate_trust: 'Director',
};

export const TRUSTEE_LABEL_TYPE_MAP = {
  trustee: 'Individual trustee',
  individual: 'Individual trustee',
  independent_trustee: 'Independent trustee',
  company: 'Corporate trustee',
};

export const COMPANY_NUMBER_LABEL_MAP = {
  AU: 'ACN',
  NZ: 'NZCN',
};
