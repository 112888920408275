import {
  StyledBlueSpan,
  StyledDarkGreySpan,
} from 'modules/reporting/components/Reporting/ApplicationName/styles';
import React, { ReactElement } from 'react';
import { capitalizeSentence } from 'utils/formatting';

interface IProps {
  isFloating?: boolean;
  applicationName: string;
  versionNumber: string;
}

const ApplicationName = ({
  isFloating,
  applicationName,
  versionNumber,
}: IProps): ReactElement => {
  const capitalizedSentence = capitalizeSentence(applicationName);
  const version =
    versionNumber && versionNumber > '1' ? `(V${versionNumber})` : '';
  return (
    <StyledDarkGreySpan isFloating={isFloating}>
      {capitalizedSentence} <StyledBlueSpan>{version}</StyledBlueSpan>
    </StyledDarkGreySpan>
  );
};

export default ApplicationName;
