import NumApplicationsBlock from 'modules/reporting/components/NumApplicationsBlock';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import React from 'react';

import { StatisticBlocksWrapper } from '../styles';

const StatisticsBlock = (props) => {
  const { activeBlock, summary, onClickBlock } = props;

  const onClick = (block) => onClickBlock({ active_block: block, page: 1 });

  return (
    <StatisticBlocksWrapper>
      <NumApplicationsBlock
        color="blue"
        title="Total alerted applications"
        value={summary.total_alerted_applications || 0}
        active={activeBlock === 'total'}
        isCompact
        isWide
        onClick={() => onClick('total')}
      />

      <NumApplicationsBlock
        color="red"
        title="Unactioned external"
        value={summary.total_unactioned_external || 0}
        active={activeBlock === 'unactioned_external'}
        isCompact
        isWide
        onClick={() => onClick('unactioned_external')}
      />

      <NumApplicationsBlock
        color="yellow"
        title="Unactioned internal"
        value={summary.total_unactioned_internal || 0}
        active={activeBlock === 'unactioned_internal'}
        isCompact
        isWide
        onClick={() => onClick('unactioned_internal')}
      />

      <NumApplicationsBlock
        color="medium-grey"
        title="Future task"
        value={summary.total_future_task || 0}
        active={activeBlock === 'future_task'}
        isCompact
        isWide
        onClick={() => onClick('future_task')}
      />

      <PopperTooltip
        title={
          'Any confirmed fraudulent accounts will be blocked and shared within the 1Centre community, to help create a safer trade environment.'
        }
      >
        <span>
          <NumApplicationsBlock
            color="black"
            title="Blocked"
            value={summary.total_blocked || 0}
            active={activeBlock === 'blocked'}
            isCompact
            isWide
            onClick={() => onClick('blocked')}
          />
        </span>
      </PopperTooltip>
    </StatisticBlocksWrapper>
  );
};

export default StatisticsBlock;
