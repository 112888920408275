import React, { Fragment } from 'react';
import { get } from 'lodash';

import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import isPresent from 'utils/isPresent';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import styles from 'modules/new-applications/css/BusinessDetails.css';
import BulkUploadPeople from './BulkUploadPeople';
import CorporateTrustPeople from './CorporateTrustPeople';

import {
  COMPANY_NUMBER_LABEL_MAP,
  PEOPLE_HEADER,
  TRUSTEE_LABEL_TYPE_MAP,
} from './constants';
import { isPeopleComponentVisible, retrieveDirectorAddress } from './helpers';
import { FEATURE_FLAGS } from 'conf';

const isTrustGuarantorsEnabled =
  FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS;

function AustraliaDirector(props) {
  const { application, index, person } = props;
  const residentialAddress = retrieveDirectorAddress({ application, person });

  return (
    <div>
      <LabeledContent label={`Director ${index}`} content={person.name} />
      <LabeledContent
        label="Email"
        content={person.email}
        isVisibleWhenEmpty={false}
      />
      <LabeledContent
        label="Residential address"
        content={residentialAddress}
      />
    </div>
  );
}

function NewZealandDirector(props) {
  const { index, person } = props;

  return (
    <div>
      <LabeledContent label={`Director ${index}`} content={person.name} />
      <LabeledContent
        label="Appointment date"
        content={person.formattedAppointedAt}
        isVisibleWhenEmpty={false}
      />
      <LabeledContent
        label="Residential address"
        content={person.formattedResidentialAddress}
      />
    </div>
  );
}

function Director(props) {
  const { application } = props;

  if (application.region === 'NZ') {
    return <NewZealandDirector {...props} />;
  }

  return <AustraliaDirector {...props} />;
}

function GenericPerson(props) {
  const { index, label, person } = props;

  return (
    <div>
      <LabeledContent label={`${label} ${index}`} content={person.name} />
      <LabeledContent label="Email" content={person.email} />
    </div>
  );
}

function CorporateTrustDirector(props) {
  const { index, label, person } = props;
  const { name, email } = person;

  return (
    <div>
      <LabeledContent label={`${label} ${index}`} content={name} />
      <LabeledContent label="Email" content={email} />
    </div>
  );
}

function Trustee(props) {
  const { index, label, person = {} } = props;
  const {
    name,
    email,
    companyNumber,
    legalType: legalTypeFromPerson,
    region,
    entityName,
  } = person;
  const legalType = legalTypeFromPerson ? legalTypeFromPerson : 'company';
  const isCorporateTrustee = legalType === 'company';
  const trusteeName = entityName || name;

  return (
    <div>
      <LabeledContent label={`${label} ${index}`} content={trusteeName} />
      {!isCorporateTrustee && <LabeledContent label="Email" content={email} />}
      {isCorporateTrustee && (
        <LabeledContent
          label={COMPANY_NUMBER_LABEL_MAP[region]}
          content={companyNumber}
        />
      )}
      <LabeledContent
        label="Trustee type"
        content={TRUSTEE_LABEL_TYPE_MAP[legalType]}
      />
    </div>
  );
}

function Individual(props) {
  const { index, person } = props;

  return (
    <div>
      <LabeledContent label={`Individual ${index}`} content={person.name} />
      <LabeledContent
        label="Previous last name"
        content={person.previousLastName}
      />
      <LabeledContent label="Email" content={person.email} />
      <LabeledContent
        label="Phone number"
        content={person.formattedPhoneNumber}
      />
      <LabeledContent
        label="Residential address"
        content={person.fullResidentialAddress}
      />
      <LabeledContent
        label="Postal address"
        content={person.fullPostalAddress}
      />
      <LabeledContent label="Employer" content={person.employer} />
      <LabeledContent label="Position" content={person.position} />
    </div>
  );
}

function SoleTrader(props) {
  const { index, person } = props;

  return (
    <div>
      <LabeledContent label={`Sole trader ${index}`} content={person.name} />
      <LabeledContent label="Email" content={person.email} />
      <LabeledContent
        label="Residential address"
        content={person.fullResidentialAddress}
      />
    </div>
  );
}

export const PEOPLE_COMPONENT = {
  company: Director,
  partnership: GenericPerson,
  personal: Individual,
  sole_trader: SoleTrader,
  trust: isTrustGuarantorsEnabled ? Trustee : GenericPerson,
  corporate_trust: CorporateTrustDirector,
};

export function FlexContent(props) {
  const { children } = props;

  return <div className={styles.flex_contents}>{children}</div>;
}

function People(props) {
  const { application } = props;
  const { legalType } = application;

  const people = get(application, 'consumer.people', []);

  if (legalType === 'sole_trader') {
    const authorisation =
      application.authorisations.find((auth) => auth.isApplicant) || {};

    const { email, firstName, lastName } = authorisation;

    const soleTrader = {
      email,
      fullResidentialAddress: get(
        authorisation,
        'signature.attributes.full_residential_address'
      ),
      name: firstName && `${firstName} ${lastName}`,
    };

    if (people.length === 0) {
      people.push(soleTrader);
    }
  }

  if (
    !isPeopleComponentVisible({
      applicationType: application.applicationType,
      hasGuarantors: isPresent(application.guarantors),
      legalType,
    })
  ) {
    return null;
  }

  const label = PEOPLE_HEADER[legalType];
  const Component = PEOPLE_COMPONENT[legalType];
  const peopleList = legalType === 'trust' ? application.trustPeople : people;
  const peopleToRender = isTrustGuarantorsEnabled ? peopleList : people;
  const peopleComponents = peopleToRender.map((person, index) => (
    <Component
      key={`person-${index + 1}`}
      index={index + 1}
      application={application}
      label={label}
      person={person}
    />
  ));

  const oldShouldRenderNull = peopleToRender.length === 0;
  const newShouldRenderNull =
    !application.hasCorporateTrust && oldShouldRenderNull;

  const shouldRenderNull = isTrustGuarantorsEnabled
    ? newShouldRenderNull
    : oldShouldRenderNull;

  if (shouldRenderNull) {
    return null;
  }

  return (
    <Fragment>
      <FixedContent header={label}>
        <FlexContent>{peopleComponents}</FlexContent>
      </FixedContent>
      {application.hasCorporateTrust && (
        <CorporateTrustPeople application={application} />
      )}
      {application.isBulkApplication && (
        <BulkUploadPeople
          hasBulkuploadGuarantors={application.hasBulkuploadGuarantors}
          bulkUploadGuarantors={application.bulkUploadGuarantors}
          consumer={application.consumer}
        />
      )}
    </Fragment>
  );
}

export default People;
