import React, { useState, useEffect, useMemo } from 'react';
import Button from 'modules/shared/components/inputs/Button';
import MenuList from 'containers/next-gen/components/shared/MenuList';
import { IMenuItem } from 'containers/next-gen/types/types';
import RulesetContent from './RulesetContent';
import { connect } from 'react-redux';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem as MaterialMenuItem } from 'mui-latest';

import './AutoDecisioning.scss';
import { loadAutoDecisions, toggleRuleSet } from 'modules/addons/actions';
import { setCurrentRuleset } from '../actions';
import { bindActionCreators } from 'redux';
import { IAutoDecision } from './RulesetContent/type';
import { addNewAutoDecision, sortRuleSet } from 'modules/addons/actions';
import moment from 'moment';
import { StyledIconButton } from './style';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';

const AutoDecisioning = ({
  autoDecisions,
  actions,
  isReadonly,
}): JSX.Element => {
  const [rulesetList, setRulesetlist] = useState<IMenuItem[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedVersionNumber, setSelectedVersionNumber] = useState<
    number | null
  >(null);

  const [anchorEl, setAnchorEl] = React.useState<null | Record<
    PropertyKey,
    HTMLElement
  >>(null);

  useEffect(() => {
    actions.loadAutoDecisions();
  }, []);

  const isViewingHistoryVersion = selectedVersionNumber !== null;

  const versionCount =
    autoDecisions?.[activeIndex]?.attributes?.history_data?.length || 0;

  let versions = Array.from(Array(versionCount).keys())
    .reverse()
    .map((i) => i + 1)
    .filter((versionNumber) => {
      if (isViewingHistoryVersion) {
        // When viewing a history version, no need to display the version
        // number for the selected version
        return versionNumber !== selectedVersionNumber;
      }
      return true;
    });

  if (isViewingHistoryVersion) {
    // When viewing latet version, we need to show that version number in the
    // version list menu.
    // But when viewing a previous version,we need to show the latest version
    // number too.
    versions.unshift(versionCount + 1);
  }

  const open = useMemo(() => {
    return Boolean(anchorEl && anchorEl[activeIndex]);
  }, [anchorEl, versions, activeIndex]);

  const handleMenuOpen = (e: React.MouseEvent<HTMLElement>, menuIndex) => {
    setAnchorEl({ [menuIndex]: e.currentTarget });
  };

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { version } = e?.currentTarget?.dataset;

    if (version) {
      const versionNumber = +version;
      if (versionNumber === selectedVersionNumber) {
        return;
      }

      // Check if user has selected the latest version from the version list menu
      const hasSelectedLatestVersion = versionNumber === versionCount + 1;

      setRulesetlist((ruleset) => {
        return ruleset.map((r) => {
          if (r.id !== autoDecisions[activeIndex].id) return r;
          return {
            ...r,
            title: hasSelectedLatestVersion
              ? autoDecisions[activeIndex].attributes.name
              : `${autoDecisions[activeIndex].attributes.name} V${versionNumber}`,
          };
        });
      });
      setSelectedVersionNumber(hasSelectedLatestVersion ? null : versionNumber);
    }

    setAnchorEl(null);
  };

  const handleListClick = (index) => {
    if (activeIndex === index) return;

    setActiveIndex(index);
    setAnchorEl(null);

    if (selectedVersionNumber !== null) {
      setSelectedVersionNumber(null);
    }
  };

  useEffect(() => {
    setRulesetlist(
      autoDecisions.map((autoDecision) => {
        const { attributes, id, is_new } = autoDecision;
        const { on_hold, paused, current_version, name } = attributes;

        return {
          status: on_hold ? 'paused' : paused || is_new ? 'off' : 'blue',
          statusName: on_hold ? 'Paused' : paused || is_new ? 'Off' : 'On',
          placeHolder: 'Ruleset name',
          title:
            id && current_version > 1 ? `${name} V${current_version}` : name,
          id,
        };
      })
    );
  }, [autoDecisions]);

  const addNewRuleset = () => {
    setActiveIndex(0);
    actions.addNewAutoDecision();
  };

  const selectedVersionIndex = isViewingHistoryVersion
    ? versionCount - selectedVersionNumber
    : null;

  const customIndicator = useMemo(() => {
    if (selectedVersionIndex === null) {
      return null;
    }

    return (
      <span>
        {moment(
          autoDecisions?.[activeIndex]?.attributes?.history_data?.[
            selectedVersionIndex
          ]?.updated_at
        ).format('DD/MM/YYYY HH:mm')}
      </span>
    );
  }, [selectedVersionNumber]);
  const autoDecision = autoDecisions[activeIndex];

  const renderOnOff = () => {
    const label = autoDecision.attributes.paused ? 'Turn on' : 'Turn off';
    return label;
  };

  const handleOnOff = () => {
    actions.toggleRuleSet(
      autoDecision.id,
      autoDecision.attributes,
      actions.loadAutoDecisions
    );
    setAnchorEl(null);
  };

  const addRulesetDisabled =
    (autoDecisions.length > 0 &&
      (!autoDecisions[0].id || autoDecisions[0].is_new)) ||
    isReadonly;

  const isRulesetPaused = autoDecisions[activeIndex]?.attributes.on_hold;

  const hasVersions = versions.length > 0;

  // For the kebab button of a ruleset shoud be shown, either
  // there should be history versions, or
  // the ruleset should not be in paused state.
  const showRulesetMenuButton = hasVersions || !isRulesetPaused;

  return (
    <div className="autoDecisioning">
      <Button
        disabled={addRulesetDisabled}
        text="+ Add ruleset "
        handleClick={addNewRuleset}
      />
      {rulesetList.length > 0 && (
        <div className="autoDecisioning__container">
          <MenuList
            key={activeIndex}
            menuItems={rulesetList}
            activeIndex={activeIndex}
            setActiveIndex={handleListClick}
            onSort={actions.sortRuleSet}
            rightIconButton={
              showRulesetMenuButton ? (
                <>
                  <StyledIconButton
                    onClick={(e) => handleMenuOpen(e, activeIndex)}
                    disabled={autoDecisions[activeIndex].is_new || isReadonly}
                    disableRipple
                  >
                    <MoreVertIcon />
                  </StyledIconButton>
                  <Menu
                    keepMounted
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl && anchorEl[activeIndex]}
                    getContentAnchorEl={null}
                    transformOrigin={{
                      vertical: -40,
                      horizontal: 'left',
                    }}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        width: 110,
                      },
                    }}
                  >
                    {!isViewingHistoryVersion && !isRulesetPaused && (
                      <MaterialMenuItem onClick={handleOnOff}>
                        {renderOnOff()}
                      </MaterialMenuItem>
                    )}
                    {versions.length > 0 &&
                      versions.map((version) => (
                        <MaterialMenuItem
                          key={version}
                          data-version={version}
                          onClick={handleClose}
                        >
                          Version {version}
                        </MaterialMenuItem>
                      ))}
                  </Menu>
                </>
              ) : null
            }
            customIndicator={customIndicator}
          />

          <RulesetContent
            rulesetIndex={activeIndex}
            selectedRulesetVersionIndex={selectedVersionIndex}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  autoDecisions: state.add_ons.auto_decisions,
  isReadonly: !isFeatureEditEnabled('1CAD'),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addNewAutoDecision,
      loadAutoDecisions,
      setCurrentRuleset,
      toggleRuleSet,
      sortRuleSet,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoDecisioning);
