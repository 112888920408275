import api from 'api';
import get from 'lodash.get';
import abnEntityTypes from 'constants/abnEntityTypes';
import { IOnSetValuesCallbackAttributes } from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/types';
import isBlank from 'utils/isBlank';
import { processError } from 'utils';

export const ACN_LENGTH = 9;
export const ABN_LENGTH = 11;

export const onRetrieveDetailsWithCompanyNumber = async (
  currentUser: {
    accessToken: string;
  },
  {
    companyNumber,
    onError,
    onEmptyResultCallback,
    onEndEvent,
    onSetConsumerIdToJoin,
    onSetValuesCallback,
    onStartEvent,
  }: {
    companyNumber: string;
    onError: (errorMessage: string) => void;
    onEmptyResultCallback?: () => void;
    onEndEvent: () => void;
    onSetConsumerIdToJoin?: (
      consumerId: string,
      belongsToEntity: boolean
    ) => void;
    onSetValuesCallback: (attributes: IOnSetValuesCallbackAttributes) => void;
    onStartEvent: () => void;
  }
): Promise<void> => {
  if (companyNumber.length < ACN_LENGTH) {
    onResetFieldsExceptCompanyNumber(onSetValuesCallback);
    return;
  }

  if (onStartEvent) {
    onStartEvent();
  }

  const acnAPI = api('company_search', currentUser.accessToken);

  try {
    const response = await acnAPI.getAsicDetails(companyNumber);
    const asicDetails = get(response, 'data.data.asic_details', {});

    if (isBlank(asicDetails)) {
      onInvalidCompanyNumber({
        onEmptyResultCallback,
        onEndEvent,
        onSetValuesCallback,
      });

      return;
    }

    if (onSetConsumerIdToJoin) {
      const belongsToEntity = get(response, 'data.belongs_to_entity', false);
      const consumerId = get(
        response,
        'data.data.relationships.entity.data.id'
      );

      onSetConsumerIdToJoin(consumerId, belongsToEntity);
    }

    // ASIC without ABN sometimes returns "0" which can screw
    // the search result for ABN.
    if (asicDetails.business_number === '0') {
      delete asicDetails.business_number;
    }

    if (onSetValuesCallback) {
      onSetValuesCallback({
        businessNumber: asicDetails.business_number,
        companyName: asicDetails.name,
        entityName: asicDetails.name,
        legalType: 'company',
        registeredAt: asicDetails.registration_date,
        tradingName: '',
      });
    }
  } catch (error: any) {
    console.error(error);

    const { errorMessage, httpStatusCode } = processError(error);

    if (httpStatusCode === 400) {
      onError && onError(errorMessage);
      return;
    }

    onInvalidCompanyNumber({
      onEmptyResultCallback,
      onEndEvent,
      onSetValuesCallback,
    });
  } finally {
    if (onEndEvent) {
      onEndEvent();
    }
  }
};

export const onRetrieveDetailsWithBusinessNumber = async (
  currentUser: {
    accessToken: string;
  },
  {
    businessNumber,
    onError,
    onEmptyResultCallback,
    onEndEvent,
    onSetConsumerIdToJoin,
    onSetValuesCallback,
    onStartEvent,
  }: {
    businessNumber: string;
    onError: (errorMessage: string) => void;
    onEmptyResultCallback?: () => void;
    onEndEvent?: () => void;
    onSetConsumerIdToJoin?: (
      consumerId: string,
      belongsToEntity: boolean
    ) => void;
    onSetValuesCallback?: (attributes: IOnSetValuesCallbackAttributes) => void;
    onStartEvent?: () => void;
  }
): Promise<void> => {
  if (businessNumber.length < ABN_LENGTH) {
    onResetFieldsExceptBusinessNumber(onSetValuesCallback);
    return;
  }

  if (onStartEvent) {
    onStartEvent();
  }

  const abnAPI = api('abn', currentUser.accessToken);

  try {
    const response = await abnAPI.getDetails(businessNumber);
    const abnDetails = get(response, 'data.data.abn_details', {});

    if (isBlank(abnDetails) || abnDetails.abn === '') {
      onInvalidBusinessNumber({
        onEmptyResultCallback,
        onEndEvent,
        onSetValuesCallback,
      });

      return;
    }

    if (onSetConsumerIdToJoin) {
      const belongsToEntity = get(response, 'data.belongs_to_entity', false);
      const consumerId = get(
        response,
        'data.data.relationships.entity.data.id'
      );

      onSetConsumerIdToJoin(consumerId, belongsToEntity);
    }

    if (onSetValuesCallback) {
      onSetValuesCallback({
        companyName: abnDetails.entity_name,
        companyNumber: abnDetails.acn,
        entityName: abnDetails.entity_name,
        legalType: abnEntityTypes[abnDetails.entity_type_code],
        registeredAt: abnDetails.abn_status_effective_from,
        tradingName: abnDetails.business_name[0],
      });
    }
  } catch (error: any) {
    console.error(error);

    const { errorMessage, httpStatusCode } = processError(error);

    if (httpStatusCode === 400) {
      onError && onError(errorMessage);
      return;
    }

    onInvalidBusinessNumber({
      onEmptyResultCallback,
      onEndEvent,
      onSetValuesCallback,
    });
  } finally {
    if (onEndEvent) {
      onEndEvent();
    }
  }
};

const onResetFieldsExceptCompanyNumber = (
  onSetValuesCallback?: (attributes: IOnSetValuesCallbackAttributes) => void
): void => {
  if (onSetValuesCallback) {
    onSetValuesCallback({
      businessNumber: '',
      companyName: '',
      entityName: '',
      legalType: '',
      registeredAt: '',
      tradingName: '',
    });
  }
};

const onInvalidCompanyNumber = ({
  onEmptyResultCallback,
  onEndEvent,
  onSetValuesCallback,
}: {
  onEmptyResultCallback?: () => void;
  onEndEvent?: () => void;
  onSetValuesCallback?: (attributes: IOnSetValuesCallbackAttributes) => void;
}) => {
  onResetFieldsExceptCompanyNumber(onSetValuesCallback);

  if (onEmptyResultCallback) {
    onEmptyResultCallback();
  }

  if (onEndEvent) {
    onEndEvent();
  }
};

const onResetFieldsExceptBusinessNumber = (
  onSetValuesCallback?: (attributes: IOnSetValuesCallbackAttributes) => void
) => {
  if (onSetValuesCallback) {
    onSetValuesCallback({
      companyName: '',
      companyNumber: '',
      entityName: '',
      legalType: '',
      registeredAt: '',
      tradingName: '',
    });
  }
};

const onInvalidBusinessNumber = ({
  onEmptyResultCallback,
  onEndEvent,
  onSetValuesCallback,
}: {
  onEmptyResultCallback?: () => void;
  onEndEvent?: () => void;
  onSetValuesCallback?: (attributes: IOnSetValuesCallbackAttributes) => void;
}) => {
  onResetFieldsExceptBusinessNumber(onSetValuesCallback);

  if (onEmptyResultCallback) {
    onEmptyResultCallback();
  }

  if (onEndEvent) {
    onEndEvent();
  }
};
