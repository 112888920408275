/* eslint-disable no-debugger */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable camelcase */
import MaterialButton from '@material-ui/core/Button';
import get from 'lodash.get';
import AntiFraudAddonVersionModel from 'models/AntiFraudAddonVersionModel';
import ApplicationModel from 'models/ApplicationModel';
import { isAustraliaDirectorDetailsVisible } from 'modules/consumer-onboarding/helpers';
import ApplicationInaccessible from 'modules/consumer-onboarding/v2/ApplicationInaccessible';
import { INACCESSIBLE_MESSAGE } from 'modules/consumer-onboarding/v2/hooks/useApplicationAccessibilityState';
import { GridContainer } from 'modules/request-permission-flow/containers/Layout/styles';
import Overlay from 'modules/shared/components/top/Overlay';
import Footer from 'modules/shared/components/v2/Footer';
import ArrowButton from 'modules/shared/components/widgets/interactive/ArrowButton';
import ApplicationProgressDots from 'modules/shared/components/widgets/static/ApplicationProgressDots';
import Loader from 'modules/shared/components/widgets/static/Loader';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import { paginate } from 'modules/shared/helpers/additionalSectionHelper';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import React, { Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import ReactTimeout from 'react-timeout';
import { debounce } from 'throttle-debounce';
import getApplicableAntiFraudRulesForApplicationFlow from 'utils/anti-fraud/getApplicableAntiFraudRulesForApplicationFlow';
import { customTermsUrl } from 'utils/extractAttachmentUrl';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import { loadingLiveChat } from 'utils/liveChat.js';

import { TERMS_AND_CONDITIONS } from '../../../constants';
import { identityComponentComplete } from '../../identity/actions';
import { addtionalComponentComplete } from '../actions/additional';
import {
  saveCardholders,
  setCardholdersCountCompletedState,
} from '../actions/cards';
import { extrasComponentComplete } from '../actions/extras';
import { financialsComponentComplete } from '../actions/financials';
import { guarantorComponentComplete } from '../actions/guarantors';
import {
  businessComponentComplete,
  setCorporateTrusteeDirectorsOfAssociatedEntity,
  setCorporateTrusteeShareholders,
  setEntityPartyDetailsData,
} from '../actions/onboarding';
import { paperlessComponentComplete } from '../actions/paperless';
import {
  updateAddonAnswerData,
  updateApplicationAddonRules,
  updateApplicationData,
  updateApplicationExtraData,
  updateBusinessSection,
  updateGuarantorsData,
  updateIdentificationData,
  updateTradeReferencesData,
} from '../actions/review';
import {
  getAppColorPalette,
  loadApplicationBusiness,
  loadApplicationSections,
  loadApplicationSignatureFromPartyDetails,
  loadApplicationSupplier,
  loadClientPeopleDetails,
  setToInprogress,
} from '../actions/section';
import { traderefComponentComplete } from '../actions/trade-reference';
import { componentFactory } from '../components';
import ApplicationStart from './ApplicationStart.js';
import styles from './css/ConsumerApplication.css';
import { getIsAntifraudAml } from 'modules/identity/components/utils';
import ZendeskChat from 'modules/shared/components/widgets/interactive/ZendeskChat';
import { FEATURE_FLAGS } from 'conf';
import { deleteGuarantor } from 'modules/guarantor/actions';

import api from 'api';
import ConfirmAcnModal from '../components/onboarding/v2/ConfirmAcnModal';

const isCat0Enabled = FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_0;
const isTrustFlowEnabled = FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS;

// eslint-disable-next-line no-undef
const ConsumerOnBoarding = createClass({
  UNSAFE_componentWillMount() {
    const { params, current_user } = this.props;
    const userData = get(current_user, 'data.data');

    if (isPresent(userData) && !FEATURE_FLAGS.FEATURE_FLAG_ZENDESK_CHAT) {
      const userAttributes = {
        email: userData.attributes.email,
        firstName: userData.attributes.first_name,
        id: userData.id,
        lastName: userData.attributes.last_name,
        restoreId: userData.attributes.freshchat_restore_id,
      };
      loadingLiveChat('consumer', userAttributes);
    }

    // TODO: Not sure where to redirect this?
    if (!(params.section === 'start')) {
      const locationSearch = get(this.props, 'location.search', '');
      const link = `/register/consumer/${params.application_id}/start${locationSearch}`;
      browserHistory.push(link);
    }
  },

  /**
   * Helper function used to format the path to this section/component
   */
  UNSAFE_componentWillReceiveProps(newProps) {
    const { sections, component, location, component_index } = this.props;

    if (location.pathname !== newProps.location.pathname) {
      const current_location = location.pathname;
      const new_location = newProps.location.pathname;
      const links = this.getSectionLinkArray();
      const section_links = Object.keys(sections);
      if (component) {
        if (isPresent(component_index) && isPresent(newProps.component_index)) {
          if (this.props.section === newProps.section) {
            // eslint-disable-next-line max-depth
            if (component_index > newProps.component_index) {
              this.setState({ animation: 'scroll-left' });
            } else {
              if (FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS) {
                const sublinkScrollDirection =
                  this.getSublinkDirection(newProps);
                if (sublinkScrollDirection) {
                  this.setState({ animation: sublinkScrollDirection });
                } else {
                  this.setState({ animation: 'scroll-right' });
                }
              } else {
                this.setState({ animation: 'scroll-right' });
              }
            }
          } else {
            // eslint-disable-next-line max-depth
            if (
              section_links.indexOf(this.props.section) >
              section_links.indexOf(newProps.section)
            ) {
              this.setState({ animation: 'scroll-left' });
            } else {
              this.setState({ animation: 'scroll-right' });
            }
          }
        } else {
          if (this.props.section === newProps.section) {
            // eslint-disable-next-line max-depth
            if (links.indexOf(current_location) > links.indexOf(new_location)) {
              this.setState({ animation: 'scroll-left' });
            } else {
              this.setState({ animation: 'scroll-right' });
            }
          } else {
            // eslint-disable-next-line max-depth
            if (
              section_links.indexOf(this.props.section) >
              section_links.indexOf(newProps.section)
            ) {
              this.setState({ animation: 'scroll-left' });
            } else {
              this.setState({ animation: 'scroll-right' });
            }
          }
        }
      }
    }
  },

  componentDidMount() {
    const {
      current_user,
      location: { search: locationSearch },
    } = this.props;

    if (isBlank(current_user.access_token)) {
      browserHistory.push(`/onboarding${locationSearch}`);
    }

    if (isPresent(current_user.data)) {
      this.loadApplicationDetails();
    }
  },

  componentDidUpdate(prevProps) {
    const previousCurrentUser = prevProps.current_user;
    const {
      current_user: currentUser,
      location: { search: locationSearch },
    } = this.props;

    if (isBlank(currentUser.access_token)) {
      browserHistory.push(`/onboarding${locationSearch}`);
    }

    if (isBlank(previousCurrentUser.data) && isPresent(currentUser.data)) {
      this.loadApplicationDetails();
    }
  },

  /**
   * Save current information of the onboarding flow to API.
   */
  componentGetDots() {
    // TODO
    // Remove the progress dots and T&C button methods
    return null;
    const { sections, section } = this.props;
    let section_index = 0;
    const links = [];
    const sort_sections = { ...sections };

    if (sections['trade-reference'] && sections['guarantors']) {
      delete sort_sections.guarantors;
    }

    // Not in a component so look at sections
    section_index = Object.keys(sort_sections).indexOf(section);
    if (section === 'guarantors') {
      section_index = 1;
    }
    Object.keys(sort_sections).forEach((item) => {
      if (item !== 'review') {
        const complete = this.isSectionComplete(item);
        if (this.state.page_complete) {
          links.push({
            complete,
            label: sort_sections[item].title,
            path: this.formatLink(item, sort_sections[item].default),
          });
        } else {
          links.push({
            complete,
            label: sort_sections[item].title,
          });
        }
      }
    });
    if (links.length > 1) {
      return (
        <ApplicationProgressDots
          type="horizontal"
          index={section_index}
          links={links}
          mobile_pages={this.getMobilePages()}
          mobile_index={this.getMobileIndex()}
        />
      );
    }
  },

  /**
   * Persist cardholders in the back-end every page to avoid problems when users
   * encounters issues at the middle of the section, i.e. when filling out 20
   * cardholders and they get stuck at 10, we don't want to user to re-do the
   * prior records
   */
  confirmReview() {
    const { dispatch, application, consumer_account } = this.props;
    dispatch(loadClientPeopleDetails());
    dispatch(
      updateApplicationData(
        application.id,
        `/register/consumer/${application.id}/review/terms`,
        consumer_account
      )
    );
  },

  /**
   * Returns the properties for the current section and component.
   */
  downloadPdfSupplierTnc() {
    const { application, supplier } = this.props;
    const hasCustomTerms =
      application.attributes.uses_custom_supplier_terms &&
      isPresent(get(application, 'attributes.custom_terms.url'));
    let pdf_url;

    if (hasCustomTerms) {
      pdf_url = customTermsUrl(application.attributes.custom_terms).url;
    } else {
      if (supplier) {
        pdf_url = TERMS_AND_CONDITIONS[supplier.attributes.region].supplier;
      }
    }

    return pdf_url;
    // const win = window.open("", "pdf_window");
    // win.open(pdf_url, "pdf_window");
  },

  /**
   * Helper function that returns links and buttons for the given
   * section/component.
   */

  formatLink(section, component, component_index, subComponent) {
    const { application, isCorporateTrusteeEnabled } = this.props;
    if (!application) {
      return null;
    }

    let link = `/register/consumer/${application.id}/${section}`;
    if (component) {
      if (
        isCorporateTrusteeEnabled &&
        component === 'party-details' &&
        !component_index
      ) {
        link = `${link}/${component}/0`;
      } else {
        link = `${link}/${component}`;
      }
    }
    if (component_index) {
      link = `${link}/${component_index}`;
    }
    if (subComponent) {
      link = `${link}/${subComponent}`;
    }
    return link;
  },

  getArrowLinks() {
    const {
      additionalTotalPage,
      application_submitting,
      application,
      cardholdersCount,
      component,
      subComponent,
      component_index,
      entity_type,
      financialsAddon,
      party_count,
      review_complete,
      section,
      submitting,
      isCorporateTrusteeEnabled,
      trusteeType,
      entity_region,
      subComponentIndex,
      corporateTrusteeDirectorCount,
      minimum_guarantees,
    } = this.props;
    // TODO: Review if this is working to hide the buttons
    if (component === 'overview' && section === 'business') {
      return [];
    }

    const { page_complete } = this.state;

    const links = [];
    const flow = this.getSectionLinks(section);
    const index = flow.findIndex((i) => i.component === component);
    let component_indexes;

    switch (component) {
      case 'referees':
        component_indexes = application.attributes.minimum_trade_references;
        break;
      case 'party-details':
        component_indexes = party_count;
        break;
      case 'card-details':
        component_indexes = cardholdersCount;
        break;
      case 'additional-details':
        component_indexes = additionalTotalPage;
        break;
    }

    if (!application) {
      return links;
    }

    if (section === 'review') {
      if (!component) {
        let checks = review_complete.checks;
        let extras_check = review_complete.extras;
        const financials = financialsAddon ? review_complete.financials : true;
        const no_required_gtors = entity_type !== 'company';
        if (application.attributes.application_type === 'cash') {
          checks = true;
          if (
            application.attributes.additional_questions_applies === 'credit' ||
            !application.attributes.additional_application_questions ||
            application.attributes.additional_application_questions.length === 0
          ) {
            extras_check = true;
          }
        } else if (
          (application.attributes.minimum_trade_references === 0 ||
            entity_type === 'personal') &&
          !application.attributes.requires_guarantees
        ) {
          checks = true;
        } else if (
          (application.attributes.minimum_trade_references === 0 ||
            entity_type === 'personal') &&
          no_required_gtors
        ) {
          checks = true;
        }
        if (
          review_complete.business &&
          checks &&
          extras_check &&
          financials &&
          this.isCardsReviewSectionComplete()
        ) {
          links.push(
            <ArrowButton
              css_style="button_small_font"
              key="next"
              text="Next"
              down={false}
              next={false}
              handleClick={this.confirmReview}
              icon="&darr;"
              loading_text="loading"
              disableOnLoading={true}
              loading={submitting || application_submitting}
            />
          );
        } else {
          links.push(
            <ArrowButton
              css_style="button_small_font"
              key="Next"
              down={true}
              next={false}
              text="Next"
              handleClick={() => {
                this.setState({ page_validation_start: true });
              }}
            />
          );
        }
      }
      return links;
    }
    if (component) {
      // Lower level component pages.
      if (
        section === 'business' &&
        (component === 'type' || component === 'other-type')
      ) {
        return links;
      }

      if (page_complete === false) {
        links.push(
          <ArrowButton
            key="next"
            css_style="button_small_font"
            down={false}
            next={false}
            text="Next"
            handleClick={() => {
              this.setState({ page_validation_start: true });
            }}
          />
        );
      } else {
        let link;
        if (
          isCorporateTrusteeEnabled &&
          component === 'party-details' &&
          trusteeType === 'corporate'
        ) {
          if (entity_region === 'NZ') {
            if (!minimum_guarantees) {
              if (!subComponent) {
                link = this.getNextLink(
                  flow,
                  component_index,
                  'director-details/0'
                );
                links.push(
                  <ArrowButton
                    key="next"
                    css_style="button_small_font"
                    down={false}
                    next={false}
                    text="Next"
                    handleClick={() => {
                      this.saveState(link);
                    }}
                  />
                );
              } else if (
                subComponentIndex <
                corporateTrusteeDirectorCount - 1
              ) {
                link = this.getNextLink(
                  flow,
                  component_index,
                  `director-details/${subComponentIndex + 1}`
                );
                links.push(
                  <ArrowButton
                    key="next"
                    css_style="button_small_font"
                    down={false}
                    next={false}
                    text="Next"
                    link={link}
                  />
                );
              } else if (component_index < party_count - 1) {
                links.push(
                  <ArrowButton
                    key="next"
                    css_style="button_small_font"
                    down={false}
                    next={false}
                    text="Next"
                    handleClick={() => {
                      this.saveState(this.getNextLink(flow, component_index));
                    }}
                    disableOnLoading={true}
                    loading={submitting}
                  />
                );
              } else {
                link = this.getNextLink(flow);
                links.push(
                  <ArrowButton
                    key="next"
                    css_style="button_small_font"
                    down={false}
                    next={false}
                    text="Next"
                    handleClick={() => {
                      this.saveState(link);
                    }}
                    disableOnLoading={true}
                    loading={submitting}
                  />
                );
              }
            } else if (component_index === party_count - 1) {
              link = this.getNextLink(flow);
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  handleClick={() => {
                    this.saveState(link);
                  }}
                  disableOnLoading={true}
                  loading={submitting}
                  loading_text="loading"
                />
              );
            } else {
              link = this.getNextLink(flow, component_index);
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  handleClick={() => {
                    this.saveState(link);
                  }}
                  disableOnLoading={true}
                  loading={submitting}
                  loading_text="loading"
                />
              );
            }
          } else if (!subComponent && entity_region === 'AU') {
            link = this.getNextLink(flow, component_index, 'director-count');
            links.push(
              <ArrowButton
                key="next"
                css_style="button_small_font"
                down={false}
                next={false}
                text="Next"
                handleClick={() => {
                  this.saveState(link);
                }}
                disableOnLoading={true}
                loading={submitting}
                loading_text="loading"
              />
            );
          } else if (subComponent === 'director-count') {
            link = this.getNextLink(
              flow,
              component_index,
              'director-details/0'
            );
            links.push(
              <ArrowButton
                key="next"
                css_style="button_small_font"
                down={false}
                next={false}
                text="Next"
                link={link}
              />
            );
          } else if (subComponent === 'director-details') {
            if (subComponentIndex < corporateTrusteeDirectorCount - 1) {
              link = this.getNextLink(
                flow,
                component_index,
                `director-details/${subComponentIndex + 1}`
              );
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  link={link}
                />
              );
            } else if (component_index < party_count - 1) {
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  handleClick={() => {
                    this.saveState(this.getNextLink(flow, component_index));
                  }}
                  disableOnLoading={true}
                  loading={submitting}
                />
              );
            } else {
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  handleClick={() => {
                    this.saveState(this.getNextLink(flow));
                  }}
                  disableOnLoading={true}
                  loading={submitting}
                />
              );
            }
          }

          // links.push(
          //   <ArrowButton
          //     key="next"
          //     css_style="button_small_font"
          //     down={false}
          //     next={false}
          //     text="Next"
          //     handleClick={() => {
          //       this.saveState(link);
          //     }}
          //     disableOnLoading={true}
          //     loading={submitting}
          //     loading_text="loading"
          //   />
          // );
        } else if (
          isPresent(component_index) &&
          component_index + 1 !== component_indexes
        ) {
          link = this.getNextLink(flow, component_index);

          if (section === 'cards') {
            links.push(
              <ArrowButton
                key="next"
                css_style="button_small_font"
                down={false}
                next={false}
                text="Next"
                handleClick={() => {
                  this.saveState(link);
                }}
                disableOnLoading={true}
                loading={submitting}
                loading_text="loading"
              />
            );
          } else {
            links.push(
              <ArrowButton
                key="next"
                css_style="button_small_font"
                down={false}
                next={false}
                text="Next"
                link={link}
              />
            );
          }
        } else {
          if (index === flow.length - 1) {
            if (section === 'identity' || section === 'extras') {
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  handleClick={() => {
                    this.saveState(this.getNextLink(flow));
                  }}
                  disableOnLoading={true}
                  loading={submitting}
                  loading_text="loading"
                />
              );
            } else {
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  handleClick={() => {
                    this.saveState(this.getNextLink(flow));
                  }}
                  disableOnLoading={true}
                  loading={submitting}
                />
              );
            }
          } else {
            if (section === 'guarantors' || section === 'trade-reference') {
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  link={this.getNextLink(flow)}
                  handleClick={() => {
                    this.saveState(this.getNextLink(flow));
                  }}
                />
              );
            } else if (section === 'cards' && component === 'card-order-form') {
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  handleClick={() => {
                    this.saveState(this.getNextLink(flow));
                  }}
                  disableOnLoading={true}
                  loading={submitting}
                  loading_text="loading"
                />
              );
            } else {
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  link={this.getNextLink(flow)}
                />
              );
            }
          }
        }
      }
    }

    return links;
  },
  getSublinkDirection(newProps) {
    const newRouteParams = newProps.routeParams;
    const currentRouteParams = this.props.routeParams;

    const nrSubIndex = newRouteParams.splat.split('/')[3];
    const crSubIndex = currentRouteParams.splat.split('/')[3];

    const newSublink = this.getPreviousSublinks(newProps);
    const sublink = this.getPreviousSublinks();

    if (!!sublink && !!newSublink) {
      if (nrSubIndex && crSubIndex) {
        return nrSubIndex > crSubIndex ? 'scroll-right' : 'scroll-left';
      } else {
        return nrSubIndex ? 'scroll-right' : 'scroll-left';
      }
    } else {
      if (!!!sublink && !!newSublink) {
        return 'scroll-right';
      }
      if (!!!newSublink && !!sublink) {
        return crSubIndex ? 'scroll-right' : 'scroll-left';
      }
    }
    return null;
  },
  getPreviousSublinks(newProps) {
    const { routeParams, component: currentComponent } = newProps || this.props;

    const [component, componentIndex, sublink, sublinkIndex] =
      routeParams.splat.split('/');

    if (currentComponent === component) {
      if (sublink === 'director-count') {
        return componentIndex;
      }

      if (sublink === 'director-details') {
        return sublinkIndex === '0'
          ? `${componentIndex}/director-count`
          : `${componentIndex}/${sublink}/${sublinkIndex - 1}`;
      }
    }
    return null;
  },
  getBackLinks() {
    const {
      application_submitting,
      component_index,
      component,
      consumer_account,
      entity_region,
      hasOneApplicableLegalTypes,
      section,
      submitting,
    } = this.props;
    const links = [];
    const flow = this.getSectionLinks(section);

    if (section === 'business' && component === 'overview') {
      return;
    }

    if (
      (!(consumer_account || hasOneApplicableLegalTypes) &&
        section === 'business' &&
        component === 'type') ||
      ((consumer_account || hasOneApplicableLegalTypes) &&
        section === 'business' &&
        component === 'other-details' &&
        entity_region === 'NZ') ||
      submitting ||
      application_submitting ||
      (section === 'review' && component)
    ) {
      return;
    }

    let link;
    if (isPresent(component_index) && component_index - 1 >= 0) {
      link = this.getPrevLink(flow, component_index);
    } else {
      link = this.getPrevLink(flow, null);
    }

    links.push(
      <ArrowButton
        key="back"
        css_style="button_small_font"
        prev={false}
        text="Back"
        link={link}
      />
    );

    return links;
  },
  setConfirmAcnModalState(state) {
    this.setState({ confirmAcnModalOpen: state });
  },
  confirmCorporateAcnModal() {
    const { component_index, section } = this.props;
    const flow = this.getSectionLinks(section);
    const link = this.getNextLink(flow, component_index, 'director-count');
    this.saveState(link);
  },

  // eslint-disable-next-line complexity
  getComponentProps() {
    const {
      application,
      completed,
      component_index,
      component,
      consumerName,
      consumer_account,
      hasOneApplicableLegalTypes,
      isProofOfAddressVisible,
      requiresID,
      section,
      sections,
      supplier_trading_name,
      subComponent,
      subComponentIndex,
      isCorporateTrusteeEnabled,
    } = this.props;
    const { page_validation_start, showGuarantorRemoveModal } = this.state;
    let complete = false;

    if (
      Object.keys(completed).includes(section) &&
      Object.keys(completed[section]).includes(component)
    ) {
      complete = completed[section][component];
    }
    return {
      ...sections[section],
      application,
      complete,
      component,
      component_index,
      consumerName,
      consumer_account,
      handleComplete: this.updateCompleteState,
      hasOneApplicableLegalTypes,
      isProofOfAddressVisible,
      page_validation_start,
      requiresID,
      section,
      setPageValidationStartFinish: this.setPageValidationStartFinish,
      supplierName: supplier_trading_name,
      showGuarantorRemoveModal,
      hideShowGuarantorModal: this.hideShowGuarantorModal,
      saveBusinessState: this.saveBusinessState,
      subComponent,
      subComponentIndex,
      isCorporateTrusteeEnabled,
    };
  },
  hideShowGuarantorModal() {
    this.setState({ showGuarantorRemoveModal: false });
  },

  getInitialState() {
    return {
      animation: 'scroll-right',
      loading: true,
      mobile_min_height: document.documentElement.clientHeight - 56,
      page_complete: false,
      page_validation_start: false,
      showLoader: true,
      showGuarantorRemoveModal: false,
    };
  },

  getMobileIndex() {
    const {
      section,
      component,
      component_index,
      minimum_trade_references,
      required_guarentees,
      requiresID,
      party_count,
      selected_type,
      entity_region,
      cardholdersCount,
      cardsAddon,
      financialsAddon,
      paperlessAddon,
      additionalTotalPage,
    } = this.props;
    const guarantee_count = required_guarentees > 0 ? 1 : 0;
    const identification_count = requiresID ? 2 : 1;
    const share_percentage_count =
      party_count > 0 && selected_type === 'partnership' ? 1 : 0;
    const applied_party_count =
      selected_type === 'partnership' ||
      selected_type === 'trust' ||
      (entity_region === 'AU' && selected_type === 'company') ||
      selected_type === 'personal'
        ? party_count
        : 0;

    const business_index =
      entity_region === 'AU' && selected_type !== 'personal' ? 3 : 2;
    const trade_reference_count =
      selected_type === 'personal' ? 0 : minimum_trade_references;

    const cardsSectionCount = cardsAddon ? 1 + (cardholdersCount || 0) : 0;

    const financialsSectionCount = financialsAddon ? 1 : 0;

    const additionalSectionCount = additionalTotalPage;

    const paperlessSectionCount = paperlessAddon ? 1 : 0;

    switch (section) {
      case 'business':
        if (component === 'other-details') {
          return 1;
        }
        // if (component === "company-details") {
        //   // AU Company
        //   return 2;
        // }
        if (component === 'party-details') {
          if (!component_index) {
            return business_index;
          }
          return business_index + component_index;
        }
        if (component === 'share') {
          return business_index + applied_party_count;
        }

        break;
      case 'trade-reference':
        if (component === 'referees') {
          if (!component_index) {
            return (
              business_index + applied_party_count + share_percentage_count
            );
          }
          if (component_index === 1) {
            return (
              business_index + 1 + applied_party_count + share_percentage_count
            );
          }
          if (component_index === 2) {
            return (
              business_index + 2 + applied_party_count + share_percentage_count
            );
          }
        }

        break;
      case 'guarantors':
        if (component === 'confirm') {
          if (!component_index) {
            return (
              business_index +
              trade_reference_count +
              applied_party_count +
              share_percentage_count
            );
          }
          if (component_index === 1) {
            return (
              business_index +
              1 +
              trade_reference_count +
              applied_party_count +
              share_percentage_count
            );
          }
        }

        break;
      case 'extras':
        if (component === 'questions') {
          return (
            business_index +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count
          );
        }

        break;
      case 'cards': {
        let cardsCount = 0;
        if (component === 'card-order-form') {
          cardsCount = 1;
          return (
            business_index +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count +
            cardsCount
          );
        }
        if (component === 'card-details') {
          cardsCount = component_index ? 2 + component_index : 2;
          return (
            business_index +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count +
            cardsCount
          );
        }
        break;
      }
      case 'financials': {
        return (
          business_index +
          guarantee_count +
          trade_reference_count +
          applied_party_count +
          share_percentage_count +
          cardsSectionCount +
          1
        );
      }
      case 'additional': {
        return (
          business_index +
          guarantee_count +
          trade_reference_count +
          applied_party_count +
          share_percentage_count +
          cardsSectionCount +
          financialsSectionCount +
          1
        );
      }
      case 'paperless': {
        return (
          business_index +
          guarantee_count +
          trade_reference_count +
          applied_party_count +
          share_percentage_count +
          cardsSectionCount +
          financialsSectionCount +
          additionalSectionCount +
          1
        );
      }
      case 'identity':
        if (component === 'confirm') {
          return (
            business_index +
            1 +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count +
            cardsSectionCount +
            financialsSectionCount +
            additionalSectionCount +
            paperlessSectionCount
          );
        }

        break;
      case 'review':
        if (!component) {
          return (
            business_index +
            1 +
            identification_count +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count +
            cardsSectionCount +
            financialsSectionCount +
            additionalSectionCount +
            paperlessSectionCount
          );
        }
        if (component === 'terms') {
          return (
            business_index +
            2 +
            identification_count +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count +
            cardsSectionCount +
            financialsSectionCount +
            additionalSectionCount +
            paperlessSectionCount
          );
        }
        if (component === 'terms') {
          return (
            business_index +
            3 +
            identification_count +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count +
            cardsSectionCount +
            financialsSectionCount +
            additionalSectionCount +
            paperlessSectionCount
          );
        }

        break;
      default:
        return '';
    }
    return '';
  },

  getMobilePages() {
    const {
      required_guarentees,
      minimum_trade_references,
      requiresID,
      party_count,
      selected_type,
      entity_region,
      cardholdersCount,
      cardsAddon,
      financialsAddon,
      paperlessAddon,
      additionalTotalPage,
    } = this.props;
    const guarantee_count = required_guarentees > 0 ? 1 : 0;
    const identification_count = requiresID ? 2 : 1;
    const share_percentage_count =
      party_count > 0 && selected_type === 'partnership' ? 1 : 0;
    const applied_party_count =
      selected_type === 'partnership' ||
      selected_type === 'trust' ||
      (entity_region === 'AU' && selected_type === 'company') ||
      selected_type === 'personal'
        ? party_count
        : 0;
    // const au_company_details_count =
    //   entity_region === "AU" && selected_type !== "personal" ? 1 : 0;
    const trade_reference_count =
      selected_type === 'personal' ? 0 : minimum_trade_references;

    const cardsSectionCount = cardsAddon ? 1 + (cardholdersCount || 0) : 0;
    const financialsSectionCount = financialsAddon ? 1 : 0;

    const additionalSectionCount = additionalTotalPage;

    const paperlessSectionCount = paperlessAddon ? 1 : 0;

    return (
      5 +
      identification_count +
      guarantee_count +
      trade_reference_count +
      applied_party_count +
      share_percentage_count +
      // au_company_details_count +
      cardsSectionCount +
      financialsSectionCount +
      additionalSectionCount +
      paperlessSectionCount
    );
  },

  getNextLink(flow, component_index, nextSubComponent) {
    const { sections, section, component } = this.props;
    const section_links = Object.keys(sections);
    const index = flow.findIndex((i) => i.component === component);

    // if (section === "identity" && index + 1 === flow.length) {
    //   return this.formatLink(section_links[section_links.indexOf(section) + 1]);
    // }

    if (nextSubComponent) {
      return this.formatLink(
        section,
        flow[index].component,
        component_index,
        nextSubComponent
      );
    }

    if (isPresent(component_index)) {
      return this.formatLink(
        section,
        flow[index].component,
        component_index + 1
      );
    }

    if (index === flow.length - 1) {
      const nextSectionComponents = this.getSectionLinks(
        section_links[section_links.indexOf(section) + 1]
      );
      return this.formatLink(
        section_links[section_links.indexOf(section) + 1],
        nextSectionComponents[0].component
      );
    }
    return this.formatLink(section, flow[index + 1].component);
  },

  /**
   * Get the button links for this section and flow.
   */
  // eslint-disable-next-line complexity
  getPrevLink(flow, component_index) {
    const { sections, section, component } = this.props;
    const section_links = Object.keys(sections);
    const index = flow.findIndex((i) => i.component === component);

    if (index < 0) return '#';

    const sectionLink = section_links[section_links.indexOf(section) - 1];
    const previousSectionComponents = this.getSectionLinks(sectionLink);

    // When in the guarantors/confirm section, skip the alert path a go straight
    // to the previous section.
    if (section === 'guarantors') {
      let previousComponent;

      // Skip the alert section of trade references
      if (sectionLink === 'trade-reference') {
        previousComponent = previousSectionComponents[0].component;
      } else {
        // eslint-disable-next-line max-len
        previousComponent =
          previousSectionComponents[previousSectionComponents.length - 1]
            .component;
      }

      return this.formatLink(sectionLink, previousComponent);
    }

    if (isPresent(component_index)) {
      if (FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS) {
        const prevSublink = this.getPreviousSublinks();
        if (prevSublink) {
          return this.formatLink(section, flow[index].component, prevSublink);
        }
      }

      return this.formatLink(
        section,
        flow[index].component,
        component_index - 1
      );
    }

    if (index > 0) {
      return this.formatLink(section, flow[index - 1].component);
    }

    if (previousSectionComponents.length > 0) {
      let previousComponentIndex = previousSectionComponents.length - 1;

      if (
        // Skip the alert section of trade references
        (component === 'confirm' || component === 'questions') &&
        sectionLink === 'trade-reference'
      ) {
        previousComponentIndex = 0;
      }

      return this.formatLink(
        sectionLink,
        previousSectionComponents[previousComponentIndex].component
      );
    }
  },

  getSectionLinkArray() {
    const { section } = this.props;
    const links = this.getSectionLinks(section);
    const link_array = [];
    links.forEach((item) => {
      link_array.push(this.formatLink(item.section, item.component));
    });
    return link_array;
  },

  getSectionLinks(section) {
    const {
      application,
      selected_type,
      entity_region,
      consumer_account,
      requiresID,
      hasOneApplicableLegalTypes,
      directorFromCreditCheckEnabled,
      entityDirectorsLoadingStatus,
    } = this.props;
    const links = [];

    if (!application) {
      return links;
    }
    switch (section) {
      case 'identity': {
        links.push({
          complete: this.isComponentComplete(section, 'confirm'),
          component: 'confirm',
          required: true,
          section,
        });
        break;
      }
      case 'business': {
        // TODO: refactor this part. This is no longer scalable.
        links.push({
          complete: this.isComponentComplete(section, 'overview'),
          component: 'overview',
          required: true,
          section,
        });
        links.push({
          complete: this.isComponentComplete(section, 'other-details'),
          component: 'other-details',
          required: true,
          section,
        });
        if (selected_type === 'company') {
          links.push({
            complete: this.isComponentComplete(section, 'child'),
            component: 'child',
            required: true,
            section,
          });
        }
        if (
          isAustraliaDirectorDetailsVisible({
            applicationType: application.attributes.application_type,
            entityRegion: entity_region,
            entityType: selected_type,
            requiresGuarantees: application.attributes.requires_guarantees,
            directorFromCreditCheckEnabled,
            entityDirectorsLoadingStatus,
          })
        ) {
          links.push({
            complete: this.isComponentComplete(section, 'party-details'),
            component: 'party-details',
            required: true,
            section,
          });
        }

        switch (selected_type) {
          case 'partnership':
          case 'trust':
            if (application.attributes.application_type === 'credit') {
              links.push({
                complete: this.isComponentComplete(section, 'party-details'),
                component: 'party-details',
                required: true,
                section,
              });
              if (selected_type === 'partnership') {
                links.push({
                  complete: this.isComponentComplete(section, 'share'),
                  component: 'share',
                  required: true,
                  section,
                });
              }
            }
            break;
          case 'personal':
            links.push({
              complete: this.isComponentComplete(section, 'party-details'),
              component: 'party-details',
              required: true,
              section,
            });
            if (selected_type === 'partnership') {
              links.push({
                complete: this.isComponentComplete(section, 'share'),
                component: 'share',
                required: true,
                section,
              });
            }
            break;
        }
        break;
      }
      case 'trade-reference': {
        links.push({
          complete: this.isComponentComplete(section, 'referees'),
          component: 'referees',
          required: true,
          section,
        });
        break;
      }
      case 'guarantors': {
        links.push({
          complete: this.isComponentComplete(section, 'confirm'),
          component: 'confirm',
          required: true,
          section,
        });
        break;
      }
      case 'extras': {
        links.push({
          complete: this.isComponentComplete(section, 'questions'),
          component: 'questions',
          required: true,
          section,
        });
        break;
      }
      case 'review': {
        links.push({ component: null, section });
        break;
      }
      case 'cards':
        links.push({
          complete: this.isComponentComplete(section, 'card-order-form'),
          component: 'card-order-form',
          required: true,
          section,
        });
        break;
      case 'additional':
        links.push({
          complete: this.isComponentComplete(section, 'additional-details'),
          component: 'additional-details',
          required: true,
          section,
        });
        break;
      case 'financials':
      case 'paperless': {
        links.push({
          complete: this.isComponentComplete(section, 'details'),
          component: 'details',
          required: true,
          section,
        });
        break;
      }
    }

    return links;
  },

  goToNextLink(section) {
    const flow = this.getSectionLinks(section);
    browserHistory.push(this.getNextLink(flow));
  },

  // eslint-disable-next-line complexity
  isCardsReviewSectionComplete() {
    const { cardholdersCount, review_complete: reviewComplete } = this.props;
    let isComplete = true;

    return isComplete;
  },

  isComponentComplete(section, component) {
    const { completed } = this.props;

    if (
      Object.keys(completed).includes(section) &&
      Object.keys(completed[section]).includes(component)
    ) {
      return completed[section][component];
    }

    return false;
  },

  /**
   * Helper function to get the dots along the top or the right.
   *
   * TODO: Make this check for completion.
   */
  isSectionComplete(section) {
    const links = this.getSectionLinks(section);
    let complete = true;

    links.forEach((item) => {
      if (item.required === true && item.complete !== true) {
        complete = false;
      }
    });

    return complete;
  },

  loadApplicationDetails() {
    const {
      dispatch,
      params: { application_id },
    } = this.props;

    this.scrollToRoute = debounce(200, true, this.scrollToRoute);

    dispatch(setToInprogress(application_id));

    const loadApplicationBusinessCallback = () =>
      this.setState({ loading: false });

    setTimeout(() => {
      dispatch(
        loadApplicationSupplier(application_id, () => {
          dispatch(loadApplicationSections(application_id));
          dispatch(loadApplicationBusiness(loadApplicationBusinessCallback));
        })
      );
      dispatch(loadClientPeopleDetails());
      dispatch(getAppColorPalette(application_id));
    }, 1000);
    window.scrollTo(0, 0);
  },

  loadingComplete() {
    this.setState({ showLoader: false });
  },

  redirect(redirectUrl) {
    browserHistory.push(redirectUrl);
  },

  render() {
    const {
      application,
      component,
      config,
      entity_type,
      supplier_hq_name,
      supplier_logo_url,
      start_complete,
      consumer_account,
      hasOneApplicableLegalTypes,
      loading_error,
      location,
      section,
      subComponent,
      sections,
      supplier_trading_name,
      warning,
      entityDirectorsLoadingStatus,
    } = this.props;
    const {
      animation,
      showLoader,
      loading,
      mobile_min_height,
      confirmAcnModalOpen,
    } = this.state;

    const props = this.getComponentProps();
    const child_component = componentFactory(
      section,
      component,
      props,
      subComponent
    );
    const dots = this.componentGetDots();
    let sectionFooter;

    let color;

    let enter;
    let enterActive;
    let leave;
    let leaveActive;
    const flow_wrap_styles = styles.flow_wrap;

    if (showLoader) {
      return (
        <Loader
          message="Please wait while we load your progress."
          loading={loading}
          handleComplete={this.loadingComplete}
        />
      );
    }

    switch (animation) {
      case 'scroll-left':
        enter = 'enterScrollLeft';
        enterActive = 'enterActiveScrollLeft';
        leave = 'leaveScrollLeft';
        leaveActive = 'leaveActiveScrollLeft';
        break;
      case 'scroll-right':
      default:
        enter = 'enterScrollRight';
        enterActive = 'enterActiveScrollRight';
        leave = 'leaveScrollRight';
        leaveActive = 'leaveActiveScrollRight';
        break;
    }
    let links = (
      <div className={styles.bottom_buttons}>
        <div>{this.getArrowLinks()}</div>
        <div>{this.getBackLinks()}</div>
      </div>
    );

    //hide buttons while directors are loading, currently showing a loader
    const directorsLoading =
      FEATURE_FLAGS.FEATURE_FLAG_AUS_AUTO_POPULATE_DIRECTORS &&
      entityDirectorsLoadingStatus === 'loading';
    if (directorsLoading) {
      links = null;
    }

    if (section === 'start' && component === null) {
      return (
        <React.Fragment>
          <ApplicationStart
            consumer_account={consumer_account}
            hasOneApplicableLegalTypes={hasOneApplicableLegalTypes}
            loading_error={loading_error}
            location={location}
            params={this.props.params}
            start_complete={start_complete}
            supplier_logo_url={supplier_logo_url}
            supplier_trading_name={supplier_trading_name}
          />

          {isMobile() && sectionFooter}
        </React.Fragment>
      );
    }

    if (section === 'review' && component === 'complete') {
      color = 'white';
    }

    const overlay = (
      <Fragment>
        <Overlay
          applicationChecklist={application.attributes.customer_check_list}
          applicationId={application.id}
          color={color}
          currentSection={section}
          entityType={entity_type}
          label={config.title}
          progressSections={sections}
          supplierLogoUrl={supplier_logo_url}
          supplierTerms={this.downloadPdfSupplierTnc()}
          tradingName={supplier_trading_name}
        />
        {FEATURE_FLAGS.FEATURE_FLAG_ZENDESK_CHAT && <ZendeskChat isBcFlow />}
      </Fragment>
    );

    const sectionHeadings = <div>{overlay}</div>;

    let warning_modal;

    if (warning) {
      warning_modal = (
        <SquareModal title={warning.title} size={warning.size}>
          <div>
            <p>{warning.details}</p>
            <div>
              <MaterialButton color="primary" onClick={warning.yesHandler}>
                Yes
              </MaterialButton>
              <MaterialButton color="primary" onClick={warning.noHandler}>
                No
              </MaterialButton>
            </div>
          </div>
        </SquareModal>
      );
    }

    let content = null;

    if (section === 'review') {
      /* review page has to be able to scroll !location.pathname.includes('review/') */
      content = (
        <div className={styles.review}>
          <div
            className={styles.container}
            style={{
              minHeight: isMobile()
                ? `${mobile_min_height}px`
                : 'calc(100vh - 30px)',
            }}
          >
            <div className={styles.application_content}>
              {child_component}
              {warning_modal}
              {links}
            </div>
          </div>
          {isMobile() && sectionFooter}
          <div className={styles.controls}>
            {sectionHeadings}
            {isMobile() && dots}
          </div>
        </div>
      );
    } else {
      const confirm_acn_modal =
        FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS &&
        confirmAcnModalOpen ? (
          <ConfirmAcnModal
            onCancel={() => this.setConfirmAcnModalState(false)}
            onConfirm={() => {
              this.confirmCorporateAcnModal();
            }}
          />
        ) : null;

      // eslint-disable-next-line no-negated-condition
      if (!isMobile()) {
        content = (
          <div className={styles.outer}>
            <div className={styles.flow}>
              <div className={flow_wrap_styles}>
                {confirm_acn_modal}
                <ReactCSSTransitionGroup
                  transitionName={{
                    enter: styles[enter],
                    enterActive: styles[enterActive],
                    leave: styles[leave],
                    leaveActive: styles[leaveActive],
                  }}
                  transitionEnterTimeout={600}
                  transitionLeaveTimeout={600}
                >
                  <div
                    className={styles.page}
                    key={this.props.location.pathname}
                  >
                    <div className={styles.container}>
                      <div className={styles.application_content}>
                        {child_component}
                        {warning_modal}
                        {links}
                      </div>
                    </div>
                  </div>
                </ReactCSSTransitionGroup>
              </div>
            </div>
            <div className={styles.controls}>
              {sectionHeadings}
              {dots}
            </div>
          </div>
        );
      } else {
        content = (
          <div>
            <div className={styles.page} key={this.props.location.pathname}>
              <div
                className={styles.container}
                style={{ minHeight: `${mobile_min_height}px` }}
              >
                <div className={styles.application_content}>
                  {child_component}
                  {warning_modal}
                  {links}
                </div>
              </div>
              {sectionFooter}
            </div>
            <div className={styles.controls}>
              {sectionHeadings}
              {dots}
            </div>
          </div>
        );
      }
    }

    if (application && get(application, 'attributes.lock_down_flag', false)) {
      content = (
        <GridContainer>
          {overlay}
          <ApplicationInaccessible
            header="Application inaccessible"
            message={`${INACCESSIBLE_MESSAGE.approval_in_progress} Please directly get in touch with ${supplier_trading_name} to discuss any questions you may have.`}
          />
          <Footer />
        </GridContainer>
      );
    }

    if (application && application.attributes.status === 'deleted') {
      content = (
        <GridContainer>
          {overlay}
          <ApplicationInaccessible
            header="Application inaccessible"
            message={`${INACCESSIBLE_MESSAGE.deleted} Please directly get in touch with ${supplier_trading_name} to discuss any questions you may have.`}
          />
          <Footer />
        </GridContainer>
      );
    }

    if (application && application.attributes.status === 'declined') {
      content = (
        <GridContainer>
          {overlay}
          <ApplicationInaccessible
            header="Application inaccessible"
            message={`${INACCESSIBLE_MESSAGE.declined} Please directly get in touch with ${supplier_trading_name} to discuss any questions you may have.`}
          />
          <Footer />
        </GridContainer>
      );
    }

    if (application && application.attributes.status === 'accepted') {
      content = (
        <GridContainer>
          {overlay}
          <ApplicationInaccessible
            header="Application inaccessible"
            message={`${INACCESSIBLE_MESSAGE.accepted} Please directly get in touch with ${supplier_trading_name} to discuss any questions you may have.`}
          />
          <Footer />
        </GridContainer>
      );
    }

    if (application && application.attributes.archived === true) {
      content = (
        <GridContainer>
          {overlay}
          <ApplicationInaccessible
            header="Application inaccessible"
            message={`${INACCESSIBLE_MESSAGE.archived} Please directly get in touch with ${supplier_trading_name} to discuss any questions you may have.`}
          />
          <Footer />
        </GridContainer>
      );
    }

    return <div>{content}</div>;
  },

  saveCardholdersState(redirectUrl) {
    const { application, component, component_index, dispatch } = this.props;

    dispatch(
      saveCardholders(() => {
        this.setPageValidationStartFinish();
        browserHistory.push(redirectUrl);
      })
    );
  },
  shouldDeleteGuarantor({
    entity_type,
    entity_region,
    component,
    completed,
    guarantorsToDelete,
  }) {
    const partyDetailsCompleted = get(completed, 'business.party-details');
    const hasGuarantorsToDelete =
      guarantorsToDelete && !!guarantorsToDelete.length;
    return (
      entity_type === 'company' &&
      entity_region === 'AU' &&
      component === 'party-details' &&
      partyDetailsCompleted &&
      hasGuarantorsToDelete
    );
  },
  async saveCorporateTrustee(nextLink) {
    const {
      dispatch,
      current_entity,
      entity_region,
      trustee,
      params: { application_id },
      component_index,
    } = this.props;
    if (trustee.corporate_trustee_id) {
      // If id is present, the entity is already created
      browserHistory.push(nextLink);
      return trustee.corporate_trustee_id;
    }
    const corporateTrusteePayload = {
      attributes: {
        business_number: trustee.corporate_trustee_business_number,
        company_number: trustee.corporate_trustee_company_number,
        entity_name: trustee.corporate_trustee_company_name,
        legal_type: 'company',
        association_type: 'corporate_trustee',
        application_id,
      },
    };
    const associatedEntityApi = api('associated_entities');
    const associatedEntityResponse =
      await associatedEntityApi.createAssociatedEntity(
        current_entity.id,
        corporateTrusteePayload
      );
    const associatedEntityId =
      associatedEntityResponse.data.associated_entity_id;

    dispatch(
      setEntityPartyDetailsData(
        component_index,
        'corporate_trustee_id',
        associatedEntityId
      )
    );

    if (entity_region === 'AU') {
      browserHistory.push(nextLink);
      return associatedEntityId;
    }

    const companySearchAPI = api('company_search');

    const companySearchResponse =
      await companySearchAPI.companyDetailsAlternative(
        trustee.corporate_trustee_business_number,
        { params: { region: entity_region } }
      );

    const people = companySearchResponse.data.data.attributes.people
      .filter((person) => person.relationship === 'Director')
      .map((person) => ({
        appointed_at: person.valid_from,
        first_name: person.first_name,
        middle_name: person.middle_name,
        last_name: person.last_name,
        name: person.name,
        email: null,
        full_address: person.address,
        legal_type: 'director',
        entity_id: current_entity.id,
      }));

    associatedEntityApi.createPeople(associatedEntityId, { people });

    const directorsForStore = people.map((person) => ({
      first_name: person.first_name,
      middle_name: person.middle_name,
      last_name: person.last_name,
      name: person.name,
      legal_type: 'director',
    }));
    dispatch(
      setCorporateTrusteeDirectorsOfAssociatedEntity(
        associatedEntityId,
        directorsForStore
      )
    );
    dispatch(
      setEntityPartyDetailsData(
        component_index,
        'corporate_trustee_director_count',
        people.length
      )
    );

    const { shareholders } = companySearchResponse.data.data.attributes;
    if (shareholders && shareholders.length) {
      dispatch(
        setCorporateTrusteeShareholders(associatedEntityId, shareholders)
      );
    }

    browserHistory.push(nextLink);
    return associatedEntityId;
  },
  async saveCorporateTrusteeDirectors(associatedEntityId, directors, nextLink) {
    const { current_entity } = this.props;
    const associatedEntityApi = api('associated_entities');
    const people = directors.map((person) => ({
      first_name: person.first_name,
      middle_name: person.middle_name,
      last_name: person.last_name,
      name: person.middle_name
        ? `${person.first_name} ${person.middle_name} ${person.last_name}`
        : `${person.first_name} ${person.last_name}`,
      email: person.email,
      is_applicant: person.is_applicant,
      legal_type: 'director',
      entity_id: current_entity.id,
    }));
    associatedEntityApi.createPeople(associatedEntityId, { people });
    browserHistory.push(nextLink);
  },
  confirmCorporateTrustees(trustees, lastAssociatedEntityId) {
    const { application, current_entity } = this.props;

    const corporateTrusteeIds = trustees
      .filter((trustee) => trustee.trustee_type === 'corporate')
      .map((trustee) => trustee.corporate_trustee_id || lastAssociatedEntityId);

    const payload = {
      application_id: application.id,
      associated_entity_ids: corporateTrusteeIds,
    };

    const entitiesAPI = api('entities');
    entitiesAPI.confirmAssociatedEntities(current_entity.id, payload);
  },
  saveBusinessState(deleteGuarantors = false, preventNavigation = false) {
    const {
      dispatch,
      section,
      application,
      current_entity,
      consumer_account,
      entity_type,
      guarantorsToDelete,
    } = this.props;
    const shouldDeleteGuarantors =
      !!guarantorsToDelete && !!guarantorsToDelete.length
        ? deleteGuarantors
        : false;
    if (shouldDeleteGuarantors) {
      guarantorsToDelete.forEach((guarantorId) =>
        dispatch(deleteGuarantor(guarantorId))
      );
    }

    const isCashApplication =
      application.attributes.application_type === 'cash';

    dispatch(
      updateBusinessSection({
        applicationId: application.id,
        consumerAccount: consumer_account,
        entityId: current_entity.id,
        entityType: entity_type,
        successCallback: () => {
          if (!isCashApplication) {
            !preventNavigation && this.goToNextLink(section);
          }
        },
      })
    );

    if (isCashApplication) {
      dispatch(
        updateApplicationAddonRules(current_entity.id, () => {
          !preventNavigation && this.goToNextLink(section);
        })
      );
    }

    dispatch(loadClientPeopleDetails());
    if (entity_type === 'personal') {
      dispatch(loadApplicationSignatureFromPartyDetails());
    }
  },
  async saveState(redirectUrl) {
    const {
      dispatch,
      section,
      application,
      current_entity,
      entity_type,
      financialsAddon,
      additionalAddon,
      paperlessAddon,
      entity_region,
      completed,
      component,
      guarantorsToDelete,
      trusteeType,
      trustee,
      corporateTrusteeDirectors,
      subComponent,
      component_index,
      party_count,
      trustees,
    } = this.props;

    switch (section) {
      case 'business':
        const shouldDeleteGuarantor = this.shouldDeleteGuarantor({
          entity_type,
          entity_region,
          component,
          completed,
          guarantorsToDelete,
        });
        if (
          shouldDeleteGuarantor &&
          FEATURE_FLAGS.FEATURE_FLAG_BC_FLOW_GUARANTOR_FIX
        ) {
          this.setState({ showGuarantorRemoveModal: true });
        } else if (
          component === 'party-details' &&
          trusteeType === 'corporate'
        ) {
          if (subComponent === 'director-details') {
            this.saveCorporateTrusteeDirectors(
              trustee.corporate_trustee_id,
              corporateTrusteeDirectors,
              redirectUrl
            );
            if (component_index === party_count - 1) {
              this.confirmCorporateTrustees(trustees);
              this.saveBusinessState(false, true);
            }
          } else if (
            !subComponent &&
            entity_region === 'AU' &&
            !this.state.confirmAcnModalOpen &&
            false // Hack to disable the confirm ACN modal, will re-introduce the modal when we work on director auto fill
          ) {
            this.setConfirmAcnModalState(true);
          } else {
            if (this.state.confirmAcnModalOpen) {
              this.setConfirmAcnModalState(false);
            }

            const associatedEntityId =
              await this.saveCorporateTrustee(redirectUrl);
            if (component_index === party_count - 1) {
              // If the last trustee is a corporate trustee, the ID of the
              // associated entity will not be available in the trustee record present
              // in the trustees array. So we need to explicitly pass that id.
              // That is the the second param in the following function call.
              this.confirmCorporateTrustees(trustees, associatedEntityId);

              this.saveBusinessState(false, true);
            }
          }
        } else {
          this.confirmCorporateTrustees(trustees);
          this.saveBusinessState();
        }

        break;
      case 'trade-reference':
        dispatch(
          updateTradeReferencesData(current_entity.id, () => {
            browserHistory.push(redirectUrl);
          })
        );
        break;
      case 'guarantors':
        dispatch(
          updateGuarantorsData(current_entity.id, () => {
            browserHistory.push(redirectUrl);
          })
        );
        break;
      case 'extras':
        dispatch(
          updateApplicationExtraData(current_entity.id, () => {
            this.goToNextLink(section);
          })
        );
        break;
      case 'identity':
        dispatch(
          updateIdentificationData(current_entity.id, () => {
            browserHistory.push(redirectUrl);
          })
        );
        break;
      case 'financials':
        dispatch(
          updateAddonAnswerData(
            application.id,
            financialsAddon.id,
            'financials',
            () => {
              browserHistory.push(redirectUrl);
            }
          )
        );
        break;
      case 'cards':
        this.saveCardholdersState(redirectUrl);
        break;
      case 'additional':
        dispatch(
          updateAddonAnswerData(
            application.id,
            additionalAddon.id,
            'additional',
            () => {
              browserHistory.push(redirectUrl);
            }
          )
        );
        break;
      case 'paperless':
        dispatch(
          updateAddonAnswerData(
            application.id,
            paperlessAddon.id,
            'paperless',
            () => {
              browserHistory.push(redirectUrl);
            }
          )
        );
        break;
    }
  },

  scroll(e) {
    const { application, section, party_count, component, component_index } =
      this.props;
    const { page_complete } = this.state;

    if (section === 'review') {
      return;
    }

    // Reduce sensitiviity for scroll on mac.
    if (Math.abs(e.deltaY) < 50.0) {
      return;
    }

    const flow = this.getSectionLinks(section);
    const index = flow.findIndex((i) => i.component === component);

    let component_indexes;

    switch (component) {
      case 'referees':
        component_indexes = application.attributes.minimum_trade_references;
        break;
      case 'party-details':
        component_indexes = party_count;
        break;
    }

    if (component) {
      if (e.deltaY > 0) {
        if (page_complete === false) {
          return;
        }

        // scrolldown
        if (index === flow.length - 1) {
          this.saveState();
        }

        if (
          isPresent(component_index) &&
          component_index + 1 !== component_indexes
        ) {
          this.scrollToRoute(this.getNextLink(flow, component_index));
        } else {
          this.scrollToRoute(this.getNextLink(flow));
        }
      } else {
        // scrollup
        if (section === 'business' && component === 'type') {
          return;
        }

        if (isPresent(component_index) && component_index - 1 >= 0) {
          this.scrollToRoute(this.getPrevLink(flow, component_index));
        } else {
          this.scrollToRoute(this.getPrevLink(flow));
        }
      }
    }
  },

  scrollToRoute(route) {
    browserHistory.push(route);
  },

  setPageValidationStartFinish() {
    this.setState({ page_validation_start: false });
  },

  /**
   * React render
   */
  updateCompleteState(state, sectionNameFromChild) {
    const {
      section: sectionFromProps,
      component,
      completed,
      dispatch,
    } = this.props;
    const section = sectionNameFromChild || sectionFromProps;
    if (completed[section][component] !== state) {
      switch (section) {
        case 'business':
          dispatch(businessComponentComplete(component, state));
          break;
        case 'trade-reference':
          dispatch(traderefComponentComplete(component, state));
          break;
        case 'guarantors':
          dispatch(guarantorComponentComplete(component, state));
          break;
        case 'extras':
          dispatch(extrasComponentComplete(component, state));
          break;
        case 'identity':
          dispatch(identityComponentComplete(component, state));
          break;
        case 'additional':
          dispatch(addtionalComponentComplete(component, state));
          break;
        case 'cards':
          dispatch(setCardholdersCountCompletedState(component, state));
          break;
        case 'financials':
          dispatch(financialsComponentComplete(component, state));
          break;
        case 'paperless':
          dispatch(paperlessComponentComplete(component, state));
          break;
      }
    }
    if (section === sectionFromProps) {
      this.setState({ page_complete: state });
    }
  },
});

/* eslint-disable sort-keys-fix/sort-keys-fix */
const config = {
  title: 'Application',
  sections: {
    business: {
      title: 'Details',
      default: 'other-details',
    },
    'trade-reference': {
      title: 'Checks',
      default: 'referees',
    },
    guarantors: {
      title: 'Checks',
      default: 'confirm',
    },
    extras: {
      title: 'Limit',
      default: 'questions',
    },
    cards: {
      default: 'card-order-form',
      title: 'Cards',
      addon: true,
    },
    financials: {
      title: 'Financials',
      default: 'financials',
      addon: true,
    },
    additional: {
      title: 'Additional',
      default: 'additional',
      addon: true,
    },
    paperless: {
      title: 'Direct Debit',
      default: 'paperless',
      addon: true,
    },
    identity: {
      title: 'Authorised applicant',
      default: 'details',
    },
    review: { title: 'review' },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

// eslint-disable-next-line complexity
export default connect((state, ownProps) => {
  let component_index = null;
  let subComponentIndex = null;
  let component = null;
  let subComponent = null;
  let selected_type = null;
  let party_count = null;
  let application = null;
  let people = [];
  let minimum_guarantees = 0;
  let hq_name = null;
  let logo_url = null;
  let trading_name = null;
  let minimum_trade_references = 0;
  let requires_applicant_identity_check = false;
  let cardholdersCount = 0;
  const addonRules = state.cob_section.addonRules || null;
  const sections = Object.assign({}, config.sections);

  const isCorporateTrusteeEnabled =
    FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS;

  let requiresApplicantAmlCheck = false;
  let requiresGuarantorAmlCheck = false;
  let requiresCardholderAmlCheck = false;
  let requiresSignatoryAmlCheck = false;

  let requiresID = false;
  let isProofOfAddressVisible = false;
  let hasOneApplicableLegalTypes = false;

  if (state.cob_business.entity_type !== null) {
    selected_type = state.cob_business.entity_type;
  }

  if (state.cob_business.entity_party_count) {
    party_count = parseInt(state.cob_business.entity_party_count);
  }

  if (typeof ownProps.params.splat !== 'undefined') {
    if (ownProps.params.splat.indexOf('/') > -1) {
      const parts = ownProps.params.splat.split('/');
      component = parts[0];
      component_index = parseInt(parts[1]);
      if (isCorporateTrusteeEnabled && parts.length > 2) {
        subComponent = parts[2];
        if (parts[3] !== undefined) {
          subComponentIndex = parseInt(parts[3]);
        }
      }
    } else {
      component = ownProps.params.splat.trim();
      if (
        [
          'referees',
          'party-details',
          'card-details',
          'additional-details',
        ].includes(component) &&
        component_index === null
      ) {
        component_index = 0;
      }
    }
  }

  if (state.cob_section.people) {
    people = state.cob_section.people;
  }
  if (state.cob_section.application) {
    application = state.cob_section.application;
    if (state.cob_section.application.attributes.application_type === 'cash') {
      delete sections['trade-reference'];
      delete sections.guarantors;
      if (
        application.attributes.additional_questions_applies === 'credit' ||
        !application.attributes.additional_application_questions ||
        application.attributes.additional_application_questions.length === 0
      ) {
        delete sections.extras;
      }
    } else {
      if (application.attributes.minimum_trade_references === 0) {
        delete sections['trade-reference'];
      }

      if (
        application.attributes.requires_guarantees &&
        state.cob_business.entity_type === 'company'
        // !["partnership", "trust", "personal", "sole_trader", "education", "society", "association", "club", "government", "other"].includes(state.cob_business.entity_type)
      ) {
        minimum_guarantees = application.attributes.minimum_guarantees;
      } else if (
        application.attributes.requires_guarantees &&
        isTrustFlowEnabled &&
        state.cob_business.entity_type === 'trust'
      ) {
        minimum_guarantees = application.attributes.minimum_guarantees_trust;
        if (!minimum_guarantees) {
          delete sections.guarantors;
        }
      } else {
        delete sections.guarantors;
        if (state.cob_business.entity_type === 'personal') {
          delete sections['trade-reference'];
          // eslint-disable-next-line max-depth
        }
      }
    }

    const antiFraudConfig = new AntiFraudAddonVersionModel(
      get(state, 'cob_section.antiFraud', {})
    );
    const antiFraudCategoryRules =
      getApplicableAntiFraudRulesForApplicationFlow({
        antiFraud: antiFraudConfig,
        application: new ApplicationModel(
          get(state, 'cob_section.application', {})
        ),
        cards: isCat0Enabled ? get(state, 'cob_cards.cardholders', []) : [],
        guarantors: isCat0Enabled ? get(state, 'cob_guarantors', {}) : {},
        paperless: isCat0Enabled ? get(state, 'cob_paperless', {}) : {},
        people: isCat0Enabled
          ? get(state, 'cob_section.current_people', [])
          : [],
        requestedLimit: get(state, 'cob_money.requested_limit'),
      });

    if (!antiFraudCategoryRules.requiresIdentificationSection) {
      delete sections['identity'];
    }

    const addonModuleNames = [];
    addonRules.forEach((rule) => {
      addonModuleNames.push(rule.attributes.addon_module_name);
    });
    Object.keys(sections).forEach((section) => {
      if (!sections[section].addon) return;
      const hasAddonRule = addonModuleNames.includes(section);
      if (!hasAddonRule) {
        delete sections[section];
      }
    });

    minimum_trade_references = application.attributes.minimum_trade_references;
    requires_applicant_identity_check =
      application.attributes.requires_applicant_identification_check;
    cardholdersCount = application.attributes.cardholders_count;

    if (state.cob_section.amlCheck) {
      const personToCheck =
        state.cob_section.amlCheck.attributes.addon_version.config
          .person_to_check;
      if (personToCheck.includes('applicant')) requiresApplicantAmlCheck = true;
      if (personToCheck.includes('guarantor')) requiresGuarantorAmlCheck = true;
      if (personToCheck.includes('cardholder')) {
        requiresCardholderAmlCheck = true;
      }
      if (personToCheck.includes('signatory')) requiresSignatoryAmlCheck = true;
    }

    const hasApplicantInGuarantors = state.cob_guarantors.form_values.some(
      (g) => g && g.is_applicant
    );
    const hasApplicantInCardholders = state.cob_cards.cardholders.some(
      (c) => c.attributes && c.attributes.isApplicant
    );
    const hasApplicantInSignatories =
      state.cob_business.entity_party_details_values.some(
        (s) => s && s.is_applicant
      );

    const requiresAmlCheck =
      requiresApplicantAmlCheck ||
      (hasApplicantInGuarantors && requiresGuarantorAmlCheck) ||
      (hasApplicantInCardholders && requiresCardholderAmlCheck) ||
      (hasApplicantInSignatories && requiresSignatoryAmlCheck);
    // Proof of address is always required if AML check is turned on
    isProofOfAddressVisible = requiresAmlCheck;

    // Enable proof of address for new CAT5 as AML
    const cobSection = state.cob_section;
    const cobMoney = state.cob_money || {};
    if (!requiresAmlCheck) {
      const creditLimit =
        cobMoney.requested_limit ||
        get(cobSection, 'application.trade_account_limit', null);
      const antifraudAttributes = get(cobSection, 'antiFraud.attributes', {});

      const isAntifraudProofOfAddressVisible = getIsAntifraudAml({
        antifraudAttributes,
        person: 'applicant',
        creditLimit,
      });

      isProofOfAddressVisible = isAntifraudProofOfAddressVisible;
    }

    requiresID =
      requires_applicant_identity_check ||
      requiresApplicantAmlCheck ||
      (hasApplicantInGuarantors &&
        (application.attributes.requires_guarantees_identification_check ||
          requiresGuarantorAmlCheck)) ||
      (hasApplicantInCardholders &&
        (application.attributes.requires_cardholder_identification_check ||
          requiresCardholderAmlCheck)) ||
      (hasApplicantInSignatories &&
        (application.attributes.requires_signatories_identification_check ||
          requiresSignatoryAmlCheck));

    const applicableLegalTypes = state.cob_section.applicableLegalTypes;
    if (applicableLegalTypes) {
      hasOneApplicableLegalTypes =
        applicableLegalTypes.attributes.addon_version.legal_types.length === 1;
    }
  }

  let supplier = null;

  if (state.cob_section.supplier) {
    supplier = state.cob_section.supplier;
    trading_name = supplier.attributes.trading_name;
    hq_name = supplier.attributes.company_name;
    if (supplier.attributes.logo) {
      logo_url = supplier.attributes.logo.url;
    }
  }

  let minimumGuarantees = minimum_guarantees;
  if (selected_type !== 'trust' && people.length < minimum_guarantees) {
    minimumGuarantees = people.length;
  }

  // Arrays of components that are completed or not.
  const completed = {
    additional: state.cob_additional.completed,
    business: state.cob_business.completed,
    cards: state.cob_cards.completed,
    extras: state.cob_extras.completed,
    financials: state.cob_financials.completed,
    guarantors: state.cob_guarantors.completed,
    identity: state.identity.completed,
    paperless: state.cob_paperless.completed,
    'trade-reference': state.cob_traderef.completed,
  };

  const consumerAccount =
    (state.current_user.current_entity &&
      state.current_user.current_entity.attributes.consumer_account) ||
    false;

  const cardsAddon = addonRules.find(
    (rule) => rule.attributes.addon_module_name === 'cards'
  );
  const financialsAddon = addonRules.find(
    (rule) => rule.attributes.addon_module_name === 'financials'
  );
  const additionalAddon = addonRules.find(
    (rule) => rule.attributes.addon_module_name === 'additional'
  );
  const paperlessAddon = addonRules.find(
    (rule) => rule.attributes.addon_module_name === 'paperless'
  );

  let additionalTotalPage = 0;
  if (additionalAddon) {
    additionalTotalPage = paginate(
      additionalAddon.attributes.addon_version.data.attributes.config.components
    ).totalPage;
  }

  const { company_details } = state.cob_business;

  let consumerName = state.cob_business.entity_name;
  if (
    (selected_type === 'company' || selected_type === 'trust') &&
    company_details &&
    company_details.name
  ) {
    consumerName = company_details.name;
  }

  const directorFromCreditCheckEnabled = get(
    state,
    'cob_section.supplier.attributes.director_from_credit_check_enabled',
    false
  );

  const entityDirectorsLoadingStatus = get(
    state,
    'cob_business.company_directors_from_api_loading_status'
  );

  const guarantorsToDelete = get(state, 'cob_business.guarantorsToDelete', []);

  let trustee = {};
  let trustees = [];
  let trusteeType = '';
  let corporateTrusteeDirectorCount = 0;
  let corporateTrusteeDirectors = [];
  if (
    component_index !== null &&
    state.cob_business.entity_party_details_values.length
  ) {
    trustees = state.cob_business.entity_party_details_values;
    trustee =
      state.cob_business.entity_party_details_values[component_index] || {};
    trusteeType = trustee.trustee_type;
    corporateTrusteeDirectorCount = trustee.corporate_trustee_director_count;
    if (
      trustee.corporate_trustee_id &&
      state.cob_business.corporateTrusteeDirectors[trustee.corporate_trustee_id]
    ) {
      corporateTrusteeDirectors =
        state.cob_business.corporateTrusteeDirectors[
          trustee.corporate_trustee_id
        ];
    }
  }

  return {
    additionalAddon,
    additionalTotalPage,
    addonRules,
    application,
    application_submitting: state.cob_review.application_submitting,
    cardholdersCount,
    cardsAddon,
    completed,
    component,
    subComponent,
    guarantorsToDelete,
    component_index,
    config,
    consumerName,
    consumer_account: consumerAccount,
    current_entity: state.current_user.current_entity,
    current_user: state.current_user,
    entity: ownProps.params.entity,
    entity_region: state.cob_business.entity_region,
    entity_type: state.cob_business.entity_type,
    entityDirectorsLoadingStatus,
    directorFromCreditCheckEnabled,
    financialsAddon,
    hasOneApplicableLegalTypes,
    identity_image_64: state.identity.image_64,
    isProofOfAddressVisible,
    loading_error: state.cob_section.loading_error,
    minimum_guarantees: minimumGuarantees,
    minimum_trade_references,
    no_identification: state.identity.noIdentification,
    paperlessAddon,
    party_count,
    people,
    required_guarentees: minimum_guarantees,
    requiresApplicantAmlCheck,
    requiresCardholderAmlCheck,
    requiresGuarantorAmlCheck,
    requiresID,
    requires_applicant_identity_check,
    review_complete: state.cob_review.completed,
    section: ownProps.params.section,
    sections,
    selected_type,
    start_complete: state.cob_section.start_complete,
    state_loading: state.state.loading,
    submitting: state.cob_review.submitting,
    supplier,
    supplier_hq_name: hq_name,
    supplier_logo_url: logo_url,
    supplier_trading_name: trading_name,
    warning: state.cob_business.warning,
    isCorporateTrusteeEnabled,
    trustee,
    trustees,
    trusteeType,
    corporateTrusteeDirectorCount,
    corporateTrusteeDirectors,
    subComponentIndex,
  };
})(ReactTimeout(ConsumerOnBoarding));
