import { get, isEqual } from 'lodash';
import UserModel from 'models/UserModel';
import { isTokenised } from 'modules/consumer-onboarding/components/utils';
import IDUpload from 'modules/identity/IDUpload';
import ProofOfAdressUpload from 'modules/identity/ProofOfAddressUpload';
import SectionHeader from 'modules/shared/components/v2/SectionHeader';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getBrowser } from 'utils/getBrowser';

import BiometricCapture from '../BiometricCapture';
import IdentityRegionDropdown from '../IdentityRegionDropdown/IdentityRegionDropdown';
import IdentityTypeDropdown from '../IdentityTypeDropdown/IdentityTypeDropdown';
import {
  checkAuthenticity,
  getAuthorisationID,
  getIsFaceMatchEnabled,
  getIsIdentificationCheckRequired,
  signatoryHasReachedMaxRetries,
} from '../utils';
import { BoldItalicLabel, IdSelectionWrapper, Wrapper } from './styles';
import { FEATURE_FLAGS } from '../../../../conf';
import { ProofOfLife } from '../ProofOfLife';

const determineIsUploadComplete = ({
  hasFrontFaceImage,
  hasIdentityImage,
  hasProofOfAddress,
  idNameMatched,
  isFaceMatchEnabled,
  isAntiFraudPassing,
  isProofOfAddressVisible,
  isValidIdentification,
  overrideIdValidation,
  requiresProofOfLife,
  hasProofOfLifeImage,
  maxRetries,
  isOtherIdType,
  maxProofOfLifeRetryReached,
  identity,
}) => {
  if (overrideIdValidation) {
    return true;
  }

  if (
    FEATURE_FLAGS.FEATURE_FLAG_AUTHENTICITY_SCORE &&
    !isValidIdentification &&
    !maxRetries &&
    !isOtherIdType
  ) {
    return false;
  }

  if ((!hasIdentityImage || !idNameMatched) && !maxRetries) {
    return false;
  }

  if (isProofOfAddressVisible && !hasProofOfAddress) {
    return false;
  }

  if (isFaceMatchEnabled && !hasFrontFaceImage) {
    return false;
  }

  if (isFaceMatchEnabled && !isAntiFraudPassing) {
    return false;
  }

  if (
    requiresProofOfLife &&
    (!hasProofOfLifeImage || !isAntiFraudPassing) &&
    !maxProofOfLifeRetryReached
  ) {
    return false;
  }

  if (!identity.region) {
    return false;
  }

  return true;
};

const determineFrontFaceSelfieHasError = ({
  hasFrontFaceImage,
  isAntiFraudPassing,
}) => {
  if (!hasFrontFaceImage) {
    return true;
  }

  if (!isAntiFraudPassing) {
    return true;
  }

  return false;
};

const determineIdUploadHasError = ({
  idNameMatched,
  hasIdentityImage,
  isAntiFraudPassing,
  isValidIdentification,
  overrideIdValidation,
  maxRetries,
  isOtherIdType,
}) => {
  if (overrideIdValidation) {
    return false;
  }

  if (
    FEATURE_FLAGS.FEATURE_FLAG_AUTHENTICITY_SCORE &&
    !isValidIdentification &&
    !maxRetries &&
    !isOtherIdType
  ) {
    return true;
  }

  if (!idNameMatched) {
    return true;
  }

  if (!hasIdentityImage) {
    return true;
  }

  if (!isAntiFraudPassing) {
    return true;
  }

  return false;
};

function IdentityCapture(props) {
  const {
    authorisationID,
    frontFaceImage,
    handleComplete,
    idNameMatched,
    image_64,
    back_image_64,
    isFaceMatchEnabled,
    requiresProofOfLife,
    isAntiFraudPassing,
    isProofOfAddressVisible,
    isValidIdentification,
    overrideIdValidation,
    page_validation_start,
    proof_of_address,
    resourceType,
    identity,
    isFailedAuthenticity,
    signature,
    idTypeMismatch,
    hasIdentityImage,
    proofOfLifeImage,
    hasProofOfLifeImage,
    maxRetries,
    isOtherIdType,
    maxProofOfLifeRetryReached,
  } = props;

  const [errors, setErrors] = useState({
    bioUpload: false,
    idTypeSelection: false,
    idUpload: false,
    poaUpload: false,
    regionSelection: false,
    proofOfLife: false,
  });
  const hasProofOfAddress = !!proof_of_address;
  const hasFrontFaceImage = !!frontFaceImage;
  // const hasCompleted = (
  //   isProofOfAddressVisible
  //   ? hasIdentityImage && hasProofOfAddress && idNameMatched
  //   : hasIdentityImage && idNameMatched
  // );
  const hasCompleted = determineIsUploadComplete({
    hasFrontFaceImage,
    hasIdentityImage,
    hasProofOfAddress,
    idNameMatched,
    isAntiFraudPassing,
    isFaceMatchEnabled,
    isProofOfAddressVisible,
    isValidIdentification,
    overrideIdValidation,
    requiresProofOfLife,
    hasProofOfLifeImage,
    maxRetries,
    isOtherIdType,
    maxProofOfLifeRetryReached,
    identity,
  });

  useEffect(() => {
    handleComplete(hasCompleted);
    // some page doesn't have page_validation_start
    if (page_validation_start) {
      const updatedErrors = { ...errors };

      const oldIdUploadChecks =
        (!isValidIdentification &&
          FEATURE_FLAGS.FEATURE_FLAG_AUTHENTICITY_SCORE) ||
        !idNameMatched ||
        idTypeMismatch;

      const newIdUploadChecks =
        (FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES && maxRetries) ||
        (FEATURE_FLAGS.FEATURE_FLAG_OTHER_ID_TYPE && isOtherIdType)
          ? false
          : oldIdUploadChecks;

      if (!hasIdentityImage || newIdUploadChecks) {
        Object.assign(updatedErrors, { idUpload: true });
      }
      if (isProofOfAddressVisible && !hasProofOfAddress) {
        Object.assign(updatedErrors, { poaUpload: true });
      }
      if (isFaceMatchEnabled) {
        Object.assign(updatedErrors, {
          bioUpload: determineFrontFaceSelfieHasError({
            hasFrontFaceImage,
            isAntiFraudPassing,
          }),
          idUpload: determineIdUploadHasError({
            hasIdentityImage,
            idNameMatched,
            isAntiFraudPassing,
            maxRetries,
            isOtherIdType,
          }),
        });
      }
      if (!identity.type) {
        Object.assign(updatedErrors, { idTypeSelection: true });
      }
      if (!identity.region) {
        Object.assign(updatedErrors, { regionSelection: true });
      }
      if (
        requiresProofOfLife &&
        !hasProofOfLifeImage &&
        !maxProofOfLifeRetryReached
      ) {
        Object.assign(updatedErrors, { proofOfLife: true });
      }
      if (!isEqual(updatedErrors, errors)) {
        setErrors(updatedErrors);
      }
    }
  }, [
    frontFaceImage,
    hasIdentityImage,
    hasFrontFaceImage,
    idNameMatched,
    image_64,
    back_image_64,
    isFaceMatchEnabled,
    isAntiFraudPassing,
    isValidIdentification,
    overrideIdValidation,
    page_validation_start,
    proof_of_address,
    idTypeMismatch,
    identity.type,
    identity.region,
    hasProofOfLifeImage,
  ]);

  useEffect(() => {
    const updatedErrors = { ...errors };
    if (
      hasIdentityImage &&
      FEATURE_FLAGS.FEATURE_FLAG_AUTHENTICITY_SCORE &&
      isValidIdentification
    ) {
      Object.assign(updatedErrors, { idUpload: false });
    }
    if (hasProofOfAddress) {
      Object.assign(updatedErrors, { poaUpload: false });
    }
    if (hasProofOfLifeImage || maxProofOfLifeRetryReached) {
      Object.assign(updatedErrors, { proofOfLife: false });
      Object.assign(updatedErrors, {
        idUpload: !isAntiFraudPassing,
      });
    }
    if (hasFrontFaceImage) {
      Object.assign(updatedErrors, {
        bioUpload: !isAntiFraudPassing,
        idUpload: !isAntiFraudPassing,
      });
    }
    if (identity.type) {
      Object.assign(updatedErrors, { idTypeSelection: false });
    }
    if (identity.region) {
      Object.assign(updatedErrors, { regionSelection: false });
    }
    if (!isEqual(updatedErrors, errors)) {
      setErrors(updatedErrors);
    }
  }, [
    image_64,
    back_image_64,
    proof_of_address,
    frontFaceImage,
    isAntiFraudPassing,
    isValidIdentification,
    idTypeMismatch,
    identity.type,
    identity.region,
    hasProofOfLifeImage,
  ]);

  const oldIdUploadErrors =
    errors.idUpload || isFailedAuthenticity || idTypeMismatch;
  const newIdUploadErrors =
    isOtherIdType || maxRetries ? false : oldIdUploadErrors;
  const idUploadErrors = FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES
    ? newIdUploadErrors
    : oldIdUploadErrors;

  return (
    <Wrapper>
      {/* NOTE: Temporarily remove the popup modal to test which behaviour is better */}
      {/* <ScrapedDetailsConfirmModal {...props} /> */}
      <SectionHeader title="ID Upload">
        <p>
          Please upload your Driver Licence or Passport in{' '}
          <BoldItalicLabel>PNG</BoldItalicLabel> or{' '}
          <BoldItalicLabel>JPEG</BoldItalicLabel> format.
          {isTokenised &&
            ' Your ID will be removed from our system once checked.'}
        </p>
      </SectionHeader>
      <IdSelectionWrapper>
        <IdentityTypeDropdown
          id={signature ? signature.name : 'identity'}
          identityType={identity ? identity.type : ''}
          dispatch={props.dispatch}
          error={errors.idTypeSelection}
          disabled={identity.scraping}
        />
        <IdentityRegionDropdown
          id={signature ? signature.name : 'region'}
          region={identity ? identity.region : ''}
          dispatch={props.dispatch}
          error={errors.regionSelection}
          disabled={identity.scraping}
        />
      </IdSelectionWrapper>

      <div style={{ columnGap: '2rem', display: 'flex' }}>
        <IDUpload
          {...props}
          resourceType={resourceType}
          hasError={idUploadErrors}
          uploadedFile={image_64}
          headerProps={
            identity.type === 'driver_licence' ? (
              <BoldItalicLabel>Drivers licence front</BoldItalicLabel>
            ) : undefined
          }
          hasDependency={identity.type === 'driver_licence'}
        />
        {identity.type === 'driver_licence' && (
          <IDUpload
            {...props}
            attachmentType={'identification_back_image'}
            fileId={'back_image_64'}
            resourceType={resourceType}
            uploadedFile={back_image_64}
            hasError={idUploadErrors}
            headerProps={
              <BoldItalicLabel> Drivers licence back</BoldItalicLabel>
            }
            hasDependency={identity.type === 'driver_licence'}
          />
        )}
      </div>
      {isFaceMatchEnabled && (
        <BiometricCapture
          {...props}
          resourceType={resourceType}
          hasError={errors.bioUpload}
        />
      )}
      {isProofOfAddressVisible && (
        <ProofOfAdressUpload
          {...props}
          resourceType={resourceType}
          hasError={errors.poaUpload}
        />
      )}
      {requiresProofOfLife && (
        <ProofOfLife
          hasIdImage={Boolean(image_64)}
          authorisationID={authorisationID}
          error={errors.proofOfLife}
          imageUrl={proofOfLifeImage}
          isAntiFraudPassing={isAntiFraudPassing}
          isOtherIdType={
            FEATURE_FLAGS.FEATURE_FLAG_OTHER_ID_TYPE && isOtherIdType
          }
        />
      )}
    </Wrapper>
  );
}

IdentityCapture.propTypes = {
  apiType: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
};

IdentityCapture.defaultProps = {
  apiType: 'authorisations',
  resourceType: 'authorisation',
};

export default connect((state, ownProps) => {
  const { authorisation } = state;
  const identity = state.identity;
  const cobSection = state.cob_section;
  const s_browser = getBrowser(window.navigator.userAgent);
  const currentUser = new UserModel.fromCurrentUser(state.current_user);
  let authorisationID = ownProps.authorisationID;

  if (!ownProps.noInstantUpload) {
    authorisationID = getAuthorisationID({ ownProps, state });
  }

  const isIdentificationCheckRequired = getIsIdentificationCheckRequired({
    guarantor: ownProps.guarantor,
    isGuarantor: ownProps.isGuarantor,
    requiresID: ownProps.requiresID,
    state,
  });

  const {
    applicantSignatory,
    frontFaceImage,
    distinctId,
    imageUrl,
    backImageUrl,
    isAntiFraudPassing,
    needDobAddress,
    proofOfAddress,
  } = ownProps;

  // const { id_name_matched } = identity;
  const { signature } = cobSection;

  let idNameMatched = true;

  if (applicantSignatory) {
    let signatoryName = get(applicantSignatory, 'first_name') || '';
    signatoryName = signatoryName.toLowerCase();
    let signatureName =
      get(signature, 'formatted_scraped_details.first_name') || '';
    signatureName = signatureName.toLowerCase();

    if (authorisation && authorisation.data) {
      signatureName =
        get(
          authorisation,
          'data.signature.attributes.formatted_scraped_details.first_name'
        ) || '';
      signatureName = signatureName.toLowerCase();
    }

    if (signatureName) {
      idNameMatched = signatoryName.includes(signatureName);
    }
  }

  const hasIdentityImage =
    identity.type === 'driver_licence'
      ? !!identity.image_64 && !!identity.back_image_64
      : !!identity.image_64;
  const shouldShowValidation = hasIdentityImage && !identity.scraping;

  const idTypeMismatch =
    identity.type &&
    identity.scraped_identification_type &&
    shouldShowValidation
      ? identity.type !== identity.scraped_identification_type
      : false;

  const isFaceMatchEnabled =
    ownProps.isFaceMatchEnabled || getIsFaceMatchEnabled(state);

  const isOtherIdTypeOld = identity.type === 'other' && !idTypeMismatch;
  const isOtherIdTypeCheckResult =
    identity.type === 'other' || identity.region === 'other';
  const isOtherIdType = FEATURE_FLAGS.FEATURE_FLAG_OTHER_ID_TYPE
    ? isOtherIdTypeCheckResult
    : false;

  const isOtherIdTypeCheck =
    FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES ||
    FEATURE_FLAGS.FEATURE_FLAG_OTHER_ID_TYPE
      ? isOtherIdTypeCheckResult
      : isOtherIdTypeOld;

  // Prevents checking authenticity score for QLD [BT-16137]
  const isQldLicence = FEATURE_FLAGS.FEATURE_FLAG_TEMP_BLOCK_QLD_DL
    ? identity.type === 'driver_licence' &&
      identity.driver_licence_state === 'QLD'
    : false;

  const rawValidIdentification = get(identity, 'is_valid_identification', true);
  const isFailedAuthenticity =
    FEATURE_FLAGS.FEATURE_FLAG_AUTHENTICITY_SCORE && shouldShowValidation
      ? (checkAuthenticity(identity.authenticity_score) ||
          !rawValidIdentification) &&
        !isQldLicence
      : false;

  const shouldCheckValidIdentification =
    FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES
      ? !isOtherIdTypeCheck && shouldShowValidation && !isQldLicence
      : !isOtherIdTypeCheck || shouldShowValidation;

  const isValidIdentification = shouldCheckValidIdentification
    ? ownProps.isValidIdentification || !isFailedAuthenticity
    : true;

  const overrideIdValidation =
    ownProps.overrideIdValidation || identity.override_id_validation;

  const proofOfLifeImage = identity.proofOfLifeImage;
  const hasProofOfLifeImage = !!proofOfLifeImage;

  // get max retries MAX = 3
  const maxRetries = signatoryHasReachedMaxRetries(signature, authorisation);
  const maxProofOfLifeRetryReached = FEATURE_FLAGS.FEATURE_FLAG_MAX_RETRY_CAT6
    ? identity.maxProofOfLifeRetryReached
    : false;

  return {
    authorisation,
    authorisationID,
    back_image_64: identity.back_image_64 || backImageUrl,
    currentUser,
    distinctId,
    file_name: identity.file_name,
    file_size: identity.file_size,
    frontFaceImage: identity.front_face_image || frontFaceImage,
    idNameMatched,
    idTypeMismatch,
    identity,
    image_64: identity.image_64 || imageUrl,
    isAntiFraudEnabled: !!cobSection.antiFraud,
    // TODO - isAntiFraudPassing: isFaceMatchPassing || isProofOfLifePassing;

    isAntiFraudPassing:
      //Force pass antifraud when reached max retry
      (FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES && maxRetries) ||
      // Force pass antifraud when otherIdType
      (FEATURE_FLAGS.FEATURE_FLAG_OTHER_ID_TYPE && isOtherIdType)
        ? true
        : identity.antiFraudCheckPassed,
    isFaceMatchEnabled,
    isFailedAuthenticity,
    isIdentificationCheckRequired,
    isScrapeDetailsModalVisible: identity.isScrapeDetailsModalVisible,
    isTokenised: isTokenised(state),
    isValidIdentification,
    needDobAddress,
    noCamera: identity.noCamera,
    overrideIdValidation,
    proof_of_address: identity.proof_of_address || proofOfAddress,
    s_browser,
    scraping: identity.scraping,
    scraping_result: identity.scraping_result,
    signature,
    hasIdentityImage,
    proofOfLifeImage,
    hasProofOfLifeImage,
    maxProofOfLifeRetryReached,
    maxRetries,
    isOtherIdType,
  };
})(IdentityCapture);
