import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from 'variables/theme';

const Container = styled.div<{ $disabled?: boolean }>`
  cursor: pointer;
  p {
    letter-spacing: normal !important;
  }
  a {
    text-decoration: underline;
  }

  ${(props) =>
    props.$disabled &&
    css`
      cursor: default;
      color: ${COLORS.mediumGrey};
      a {
        color: ${COLORS.mediumGrey};
        cursor: default;
      }
    `}
`;

type FilterButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
};

export const FilterButton = ({ children, disabled }: FilterButtonProps) => {
  return <Container $disabled={disabled}>{children}</Container>;
};
