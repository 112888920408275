/* Import libraries */
import Badge from 'modules/shared/components/inputs/Badge';
import Icon from 'modules/shared/components/svg/Icon';
/* Import components */
import Clickable from 'modules/shared/components/widgets/interactive/Clickable';
import SimpleDropdown from 'modules/shared/components/widgets/interactive/SimpleDropdown';
import React, { Component } from 'react';

/* Import CSS */
import styles from '../css/DashboardContainer.css';
import { FEATURE_FLAGS } from 'conf';

class MenuItem extends Component {
  render() {
    const {
      items,
      title,
      icon,
      target,
      active,
      style,
      hideHandler,
      visible,
      navActive,
      showTooltip,
      indicator,
      alertCount,
      useNewMenuItem,
    } = this.props;

    if (visible === false) {
      return null;
    }

    let link_style = style || styles.link;

    let dropdown = null;
    let arrow = null;
    let menuItem = useNewMenuItem ? styles.menu_item_mobile : styles.menu_item;
    if (this.props.children) {
      if (active) {
        arrow = <i className={`${styles.arrow_up} ${styles.hidden_md_lg}`}></i>;
        menuItem = `${menuItem} ${styles.menu_item_active}`;
        dropdown = (
          <div
            className={
              FEATURE_FLAGS.FEATURE_FLAG_NEW_NAV_HEADER
                ? active
                  ? styles.show_dropdown_list
                  : styles.dropdown_list
                : `${styles.dropdown_list} ${styles.show_dropdown_list}`
            }
          >
            <SimpleDropdown
              parent={this.id}
              hideHandler={hideHandler}
              pullLeft={true}
              type={useNewMenuItem ? 'dropdown_mobile' : 'dropdown'}
            >
              {this.props.children}
            </SimpleDropdown>
          </div>
        );
      } else {
        arrow = (
          <i className={`${styles.arrow_down} ${styles.hidden_md_lg}`}></i>
        );
        dropdown = (
          <div
            className={`${styles.dropdown_list} ${styles.hide_dropdown_list}`}
          >
            <SimpleDropdown
              parent={this.id}
              hideHandler={hideHandler}
              pullLeft={true}
              type={useNewMenuItem ? 'dropdown_mobile' : 'dropdown'}
            >
              {this.props.children}
            </SimpleDropdown>
          </div>
        );
      }
    }

    if (navActive) {
      link_style = styles.link_active;
    }

    if (indicator) {
      return (
        <li key={`${title}-menu`} className={menuItem}>
          <div className={styles.link1}>
            <Icon icon={icon} />
            <span className={styles.link_label}>
              {title}
              {...indicator}
            </span>
          </div>
        </li>
      );
    }
    return (
      <li key={`${title}-menu`} className={menuItem} onMouseEnter={showTooltip}>
        <Clickable target={target}>
          <div className={link_style}>
            <Icon icon={icon} shadow={true} />
            {!!alertCount && <Badge theme="white" text={alertCount} />}
            <span className={styles.link_label}>
              {title}
              {arrow}
            </span>
          </div>
        </Clickable>
        {dropdown}
      </li>
    );
  }
}

export default MenuItem;
