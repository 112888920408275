import React from 'react';
import styles from './report-styles.css';
import { formatMoney } from 'utils/formatting';

const ReportEnquiriesDefaults = ({ characteristic, reportType }) => {
  const reportDataPrefixMap = {
    individual: 'NX',
    personal: 'NY',
  };
  const reportDataPrefix = reportDataPrefixMap[reportType];

  let timeSinceLastEnquiry =
      characteristic.find((obj) => obj['@id'] === `${reportDataPrefix}8059`) ||
      {},
    numEnquiries3months =
      characteristic.find((obj) => obj['@id'] === `${reportDataPrefix}8001`) ||
      {},
    totalEnquiries3months =
      characteristic.find((obj) => obj['@id'] === `${reportDataPrefix}8062`) ||
      {},
    numEnquiries5years =
      characteristic.find((obj) => obj['@id'] === `${reportDataPrefix}8006`) ||
      {},
    totalEnquiries5years =
      characteristic.find((obj) => obj['@id'] === `${reportDataPrefix}8063`) ||
      {},
    numDefaults =
      characteristic.find((obj) => obj['@id'] === `${reportDataPrefix}7516`) ||
      {},
    totalDefaults =
      characteristic.find((obj) => obj['@id'] === `${reportDataPrefix}7588`) ||
      {};

  if (characteristic.length < 1) return <p>Information Unavailable</p>;

  return (
    <div>
      <p className="is-pulled-right">
        No. of enquiries: {numEnquiries5years.value}
        &emsp; No. of defaults: {numDefaults.value}
      </p>
      <br />
      <div className={styles.table + ' mt-2'}>
        <div className={styles.row}>
          <div className={styles.th + ' ' + styles.col_4}>Data</div>
          <div className={styles.th + ' ' + styles.col_8}>Commercial</div>
        </div>
        <div className={styles.row}>
          <div className={styles.th_underline + ' ' + styles.col_4}>
            Time since last enquiry
          </div>
          <div className={styles.th_underline + ' ' + styles.col_8}>
            {!timeSinceLastEnquiry || timeSinceLastEnquiry.value < 0
              ? '-'
              : `Less than ${timeSinceLastEnquiry.value} month(s)`}
          </div>
        </div>
      </div>

      <div className={styles.table}>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_4}></div>
          <div className={styles.td + ' ' + styles.col_4}>
            <strong>Number</strong>
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            <strong>Total</strong>
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_4}>
            Enquiries in the last 3 months
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {numEnquiries3months.value}
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!totalEnquiries3months || totalEnquiries3months.value < 0
              ? '-'
              : `$${formatMoney(
                  parseFloat(totalEnquiries3months.value),
                  null,
                  null,
                  null,
                  null
                )}`}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_4}>
            Enquiries in the last 5 years
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {numEnquiries5years.value}
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!totalEnquiries5years || totalEnquiries5years.value < 0
              ? '-'
              : `$${formatMoney(
                  parseFloat(totalEnquiries5years.value),
                  null,
                  null,
                  null,
                  null
                )}`}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_4}>Defaults</div>
          <div className={styles.td + ' ' + styles.col_4}>
            {numDefaults.value}
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!totalDefaults || totalDefaults.value < 0
              ? '-'
              : `$${formatMoney(
                  parseFloat(totalDefaults.value),
                  null,
                  null,
                  null,
                  null
                )}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportEnquiriesDefaults;
