import React from 'react';
import EquifaxReport from './shared/EquifaxReport';

const PersonalReport = (props) => {
  return (
    <EquifaxReport
      reportType="personal"
      mainHeader="Equifax Commercial Apply"
      {...props}
    />
  );
};

export default PersonalReport;
