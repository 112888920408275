import React from 'react';

import { ExclusionDescription } from './ExclusionDescription';
import { DropdownOptions, Steps } from './types';
import { addFlaggedArray } from 'conf/flaggingHelpers';

export const WATCHTOWER_ADDON_NAME = 'watchtower_module';
export const WATCHTOWER_READABLE_NAME = 'Watchtower module';

export const DEFAULT_CREDIT_SCORE_DECREASE = 100;

export const externalAlertDropdownOptions: DropdownOptions[] = [
  {
    brief: 'Change of key person/s',
    label: 'Change of key person/s',
    value: 'company_persons_changed',
  },
  {
    brief: 'Credit score decrease',
    label: 'Credit score decrease',
    value: 'credit_score_decrease',
  },
  {
    brief: 'Deregistered',
    label: 'Deregistered',
    value: 'company_deregistered',
  },
  {
    brief: 'Director mismatch (AU)',
    label: 'Director mismatch (AU)',
    value: 'au_company_director_mismatch',
  },
  {
    brief: 'GST registered (AU)',
    label: 'GST registered (AU)',
    value: 'au_company_gst_registered',
  },
  {
    brief: 'Insolvency',
    label: 'Insolvency',
    value: 'company_insolvency',
  },
  {
    brief: 'Liquidation (NZ)',
    label: 'Liquidation (NZ)',
    value: 'nz_company_liquidation',
  },
  {
    brief: 'Liquidation/Receivership (AU)',
    label: 'Liquidation/Receivership (AU)',
    value: 'au_liquidation_recievership',
  },
  {
    brief: 'Receivership (NZ)',
    label: 'Receivership (NZ)',
    value: 'nz_company_receivership',
  },
  {
    brief: 'Voluntary administration',
    label: 'Voluntary administration',
    value: 'company_voluntary_administration',
  },
];

export const additionalExternalValuesMapping = {
  au_liquidation_recievership: [
    'au_company_liquidation',
    'au_company_receivership',
  ],
  company_deregistered: ['au_company_deregistered', 'nz_company_deregistered'],
  company_insolvency: ['au_company_insolvency', 'nz_company_insolvency'],
  company_voluntary_administration: [
    'au_company_voluntary_administration',
    'nz_company_voluntary_administration',
  ],
  company_persons_changed: [
    'nz_company_persons_changed',
    'au_company_persons_changed',
  ],
};

export const internalAlertsDropdownOptions: DropdownOptions[] = [
  {
    brief: 'Director from Liquidated company',
    label: 'Director from Liquidated company',
    value: 'director_liquidated_company',
  },
  {
    brief: 'Email age',
    label: 'Email age',
    value: 'email_age',
  },
  ...addFlaggedArray('FEATURE_FLAG_WATCHTOWER_EMAIL_DOMAINS', [
    {
      brief: 'Email client domain',
      label: 'Email client domain',
      value: 'email_domains',
    },
  ]),
  {
    brief: 'Foreign IP addresses',
    label: 'Foreign IP addresses',
    value: 'blocked_geo_ips',
  },
  {
    brief: 'Identical ABN/ACN/NZBN/NZCN',
    label: 'Identical ABN/ACN/NZBN/NZCN',
    value: 'identical_company_numbers',
  },
  {
    brief: 'Identical directors',
    label: 'Identical directors',
    value: 'identical_directors', // no enum value
  },
  {
    brief: 'Identical emails',
    label: 'Identical emails',
    value: 'identical_emails',
  },
  {
    brief: 'Identical IDs',
    label: 'Identical IDs',
    value: 'identical_identifications',
  },
  {
    brief: 'Repeat applications',
    label:
      'Repeat applications (applies to entities without ABN/ACN/NZBN/NZCN)',
    value: 'repeat_application',
  },
  {
    brief: 'Unusual application time',
    label: 'Unusual application time',
    value: 'unusual_application_times',
  },
];

export const formConstants: { [key: string]: Steps } = {
  step1: {
    description:
      'These indicators are triggers that will alert you as a result of external factors that may or may not impact the credit risk of your customer.',
    header: 'External alert',
    step: 'Step 1.',
  },
  step1a: {
    description: 'Please select the users to receive notifications.',
    header: 'External alert key contact',
    step: 'Step 1a.',
  },
  step2: {
    description:
      "The 'Identical' indicators trigger where a key piece of data (email/ID/IP/entity No.) appears to be exactly the same on another Credit File in your 1Centre account. The Foreign IP trigger allows you to add certain countries IP addresses to the watchlist. This may be completely legitimate but requires your attention to ensure you are aware.",
    header: 'Internal alert',
    step: 'Step 2.',
  },
  step2a: {
    description: 'Please select the users to receive notifications.',
    header: 'Internal alert key contact',
    step: 'Step 2a.',
  },
  step2b: {
    description: <ExclusionDescription />,
    header: 'Exclusions',
    step: 'Step 2b.',
    isOptional: true,
  },
};

export const emailAgeOptions = [
  {
    brief: '<1 week',
    label: '<1 week',
    value: 7,
  },
  {
    brief: '<1 month',
    label: '<1 month',
    value: 30,
  },
  {
    brief: '<3 month',
    label: '<3 month',
    value: 90,
  },
  {
    brief: '<6 month',
    label: '<6 month',
    value: 180,
  },
  {
    brief: '<12 month',
    label: '<12 month',
    value: 360,
  },
];

export const blacklistCountriesOptions = [
  {
    brief: 'All foreign countries other than AU & NZ',
    label: 'All foreign countries other than AU & NZ',
    value: 'non_au_nz',
  },
  {
    brief: 'China',
    label: 'China',
    value: 'CN',
  },
  {
    brief: 'India',
    label: 'India',
    value: 'IN',
  },
  {
    brief: 'U.S.A.',
    label: 'U.S.A.',
    value: 'US',
  },
  {
    brief: 'Russia',
    label: 'Russia',
    value: 'RU',
  },
];

export const exclusionOptions = [
  {
    brief: 'Incomplete applications',
    label: 'Incomplete applications',
    value: 'not_submitted',
  },
  {
    brief: 'Archived applications',
    label: 'Archived applications',
    value: 'archived',
  },
];

export const emailDomainsOptions = [
  {
    brief: 'Yahoo',
    label: 'Yahoo',
    value: 'yahoo.com',
  },
  {
    brief: 'Hotmail',
    label: 'Hotmail',
    value: 'hotmail.com',
  },
  {
    brief: 'Gmail',
    label: 'Gmail',
    value: 'gmail.com',
  },
  {
    brief: 'Outlook',
    label: 'Outlook',
    value: 'outlook.com',
  },
  {
    brief: 'Xtra',
    label: 'Xtra',
    value: 'xtra.com',
  },
];

export const entityTypeOptions = [
  {
    brief: 'Company',
    label: 'Company',
    value: 'company',
  },
  {
    brief: 'Partnership',
    label: 'Partnership',
    value: 'partnership',
  },
  {
    brief: 'Trust',
    label: 'Trust',
    value: 'trust',
  },
  {
    brief: 'Sole Trader',
    label: 'Sole Trader',
    value: 'sole_trader',
  },
  {
    brief: 'Personal',
    label: 'Personal',
    value: 'personal',
  },
  {
    brief: 'Education',
    label: 'Education',
    value: 'education',
  },
  {
    brief: 'Society',
    label: 'Society',
    value: 'society',
  },
  {
    brief: 'Association',
    label: 'Association',
    value: 'association',
  },
  {
    brief: 'Club',
    label: 'Club',
    value: 'club',
  },
  {
    brief: 'Government',
    label: 'Government',
    value: 'government',
  },
  {
    brief: 'Other',
    label: 'Other',
    value: 'other',
  },
];
