import Button from 'modules/shared/components/inputs/Button';
import Checkbox from 'modules/shared/components/inputs/Checkbox';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import ExtraDescription from 'modules/shared/components/text/ExtraDescription';
import CircleIconButton from 'modules/shared/components/widgets/interactive/CircleIconButton';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip.js';
import RoundedDropdown from 'modules/shared/components/widgets/interactive/RoundedDropdown';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import {
  isFeatureEditEnabled,
  isHeadquarter,
  isRegular,
} from 'modules/shared/helpers/headquarterDetect';
import { ImpactAutoDecisionRulesetPopup } from 'modules/shared/components/widgets/static/ImpactAutoDecisionRulesetPopup/ImpactAutoDecisionRulesetPopup';
import { updateRuleSet } from 'modules/addons/auto_decisioning/actions';

/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import toggleArrayValue from 'utils/toggleArrayValue';
import { FEATURE_FLAGS } from 'conf';

import {
  clearSeletedCreditCheckRules,
  loadAmlCheckLookup,
  loadAntiFraud,
  loadCard,
  loadCreditCheckLookup,
  persistAmlCheckConfig,
  setAmlCheckLevels,
  setCreditCheckInitialValues,
  setCreditWorksStatus,
  setMandatoryCheck,
  setSelectedAmlCheckRules,
  setSeletedCreditCheckRules,
  updateMandatoryChecks,
  updateMandatoryChecksCreditWorks,
} from '../actions';

import AmlCheckModal from './AmlCheckModal';
import AntiFraud from './AntiFraud';
import CreditCardModal from './CreditCardModal';
import CreditCheckModal from './CreditCheckModal';
import styles from './css/CommonEditProfile.css';
import checksStyles from './css/MandatoryChecks.css';
import Guarantor from './Guarantor';
import { ADD_CREDIT_CARD_STRING } from './Guarantor/constants';

const MandatoryChecks = createClass({
  amlCheckModal() {
    const {
      autosave,
      card,
      role_types: roleTypes,
      update_loading: updateLoading,
    } = this.props;
    const { auAmlCheckProviders, nzAmlCheckProviders } =
      this.amlCheckProviders();

    let button;

    if (!card && isRegular()) {
      if (roleTypes.includes('admin')) {
        button = (
          <Button
            text="Add Card"
            handleClick={this.cancelAmlCheckModal.bind(this, true)}
          />
        );
      } else {
        button = (
          <Button
            text="Cancel"
            handleClick={this.cancelAmlCheckModal.bind(this, false)}
          />
        );
      }
    } else {
      button = <Button text="OK" handleClick={this.dismissAmlCheckModal} />;
    }

    let closeButtonStyle = 'invite_mandatory_button_close';
    if (autosave) closeButtonStyle = 'mandatory_button_close';

    return (
      <AmlCheckModal
        title="AML Check"
        bodyText={defaults.options.aml.modalText}
        button={button}
        updateLoading={updateLoading}
        nzAmlCheckProviders={nzAmlCheckProviders}
        auAmlCheckProviders={auAmlCheckProviders}
        dismissHandler={this.cancelAmlCheckModal.bind(this, false)}
        closeButtonStyle={closeButtonStyle}
      />
    );
  },

  amlCheckProviders() {
    const { amlCheckOptions, amlCheckRules, card } = this.props;

    const selectedProviders = amlCheckRules.selectedProviders;
    const divStyle = { minHeight: '50px' };
    const providers = {
      auAmlCheckProviders: [],
      nzAmlCheckProviders: [],
    };

    for (const lookup of amlCheckOptions) {
      const { attributes } = lookup;
      let isActive = attributes.active;
      let description = attributes.description;

      if (!card && isRegular() && attributes.active) {
        isActive = false;
        description = (
          <span>
            {`${description} `}
            <p className={checksStyles.requiredNotice}>
              {ADD_CREDIT_CARD_STRING}
            </p>
          </span>
        );
      }

      const isChecked =
        selectedProviders[attributes.region] === attributes.slug;

      const key = `${attributes.region.toLowerCase()}AmlCheckProviders`;

      const handleChange = FEATURE_FLAGS.REVAMPED_1CAD
        ? this.checkAmlProviderImpactOnAutoDecision
        : this.toggleAmlCheckProvider;

      providers[key].push(
        <div key={attributes.slug} className={checksStyles.checkbox_container}>
          <Checkbox
            key={attributes.slug}
            checkboxId={attributes.slug}
            checked={isChecked}
            handleChange={handleChange.bind(
              this,
              attributes.region,
              attributes.slug
            )}
            label={attributes.name}
            description={description}
            disabled={!isActive || !isFeatureEditEnabled('Credit')}
            divStyle={divStyle}
          />
        </div>
      );
    }

    return providers;
  },

  cancelAmlCheckModal(addCardInfo) {
    this.setState({ amlCheckModalVisible: false });

    if (addCardInfo) {
      this.setState({ showCreditInfoModal: true });
      this.setState({ currentMandatoryCheck: 'requires_aml_check' });
    }
  },

  cancelCardInfo() {
    this.setState({ showCreditInfoModal: false });
  },

  cancelCreditModal(addCardInfo) {
    this.setState({ showCreditModal: false });

    if (addCardInfo) {
      this.setState({ showCreditInfoModal: true });
      this.setState({ currentMandatoryCheck: 'requires_credit_check' });
    }
  },

  checkboxOptions(field) {
    const { cardsAddons } = this.props;
    const key = field.options.key;
    const checkboxes = [];

    let list = field.options.list;
    if (cardsAddons.length === 0) {
      list = list.filter((item) => item.value !== 'cardholder');
    }

    list.forEach((item, index) => {
      const isChecked = this.isCheckLevelSelected(key, item.value);

      const isAmlCheckLevel = key === 'aml_check_level';

      const onChangeHandler =
        FEATURE_FLAGS.REVAMPED_1CAD && isAmlCheckLevel
          ? this.checkAmlImpactOnAutoDecision
          : this.getHandler(key);

      checkboxes.push(
        <PopperTooltip
          title={item.tooltip}
          open={!!this.state[`${key}-${index}`]}
          placement={'right'}
          noArrow={true}
        >
          <SquareCheckbox
            key={`${key}-${index}`}
            checkboxId={`${key}-${item.value}`}
            checkboxName={item.value}
            checked={isChecked}
            handleChange={onChangeHandler.bind(this, item.value)}
            toggleTooltip={(flag) =>
              this.toggleTooltip(`${key}-${index}`, flag)
            }
            label={item.label}
            no_float={true}
            disabled={!isFeatureEditEnabled('Credit')}
          />
        </PopperTooltip>
      );
    });

    return (
      <div className={checksStyles.square_checkbox_container}>{checkboxes}</div>
    );
  },

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(loadCreditCheckLookup());
    dispatch(loadAmlCheckLookup());
    dispatch(setCreditCheckInitialValues());
    dispatch(loadAntiFraud());
  },

  dismissAmlCheckModal() {
    this.setState({ amlCheckModalVisible: false });
  },

  dismissCreditModal() {
    this.setState({ showCreditModal: false });
  },

  dismissFeatureNotEnabledModal() {
    this.setState({ showFeatureNotEnabledModal: false });
  },

  formatDescription(description) {
    if (isBlank(description)) {
      return;
    }

    return description.map((content) => <p>{content}</p>);
  },

  getHandler(key) {
    switch (key) {
      case 'aml_check_level':
        return this.setAmlCheckLevel;
      default:
        console.error('No handler located for this key.');
    }
  },

  getInitialState() {
    return {
      amlCheckModalVisible: false,
      check: false,
      currentMandatoryCheck: null,
      showAntiFraudModal: false,
      showCreditModal: false,
      showGuarantorModal: false,
      showFeatureNotEnabledModal: false,
      autoDecisionImpact: {
        isAutoDecisionImpactModalVisible: false,
        onConfirm: null,
        rulesetsThatWillBeImpacted: [],
        tabStatusToBePaused: null,
      },
    };
  },

  isCheckLevelSelected(key, value) {
    if (key === 'aml_check_level' && this.props.amlCheckRules.selectedLevels) {
      return this.props.amlCheckRules.selectedLevels.includes(value);
    }

    return false;
  },

  getRulesetNames() {
    const {
      autoDecisionImpact: { rulesetsThatWillBeImpacted },
    } = this.state;
    return rulesetsThatWillBeImpacted
      .map((ruleset) => ruleset.attributes.name)
      .join(', ');
  },

  checkAmlImpactOnAutoDecision(amlValueToToggle) {
    // User has clicked on the button to enable/disable guarators
    // We need to check if the user action will affect any active auto decision rulesets

    const onConfirm = () => this.setAmlCheckLevel(amlValueToToggle);

    const {
      autoDecisionRulesets = [],
      amlCheckRules = {
        addonConfigActive: false,
        selectedProviders: { NZ: null },
        selectedLevels: [],
      },
    } = this.props;

    const { addonConfigActive, selectedProviders, selectedLevels } =
      amlCheckRules;

    // If the value of the check box ticked by user is already present in the
    // AMl check levels array, it implies that the user intends to remove the
    // check level
    const isGoingToRemoveAmlCheckLevel =
      selectedLevels.includes(amlValueToToggle);

    const isGoingToAddAmlCheckLevel = !isGoingToRemoveAmlCheckLevel;

    // If the user is trying to remove an AML check level,
    // check if the AML check levels will be empty after this operation.
    // If the array will get empty, AML won't apply anymore.
    // For this to happen, the current count of AML check levels should be 1.
    const isAmlCheckLevelsGoingToBeEmpty =
      isGoingToRemoveAmlCheckLevel && selectedLevels.length === 1;

    const isProviderConfigured = Boolean(selectedProviders.NZ);

    if (
      !addonConfigActive ||
      isGoingToAddAmlCheckLevel ||
      !isAmlCheckLevelsGoingToBeEmpty ||
      !isProviderConfigured
    ) {
      // The change in AML settings is not going to affect any auto decision
      // rulesets for one or more of the following reasons.
      // 1. AML add on is not active
      // 2. The change is to add a level, not remove one
      // 3. The change is to remove a level, but this doesn't empty the array.
      // 4. Provider is not configured. So AML doesn't apply anyway.
      // Hence proceed to make tha change
      onConfirm();
      return;
    }

    // An auto decision ruleset will be affected if that one requries AML check
    const rulesetsThatWillBeImpacted = autoDecisionRulesets.filter(
      (ruleset) => {
        const rulesetAttributes = ruleset.attributes;
        const isAmlRequiredInRuleset =
          rulesetAttributes.aml_verification === true;

        return isAmlRequiredInRuleset;
      }
    );

    if (!rulesetsThatWillBeImpacted.length) {
      // We didn't find any ruleset that will be impacted
      // We'll just proced to update the settings
      onConfirm();
      return;
    }

    this.setState({
      autoDecisionImpact: {
        rulesetsThatWillBeImpacted,
        isAutoDecisionImpactModalVisible: true,
        onConfirm,
        tabStatusToBePaused: 'validation_status',
      },
    });
  },

  checkAmlProviderImpactOnAutoDecision(region, provider) {
    // User is about to enable/disable an AML provider.
    // We need to check if the user action will affect any active auto decision rulesets.

    const onConfirm = () => this.toggleAmlCheckProvider(region, provider);

    const {
      autoDecisionRulesets = [],
      amlCheckRules = {
        addonConfigActive: false,
        selectedProviders: { NZ: null },
        selectedLevels: [],
      },
    } = this.props;

    const { addonConfigActive, selectedProviders } = amlCheckRules;

    // Check if user is going to cconfigure a provider for the region
    const isGoingToAddAmlprovider = !isPresent(selectedProviders[region]);

    if (!addonConfigActive || isGoingToAddAmlprovider) {
      // Changing the AML provider config is not going to affect any auto decision
      // rulesets for one or more of the following reasons.
      // 1. AML add on is not active
      // 2. The change is to add a provider, not remove one
      // Hence proceed to make tha change
      onConfirm();
      return;
    }

    // Since the control has reached here, it means that the user is intending to
    // remove the AML provider.
    // An auto decision ruleset will be affected if that one requries AML check.
    const rulesetsThatWillBeImpacted = autoDecisionRulesets.filter(
      (ruleset) => {
        const rulesetAttributes = ruleset.attributes;
        const isAmlRequiredInRuleset =
          rulesetAttributes.aml_verification === true;

        return isAmlRequiredInRuleset;
      }
    );

    if (!rulesetsThatWillBeImpacted.length) {
      // We didn't find any ruleset that will be impacted
      // We'll just proced to update the settings
      onConfirm();
      return;
    }

    this.setState({
      autoDecisionImpact: {
        rulesetsThatWillBeImpacted,
        isAutoDecisionImpactModalVisible: true,
        onConfirm,
        tabStatusToBePaused: 'validation_status',
      },
    });
  },

  handleConfirmAutoDecisionImpact() {
    const onConfirm = this.state.autoDecisionImpact.onConfirm;
    onConfirm && onConfirm();

    const { rulesetsThatWillBeImpacted, tabStatusToBePaused } =
      this.state.autoDecisionImpact;
    rulesetsThatWillBeImpacted.forEach((ruleset) => {
      // Let's pause the auto decision rules
      const { attributes, id } = ruleset;
      const updatedAttributes = {
        ...attributes,
        [tabStatusToBePaused]: 'paused',
      };
      this.props.dispatch(updateRuleSet(id, updatedAttributes));
    });
    this.setState(() => ({
      autoDecisionImpact: {
        isAutoDecisionImpactModalVisible: false,
        onConfirm: null,
        rulesetsThatWillBeImpacted: [],
      },
    }));
  },

  handleCancelAutoDecisionImpact() {
    this.setState({
      autoDecisionImpact: {
        isAutoDecisionImpactModalVisible: false,
        onConfirm: null,
        rulesetsThatWillBeImpacted: [],
        tabStatusToBePaused: null,
      },
    });
  },

  render() {
    const {
      amlCheckRules,
      antiFraudSettings,
      autosave,
      card,
      company_name,
      creditCheckOptions,
      creditModalText,
      creditWorksStatus,
      options,
      requiresAmlCheck,
      requires_credit_check,
      requiresAntiFraudCheck,
      role_types,
      router,
      selected_credit_check_rules,
      title,
      update_loading,
      requires_guarantees,
      minimum_guarantees,
      director_from_credit_check_enabled,
      minimum_guarantees_trust,
      guarantor_history,
      dispatch,
      autoDecisionRulesets,
    } = this.props;

    const {
      showAntiFraudModal,
      amlCheckModalVisible,
      showCreditInfoModal,
      showCreditModal,
      showFeatureNotEnabledModal,
      autoDecisionImpact: { isAutoDecisionImpactModalVisible },
      updating,
      showGuarantorModal,
    } = this.state;

    const button_components = [];

    let modalMarkup;
    let login_verification = false;

    const nzCreditCheckComponents = [];
    const auCreditCheckComponents = [];
    const nzPersonalCreditCheckComponents = [];
    const auPersonalCreditCheckComponents = [];
    const auIndividualCreditCheckComponents = [];
    let isAuEquifaxBusinessEnabled = false;

    const divStyle = { minHeight: '50px' };

    creditCheckOptions.forEach((item, index) => {
      const current_check = item;
      if (
        (item.requires_login &&
          item.id == selected_credit_check_rules.nz_rule_selection_id) ||
        (item.requires_login &&
          item.id == selected_credit_check_rules.au_rule_selection_id)
      ) {
        login_verification = true;
      }

      let current_check_active = current_check.active;
      let additionalDescription = '';

      const checkDescription = current_check.description || {};
      const businessCheckDescription = checkDescription.business_check;
      const formattedBusinessCheckDescription = this.formatDescription(
        businessCheckDescription
      );
      const individualCheckDescription = checkDescription.individual_check;
      const formattedIndividualCheck = this.formatDescription(
        individualCheckDescription
      );
      const personalCheckDescription = checkDescription.personal_check;
      const formattedPersonalCheckDescription = this.formatDescription(
        personalCheckDescription
      );

      if (
        current_check.requires_login &&
        creditWorksStatus === 'inactive' &&
        !company_name
      ) {
        current_check_active = false;
        additionalDescription = (
          <p className={checksStyles.requiredNotice}>
            Please complete business details section before selected this
            option.
          </p>
        );
      }

      if (
        !card &&
        isRegular() &&
        !current_check.requires_login &&
        current_check.active
      ) {
        current_check_active = false;
        additionalDescription = (
          <p className={checksStyles.requiredNotice}>
            {ADD_CREDIT_CARD_STRING}
          </p>
        );
      }

      if (item.region === 'NZ') {
        if (item.business_check) {
          const description = (
            <span>
              {formattedBusinessCheckDescription}
              {additionalDescription}
            </span>
          );

          nzCreditCheckComponents.push(
            <div key={index} className={checksStyles.checkbox_container}>
              <Checkbox
                key={index + '_business'}
                checkboxId={current_check.id + '_business'}
                checked={
                  item.id == selected_credit_check_rules.nz_rule_selection_id
                }
                handleChange={() =>
                  this.checkCreditCheckImpactOnAutoDecision(
                    current_check,
                    'business'
                  )
                }
                label={current_check.name}
                description={description}
                disabled={
                  !current_check_active || !isFeatureEditEnabled('Credit')
                }
                divStyle={divStyle}
              />
            </div>
          );
        }

        if (item.personal_check) {
          const description = (
            <span>
              {formattedPersonalCheckDescription}
              {additionalDescription}
            </span>
          );

          nzPersonalCreditCheckComponents.push(
            <div key={index} className={checksStyles.checkbox_container}>
              <Checkbox
                key={index + '_personal'}
                checkboxId={current_check.id + '_personal'}
                checked={
                  item.id ==
                  selected_credit_check_rules.nz_personal_rule_selection_id
                }
                handleChange={() =>
                  this.checkCreditCheckImpactOnAutoDecision(
                    current_check,
                    'personal'
                  )
                }
                label={current_check.name}
                description={description}
                disabled={
                  !current_check_active || !isFeatureEditEnabled('Credit')
                }
                divStyle={divStyle}
              />
            </div>
          );
        }
      } else if (item.region === 'AU') {
        if (item.business_check) {
          const description = (
            <span>
              {formattedBusinessCheckDescription}
              {additionalDescription}
            </span>
          );

          auCreditCheckComponents.push(
            <div key={index} className={checksStyles.checkbox_container}>
              <Checkbox
                key={index + '_business'}
                checkboxId={current_check.id + '_business'}
                checked={
                  item.id == selected_credit_check_rules.au_rule_selection_id
                }
                handleChange={() =>
                  this.checkCreditCheckImpactOnAutoDecision(
                    current_check,
                    'business'
                  )
                }
                label={current_check.name}
                description={description}
                disabled={
                  !current_check_active || !isFeatureEditEnabled('Credit')
                }
                divStyle={divStyle}
              />
            </div>
          );
        }

        if (
          FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_CONSUMER &&
          item.individual_check
        ) {
          const description = (
            <span>
              {formattedIndividualCheck}
              {additionalDescription}
            </span>
          );

          auIndividualCreditCheckComponents.push(
            <div key={index} className={checksStyles.checkbox_container}>
              <Checkbox
                key={index + '_individual'}
                checkboxId={current_check.id + '_individual'}
                checked={
                  item.id ==
                  selected_credit_check_rules.au_individual_rule_selection_id
                }
                handleChange={() =>
                  this.checkCreditCheckImpactOnAutoDecision(
                    current_check,
                    'individual'
                  )
                }
                label={current_check.name}
                description={description}
                disabled={
                  !current_check_active || !isFeatureEditEnabled('Credit')
                }
                divStyle={divStyle}
              />
            </div>
          );
        }

        if (item.personal_check) {
          isAuEquifaxBusinessEnabled = item.slug === 'au_equifax';

          const description = (
            <span>
              {formattedPersonalCheckDescription}
              {additionalDescription}
            </span>
          );

          auPersonalCreditCheckComponents.push(
            <div key={index} className={checksStyles.checkbox_container}>
              <Checkbox
                key={index + '_personal'}
                checkboxId={current_check.id + '_personal'}
                checked={
                  item.id ==
                  selected_credit_check_rules.au_personal_rule_selection_id
                }
                handleChange={() =>
                  this.checkCreditCheckImpactOnAutoDecision(
                    current_check,
                    'personal'
                  )
                }
                label={current_check.name}
                description={description}
                disabled={
                  !current_check_active || !isFeatureEditEnabled('Credit')
                }
                divStyle={divStyle}
              />
            </div>
          );
        }
      }
    });

    if (showAntiFraudModal) {
      modalMarkup = (
        <AntiFraud
          data={antiFraudSettings}
          closeModal={() => this.setState({ showAntiFraudModal: false })}
          router={router}
        />
      );
    }

    if (showGuarantorModal) {
      modalMarkup = (
        <Guarantor
          closeModal={() => this.setState({ showGuarantorModal: false })}
          dispatch={dispatch}
          minimumGuarantees={minimum_guarantees}
          requiresGuarantees={requires_guarantees}
          directorFromCreditCheckEnabled={director_from_credit_check_enabled}
          minimumGuaranteesTrust={minimum_guarantees_trust}
          autoDecisionRulesets={autoDecisionRulesets}
          guarantorHistory={guarantor_history}
          disableDirectorFromCreditCheck={!card && isRegular()}
        />
      );
    }

    if (amlCheckModalVisible) {
      modalMarkup = this.amlCheckModal();
    }

    if (showCreditModal) {
      let button;

      if (!card && isRegular() && !login_verification) {
        button = role_types.includes('admin') ? (
          <Button
            text="Add Card"
            handleClick={() => this.cancelCreditModal(true)}
          />
        ) : (
          <Button
            text="Cancel"
            handleClick={() => this.cancelCreditModal(false)}
          />
        );
      } else {
        button = <Button text="OK" handleClick={this.dismissCreditModal} />;
      }

      modalMarkup = (
        <CreditCheckModal
          title="Credit Check"
          bodyText={creditModalText}
          button={button}
          update_loading={update_loading}
          nzCreditCheckComponents={nzCreditCheckComponents}
          nzPersonalCreditCheckComponents={nzPersonalCreditCheckComponents}
          auCreditCheckComponents={auCreditCheckComponents}
          auPersonalCreditCheckComponents={auPersonalCreditCheckComponents}
          auIndividualCreditCheckComponents={auIndividualCreditCheckComponents}
          isAuEquifaxBusinessEnabled={isAuEquifaxBusinessEnabled}
          dismissHandler={() => this.cancelCreditModal(false)}
          close_button_style={
            autosave
              ? 'mandatory_button_close'
              : 'invite_mandatory_button_close'
          }
        />
      );
    }

    if (showCreditInfoModal) {
      modalMarkup = (
        <CreditCardModal
          onSave={this.saveCard}
          onCancel={this.cancelCardInfo}
        />
      );
    }

    const selectedProviders = Object.values(
      amlCheckRules.selectedProviders || {}
    );

    const checkKeys = {
      requires_aml_check: requiresAmlCheck,
      requires_anti_fraud_check: requiresAntiFraudCheck,
      requires_credit_check: requires_credit_check,
      requires_guarantees: requires_guarantees,
    };

    const mandatoryChecks = Object.keys(checkKeys).filter(
      (key) => checkKeys[key]
    );

    Object.keys(options).forEach((key, index) => {
      let selected = false;
      let highlighted = true;
      let dropdown = null;
      let checkboxOptions = null;
      const optionKey = options[key].value;

      if (mandatoryChecks.includes(optionKey)) {
        selected = true;
      }

      if (options[key].options && selected) {
        checkboxOptions = this.checkboxOptions(options[key]);
      }

      button_components.push(
        <div
          className={
            requiresAmlCheck ? checksStyles.item_aml : checksStyles.item
          }
          key={`container-${index}`}
        >
          <CircleIconButton
            key={`button-${index}`}
            value={options[key].value}
            type={key}
            selected={selected && highlighted}
            text={options[key].title}
            icon={options[key].icon}
            handleClick={(event) => {
              this.selectMainOption(event);
            }}
          />
          {dropdown}
          {checkboxOptions}
        </div>
      );
    });

    let loader = null;
    let update_button_text = 'update changes';
    let update_click = this.submit;
    let update_button = null;

    if (update_loading) {
      loader = <span className={styles.loading}></span>;
      update_button_text = 'saving';
      update_click = (event) => {};
    }

    update_button = (
      <div className={styles.button}>
        <Button handleClick={update_click} text={update_button_text} />
        {loader}
      </div>
    );

    return (
      <div className={styles.row}>
        <div className={styles.block_wide}>
          <div className={styles.liner}>
            {showFeatureNotEnabledModal && (
              <SquareModal
                dismissHandler={this.dismissFeatureNotEnabledModal}
                size="small"
                title="Ops! This feature cannot be enabled."
              >
                <p>
                  Please contact{' '}
                  <a href="mailto:support@1centre.com">support@1centre.com</a>{' '}
                  or live chat with us if you want to enable this feature.
                </p>
              </SquareModal>
            )}
            <h2 className={styles.header}>
              {autosave ? title : 'Mandatory checks'}
            </h2>
            <ExtraDescription
              hintName={'headquarterSettingHint'}
              show={isHeadquarter()}
            />
            {update_loading && update_button}
            <div className={checksStyles.row}>
              <div className={checksStyles.options}>{button_components}</div>
            </div>
            {modalMarkup}
          </div>
        </div>

        {isAutoDecisionImpactModalVisible && FEATURE_FLAGS.REVAMPED_1CAD && (
          <ImpactAutoDecisionRulesetPopup
            onCancel={this.handleCancelAutoDecisionImpact}
            onConfirm={this.handleConfirmAutoDecisionImpact}
            rulesetNames={this.getRulesetNames()}
          />
        )}
      </div>
    );
  },

  saveCard(response) {
    const { dispatch } = this.props;
    const { currentMandatoryCheck } = this.state;
    this.setState({ showCreditInfoModal: false });
    if (response) {
      dispatch(loadCard());
      switch (currentMandatoryCheck) {
        case 'requires_credit_check':
          this.submitCreditCheck(true);
          break;
        case 'requires_identification_check':
          this.submitIdentityCheck(true);
          break;
      }
    }
  },

  checkCreditCheckImpactOnAutoDecision(creditCheck, type) {
    const onConfirm = () => this.selectCreditCheckType(creditCheck, type);

    const {
      autoDecisionRulesets,
      selected_credit_check_rules: existingCreditCheckRules,
    } = this.props;

    const creditCheckKeyRegion = creditCheck.region.toLowerCase();

    // *** Old implementation
    // The key corresponding to the selected credit check in the
    // account settings
    const creditChecKey = `${creditCheckKeyRegion}${
      type === 'personal' ? '_personal_' : '_'
    }rule_selection_id`;

    // The key corresponding to the selected credit check in the
    // account settings
    const creditCheckKeyMap = {
      personal: '_personal_',
      individual: '_individual_',
    };

    const creditCheckKey = `${creditCheckKeyRegion}${
      creditCheckKeyMap[type] ? creditCheckKeyMap[type] : '_'
    }rule_selection_id`;

    // The key corresponding to the selected credit check in the
    // auto decision ruleset
    const autoDecisionCreditCheckKey = `${creditCheckKeyRegion}_${type}_credit_indicator`;

    // Let's check if the user intends to switch the bureau or turn off credit
    // check for a region and type combination. We determine this by checking the
    // value of the credit check id in the current settings. If the id exists in
    // the current settings, it means that the user is trying to switch bureau or
    // to turn on credit check for a region and type combo. This has the
    // potential to affect an auto decision ruleset.
    const isSwitchingBureauOrTurningCreditCheckOff =
      existingCreditCheckRules[
        FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_CONSUMER
          ? creditCheckKey
          : creditChecKey
      ];

    if (!isSwitchingBureauOrTurningCreditCheckOff) {
      onConfirm();
      return;
    }

    const matchingAutoDecisionRulesets = autoDecisionRulesets.filter(
      (ruleset) => {
        const { attributes } = ruleset;
        const { credit_checks_required: isCreditChecksRequiredInRuleset } =
          attributes;
        const creditCheckIndicator = attributes[autoDecisionCreditCheckKey];
        const isTheCreditIndicatorSet =
          creditCheckIndicator !== null && creditCheckIndicator !== undefined;
        return isCreditChecksRequiredInRuleset && isTheCreditIndicatorSet;
      }
    );

    if (!matchingAutoDecisionRulesets.length) {
      onConfirm();
      return;
    }

    this.setState({
      autoDecisionImpact: {
        isAutoDecisionImpactModalVisible: true,
        onConfirm,
        rulesetsThatWillBeImpacted: matchingAutoDecisionRulesets,
        tabStatusToBePaused: 'credit_checks_status',
      },
    });
  },

  selectCreditCheckType(creditCheck, type) {
    const { dispatch } = this.props;

    dispatch(setSeletedCreditCheckRules(creditCheck, type));
    this.submitCreditCheck(true);
  },

  selectMainOption(event) {
    const {
      update_loading: updateLoading,
      requiresAmlCheck,
      requiresAntiFraudCheck,
    } = this.props;

    if (!updateLoading) {
      switch (event) {
        case 'requires_anti_fraud_check':
          if (requiresAmlCheck) {
            this.setState({ showFeatureNotEnabledModal: true });
            break;
          }

          this.setState({ showAntiFraudModal: true });
          break;
        case 'requires_credit_check':
          this.setState({ showCreditModal: true });
          break;
        case 'requires_guarantees':
          this.setState({ showGuarantorModal: true });
          break;
        case 'requires_aml_check':
          if (requiresAntiFraudCheck) {
            this.setState({ showFeatureNotEnabledModal: true });
            break;
          }

          this.setState({ amlCheckModalVisible: true });
          break;
      }
    }
  },

  setAmlCheckLevel(value) {
    const {
      amlCheckRules,
      dispatch,
      update_loading: updateLoading,
    } = this.props;

    if (updateLoading) {
      return;
    }

    const newLevels = toggleArrayValue(amlCheckRules.selectedLevels, value);
    dispatch(setAmlCheckLevels(newLevels));

    this.submitAmlCheck();
  },

  submitAmlCheck() {
    const { autosave, dispatch } = this.props;

    if (autosave) {
      dispatch(persistAmlCheckConfig());
    }
  },

  submitCreditCheck(requiresCreditCheck) {
    const {
      dispatch,
      creditCheckOptions,
      creditWorksStatus,
      selected_credit_check_rules: selectedCreditCheckRules,
      autosave,
    } = this.props;

    let _requiresCreditCheck = requiresCreditCheck || false;
    let _selectedCreditCheckRule = selectedCreditCheckRules;
    let _creditWorksStatus = creditWorksStatus;

    const creditCheckOptionNZ = creditCheckOptions.find((item) => {
      return item.id == selectedCreditCheckRules.nz_rule_selection_id;
    });

    if (
      creditCheckOptionNZ &&
      creditCheckOptionNZ.requires_login &&
      creditWorksStatus === 'inactive'
    ) {
      dispatch(setCreditWorksStatus('pending'));
      _creditWorksStatus = 'pending';
    }

    if (
      (!selectedCreditCheckRules.nz_rule_selection_id &&
        !selectedCreditCheckRules.au_rule_selection_id &&
        !selectedCreditCheckRules.nz_personal_rule_selection_id &&
        !selectedCreditCheckRules.au_personal_rule_selection_id) ||
      !_requiresCreditCheck
    ) {
      _requiresCreditCheck = false;
      _selectedCreditCheckRule = {};
      dispatch(clearSeletedCreditCheckRules());
    }

    const attributes = {
      credit_works_status: _creditWorksStatus,
      requires_credit_check: _requiresCreditCheck,
      selected_credit_check_rules: _selectedCreditCheckRule,
    };

    dispatch(setMandatoryCheck('requires_credit_check', _requiresCreditCheck));

    if (autosave) {
      dispatch(updateMandatoryChecks(attributes));
    } else {
      dispatch(
        updateMandatoryChecksCreditWorks({
          credit_works_status: _creditWorksStatus,
        })
      );
    }
  },

  toggleAmlCheckProvider(region, provider) {
    const { amlCheckRules, dispatch } = this.props;
    const regionProvider = amlCheckRules.selectedProviders[region];

    const payload = { [region]: null };
    if (isPresent(regionProvider) && regionProvider === provider) {
      payload[region] = null;
    } else {
      payload[region] = provider;
    }

    dispatch(setSelectedAmlCheckRules(payload));

    const selectedProviders = Object.values(amlCheckRules.selectedProviders);
    const isAmlCheckRequired = selectedProviders.some((provider) =>
      isPresent(provider)
    );

    if (!isAmlCheckRequired) {
      dispatch(setAmlCheckLevels([]));
    }

    this.submitAmlCheck();
  },

  toggleTooltip(toolTipName, flag) {
    this.setState({ [toolTipName]: flag });
  },
});

const defaults = {
  creditModalText: (
    <span>
      Default company checks will be determined by which agency you select
      below. You must be an <u>existing</u> 'CreditWorks' customer (NZ only) to
      use this option. For Sole Traders, Trusts & Partnerships in New Zealand,
      checks on individuals will be completed via Equifax.
    </span>
  ),
  identityModalText:
    'Verify your customer and/or guarantors through a Drivers License or Passport check. We will confirm the validity of the ID, for you to check with the photo provided by your prospect customer.',
  options: {
    /* eslint-disable sort-keys-fix/sort-keys-fix */
    credit: {
      title: 'Credit checks',
      value: 'requires_credit_check',
      icon: 'wallet',
      count: null,
    },
    guarantees: {
      title: 'Guarantors',
      value: 'requires_guarantees',
      icon: 'pig',
    },
    anti_fraud: {
      title: 'Anti-Fraud (1CAF)',
      value: 'requires_anti_fraud_check',
      icon: 'card',
    },
  },
  title: 'Mandatory checks (required)',
};

function filterIllionChecks(
  name,
  illionCodeExist,
  isManual = false,
  is_hybrid_but_include_nz = false,
  region = null
) {
  if (isManual) {
    return true;
  }

  const isIllion = name.includes('Illion');

  if (illionCodeExist) {
    if (is_hybrid_but_include_nz) {
      return isIllion || region === 'NZ';
    } else {
      return isIllion;
    }
  }

  return !isIllion;
}

export default connect((state, ownProps) => {
  const s = state.manage_profile;
  const entity = state.manage_profile.current_entity.attributes;
  const illionCodeExist = entity.illion_code_exist;
  let creditCheckOptions = s.settings_credit_check_lookup || [];
  let amlCheckOptions = s.settingsAmlCheckLookup || [];
  const is_hybrid_but_include_nz = entity.is_hybrid_but_include_nz;
  const antiFraudSettings = s.settingsAntiFraud || [];

  const amlCheckRules = s.settingsAmlCheckRules || {
    addonConfigActive: false,
    selectedProviders: {},
  };

  let requiresAmlCheck = false;
  if (amlCheckRules.addonConfigActive) {
    const amlProviders = amlCheckRules.selectedProviders;
    const amlProviderKeys = Object.keys(amlProviders);

    requiresAmlCheck = amlProviderKeys.length > 0;
  }

  if (requiresAmlCheck) {
    defaults.options = {
      ...defaults.options,
      aml: {
        title: 'AML check',
        modalText:
          'Verify your customer and/or guarantors through a Drivers License or Passport check. We will confirm the validity of the ID, for you to check with the photo provided by your prospect customer.',
        value: 'requires_aml_check',
        icon: 'card',
        count: null,
        options: {
          key: 'aml_check_level',
          label: 'Who would you like to check?',
          default_value: 'applicant',
          list: [
            {
              label: 'Applicant',
              tooltip: 'the authorised person completing application',
              value: 'applicant',
            },
            {
              label: 'Guarantor',
              tooltip: 'the authorised person/s accepting liability for debt',
              value: 'guarantor',
            },
            {
              label: 'Signatory',
              tooltip: 'for trustees, partners and joint applicants',
              value: 'signatory',
            },
            {
              label: 'Cardholder',
              tooltip: 'for additional cardholders on account',
              value: 'cardholder',
            },
          ],
        },
      },
    };
  }

  creditCheckOptions = creditCheckOptions.filter(
    (cc) =>
      cc.name &&
      filterIllionChecks(
        cc.name,
        illionCodeExist,
        false,
        is_hybrid_but_include_nz,
        cc.region
      )
  );
  amlCheckOptions = amlCheckOptions.filter(
    (cc) =>
      cc.attributes &&
      filterIllionChecks(
        cc.attributes.name,
        illionCodeExist,
        false,
        is_hybrid_but_include_nz,
        cc.attributes.region
      )
  );

  let requiresIdentificationCheck = s.settings_requires_identification_check;
  let requiresAntiFraudCheck = s.settingsRequiresAntiFraud;
  if (!isPresent(s.settings_requires_identification_check)) {
    const selectedIdentificationCheckRules = Object.values(
      entity.selected_identification_check_rules
    );

    requiresIdentificationCheck = selectedIdentificationCheckRules.some(
      (rule) => isPresent(rule)
    );
  }

  if (!s.settingsRequiresAntiFraud && antiFraudSettings.length > 0) {
    requiresAntiFraudCheck = antiFraudSettings.some(
      (antiFraudSetting) => antiFraudSetting.active
    );
  }

  const requires_credit_check =
    s.settings_requires_credit_check != null
      ? s.settings_requires_credit_check
      : entity.requires_credit_check;
  const requires_guarantees =
    s.settings_requires_guarantees != null
      ? s.settings_requires_guarantees
      : entity.requires_guarantees;
  const minimum_guarantees =
    s.settings_minimum_guarantees != null
      ? s.settings_minimum_guarantees
      : entity.minimum_guarantees;
  const director_from_credit_check_enabled =
    s.settings_director_from_credit_check_enabled != null
      ? s.settings_director_from_credit_check_enabled
      : entity.director_from_credit_check_enabled;
  const minimum_guarantees_trust =
    s.settings_minimum_guarantees_trust != null
      ? s.settings_minimum_guarantees_trust
      : entity.minimum_guarantees_trust;
  const guarantor_history = s.settings_guarantors_account_rule_changed_histories
    ? s.settings_guarantors_account_rule_changed_histories
    : entity.guarantors_account_rule_changed_histories || [];
  const changes_made =
    s.settings_section_edited != null
      ? s.settings_section_edited['mandatory_checks']
      : false;

  const creditWorksStatus = s.settings_credit_works_status;

  return {
    amlCheckOptions,
    antiFraudSettings,
    amlCheckRules,
    autosave: ownProps.autosave || false,
    card: s.current_entity_card,
    cardsAddons: s.settingsCardsAddons || [],
    changes_made,
    company_name:
      s.settings_company_name != null
        ? s.settings_company_name
        : entity.company_name,
    creditCheckOptions,
    creditModalText: defaults.creditModalText,
    creditWorksStatus,
    entity: state.current_user.current_entity,
    identityModalText: defaults.identityModalText,
    illionCodeExist,
    options: defaults.options,
    requires_credit_check,
    requires_guarantees,
    minimum_guarantees,
    director_from_credit_check_enabled,
    minimum_guarantees_trust,
    guarantor_history,
    requires_identification_check: requiresIdentificationCheck,
    requiresAntiFraudCheck,
    requiresAmlCheck,
    role_types:
      state.current_user.current_user_entity_link.attributes.role_types,
    selected_credit_check_rules: s.settings_selected_credit_check_rules,
    title: defaults.title,
    update_loading: s.settings_mandatory_checks_updating,
    ...(FEATURE_FLAGS.REVAMPED_1CAD && {
      autoDecisionRulesets: state.add_ons.auto_decisions,
    }),
  };
})(MandatoryChecks);
