import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';
import styled from 'styled-components';

import Button from '../inputs/Button';

const CloseIconContainer = styled.div`
  .close-icon-button {
    position: absolute;
    right: 4px;
    top: 4px;
  }
  .close-icon {
    height: 28px;
    width: 28px;
  }
`;

const StyledDialog = styled(MuiDialog)`
  .MuiPaper-rounded {
    border-radius: 15px;
  }
  .MuiDialogTitle-root {
    text-align: center;
    padding: 50px 24px 16px;
  }
  .MuiDialogContent-root {
    padding: 8px 50px;
  }
  .MuiDialogActions-root {
    padding-bottom: 50px;
    padding-top: 32px;
    justify-content: center;
  }
  .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 16px;
  }
`;

type NewModalProps = {
  children: JSX.Element | JSX.Element[];
  title: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  onClose?: () => void;
  confirmButtonProps?: any;
  cancelButtonProps?: any;
  isOpen: boolean;
};

const Dialog = (props: NewModalProps) => {
  const {
    children,
    isOpen,
    title,
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    onConfirm = () => {},
    onCancel = () => {},
    onClose,
    confirmButtonProps,
    cancelButtonProps,
  } = props;

  return (
    <StyledDialog open={isOpen} classes={{ paper: 'MuiPaper-rounded' }}>
      <CloseIconContainer>
        <IconButton className="close-icon-button" onClick={onClose || onCancel}>
          <CancelIcon className="close-icon" />
        </IconButton>
      </CloseIconContainer>

      <DialogTitle className="MuiDialogTitle-root">{title}</DialogTitle>
      <DialogContent className="MuiDialogContent-root">
        {children}
      </DialogContent>
      <DialogActions
        classes={{ spacing: 'MuiDialogActions-spacing' }}
        className="MuiDialogActions-root"
      >
        <Button
          {...confirmButtonProps}
          text={confirmButtonText}
          onClick={onConfirm}
          type="button"
        />
        <Button
          {...cancelButtonProps}
          text={cancelButtonText}
          onClick={onCancel}
          type="button"
        />
      </DialogActions>
    </StyledDialog>
  );
};

export default Dialog;
