import React, { useEffect, useState } from 'react';
import logo from 'images/logo-equifax.png';
import get from 'lodash.get';

import styles from './report-styles.css';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import ReportHeader from './ReportHeader';
import ReportSection from './ReportSection';
import ReportScore from './ReportScore';
import ReportHeadline from './ReportHeadline';
import ReportEnquiriesDefaults from './ReportEnquiriesDefaults';
import ReportInsolvenciesCourtActions from './ReportInsolvenciesCourtActions';
import ReportBusinessRelationships from './ReportBusinessRelationships';
import ReportIdentity from './ReportIdentity';
import ReportCreditInquiries from './ReportCreditInquiries';
import ReportEmptySection from './ReportEmptySection';
import ReportDictatorship from './ReportDictatorship';
import ReportPdf from './ReportPdf';

const reportLabelMap = {
  personal: 'Commercial',
  individual: 'Consumer',
};

const ErrorPage = ({ type, data, mainHeader }) => {
  let message = '';

  switch (type) {
    case 'fault':
      {
        const faultString = get(data, 'fault.faultstring');
        const faultError = get(data, 'fault.detail.error.description');
        message = faultError || faultString;
      }
      break;
    case 'errors':
      {
        message = get(data, 'response.errors.error.fault_string');
      }
      break;
  }

  return (
    <div>
      <ReviewContainer
        subHeading={mainHeader}
        content_class="content_wide"
        css_class="block_noborder"
        fontSize="large"
      >
        <p>{message}</p>
        <p>
          Contact <a href="mailto:support@1centre.com">support@1centre.com</a>{' '}
          or live chat with us.
        </p>
      </ReviewContainer>
    </div>
  );
};

const parseData = (data, reportType) => {
  let productHeader = get(data, 'response.product_header', {}),
    productData = get(data, 'response.product_data', {}),
    scoreData = get(productData, 'score_data', {}),
    enquiryReport = get(productData, 'enquiry_report', {}),
    creditEnquiries = get(
      enquiryReport,
      `${reportLabelMap[
        reportType
      ].toLowerCase()}_credit_file.credit_enquiries`,
      {}
    ),
    creditEnquiry = get(creditEnquiries, 'credit_enquiry', {}),
    primaryMatch = get(enquiryReport, 'primary_match', {}),
    individualName = get(primaryMatch, 'individual_name', {}),
    employment = get(enquiryReport, 'employment', {}),
    employer = get(employment, 'employer', {}),
    employerList = Array.isArray(employer) ? employer : [employer],
    addresses = get(enquiryReport, 'addresses', {}),
    address = get(addresses, 'address', []),
    characteristic = get(
      productData,
      'summary_data.data_block.characteristic',
      []
    ),
    directorships = get(
      enquiryReport,
      'public_record_or_consumer_credit_information.directorships',
      {}
    ),
    directorship = get(directorships, 'directorship', []),
    businessNames = get(
      enquiryReport,
      'public_record_or_consumer_credit_information.business_names',
      {}
    ),
    businessName = get(businessNames, 'business_name', []);

  return {
    productHeader,
    productData,
    scoreData,
    enquiryReport,
    creditEnquiries,
    creditEnquiry,
    primaryMatch,
    individualName,
    addresses,
    address,
    characteristic,
    directorships,
    directorship,
    employment,
    employer,
    employerList,
    businessNames,
    businessName,
  };
};

const EquifaxReport = (props) => {
  const { data, mainHeader, pdf, signed_pdf, reportType } = props;

  const [reportData, setReportData] = useState<any>();

  useEffect(() => {
    setReportData(parseData(data, reportType));
  }, [data]);

  const hasParsedData = !!Object.keys(reportData || {}).length;

  if (!data || !data.response || !hasParsedData) {
    if (data && data.fault) {
      return <ErrorPage type="fault" data={data} mainHeader={mainHeader} />;
    }
    return null;
  } else if (data.response.errors) {
    return <ErrorPage type="errors" data={data} mainHeader={mainHeader} />;
  }

  return (
    <div className="pt-6">
      <img
        className={styles.veda_logo}
        src={logo}
        alt="Equifax"
        width="277.9"
        height="79.1"
      />
      <h2 className={styles.heading_large}>{mainHeader}</h2>
      <ReportSection fontSize="default">
        <ReportHeader {...reportData} />
      </ReportSection>
      <ReportSection sectionHeader="Summary">
        <ReportScore
          productData={get(reportData, 'productData')}
          scoreData={get(reportData, 'scoreData')}
        />
      </ReportSection>
      <ReportSection sectionHeader="Headlines">
        <ReportHeadline
          characteristic={get(reportData, 'characteristic')}
          reportType={reportType}
        />
      </ReportSection>
      <ReportSection sectionHeader="Credit enquiries &amp; defaults">
        <ReportEnquiriesDefaults
          reportType={reportType}
          characteristic={get(reportData, 'characteristic')}
        />
      </ReportSection>
      <ReportSection sectionHeader="Personal insolvencies &amp; court actions">
        <ReportInsolvenciesCourtActions
          insolvencyType="personal"
          characteristic={get(reportData, 'characteristic')}
        />
      </ReportSection>
      <ReportSection sectionHeader="Business relationships">
        <ReportBusinessRelationships
          characteristic={get(reportData, 'characteristic')}
        />
      </ReportSection>
      <ReportSection sectionHeader="Identity details">
        <ReportIdentity {...reportData} />
      </ReportSection>
      <ReportSection sectionHeader="Credit enquiries">
        <ReportCreditInquiries
          creditEnquiries={get(reportData, 'creditEnquiry')}
          creditEnquiry={get(reportData, 'creditEnquiry')}
          characteristic={get(reportData, 'characteristic')}
          inquiryType={reportLabelMap[reportType]}
        />
      </ReportSection>
      <ReportSection sectionHeader="Insolvencies &amp; court actions">
        <ReportInsolvenciesCourtActions
          characteristic={get(reportData, 'characteristic')}
        />
      </ReportSection>
      <ReportSection subHeading="Defaults &amp; CCPs">
        <ReportEmptySection label={`${reportLabelMap[reportType]} Accounts`} />
      </ReportSection>
      <ReportSection subHeading="Business relationships">
        <ReportDictatorship
          characteristic={get(reportData, 'characteristic')}
          directorship={get(reportData, 'directorship')}
          businessName={get(reportData, 'businessName')}
        />
      </ReportSection>
      <ReportSection>
        <ReportPdf pdf={pdf} signed_pdf={signed_pdf} />
      </ReportSection>
    </div>
  );
};

export default EquifaxReport;
