import { Typography } from '@material-ui/core';
import { FEATURE_FLAGS } from 'conf';
import { THIRD_PARTY_CONSENT_LABEL } from 'constants';
import get from 'lodash.get';
import commonStyles from 'modules/card-management-onboarding/css/Section.css';
import styles from 'modules/card-management-onboarding/css/Terms.css';
import Button from 'modules/shared/components/inputs/Button';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import UncontrolledTextInput from 'modules/shared/components/inputs/UncontrolledTextInput';
import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isSanitizedStringEqual } from 'utils/sanitizeName';
import * as yup from 'yup';

export function checkListItems(application) {
  const supplierName = application.supplierName;
  const isTokenised = get(application, 'antiFraudCheckConfig.config.tokenised');

  const items = [
    {
      label: 'I am authorised to sign this form',
      name: 'authorised',
    },
    {
      label: `I have read and understood the terms and conditions for ${supplierName}`,
      name: 'read_terms',
    },
  ];

  if (isTokenised && !items.find(({ name }) => name === 'anti_fraud_id_hold')) {
    items.push({
      label: `I confirm that ${supplierName} has the right to hold my\n
        ID documents on file until my identity has been confirmed`,
      name: 'anti_fraud_id_hold',
    });
  }

  items.push({
    label: THIRD_PARTY_CONSENT_LABEL,
    name: 'thirdPartyCheckConsent',
  });

  return items;
}

function CheckListItem(props) {
  const [isChecked, setIsChecked] = useState(false);

  const onToggleIsChecked = () => {
    const bool = !isChecked;
    if (bool) props.clearError(props.checkboxName);
    setIsChecked(bool);
  };

  return (
    <div>
      <div className={commonStyles.row} onClick={onToggleIsChecked}>
        <SquareCheckbox
          checked={isChecked}
          label={props.label}
          inputRef={props.inputRef}
          checkboxName={props.checkboxName}
        />
      </div>
      <div className={commonStyles.error}>
        {get(props.errors, `${props.checkboxName}.message`, '')}
      </div>
    </div>
  );
}

function CheckList(props) {
  const { application } = props;
  const checkList = checkListItems(application);

  return (
    <div className={styles.check_list}>
      {checkList.map((item) => (
        <CheckListItem
          key={item.name}
          checkboxName={item.name}
          label={item.label}
          {...props}
        />
      ))}
    </div>
  );
}

function TermsAndConditions(props) {
  const { application } = props;

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.terms_block}>
        <span className={styles.terms}>{application.terms}</span>
      </div>
      {FEATURE_FLAGS.FEATURE_FLAG_UPDATED_TNCS ? (
        <Fragment>
          <div>
            <Typography variant="h5" gutterBottom>
              General Acknowledgement and Agreement
            </Typography>
            <div className={commonStyles.checklist_container}>
              <CheckList application={application} {...props} />
            </div>
          </div>
          <hr className={styles.divider} />
        </Fragment>
      ) : (
        <CheckList application={application} {...props} />
      )}
    </div>
  );
}

function DigitalSignature(props) {
  return (
    <div>
      <div className={styles.tnc_instruction}>
        If you agree please fill out your first and last name in the fields
        below and press agree.
      </div>
      <div className={commonStyles.row}>
        <div className={commonStyles.half_col}>
          <UncontrolledTextInput
            id={'first_name'}
            label={'First name'}
            error={get(props.errors, 'first_name.message', '')}
            inputRef={props.inputRef}
          />
        </div>
        <div className={commonStyles.half_col}>
          <UncontrolledTextInput
            id={'last_name'}
            label={'Last name'}
            error={get(props.errors, 'last_name.message', '')}
            inputRef={props.inputRef}
          />
        </div>
      </div>
    </div>
  );
}

export default function Terms(props) {
  const { cardholderAuthorisation } = props;
  const { first_name: firstName, last_name: lastName } =
    cardholderAuthorisation.signature;

  const termsSchema = yup.object().shape({
    authorised: yup
      .boolean()
      .test('authorised', 'Please check to agree.', (value) => value),
    first_name: yup
      .string()
      .required('Please input first name.')
      .test('first_name_test', 'Incorrect first name.', (value) =>
        isSanitizedStringEqual(firstName, value)
      ),
    last_name: yup
      .string()
      .required('Please input last name.')
      .test('last_name_test', 'Incorrect last name.', (value) =>
        isSanitizedStringEqual(lastName, value)
      ),
    read_terms: yup
      .boolean()
      .test('readTerms', 'Please check to agree.', (value) => value),
    thirdPartyCheckConsent: yup
      .boolean()
      .test(
        'thirdPartyCheckConsent',
        'Please check to agree.',
        (value) => value
      ),
  });

  const onSubmit = (data) => {
    cardholderAuthorisation.setAttributes({ approved: true });
    (async () => {
      const result = await cardholderAuthorisation.saveDecision(
        props.cardholderAuthorisationId
      );
      if (result.status === 200) props.toNextSection();
    })();
  };

  const { setValue, clearError, errors, handleSubmit, register } = useForm({
    mode: 'onBlur',
    validationSchema: termsSchema,
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={commonStyles.container}>
          <section className={commonStyles.section}>
            <TermsAndConditions
              application={cardholderAuthorisation}
              inputRef={register}
              errors={errors}
              clearError={clearError}
            />
            <DigitalSignature inputRef={register} errors={errors} />
            <div className={commonStyles.flow_buttons}>
              <Button text="Back" onClick={props.toPrevSection} />
              <Button
                text="Agree"
                type="submit"
                loading={cardholderAuthorisation.isLoading}
              />
            </div>
          </section>
        </div>
      </form>
    </div>
  );
}
