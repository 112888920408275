import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import { COLORS } from 'variables/theme';

const Container = styled.div`
  background: ${COLORS.lightGrey};
  padding: 20px;
  position: sticky;
  top: 66px;
  .title {
    font-size: 20px;
  }
  z-index: 1;
  @media screen and (min-width: 992px) {
    padding: 32px 50px;
  }
`;

type Props = {
  title?: string;
  children: JSX.Element | JSX.Element[];
};

export const Header = (props: Props) => {
  const { children, title } = props;
  return (
    <Container>
      {title ? (
        <Typography className="title" variant="h1">
          {title}
        </Typography>
      ) : null}
      {children}
    </Container>
  );
};
