import get from 'lodash.get';
import AddonConfigModel from 'models/AddonConfigModel';
import { loadInternalUseFieldsAddon } from 'modules/addons/actions';
import { loadCurrentEntity } from 'modules/profile/actions';
import Button from 'modules/shared/components/inputs/Button';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import AdminTitle from 'modules/shared/components/widgets/static/AdminTitle';
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';
import { isIUFApprovalEnabled } from 'modules/shared/helpers/internalUseFieldsHelper';
import isReviewerEnabled from 'modules/shared/helpers/isReviewerEnabled';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { formatMoney } from 'utils/formatting';

import { showPricingPlan } from '../../pricing-plans/actions';
import { getLatestApprovalLevels } from '../../profile/actions';
import {
  checkIfEmailExists,
  clearEmailValidation,
  clearUserContacts,
  loadUser,
  updateUser,
} from '../actions';
import UserForm from '../forms/UserForm';
import styles from './css/UpdateUser.css';
import ManageNonUserKeyContacts from './ManageNonUserKeyContacts';
import ManageUserKeyContacts from './ManageUserKeyContacts';
import AccessTokenisedDocument from './Permissions/AccessTokenisedDocument';
import CallCentre from './Permissions/CallCentre';
import CreditChecks from './Permissions/CreditChecks';
import IUFApproval from './Permissions/IUFApproval';
import OverrideLimitAmount from './Permissions/OverrideLimitAmount';
import VirtualCreditFile from './Permissions/VirtualCreditFile';
import UserRolePermissions from './UserRolePermissions';
import userTypes from 'modules/shared/users/userTypes';
import ConsumerManagerUserType from './ConsumerManagerUserType';

const ROLE_TYPE_CONSUMER_MANAGER = 'consumer_manager';

export function UserKeyContactsComponent(props) {
  const { user_type } = props;

  if (user_type === 'websitebutton') {
    return <ManageNonUserKeyContacts {...props} />;
  }

  return <ManageUserKeyContacts {...props} />;
}

class UpdateUser extends React.Component {
  state = {
    bulkReassignModalOpen: false,
    form_errors: {},
    form_loading: {},
    form_values: null,
    isCallCentreActive: false,
  };

  backToProfilePage = () => {
    const { dispatch } = this.props;
    dispatch(clearUserContacts());
    browserHistory.push('/dashboard/profile?active_tab=your_team');
  };

  checkEmail = (email_address) => {
    const { dispatch } = this.props;
    dispatch(checkIfEmailExists(email_address, this.updateEmailValid));
  };

  setBulkReassignModalState = (open) => {
    this.setState({ bulkReassignModalOpen: open });
  };

  async componentDidMount() {
    const { dispatch } = this.props;
    dispatch(clearEmailValidation());
    dispatch(getLatestApprovalLevels());
    dispatch(loadUser(this.props.user_id));
    dispatch(loadInternalUseFieldsAddon());
    await this.loadFormData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props;
    if (prevProps.user !== user) this.loadFormData();
  }

  loadFormData = async () => {
    const { user, currentUser } = this.props;

    if (user) {
      const {
        userType,
        userTypeConsumerManager,
      } = this.evalUserTypeAndUserTypeConsumerManager(user.attributes);
      this.setState({
        form_values: {
          page_permissions: user.attributes.page_permissions,
          user_approval_level: user.attributes.level,
          user_can_override_limit: user.attributes.can_override_limit,
          user_contact_phone_number: user.attributes.contact_phone_number,
          user_email: user.attributes.email,
          user_first_name: user.attributes.first_name,
          user_last_name: user.attributes.last_name,
          user_permissions: user.attributes.permissions,
          user_receive_email_notifications:
            user.attributes.receive_email_notifications,
          user_type: userType,
          user_type_consumer_manager: userTypeConsumerManager,
        },
        original_email: user.attributes.email,
      });

      const addonConfigs = await AddonConfigModel.fetchAddonConfigByAddonType({
        addonType: ['call_centre'],
        entityId: currentUser.current_entity.id,
      });
      let addonConfig = addonConfigs[0];
      this.setState({
        isCallCentreActive: addonConfig && addonConfig.active,
      });

      this.checkValid({
        name: 'user_first_name',
        value: user.attributes.first_name || '',
      });
      this.checkValid({
        name: 'user_last_name',
        value: user.attributes.last_name || '',
      });
      this.checkValid({
        name: 'user_email',
        value: user.attributes.email || '',
      });
      this.checkValid({
        name: 'user_contact_phone_number',
        value: user.attributes.contact_phone_number || '',
      });
    }
  };

  evalUserTypeAndUserTypeConsumerManager = (userAttributes) => {
    return {
      userType:
        userAttributes.role_types.find(
          (item) => item !== ROLE_TYPE_CONSUMER_MANAGER
        ) || null,
      userTypeConsumerManager:
        userAttributes.role_types.find(
          (item) => item === ROLE_TYPE_CONSUMER_MANAGER
        ) || '',
    };
  };

  update = () => {
    const { dispatch, location, user } = this.props;
    const { form_errors, form_values, contact_changes } = this.state;

    let attributes = {
      can_override_limit: form_values.user_can_override_limit,
      can_review_credit_checks: form_values.user_can_review_credit_checks,
      can_review_virtual_credit_file:
        form_values.user_can_review_virtual_credit_file,
      contact_phone_number: form_values.user_contact_phone_number,
      email: form_values.user_email,
      first_name: form_values.user_first_name,
      last_name: form_values.user_last_name,
      level: form_values.user_approval_level,
      permissions: form_values.user_permissions,
      receive_email_notifications: form_values.user_receive_email_notifications,
      role_types: this.evalRoleTypes(form_values),
    };

    dispatch(updateUser(user.id, attributes, (id) => {}));
  };

  _onEmailChangeHandler = (value, isValid) => {
    const { form_values, form_errors } = this.state;

    form_values['user_email'] = value;
    this.setState({ form_values });

    if (isValid) {
      const { user_email, ...rest } = form_errors;
      this.setState({
        form_errors: { ...rest },
      });

      this.checkEmail(value);
    } else {
      this.setState({
        form_errors: { ...form_errors, user_email: true },
      });
    }
  };

  _onEmailBlurHandler = (value, isValid) => {
    if (!isValid) return;
    if (this.checkAllValid()) this.update();
  };

  handleChange = (e) => {
    const { dispatch, user } = this.props;

    let { form_values } = this.state;
    if (e.hasOwnProperty('target')) {
      e = e.target;
      this.checkValid(e);
    }

    if (e.id === 'user_receive_email_notifications') {
      form_values[e.id] = e.checked;
      this.setState({ form_values });
      let attributes = {
        receive_email_notifications: e.checked,
      };

      dispatch(updateUser(user.id, attributes, (id) => {}));
    } else {
      form_values[e.id] = e.value;
      this.setState({ form_values });
    }
  };

  handleBlur = (event) => {
    const { dispatch, email_valid, email_validating } = this.props;
    const { form_values, original_email, contact_changes } = this.state;

    if (event.target) {
      this.checkValid(event.target);
    }

    let valid = this.checkAllValid();

    if (valid) {
      this.update();
    }
  };

  handleCheckbox = (item) => {
    const { dispatch, current_pricing_plan, user } = this.props;
    const { form_values } = this.state;

    let attributes = {};

    switch (item.type) {
      case 'user_can_override_limit':
        form_values.user_can_override_limit = item.value;
        attributes = { can_override_limit: item.value };
        break;
      case 'user_permissions':
        if (current_pricing_plan.attributes.permissioning) {
          form_values[item.type] = form_values[item.type] === 'n' ? 'm' : 'n';

          attributes = { permissions: form_values.user_permissions };
        } else {
          dispatch(showPricingPlan(true, 'others'));
          return false;
        }
        break;
      case 'user_type':
        form_values[item.type] = item.value;
        attributes = { role_types: this.evalRoleTypes(form_values) };
        if (item.value == 'standard') {
          form_values.user_credit_limit = null;
          attributes.level = null;
        }
        break;
      case 'user_type_consumer_manager':
        form_values[item.type] =
          form_values[item.type] === ROLE_TYPE_CONSUMER_MANAGER
            ? ''
            : ROLE_TYPE_CONSUMER_MANAGER;
        attributes = { role_types: this.evalRoleTypes(form_values) };
        break;
      case 'approval_level':
        form_values.user_approval_level = item.value;
        attributes = { level: item.value };
        break;
      case 'page_permissions': {
        const updatedPagePermissions = {
          ...form_values.page_permissions,
          [item.id]: item.value,
        };

        form_values.page_permissions = updatedPagePermissions;
        attributes = {
          page_permissions: updatedPagePermissions,
        };

        break;
      }
    }
    this.setState({
      form_values,
    });

    dispatch(
      updateUser(
        user.id,
        {
          level: get(user, 'attributes.level'),
          ...attributes,
        },
        (id) => {}
      )
    );
  };

  evalRoleTypes = (formValues) => {
    let result = [formValues.user_type];
    if (formValues.user_type_consumer_manager === ROLE_TYPE_CONSUMER_MANAGER) {
      result.push(ROLE_TYPE_CONSUMER_MANAGER);
    }
    return result;
  };

  checkAllValid = () => {
    const { email_valid, email_validating } = this.props;
    const { form_values, form_errors, original_email } = this.state;

    if (
      !form_values['user_first_name'] ||
      form_values['user_first_name'].length < 1 ||
      !form_values['user_last_name'] ||
      form_values['user_last_name'].length < 1 ||
      !form_values['user_contact_phone_number'] ||
      form_values['user_contact_phone_number'].length < 1
    )
      return false;
    if (form_errors.hasOwnProperty('user_email') && form_errors.user_email)
      return false;
    if (
      email_valid === false &&
      !email_validating &&
      original_email !== form_values['user_email']
    )
      return false;

    return true;
  };

  checkValid = (target) => {
    switch (target.name) {
      case 'user_first_name':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...this.state.form_errors,
              user_first_name: 'You must enter a first name.',
            },
          });
        } else {
          const { user_first_name, ...rest_errors } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case 'user_contact_phone_number':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...this.state.form_errors,
              user_contact_phone_number: 'You must enter a phone number.',
            },
          });
        } else {
          const {
            user_contact_phone_number,
            ...rest_errors
          } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case 'user_last_name':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...this.state.form_errors,
              user_last_name: 'You must enter a last name.',
            },
          });
        } else {
          const { user_last_name, ...rest_errors } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
    }
  };

  render() {
    const {
      title,
      user,
      saving,
      email_valid,
      email_validating,
      current_pricing_plan,
      team_list_loading,
      isSupplier,
      user_id,
    } = this.props;
    const {
      form_errors,
      form_values,
      form_loading,
      original_email,
      isCallCentreActive,
      bulkReassignModalOpen,
    } = this.state;

    if (team_list_loading) {
      return <SimpleLoader css_class="loader_relative" />;
    }

    if (!user || !form_values) {
      return <div />;
    }

    let email_error = form_errors['user_email'];
    if (
      !email_error &&
      email_valid === false &&
      original_email !== form_values['user_email'] &&
      !email_validating
    ) {
      email_error = 'Email already in use. Please use another';
    }
    let errors = { ...form_errors, user_email: email_error };

    let loading = form_loading;
    if (email_validating) {
      loading = { ...loading, user_email: true };
    }

    let valid =
      this.checkAllValid() &&
      (email_valid || original_email === form_values['user_email']) &&
      !email_validating;
    let button_text = 'update',
      disable_btn = false;
    if (!valid) {
      button_text = 'invalid info';
      disable_btn = true;
    }

    let permissions_content;
    if (current_pricing_plan) {
      permissions_content = (
        <div className={styles.permissions}>
          <span className={styles.permissions_line} />
          <UserRolePermissions
            selected_value={form_values.user_permissions}
            component={this.props.permissions}
            handleChange={this.handleCheckbox}
            permissionTip={true}
          >
            <OverrideLimitAmount
              handleCheck={this.handleCheckbox}
              isChecked={form_values.user_can_override_limit}
              userType={form_values.user_type}
            />
            <CreditChecks
              handleCheck={this.handleCheckbox}
              isChecked={get(
                form_values,
                'page_permissions.can_review_credit_checks',
                false
              )}
            />
            <VirtualCreditFile
              handleCheck={this.handleCheckbox}
              isChecked={get(
                form_values,
                'page_permissions.can_review_virtual_credit_file',
                false
              )}
            />
            <AccessTokenisedDocument
              handleCheck={this.handleCheckbox}
              isChecked={get(
                form_values,
                'page_permissions.can_access_tokenised_document',
                false
              )}
            />
            {isIUFApprovalEnabled() && (
              <IUFApproval
                handleCheck={this.handleCheckbox}
                isChecked={get(
                  form_values,
                  'page_permissions.can_approve_iuf',
                  false
                )}
                userType={form_values.user_type}
              />
            )}
            {isCallCentreActive && (
              <CallCentre
                handleCheck={this.handleCheckbox}
                isChecked={get(
                  form_values,
                  'page_permissions.can_call_centre_sudo',
                  false
                )}
              />
            )}
          </UserRolePermissions>
        </div>
      );
    }

    let approval_levels_content;
    approval_levels_content = (
      <div className={styles.user_type}>
        <UserRolePermissions
          selected_value={form_values.user_approval_level}
          component={this.props.approval_levels}
          handleChange={this.handleCheckbox}
        />
      </div>
    );

    return (
      <section className={styles.section}>
        {!bulkReassignModalOpen && (
          <CloseButton
            text="Close"
            css_class={'mandatory_button_close'}
            handleClick={this.backToProfilePage}
          />
        )}
        {form_values.user_type !== 'websitebutton' && (
          <div className={styles.row}>
            <div className={styles.columns}>
              <AdminTitle text={title}>
                <span className={styles.controls}>
                  {(saving || !valid) && (
                    <Button
                      small={true}
                      text={button_text}
                      loading_text={'saving'}
                      handleClick={this.update}
                      loading={saving}
                      disableOnLoading={true}
                      disabled={disable_btn}
                      grey={disable_btn}
                    />
                  )}
                </span>
              </AdminTitle>
              <div className={styles.edit_row}>
                <UserForm
                  handleBlur={this.handleBlur}
                  handleChange={this.handleChange}
                  onEmailChangeHandler={this._onEmailChangeHandler}
                  onEmailBlurHandler={this._onEmailBlurHandler}
                  form_values={form_values}
                  form_errors={errors}
                  form_loading={loading}
                  cssClass="col-2"
                  isSupplier={isSupplier}
                />
              </div>
            </div>
          </div>
        )}
        {form_values.user_type !== 'websitebutton' && isSupplier && (
          <div className={styles.row}>
            <div className={styles.user_type_permissions}>
              <span className={styles.line} />
              <div className={styles.user_type}>
                <UserRolePermissions
                  selected_value={form_values.user_type}
                  component={this.props.user_type}
                  handleChange={this.handleCheckbox}
                >
                  <ConsumerManagerUserType
                    className="mt-1 pt-5"
                    handleCheck={this.handleCheckbox}
                    isManager={
                      get(form_values, 'user_type_consumer_manager', false) ==
                      ROLE_TYPE_CONSUMER_MANAGER
                    }
                  />
                </UserRolePermissions>
              </div>
              {form_values.user_type == 'standard'
                ? ''
                : approval_levels_content}
              {permissions_content}
            </div>
          </div>
        )}
        {isSupplier && (
          <UserKeyContactsComponent
            user_id={user_id}
            user_type={user.attributes.role_type}
            hideParentCloseButton={this.setBulkReassignModalState}
            userFullName={user.attributes.full_name}
          />
        )}
      </section>
    );
  }
}

const defaults = {
  permissions: {
    default_title: 'Permissions',
    options: [
      {
        description:
          'This function allows the user to tailor mandatory checks for individual applications. The checks will always default to the company level for every application but these can be altered (turned on/off) by this user. Changing an applications settings will disable it for auto-decisioning.',
        id: 'user_permissions',
        label: 'Mandatory checks',
        type: 'user_permissions',
        value: 'm',
      },
    ],
  },
  title: 'Edit user',
  user_type: {
    default_title: 'User type',
    options: userTypes.filter(({ type }) => type === 'user_type'),
  },
};

export default connect((state, ownProps) => {
  let users = state.manage_users.team_list,
    currentUserId = ownProps.params.userId,
    currentUser = state.current_user,
    ownEntityName = state.current_user.current_entity.attributes.trading_name,
    team_list_loading = state.manage_users.team_list_loading;

  let approval_levels_options = [
    {
      description: 'Cannot approve an application.',
      id: 'no_level',
      label: 'N/A.',
      type: 'approval_level',
      value: null,
    },
  ];

  if (isReviewerEnabled()) {
    approval_levels_options.push({
      description: `This person is typically a reviewer of the credit
        file prior to approval. The user type doesn't have any credit
        limit approval but is responsible for ensuring all elements of
        the credit file are complete prior to it going to the approvers.`,
      id: 'level_0',
      label: 'Reviewer',
      type: 'approval_level',
      value: 0,
    });
  }

  (state.manage_profile.current_entity_approval_levels || []).forEach(
    (approval_level, level) => {
      approval_levels_options.push({
        description:
          'Can approve credit limits ≤ $' +
          formatMoney(approval_level.credit_limit) +
          '.',
        id: 'level_' + approval_level.level,
        label: 'Level ' + (level + 1) + '.',
        type: 'approval_level',
        value: approval_level.level,
      });
    }
  );

  let approval_levels = {
    default_title: 'Approval levels',
    options: approval_levels_options,
  };

  return {
    approval_levels: approval_levels,
    currentUser,
    current_pricing_plan: state.pricing_plans.current_pricing_plan,
    email_valid: state.manage_users.exists_user
      ? !state.manage_users.exists_user.user_exists
      : false,
    email_validating: state.manage_users.email_validating,
    isSupplier: state.current_user.current_entity.attributes.supplier,
    ownEntityName,
    permissions: defaults.permissions,
    saving: state.manage_users.saving,
    title: defaults.title,
    team_list_loading: team_list_loading,
    user: state.manage_users.edit_user,
    user_id: currentUserId,
    user_type: defaults.user_type,
  };
})(UpdateUser);
