import styled, { css } from 'styled-components';
import { COLORS } from 'variables/theme';

interface IStyledDarkGreySpanProps {
  isFloating?: boolean;
}

export const StyledDarkGreySpan = styled.span<IStyledDarkGreySpanProps>`
  ${(props) =>
    props.isFloating &&
    css`
      color: ${COLORS.darkGrey};
    `}
`;
export const StyledBlueSpan = styled.span`
  color: ${COLORS.lightBlue};
  /* Add any other styles you need for the StyledBlueSpan */
`;
