import get from 'lodash.get';
import IdentityCapture from 'modules/identity/components/IdentityCapture/IdentityCapture';
import { checkListItems } from 'modules/paperless-onboarding/components/Authorisation';
import styles from 'modules/paperless-onboarding/css/Authorisation.css';
import commonStyles from 'modules/paperless-onboarding/css/Section.css';
import Button from 'modules/shared/components/inputs/Button';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import UncontrolledTextInput from 'modules/shared/components/inputs/UncontrolledTextInput';
import PdfReader from 'modules/shared/components/widgets/static/pdfReader';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { areStringsEqual } from 'utils';
import * as yup from 'yup';

function IDUpload(props) {
  const { attributes } = props.authorisation;
  const {
    image_64: imageUrl,
    user_information_id: distinctId,
    user_role_id: userRoleId,
  } = attributes;

  return (
    <div>
      <IdentityCapture
        apiType="user_roles"
        authorisationID={userRoleId}
        distinctId={distinctId}
        handleComplete={() => null}
        imageUrl={imageUrl}
        noInstantUpload={true}
        resourceType="user_role"
      />
      <div className={commonStyles.error}>
        {get(props.errors, 'image64.message', '')}
      </div>
    </div>
  );
}

function CheckListItem(props) {
  const [isChecked, setIsChecked] = useState(false);

  const onToggleIsChecked = () => {
    if (!isChecked) props.clearError(props.checkboxName);
    setIsChecked(!isChecked);
  };

  return (
    <div>
      <div className={commonStyles.row} onClick={onToggleIsChecked}>
        <SquareCheckbox
          checked={isChecked}
          label={props.label}
          inputRef={props.inputRef}
          checkboxName={props.checkboxName}
        />
      </div>
      <div className={commonStyles.error}>
        {get(props.errors, `${props.checkboxName}.message`, '')}
      </div>
    </div>
  );
}

function CheckList(props) {
  const { authorisation } = props;
  const checkList = checkListItems(authorisation.attributes.supplier_name);

  return (
    <div className={styles.check_list}>
      {checkList.map((item) => (
        <CheckListItem
          key={item.name}
          checkboxName={item.name}
          label={item.label}
          inputRef={props.inputRef}
          errors={props.errors}
          clearError={props.clearError}
        />
      ))}
    </div>
  );
}

function TermsAndConditions(props) {
  const { authorisation } = props;

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.tnc_title}>
        Supplier, paperless Terms and Conditions
      </div>
      <PdfReader urls={authorisation.attributes.ts_and_cs} />
      <CheckList
        authorisation={authorisation}
        inputRef={props.inputRef}
        errors={props.errors}
        clearError={props.clearError}
      />
      <div className={styles.tnc_instruction}>
        {
          'By electronically signing this section you confirm and acknowledge that:\r\n\
          You have read and understood this application and the information supplied by you is true and complete'
        }
      </div>
      <div className={styles.tnc_instruction}>
        {`I authorise ${authorisation.attributes.supplier_name}, until further notice in writing to debit my/our account.`}
      </div>
    </div>
  );
}

function DigitalSignature(props) {
  return (
    <div>
      <div className={styles.tnc_instruction}>
        If you agree please fill out your first and last name in the fields
        below and press confirm.
      </div>
      <div className={commonStyles.row}>
        <div className={commonStyles.half_col}>
          <UncontrolledTextInput
            id={'first_name'}
            label={'First name'}
            error={get(props.errors, 'first_name.message', '')}
            inputRef={props.inputRef}
          />
        </div>
        <div className={commonStyles.half_col}>
          <UncontrolledTextInput
            id={'last_name'}
            label={'Last name'}
            error={get(props.errors, 'last_name.message', '')}
            inputRef={props.inputRef}
          />
        </div>
      </div>
    </div>
  );
}

export default function Authorisation(props) {
  const { paperlessAuthorisation } = props;
  const correctFirstName =
    paperlessAuthorisation.attributes.first_name.toLowerCase();
  const correctLastName =
    paperlessAuthorisation.attributes.last_name.toLowerCase();

  const authorisationSchema = yup.object().shape({
    authorised: yup
      .boolean()
      .test('authorised', 'Please check to agree.', (value) => value),
    disclose_information: yup
      .boolean()
      .test('disclose_information', 'Please check to agree', (value) => value),
    first_name: yup
      .string()
      .required('Please input first name.')
      .test('first_name_test', 'Incorrect first name.', (value) =>
        areStringsEqual(value, correctFirstName)
      ),
    image64: yup
      .string()
      .required('Please upload/take a photo of your ID.')
      .test(
        'images',
        'Please upload/take a photo of your ID.',
        (value) => value !== null
      ),
    last_name: yup
      .string()
      .required('Please input last name.')
      .test('last_name_test', 'Incorrect last name.', (value) =>
        areStringsEqual(value, correctLastName)
      ),
    over18: yup
      .boolean()
      .test('over18', 'Please check to agree.', (value) => value),
    read_terms: yup
      .boolean()
      .test('readTerms', 'Please check to agree.', (value) => value),
  });

  const { errors, handleSubmit, clearError, setValue, register } = useForm({
    mode: 'onBlur',
    validationSchema: authorisationSchema,
  });

  setValue(
    'image64',
    props.identificationImage || paperlessAuthorisation.attributes.image_64
  );
  if (errors.image64 && props.identificationImage) clearError('image64');

  useEffect(() => {
    register({ name: 'image64' });
  }, [register]);

  const onSubmit = (data) => {
    paperlessAuthorisation.setAttributes({ approved: true });
    paperlessAuthorisation.setAttributes(data);
    (async () => {
      const result = await paperlessAuthorisation.saveIDphoto(
        props.paperlessAuthorisationId
      );
      if (result.status === 200) {
        props.toNextSection();
      }
    })();
  };

  const toDecline = () => {
    paperlessAuthorisation.setAttributes({ approved: false });
    (async () => {
      const result = await paperlessAuthorisation.saveDecision(
        props.paperlessAuthorisationId
      );
      if (result.status === 200) props.toLastSection();
    })();
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={commonStyles.container}>
          <section className={commonStyles.section}>
            <IDUpload errors={errors} authorisation={paperlessAuthorisation} />
            <TermsAndConditions
              authorisation={paperlessAuthorisation}
              inputRef={register}
              errors={errors}
              clearError={clearError}
            />
            <DigitalSignature inputRef={register} errors={errors} />
            <div className={commonStyles.flow_buttons}>
              <Button text="Decline" onClick={toDecline} />
              <Button
                text="Approve"
                type="submit"
                loading={paperlessAuthorisation.isLoading}
              />
            </div>
          </section>
        </div>
      </form>
    </div>
  );
}

module.exports = connect((state) => {
  return {
    identificationImage: state.identity.image_64,
  };
})(Authorisation);
