import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';
// @ts-ignore
import { Link } from 'react-router';

import { FilterButton } from './FilterButton';
import { CustomersContext } from '../CustomersContext';

export const CustomersFilterTrigger = () => {
  const { toggleFilter } = useContext(CustomersContext);

  const handleClick = (e) => {
    e.preventDefault();
    toggleFilter();
  };

  return (
    <FilterButton>
      <Typography onClick={handleClick}>
        Advanced{' '}
        <Link onClick={handleClick} to={'#'}>
          search
        </Link>
      </Typography>
    </FilterButton>
  );
};
