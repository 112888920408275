import { Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
// @ts-ignore
import { Link } from 'react-router';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import get from 'lodash.get';

import ReportApplicationModel from 'models/ReportApplicationModel';
import { FilterButton } from './FilterButton';
import { CustomersContext } from '../CustomersContext';
import { processError } from 'utils';

type DownloadReportProps = {
  email: string;
  entityId: string;
};

export const DownloadReport = connect((state) => ({
  email: get(state, 'current_user.data.data.attributes.email', ''),
  entityId: get(state, 'current_user.current_entity.id'),
}))((props: DownloadReportProps) => {
  const { isDownloadDisabled, params } = useContext(CustomersContext);

  const { email, entityId } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      await ReportApplicationModel.exportApplications({
        accessToken: '',
        entityId,
        params: { ...params, acting_as_supplier: true, loaded_in_system: true },
      });
      const successMessage = `A link to the CSV file will be sent to ${email} in few minutes.`;
      enqueueSnackbar(successMessage, { variant: 'success' });
    } catch (error: any) {
      const { errorMessage } = processError(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleClickLink = (e) => e.preventDefault();

  const isDisabled = isDownloadDisabled || isLoading;

  return (
    <FilterButton disabled={isDisabled}>
      <Typography onClick={isDisabled ? undefined : handleClick}>
        {'Download '}
        <Link onClick={handleClickLink} to={'#'}>
          report
        </Link>
      </Typography>
    </FilterButton>
  );
});
