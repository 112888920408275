import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form-latest';
import { Typography } from '@material-ui/core';

import RuleSetSection from './RuleSetSection';
import Button from 'modules/shared/components/inputs/Button';
import { updateSelectedRulesetValue } from 'modules/addons/actions';
import { updateRuleSet } from '../../actions';
import { loadAutoDecisions } from 'modules/addons/actions';
import { IRuleSetAttributes, IValidationDetails } from './type';
import { get } from 'lodash';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import { getNumberOptions } from './constant';
import FormFieldSection from './FormFieldSection';
import YesOrNoRadioBoxGroup, {
  YesOrNo,
  getYesOrNo,
} from './YesOrNoRadioBoxGroup';
import { loadCurrentEntity } from 'modules/profile/actions.js';
import {
  checkValidationFormComplete,
  checkAmlEnabledInSettings,
  processAntiFraudSettings,
} from './utils';
import { AmlSettings } from 'types/AmlSettings';
import { getValidationTabPauseReasons } from './getPauseReasons';
import { FEATURE_FLAGS } from 'conf';

const applyCashRuleChanges = FEATURE_FLAGS.FEATURE_FLAG_1CAD_CASH_RULE_CHANGES;

const GuarantorSignatoryError = () => (
  <Typography color="error">
    This setting is required when Personal credit checks are turned on for 1CAD.
  </Typography>
);

interface IProps {
  attributes: IRuleSetAttributes;
  isReadyToSubmitForm: () => boolean;
  isUpdating: boolean;
  settingsGuarantorCount: number;
  amlSettings: AmlSettings;
  antiFraudSettings: any;
  actions: {
    loadAutoDecisions: () => void;
    updateRuleSet: (id: string, ruleset: IValidationDetails, onSuccess) => void;
    loadCurrentEntity: () => void;
  };
  nameModified: boolean;
  setNameModified: (boolean) => void;
  submitButtonLabel: string;
  onSubmitTabData: (data: IValidationDetails) => void;
  disabled?: boolean;
  hideSaveButton?: boolean;
  isGuarantorRequired: boolean;
  shouldShowGuarantorError: boolean;
  isSignatoryRequired: boolean;
  rulesetName: IRuleSetAttributes['name'];
  shouldShowSignatoryError: boolean;
  setDirty: (flag: boolean) => void;
}

// maximum signatories count
const MAX_SIGNATORIES_COUNT = 10;

const ValidationForm = ({
  attributes,
  settingsGuarantorCount,
  actions,
  amlSettings,
  antiFraudSettings,
  isReadyToSubmitForm,
  isUpdating,
  nameModified,
  setNameModified,
  submitButtonLabel,
  onSubmitTabData,
  disabled = false,
  hideSaveButton = false,
  isGuarantorRequired,
  shouldShowGuarantorError,
  isSignatoryRequired,
  shouldShowSignatoryError,
  rulesetName,
  setDirty,
}: IProps): JSX.Element => {
  const isAntiFraudApplicable = processAntiFraudSettings(
    antiFraudSettings,
    attributes
  );

  const isAmlEnabledInSettings = checkAmlEnabledInSettings(amlSettings);

  useEffect(() => {
    actions.loadCurrentEntity();
  }, []);

  const pauseReasons = getValidationTabPauseReasons({
    amlSettings,
    antiFraudSettings,
    rulesetAttributes: attributes,
    settingsGuarantorCount,
  });

  const defaultValues = {
    require_anti_fraud:
      attributes.require_anti_fraud === undefined ||
      attributes.require_anti_fraud === null
        ? YesOrNo.NOT_SELECTED
        : getYesOrNo(attributes.require_anti_fraud),
    aml_verification: getYesOrNo(attributes.aml_verification),
    guarantors: isGuarantorRequired
      ? YesOrNo.YES
      : getYesOrNo(attributes.guarantors),
    guarantors_count:
      settingsGuarantorCount > 0 &&
      getYesOrNo(attributes.guarantors) === YesOrNo.YES
        ? attributes.guarantors_count
        : '',
    signatories: isSignatoryRequired
      ? YesOrNo.YES
      : getYesOrNo(attributes.signatories),
    signatories_count:
      getYesOrNo(attributes.signatories) === YesOrNo.YES
        ? attributes.signatories_count
        : '',
  };

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues,
  });

  const currentValues = watch();

  const isDirty =
    JSON.stringify(defaultValues) !== JSON.stringify(currentValues);

  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    reset(defaultValues);
  }, [attributes]);

  useEffect(() => {
    if (currentValues.guarantors === YesOrNo.NO) {
      setValue('guarantors_count', '');
    }
  }, [currentValues.guarantors]);

  useEffect(() => {
    if (currentValues.signatories === YesOrNo.NO) {
      setValue('signatories_count', '');
    }
  }, [currentValues.signatories]);

  useEffect(() => {
    if (pauseReasons.aml) {
      // If the reason why this ruleset was paused is due to change in AML
      // settings, we'll set the field value to NO.
      // We set this, and not the default value, so that isDirty will be true,
      // allowing user to save the ruleset.
      setValue('aml_verification', YesOrNo.NO);
    }
  }, [pauseReasons.aml]);

  useEffect(() => {
    if (pauseReasons.antiFraud) {
      // If the reason why this ruleset was paused is due to change in anti fraud
      // settings, we'll set the field value to NO.
      // We set this, and not the default value, so that isDirty will be true,
      // allowing user to save the ruleset.
      setValue('require_anti_fraud', YesOrNo.NO);
    }
  }, [pauseReasons.antiFraud]);

  useEffect(() => {
    if (pauseReasons.guarantor && settingsGuarantorCount === 0) {
      // If the reason why this ruleset was paused is due to change in guarantor
      // settings, we'll set the field value to NO.
      // We set this, and not the default value, so that isDirty will be true,
      // allowing user to save the ruleset.
      setValue('guarantors', YesOrNo.NO);
    }
  }, [pauseReasons.guarantor, settingsGuarantorCount]);

  const isCompanyEntitySelected = attributes.legal_types.includes('company');

  const isCashRuleset = attributes.application_types.includes('cash');

  useEffect(() => {
    if (!isCompanyEntitySelected) {
      setValue('guarantors', YesOrNo.NO);
    }
  }, [attributes.legal_types]);

  const onSubmit = (data) => {
    const isReady = isReadyToSubmitForm();

    if (isReady) {
      const rulesetToSubmit: IValidationDetails = {
        name: rulesetName,
        signatories: data.signatories === YesOrNo.YES,
        signatories_count: data.signatories_count || undefined,
        guarantors: data.guarantors === YesOrNo.YES,
        guarantors_count:
          data.guarantors === YesOrNo.YES
            ? data.guarantors_count || undefined
            : null,
        aml_verification: data.aml_verification === YesOrNo.YES,
        require_anti_fraud: data.require_anti_fraud === YesOrNo.YES,
        validation_status: 'complete',
      };
      onSubmitTabData(rulesetToSubmit);
    }
  };

  const guarantorsSelectChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const value = parseInt(event.target.value);
    setValue('guarantors_count', value);
    setValue('guarantors', YesOrNo.YES);
  };

  const signatoriesSelectChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const value = parseInt(event.target.value);
    setValue('signatories_count', value);
    setValue('signatories', YesOrNo.YES);
  };

  const hasEmptyFields = !checkValidationFormComplete(
    {
      ...currentValues,
      require_anti_fraud:
        currentValues.require_anti_fraud === undefined ||
        currentValues.require_anti_fraud === null
          ? YesOrNo.NOT_SELECTED
          : currentValues.require_anti_fraud,
      aml_verification:
        currentValues.aml_verification === undefined ||
        currentValues.aml_verification === null
          ? YesOrNo.NOT_SELECTED
          : currentValues.aml_verification,
      guarantors:
        currentValues.guarantors === undefined ||
        currentValues.guarantors === null
          ? YesOrNo.NOT_SELECTED
          : currentValues.guarantors,
      signatories:
        currentValues.signatories === undefined ||
        currentValues.signatories === null
          ? YesOrNo.NOT_SELECTED
          : currentValues.signatories,
      guarantors_count: currentValues.guarantors_count ?? 0,
      signatories_count: currentValues.signatories_count ?? 0,
    },
    isAmlEnabledInSettings,
    isAntiFraudApplicable,
    settingsGuarantorCount,
    !isCashRuleset
  );

  const isSaveButtonDisabled =
    disabled || hasEmptyFields || !(isDirty || nameModified);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RuleSetSection title="Anti-Fraud (1CAF)">
        <FormFieldSection
          hasPausedMessage={pauseReasons.antiFraud}
          id="require_anti_fraud"
          label="Do you require 1CAF?"
        >
          <YesOrNoRadioBoxGroup
            name="require_anti_fraud"
            control={control}
            disabled={!isAntiFraudApplicable || disabled}
          />
        </FormFieldSection>
      </RuleSetSection>
      <RuleSetSection title="AML verification">
        <FormFieldSection
          id="aml_verification"
          label="Do you require AML verification?"
        >
          <YesOrNoRadioBoxGroup
            name="aml_verification"
            control={control}
            disabled={!isAmlEnabledInSettings || disabled}
          />
        </FormFieldSection>
      </RuleSetSection>
      <RuleSetSection title="Guarantor/s">
        <>
          {shouldShowGuarantorError && <GuarantorSignatoryError />}
          <FormFieldSection
            hasPausedMessage={pauseReasons.guarantor}
            id="guarantors"
            label="Do you require guarantor/s? This only applies to Company entities. If number of available directors is less than the number required for this rule, it will pass."
          >
            <YesOrNoRadioBoxGroup
              name="guarantors"
              control={control}
              disabled={
                applyCashRuleChanges
                  ? !isCompanyEntitySelected ||
                    !settingsGuarantorCount ||
                    isGuarantorRequired ||
                    isCashRuleset ||
                    disabled
                  : !isCompanyEntitySelected ||
                    !settingsGuarantorCount ||
                    isGuarantorRequired ||
                    disabled
              }
              value={currentValues.guarantors}
            />
          </FormFieldSection>
          <FormFieldSection id="guarantors_count" label="Number of guarantor/s">
            <BorderedSelect
              id="guarantors_count"
              value={currentValues.guarantors_count}
              options={getNumberOptions(settingsGuarantorCount)}
              disabled={
                applyCashRuleChanges
                  ? !isCompanyEntitySelected ||
                    currentValues.guarantors === YesOrNo.NO ||
                    !settingsGuarantorCount ||
                    isCashRuleset ||
                    disabled
                  : !isCompanyEntitySelected ||
                    currentValues.guarantors === YesOrNo.NO ||
                    !settingsGuarantorCount ||
                    disabled
              }
              formControlCustomProps={{ withBottomMargin: false }}
              onChange={guarantorsSelectChangeHandler}
              placeholder={
                pauseReasons.guarantor ? defaultValues.guarantors_count : ''
              }
            />
          </FormFieldSection>
        </>
      </RuleSetSection>
      <RuleSetSection title="Signatory/ies">
        <>
          {shouldShowSignatoryError && <GuarantorSignatoryError />}
          <FormFieldSection
            id="signatories"
            label="Do you require signatory/ies? This applies to Trust, Partnership and Joint personal entity types. "
          >
            <YesOrNoRadioBoxGroup
              name="signatories"
              control={control}
              disabled={
                applyCashRuleChanges
                  ? isSignatoryRequired || isCashRuleset || disabled
                  : isSignatoryRequired || disabled
              }
            />
          </FormFieldSection>
          <FormFieldSection
            id="signatories_count"
            label="Number of signatory/ies"
          >
            <BorderedSelect
              id="signatories_count"
              value={currentValues.signatories_count}
              options={getNumberOptions(MAX_SIGNATORIES_COUNT)}
              disabled={
                applyCashRuleChanges
                  ? disabled ||
                    currentValues.signatories === YesOrNo.NO ||
                    isCashRuleset
                  : disabled || currentValues.signatories === YesOrNo.NO
              }
              formControlCustomProps={{ withBottomMargin: false }}
              onChange={signatoriesSelectChangeHandler}
            />
          </FormFieldSection>
        </>
      </RuleSetSection>
      {!hideSaveButton ? (
        <Button
          type="submit"
          className="mt-6"
          text={submitButtonLabel}
          disabled={isSaveButtonDisabled}
          loading={isUpdating}
          loading_text="Saving..."
        />
      ) : null}
    </form>
  );
};

const mapStateToProps = (state, props) => ({
  currentRuleSet: state.add_ons.auto_decisions[props.rulesetIndex],
  settingsGuarantorCount: get(
    state,
    'manage_profile.current_entity.attributes.minimum_guarantees',
    0
  ),
  amlSettings: get(state, 'manage_profile.settingsAmlCheckRules', {}),
  antiFraudSettings: get(state, 'manage_profile.settingsAntiFraud', []),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateRuleSet,
      loadAutoDecisions,
      updateSelectedRulesetValue,
      loadCurrentEntity,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidationForm);
