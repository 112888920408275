import React from 'react';
import styles from './report-styles.css';

const ReportEmptySection = ({ label }) => {
  return (
    <div>
      <div className={styles.row + ' mt-4'}>
        <div className={styles.col_4}>
          <label className="has-text-danger has-text-weight-semibold">
            {label}
          </label>
          <p>Not present on file</p>
        </div>
      </div>
    </div>
  );
};

export default ReportEmptySection;
