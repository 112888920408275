import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';
import ApplicationModel from 'models/ApplicationModel';
import store from 'stores/store';
import APPLICATION_TYPE from 'types/applicationType';
import ENTITY_TYPE from 'types/entityType';
import REGION from 'types/region';
import { EntityType } from 'utils/entityType';

export function cobApplication() {
  const attributes = get(
    store.getState(),
    'cob_section.application.attributes'
  );
  return new ApplicationModel({ attributes });
}

export const isAustraliaDirectorDetailsVisible = ({
  applicationType,
  entityType,
  entityRegion,
  requiresGuarantees,
  directorFromCreditCheckEnabled,
  entityDirectorsLoadingStatus,
}: {
  applicationType: APPLICATION_TYPE;
  entityType: ENTITY_TYPE;
  entityRegion: REGION;
  requiresGuarantees: boolean;
  directorFromCreditCheckEnabled: boolean;
  entityDirectorsLoadingStatus: null | string;
}): boolean => {
  // When there's a value for entityDirectorsLoadingStatus,
  // Any other status should display directors input field
  // Display directors field if null
  const directorLoadingStatus =
    entityDirectorsLoadingStatus &&
    FEATURE_FLAGS.FEATURE_FLAG_AUS_AUTO_POPULATE_DIRECTORS
      ? entityDirectorsLoadingStatus !== 'success'
      : true;
  const autoloadDirectors =
    directorFromCreditCheckEnabled &&
    FEATURE_FLAGS.FEATURE_FLAG_AUS_AUTO_POPULATE_DIRECTORS
      ? directorLoadingStatus
      : true; // force true to show directors input field

  return (
    applicationType === 'credit' &&
    entityType === EntityType.Company &&
    entityRegion === 'AU' &&
    requiresGuarantees &&
    autoloadDirectors
  );
};

export const getCorporateTrusteeSignatories = (state) => {
  const application = get(state, 'cob_section.application.attributes', {});
  const isTrustFlow = application.legal_type === 'trust';
  const isGuarantorsEnabled = Boolean(application.minimum_guarantees_trust);

  if (
    !FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS ||
    !isTrustFlow ||
    isGuarantorsEnabled
  ) {
    return [];
  }

  const trustees = get(state, 'cob_business.entity_party_details_values', []);
  const corporateTrusteeIds = trustees
    .filter((trustee) => trustee.trustee_type === 'corporate')
    .map((trustee) => trustee.corporate_trustee_id);

  let signatories: any[] = [];

  const corporateTrusteeDirectors = get(
    state,
    'cob_business.corporateTrusteeDirectors',
    {}
  );

  corporateTrusteeIds.forEach((id) => {
    const directors = corporateTrusteeDirectors[id].map((director) => ({
      ...director,
      position: 'director',
      associated_entity_id: id,
    }));
    signatories = [...signatories, ...directors];
  });

  return signatories;
};
