import Modal from 'modules/dashboard/containers/components/Modal';
import Button from 'modules/shared/components/inputs/Button';
import React, { ReactElement } from 'react';

import {
  ConfirmAcnModalButtonsWrapper,
  ConfirmAcnTitle,
  ConfirmAcnBoldItalicText,
} from './styles';

type IConfirmAcnModal = {
  onConfirm: () => void;
  onCancel: () => void;
};

function ConfirmAcnModal(props: IConfirmAcnModal): ReactElement {
  const { onConfirm, onCancel } = props;

  return (
    <Modal
      onClose={onCancel}
      title={
        <ConfirmAcnTitle>
          <span>Confirm ACN</span>
        </ConfirmAcnTitle>
      }
      content={
        <p>
          Once proceeded, you won't be able to amend the ACN. You will be asked
          for the director details of the Company. Please{' '}
          <ConfirmAcnBoldItalicText>
            double check the ACN and the Company name
          </ConfirmAcnBoldItalicText>
          , only continue if both details are correct.
        </p>
      }
      footer={
        <ConfirmAcnModalButtonsWrapper>
          <Button text="Yes, proceed" handleClick={onConfirm} />
          <Button text="Cancel" white={true} handleClick={onCancel} />
        </ConfirmAcnModalButtonsWrapper>
      }
    />
  );
}

export default ConfirmAcnModal;
