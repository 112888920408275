import React from 'react';
import styles from './report-styles.css';
import { formatMoney } from 'utils/formatting';
import { UnfoldLess } from '@material-ui/icons';
import { formatDate } from 'utils/dateFormatter';

const ReportCreditInquiries = ({
  creditEnquiries,
  creditEnquiry,
  characteristic,
  inquiryType,
}) => {
  let totalValue = 0,
    totalNumber = characteristic.find((obj) => obj['@id'] === 'NA8922') || {};

  if (creditEnquiries && creditEnquiry) {
    if (creditEnquiry.constructor !== Array) {
      creditEnquiry = [{ ...creditEnquiry }];
    }
    totalValue = creditEnquiry.reduce((prev, current) => {
      return prev + +current.enquiry_amount;
    }, 0);
  }

  return (
    <div>
      <div className={styles.row + ' mt-4'}>
        <div className={styles.col_4}>
          <label className="has-text-danger has-text-weight-semibold">
            {`${inquiryType} enquiries`}
          </label>
        </div>
        <div className={styles.col_4}>
          <p>
            Total number: {creditEnquiry.length}
            &emsp; Total value:{' '}
            {`$${formatMoney(
              parseFloat(`${totalValue}`),
              undefined,
              undefined,
              undefined,
              undefined
            )}`}
          </p>
        </div>
      </div>

      <div className={styles.table + ' mt-4'}>
        <div className={styles.row}>
          <div className={styles.th + ' ' + styles.col_1}>Date</div>
          <div className={styles.th + ' ' + styles.col_3}>Enquirer</div>
          <div className={styles.th + ' ' + styles.col_1}>ID source</div>
          <div className={styles.th + ' ' + styles.col_2}>Account type</div>
          <div className={styles.th + ' ' + styles.col_1}>Amount</div>
          <div className={styles.th + ' ' + styles.col_1}>Role</div>
          <div className={styles.th + ' ' + styles.col_1}>Co-borrower</div>
          <div className={styles.th + ' ' + styles.col_2}>Reference no.</div>
        </div>
        {creditEnquiry.map((ce, index) => {
          return (
            <div key={index} className={styles.tr}>
              <div className={styles.td + ' ' + styles.col_1}>
                {!ce['@enquiry_date']
                  ? '-'
                  : formatDate(ce['@enquiry_date'], 'DD MMM YYYY')}
              </div>
              <div className={styles.td + ' ' + styles.col_3}>
                {ce['credit_enquirer'] && ce['credit_enquirer'].name}
              </div>
              <div className={styles.td + ' ' + styles.col_1}>P</div>
              <div className={styles.td + ' ' + styles.col_2}>
                {ce['account_type']}
              </div>
              <div className={styles.td + ' has-text-right ' + styles.col_1}>
                {`$${formatMoney(
                  parseFloat(ce['enquiry_amount']),
                  undefined,
                  undefined,
                  undefined,
                  UnfoldLess
                )}`}
              </div>
              <div className={styles.td + ' ' + styles.col_1}>
                {ce['relationship'] == "Principal's Account (sole)"
                  ? 'Principal (sole)'
                  : ce['relationship']}
              </div>
              <div className={styles.td + ' ' + styles.col_1}></div>
              <div className={styles.td + ' ' + styles.col_2}>
                {ce['enquiry_client_reference']}
              </div>
            </div>
          );
        })}
      </div>

      <br />
      <div className={styles.row + ' mt-4'}>
        <div className={styles.col_4}>
          <label className="has-text-danger has-text-weight-semibold">
            {`${inquiryType} File notes`}
          </label>
          <p>Not present on file</p>
        </div>
        <div className={styles.col_4}>
          <p>
            Total number: &nbsp;
            {!totalNumber || totalNumber.value < 0 ? 0 : totalNumber.value}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReportCreditInquiries;
