import api from 'api';
import { FEATURE_FLAGS } from 'conf';
import RecordHistory from 'modules/new-applications/components/RecordHistory';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import Table from 'modules/shared/components/table/Table';
import { isFeatureAvailable } from 'modules/shared/helpers/headquarterDetect';
import { Typography } from 'mui-latest';
import React, { ReactElement, useState } from 'react';
// @ts-ignore-next-line: TS is not able to find `Link` as an exported value of react-router but definitely there is
import { browserHistory, Link } from 'react-router';

import { WatchtowerAlertProps } from '..';
import ActionModal, { ModalActionType } from '../Modal/ActionModal';
import {
  ALERTS_NAME_MAP,
  EXTERNAL_ALERTS_COLUMNS,
  INTERNAL_ALERTS_COLUMNS,
} from './constants';
import {
  ActionTextWrapper,
  ActionWrapper,
  LinkedApplicationWrapper,
  StatusWrapper,
  TableLabel,
  TableRowWrapper,
} from './styles';

type ModalData = {
  actionType?: ModalActionType;
  alertId?: string;
  isModalOpen: boolean;
};

const defaultModalData: ModalData = {
  actionType: undefined,
  alertId: undefined,
  isModalOpen: false,
};

const getAlertTier = ({ linkedApplication, entityTierData }) => {
  const linkedSupplierId = linkedApplication.supplier_id;

  const hasOtherTiers = entityTierData.some(
    (tierData) => tierData.id !== linkedSupplierId
  );

  if (!hasOtherTiers) return null;

  const alertTier = entityTierData.find(
    (tierData) => tierData.id === linkedSupplierId
  );

  return alertTier;
};

const DataRowAction = ({ setModalData, alertType, alertId }) => {
  const actionAlertItem = (type: ModalActionType) =>
    setModalData({
      actionType: type,
      alertId,
      isModalOpen: true,
    });

  return (
    <ActionWrapper>
      {alertType === 'internal' ? (
        <>
          <a onClick={() => actionAlertItem('safe')}>Mark as safe</a>
          <a onClick={() => actionAlertItem('block')}>Block</a>
        </>
      ) : (
        <a onClick={() => actionAlertItem('actioned')}>Mark as actioned</a>
      )}
    </ActionWrapper>
  );
};

const DataRowActionedText = ({
  status,
  alertType,
  actionedDatetime,
  updateByUser,
}) => {
  const internalStatusText =
    status === 'blocked' ? 'Blocked by' : 'Marked as safe by';
  const statusText =
    alertType === 'external' ? 'Actioned by' : internalStatusText;

  let formattedDatetime = '-';

  if (actionedDatetime) {
    const actionedDatetimeUTC = actionedDatetime.endsWith('Z')
      ? actionedDatetime
      : actionedDatetime + 'Z';
    formattedDatetime = new Date(actionedDatetimeUTC).toLocaleString();
  }

  return (
    <ActionTextWrapper
      status={status}
    >{`${statusText} ${updateByUser} on ${formattedDatetime}`}</ActionTextWrapper>
  );
};

const AlertTableRow = (props) => {
  const { status, alertType, firstGroupMember, lastGroupMember } = props;
  const rowStatus = status === 'open' ? `${alertType}_open` : undefined;
  return (
    <TableRowWrapper
      rowStatus={rowStatus}
      alertType={alertType}
      firstGroupMember={firstGroupMember}
      lastGroupMember={lastGroupMember}
    >
      {props.children}
    </TableRowWrapper>
  );
};

const DataRowLinkedApplicationText = ({
  linkedApplication,
  entityTierData,
}) => {
  if (!linkedApplication) return <span>-</span>;

  const tierData = getAlertTier({ linkedApplication, entityTierData });

  return (
    <React.Fragment>
      {tierData ? (
        <LinkedApplicationWrapper>
          <Link
            key={linkedApplication.application_name}
            to={`/dashboard/applications/${linkedApplication.application_id}/watchtower`}
          >
            {linkedApplication.application_name}
          </Link>
        </LinkedApplicationWrapper>
      ) : (
        <span>{linkedApplication.application_name}</span>
      )}
    </React.Fragment>
  );
};

type DataRowTierText = {
  linkedApplications?: any[];
  linkedApplication?: any;
  entityTierData: any;
};

export const DataRowTierText = ({
  linkedApplications,
  linkedApplication,
  entityTierData,
}: DataRowTierText) => {
  const application =
    (linkedApplications ? linkedApplications[0] : linkedApplication) || {};
  const linkedSupplierId = application.supplier_id;

  const applicationTier = entityTierData.find(
    (tier) => tier.id === linkedSupplierId
  );

  return <span>{applicationTier ? applicationTier.title : '-'}</span>;
};

const addTableData = (
  alerts,
  type,
  setModalData,
  currentUser,
  applicationId
) => {
  const tableData: any[] = [];
  const { entityTierData } = currentUser.currentEntity;

  alerts.forEach((outer_alert: any) => {
    const tableMap = outer_alert.reduce((tableData, innerAlert) => {
      innerAlert.linked_applications.forEach((linkedApplication, index) => {
        if (
          type === 'external' &&
          linkedApplication.application_id !== applicationId
        ) {
          return;
        }

        const alert = innerAlert.watchtower_alert;
        const applicationAlertData =
          linkedApplication.alert_application_data || {};
        const newAlertsData = { ...linkedApplication };

        let filteredLinkedApplications = [];
        if (applicationId && type === 'internal') {
          filteredLinkedApplications = (
            innerAlert.linked_applications || []
          ).filter((app) => (app || {}).application_id != applicationId);
        }

        const firstGroupMember = index === 0;
        const lastGroupMember =
          index === innerAlert.linked_applications.length - 1;

        newAlertsData['table_alert_name'] =
          firstGroupMember || type === 'external'
            ? ALERTS_NAME_MAP[alert.alert_name]
            : '';
        newAlertsData['table_alert_date'] = new Date(
          applicationAlertData.created_at
        ).toLocaleDateString();

        newAlertsData['table_linked_application'] = (
          <DataRowLinkedApplicationText
            linkedApplication={linkedApplication}
            entityTierData={entityTierData}
          />
        );
        newAlertsData['table_tier'] = (
          <DataRowTierText
            linkedApplication={linkedApplication}
            entityTierData={entityTierData}
          />
        );

        newAlertsData['table_action'] =
          applicationAlertData.status === 'open' ? (
            <DataRowAction
              setModalData={setModalData}
              alertType={type}
              alertId={applicationAlertData.id}
            />
          ) : (
            <DataRowActionedText
              status={applicationAlertData.status}
              alertType={type}
              actionedDatetime={applicationAlertData.updated_at}
              updateByUser={
                applicationAlertData.updated_by_name ||
                applicationAlertData.current_approver
              }
            />
          );

        newAlertsData['RowComponent'] = function AlertTableRowRender(props) {
          return (
            <AlertTableRow
              status={alert.status}
              alertType={type}
              firstGroupMember={firstGroupMember}
              lastGroupMember={lastGroupMember}
              {...props}
            />
          );
        };
        tableData.push(newAlertsData);
      });

      return tableData;
    }, []);

    tableData.push(tableMap);
  });
  return [].concat(...tableData);
};

const useAlertModal = () => {
  const [modalData, setModalData] = useState<ModalData>(defaultModalData);

  const setIsModalOpen = (value: boolean): void =>
    setModalData(
      (prevModalData: ModalData): ModalData => ({
        ...prevModalData,
        isModalOpen: value,
      })
    );

  const setAlertId = (value: ModalData['alertId']): void =>
    setModalData(
      (prevModalData: ModalData): ModalData => ({
        ...prevModalData,
        alertId: value,
      })
    );

  const setActionType = (value: ModalData['actionType']): void =>
    setModalData(
      (prevModalData: ModalData): ModalData => ({
        ...prevModalData,
        actionType: value,
      })
    );

  return {
    ...modalData,
    modalData,
    setActionType,
    setAlertId,
    setIsModalOpen,
    setModalData,
  };
};

export default function WatchtowerAlerts(
  props: WatchtowerAlertProps
): ReactElement | null {
  if (
    !FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER ||
    !isFeatureAvailable('watchtower')
  ) {
    const baseName = location.pathname.replace('/watchtower', '');
    browserHistory.push({ pathname: baseName });

    return null;
  }

  const {
    application,
    currentUser,
    onFetchApplicationRecord,
    watchtowerExclusions,
  } = props;

  const { externalAlerts } = application;
  const { isModalOpen, actionType, alertId, setModalData, setIsModalOpen } =
    useAlertModal();

  const internalAlerts = application.getInternalAlerts(watchtowerExclusions);
  const alertsToDisplay = application.getAlertsToDisplay(watchtowerExclusions);

  const internalAlertsTableData = addTableData(
    internalAlerts,
    'internal',
    setModalData,
    currentUser,
    (application.data || {}).id
  );

  const externalAlertsTableData = addTableData(
    externalAlerts,
    'external',
    setModalData,
    currentUser,
    (application.data || {}).id
  );

  const applicationAPI = api(
    'applications',
    currentUser.accessToken,
    currentUser.currentEntity.id
  );

  const DisplayStatus = ({ status }) => {
    const statusMap = {
      irregular: {
        description: 'An anomaly has occurred on this account.',
        header: 'Irregular',
        status: 'irregular',
      },
      watching: {
        description:
          'There aren’t any new alerts detected - Watchtower will keep watching and notify you if any suspicious activities arise.',
        header: 'Watching',
        status: 'watching',
      },
    };

    const statusData = statusMap[status];

    return (
      <StatusWrapper variant={statusData.status}>
        <Typography variant="h6">{statusData.header}</Typography>
        <Typography variant="subtitle1">{statusData.description}</Typography>
      </StatusWrapper>
    );
  };

  const onStatusSetSuccess = () => {
    onFetchApplicationRecord();
    setModalData(defaultModalData);
  };

  const setAlertStatus = () => {
    const status = actionType === 'block' ? 'blocked' : 'closed';
    applicationAPI.setWatchtowerAlertApplicationStatus(
      alertId,
      status,
      onStatusSetSuccess
    );
  };

  return (
    <ContentWithFooter
      withBodyBottomPadding
      footer={
        <RecordHistory histories={application.watchtowerAlertHistories} />
      }
    >
      <ScrollableContent>
        {alertsToDisplay && <DisplayStatus status={alertsToDisplay} />}
        {isModalOpen && actionType && alertId && (
          <ActionModal
            onConfirm={() => setAlertStatus()}
            onCancel={() => setIsModalOpen(false)}
            actionType={actionType}
          />
        )}
        {!!internalAlertsTableData.length && (
          <div className="internal-alerts-table-container">
            <TableLabel variant="h6">{`Internal`}</TableLabel>
            <Table
              columns={INTERNAL_ALERTS_COLUMNS}
              data={internalAlertsTableData}
              dataLabel="internal_alerts"
              withPagination={false}
            />
          </div>
        )}
        {!!externalAlertsTableData.length && (
          <div className="external-alerts-table-container">
            <TableLabel variant="h6">{`External`}</TableLabel>
            <Table
              columns={EXTERNAL_ALERTS_COLUMNS}
              data={externalAlertsTableData}
              dataLabel="external_alerts"
              withPagination={false}
            />
          </div>
        )}
      </ScrollableContent>
    </ContentWithFooter>
  );
}
