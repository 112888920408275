/* Import libraries */
// import AddressTextArea from "modules/shared/components/inputs/AddressTextArea";
import Button from 'modules/shared/components/inputs/Button';
/* Import components */
import TextInput from 'modules/shared/components/inputs/TextInput';
// import AutoSuggest from "modules/shared/components/widgets/interactive/AutoSuggest";
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import LabeledInformation from 'modules/shared/components/widgets/static/LabeledInformation';
import RegionStatesDropdown from 'modules/shared/components/widgets/static/RegionStatesDropdown';
// import { isFeatureEditEnabled } from "modules/shared/helpers/headquarterDetect";
import { setUserPerference } from 'modules/user/actions';
import React from 'react';
import { connect } from 'react-redux';
import { throttle } from 'throttle-debounce';
import { defaultTimeZone } from 'utils/dateFormatter';
import { getBasicInfoProperty } from 'modules/shared/helpers/entityAttributes';

/* Import actions */
import {
  clearSettingsState,
  loadTimezones,
  lookupAddress,
  selectPhysicalAddress,
  selectPostalAddress,
  setEntitySettingsFormValue,
  updateEntityBasicInfo,
} from '../actions';
/* Import CSS */
import styles from './css/CommonEditProfile.css';

class BasicInfo extends React.Component {
  state = { form_errors: {} };

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loadTimezones());
    this.lookupPhysicalAddress = throttle(
      500,
      false,
      this.lookupPhysicalAddress
    );
    this.lookupPostalAddress = throttle(500, false, this.lookupPostalAddress);
  }

  handleChange = (event) => {
    const { dispatch } = this.props;
    dispatch(setEntitySettingsFormValue(event.target.name, event.target.value));
    if (event.target) {
      this.checkValid(event.target);
    }
  };

  checkAllValid = () => {
    const { form_values } = this.props;

    let isAllValid = true;
    let errors = new Object();

    if (!form_values['trading_name'] || form_values['trading_name'] < 1) {
      errors = { ...errors, trading_name: 'You must specify a trading name.' };
      isAllValid = false;
    }

    if (
      form_values['contact_phone_number'] < 1 ||
      !form_values['contact_phone_number']
    ) {
      errors = {
        ...errors,
        contact_phone_number: 'You must specify a phone number.',
      };
      isAllValid = false;
    }

    if (form_values['company_name'] < 3 || !form_values['company_name']) {
      errors = { ...errors, company_name: 'You must specify a business name.' };
      isAllValid = false;
    }

    this.setState({
      form_errors: errors,
    });

    // if (form_values['postal_address'] !== null && postal_dpid === null) {
    //   return false;
    // }
    //
    // if (form_values['physical_address'] !== null && physical_dpid === null) {
    //   return false;
    // }

    return isAllValid;
  };

  handlePhysicalChange = (event) => {
    const { dispatch } = this.props;
    dispatch(setEntitySettingsFormValue(event.target.name, event.target.value));
    if (event.target.value.length > 2) {
      event.persist();
      this.lookupPhysicalAddress(event.target.value);
    }
  };

  checkValid = (target) => {
    switch (target.name) {
      case 'company_name':
        if (target.value.length < 3) {
          this.setState({
            form_errors: {
              ...this.state.form_errors,
              company_name: 'You must specify a business name.',
            },
          });
        } else {
          var { company_name, ...rest_errors } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case 'trading_name':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...this.state.form_errors,
              trading_name: 'You must specify a trading name.',
            },
          });
        } else {
          var { trading_name, ...rest_errors } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case 'contact_phone_number':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...this.state.form_errors,
              contact_phone_number: 'You must specify a phone number.',
            },
          });
        } else {
          var { contact_phone_number, ...rest_errors } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case 'region':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              entity_name: 'You must enter your company location',
            },
          });
        } else {
          const { entity_name, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case 'state':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              entity_name: 'You must enter state of location',
            },
          });
        } else {
          const { entity_name, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
    }
  };

  handlePhysicalClick = (dpid) => {
    const { dispatch } = this.props;
    dispatch(selectPhysicalAddress(dpid));
  };

  UNSAFE_componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearSettingsState('business_info'));
  }

  handlePostalChange = (event) => {
    const { dispatch } = this.props;
    dispatch(setEntitySettingsFormValue(event.target.name, event.target.value));
    if (event.target.value.length > 2) {
      event.persist();
      this.lookupPostalAddress(event.target.value);
    }
  };

  handleBlur = (event) => {
    if (this.checkAllValid()) this.submit();
  };

  submit = () => {
    const {
      billingEntityName,
      dispatch,
      form_values,
      settings_physical_dpid,
      settings_postal_dpid,
    } = this.props;

    const attributes = { ...form_values };

    if (!billingEntityName) {
      attributes['billing_entity_name'] = form_values.company_name;
    }

    dispatch(
      updateEntityBasicInfo({
        ...attributes,
        physical_dpid: settings_physical_dpid,
        postal_dpid: settings_postal_dpid,
      })
    );
  };

  handlePostalClick = (dpid) => {
    const { dispatch } = this.props;
    dispatch(selectPostalAddress(dpid));
  };

  handleTimezoneChange = (target) => {
    const { currentEntityId, dispatch } = this.props;
    dispatch(
      setUserPerference({ [currentEntityId]: { timezone: target.value } })
    );
  };

  lookupPhysicalAddress = (name) => {
    const { dispatch } = this.props;
    dispatch(lookupAddress(name, 'Physical'));
  };

  lookupPostalAddress(name) {
    const { dispatch } = this.props;
    dispatch(lookupAddress(name, 'Postal'));
  }

  regionStateHandleChange = (target) => {
    const { dispatch, form_values } = this.props;
    dispatch(setEntitySettingsFormValue(target.name, target.value));
    if (target) {
      this.checkValid(target);
    }

    const formValues = {};
    formValues[target.name] = target.value;

    if (form_values.state === '') {
      formValues['state'] = 'NSW';
    }

    dispatch(updateEntityBasicInfo(formValues));
  };

  render() {
    const {
      title,
      update_text,
      form_inputs,
      form_values,
      changes_made,
      timezoneOptions,
    } = this.props;
    const { form_errors } = this.state;

    const form_textarea = [];
    const form_textinput = [];
    let textinput;

    let loader = null;
    let update_button_text = update_text;
    let update_click = this.submit;
    let disable_btn = false;
    if (this.props.update_loading) {
      loader = <span className={styles.loading}></span>;
      update_button_text = 'saving';
      update_click = (event) => {};
    }

    if (
      form_values['trading_name'] < 1 ||
      !form_values['trading_name'] ||
      form_values['contact_phone_number'] < 1 ||
      !form_values['contact_phone_number'] ||
      form_values['company_name'] < 3 ||
      !form_values['company_name']
    ) {
      update_button_text = 'invalid info';
      disable_btn = true;
    }

    let update_button = null;
    if (changes_made) {
      update_button = (
        <div className={styles.button}>
          <Button
            handleClick={update_click}
            text={update_button_text}
            disabled={disable_btn}
            grey={disable_btn}
          />
          {loader}
        </div>
      );
    }

    Object.keys(form_inputs).forEach((value, index) => {
      if (!form_inputs[value].textarea) {
        const error = form_errors[value];
        if (value === 'region') {
          textinput = (
            <RegionStatesDropdown
              key={index}
              id={value}
              regionId="region"
              stateId="state"
              label={form_inputs[value].label}
              regionError={form_errors[value]}
              stateError={form_errors['state']}
              regionValue={form_values[value]}
              stateValue={form_values['state']}
              required={form_inputs[value].required}
              handleChange={this.regionStateHandleChange}
            />
          );

          form_textinput.push(textinput);
        }
        if (value === 'timezone') {
          form_textinput.push(
            <OptionsDropdown
              key="timezone"
              id="timezone"
              name="timezone"
              label="Timezone"
              value={defaultTimeZone()}
              handleChange={this.handleTimezoneChange}
              required={true}
              options={timezoneOptions}
            />
          );
        } else {
          textinput = (
            <TextInput
              key={index}
              id={value}
              error={error}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label={form_inputs[value].label}
              value={form_values[value]}
              required={form_inputs[value].required}
            />
          );

          form_textinput.push(textinput);
        }
      }
    });

    let locationDetails = '';
    if (form_values.region === 'NZ') {
      locationDetails = 'New Zealand';
    } else if (form_values.region === 'AU') {
      locationDetails = `Australia, ${form_values.state}`;
    }

    return (
      <div className={styles.row}>
        <div className={styles.block}>
          <div className={styles.liner}>
            <h2 className={styles.header}>{title}</h2>
            {update_button}
            <div className={styles.items}>
              {form_textinput}
              <LabeledInformation
                label="Business location"
                data={locationDetails}
                noHumanize={true}
              />
            </div>
            <div className={styles.items}>{form_textarea}</div>
          </div>
        </div>
      </div>
    );
  }
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const defaults = {
  title: 'Basic info (required)',
  update_text: 'update info',
  form_inputs: {
    trading_name: {
      label: 'Trading name',
      required: true,
    },
    company_name: {
      label: 'Business name (Full legal name)',
      required: true,
    },
    contact_phone_number: {
      label: 'Business phone number',
      required: true,
    },
    physical_address: {
      label: 'Physical address',
      required: true,
      textarea: true,
    },
    postal_address: {
      label: 'Postal address',
      required: true,
      textarea: true,
    },
    timezone: {
      label: 'Timezone',
      dropdown: true,
      required: true,
    },
    organisation_code: {
      label: 'Organisation Code',
    },
    sales_code: {
      label: 'Sales Code',
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

export default connect((state, ownProps) => {
  const profile = state.manage_profile;

  const company_name = getBasicInfoProperty('company_name', profile);
  const trading_name = getBasicInfoProperty('trading_name', profile);
  const contact_phone_number = getBasicInfoProperty(
    'contact_phone_number',
    profile
  );
  const physical_address = getBasicInfoProperty('physical_address', profile);
  const postal_address = getBasicInfoProperty('postal_address', profile);
  const physical_dpid = getBasicInfoProperty('physical_dpid', profile);
  const postal_dpid = getBasicInfoProperty('postal_dpid', profile);
  const region = getBasicInfoProperty('region', profile, true);
  const states = getBasicInfoProperty('state', profile, true);
  const organisation_code = getBasicInfoProperty(
    'organisation_code',
    profile,
    true
  );
  const sales_code = getBasicInfoProperty('sales_code', profile, true);

  const {
    settings_section_edited,
    current_entity,
    timezone_options,
    settings_physical_address_list,
    settings_physical_address_loading,
    settings_postal_address_list,
    settings_postal_address_loading,
    settings_update_loading,
  } = profile || {};

  const persistedProfile = current_entity.attributes;

  const { billing_entity_name } = persistedProfile;

  const changes_made =
    settings_section_edited && settings_section_edited['business_info'];
  const currentEntityId = current_entity.id;
  const timezoneOptions = timezone_options.map((tz) => {
    return { label: tz, value: tz };
  });
  return {
    changes_made,
    form_inputs: defaults.form_inputs,
    form_values: {
      company_name,
      contact_phone_number,
      physical_address,
      postal_address,
      region,
      state: states,
      trading_name,
      organisation_code,
      sales_code,
    },
    billingEntityName: billing_entity_name,
    physical_address_list: settings_physical_address_list,
    currentEntityId,
    physical_address_loading: settings_physical_address_loading,
    physical_dpid,
    postal_address_list: settings_postal_address_list,
    postal_address_loading: settings_postal_address_loading,
    title: defaults.title,
    postal_dpid,
    update_text: defaults.update_text,
    timezoneOptions,
    update_loading: settings_update_loading,
  };
})(BasicInfo);
