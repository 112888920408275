import React from 'react';
import styles from './css/ImageModal.css';

import CloseButton from 'modules/shared/components/inputs/CloseButton';
import PdfReader from 'modules/shared/components/widgets/static/pdfReader';

function ImageModal(props) {
  const { dismissHandler, title, size, src } = props;

  let dismissButton = null;
  let contentStyle = styles.content;

  function is_src_pdf(src) {
    return src.includes('.pdf') || src.includes('application/pdf');
  }

  if (size) {
    contentStyle = styles['content_' + size];
  }

  if (dismissHandler) {
    dismissButton = (
      <CloseButton
        css_class="button_position_imageModal"
        text="Close"
        handleClick={dismissHandler}
      />
    );
  }

  const onPDFError = () => {
    const win = window.open('', 'pdf_window');
    win.open(src, 'pdf_window');

    if (dismissHandler) {
      dismissHandler();
    }
  };

  return (
    <div className={styles.modal}>
      {dismissButton}
      <div className={styles.fade}>
        <div className={contentStyle}>
          <div className={[styles.body, 'p-6'].join(' ')}>
            {title && <p className="mb-5 has-text-left">{title}</p>}
            {src && is_src_pdf(src) ? (
              <PdfReader url={src} no_wrapper={true} onError={onPDFError} />
            ) : Array.isArray(src) ? (
              src.map((curSrc) => <img className={styles.image} src={curSrc} />)
            ) : (
              <img className={styles.image} src={src} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageModal;
