import React, { ReactElement } from 'react';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import styles from 'modules/new-applications/css/LimitAndApprovals.css';
import * as Sentry from '@sentry/browser';
import { taggedApproverEscalationData } from 'modules/new-applications/utils/escalationHelpers';

const getReviewMessage = (review: any, reviewEscalations: any): string => {
  const {
    approvalHierarchyVersion,
    decision,
    formattedReviewedAt,
    id,
    level,
  } = review;

  if (decision === 'escalated') {
    const escalationData = taggedApproverEscalationData({
      review,
      reviewEscalations: reviewEscalations,
    });

    if (escalationData) {
      const {
        escalated_to_level,
        escalated_for_user_name,
      } = escalationData.attributes;

      return `Escalated approval to ${escalated_for_user_name} (L${escalated_to_level}) on ${review.formattedReviewedAt}.`;
    }

    return `Escalated to L${level +
      1} on ${formattedReviewedAt} (approval hierarchy version ${approvalHierarchyVersion}).`;
  }

  if (decision === 'reviewed') {
    return `Reviewed on ${formattedReviewedAt} (approval hierarchy version ${approvalHierarchyVersion}).`;
  }

  console.error('Invalid review decision');
  Sentry.captureException(`Invalid review decision for review ${id}`);
  return '';
};

const ReviewerContent = ({
  review,
  application,
}: {
  review: { [key: string]: any };
  application: any;
}): ReactElement => (
  <FixedContent withBottomSeparator={false} withBottomMargin={false}>
    <p className={styles.approver_name}>
      {`Reviewer name: ${review.reviewerName}`}
    </p>
    <p>{getReviewMessage(review, application.reviewEscalations)}</p>
    <p>{review.notes || 'No notes.'}</p>
  </FixedContent>
);

export default ReviewerContent;
