import BottomRight from 'containers/position/BottomRight';
import get from 'lodash.get';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import AccountTypeSelectDropdown from 'modules/shared/components/v2/Form/SelectDropdown/AccountTypeSelectDropdown';
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';
import { setCreditCheckAccountTypes } from 'modules/user/actions';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import getAccountTypeOptions from 'utils/getAccountTypeOptions';

import styles from './css/CreditCheckModal.css';
import { FEATURE_FLAGS } from 'conf';

/* Import components */
const Modal = createClass({
  render() {
    const {
      title,
      button,
      dismissHandler,
      card,
      role_types,
      close_button_style,
      update_loading,
      entity_region,
    } = this.props;

    let credit_card_message;
    if (!card && !role_types.includes('admin')) {
      credit_card_message = (
        <span className={styles.message}>
          Please ask your administrator to add credit card.
        </span>
      );
    }
    const region = entity_region.toLowerCase();
    let contents;

    switch (region) {
      case 'au':
        contents = (
          <div>
            {this.renderAccountTypeSelect()}
            {this.renderAUCompany()}
            {this.renderAUPersonal()}
            {this.renderAUInidividual()}
            {this.renderNZCompany()}
            {this.renderNZPersonal()}
          </div>
        );
        break;
      default:
        contents = (
          <div>
            {this.renderAccountTypeSelect()}
            {this.renderNZCompany()}
            {this.renderNZPersonal()}
            {this.renderAUCompany()}
            {this.renderAUPersonal()}
            {this.renderAUInidividual()}
          </div>
        );
        break;
    }

    return (
      <div className={styles.modal}>
        {update_loading ? (
          <SimpleLoader />
        ) : (
          <div className={styles.fade}>
            <div className={styles.content}>
              <div className={styles.container}>
                <div className={styles.col}>
                  <div className={styles.body}>
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.titleDesc}>
                      If you are trading in both New Zealand and Australia and
                      would like Credit Reports provided, please select a credit
                      agency for each country.
                      <br />
                      After your customer has completed their application, you
                      can conduct a credit check when reviewing the application.
                      You will also be able to conduct credit checks on
                      guarantors once they have agreed to guarantee the account.
                      You will not be charged unless you have initiated the
                      checks on each application. Costs excl. GST.
                    </p>

                    {contents}
                    {credit_card_message}
                    <BottomRight>{button}</BottomRight>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <CloseButton
          text="Close"
          css_class={close_button_style}
          handleClick={dismissHandler}
        />
      </div>
    );
  },

  renderAUCompany() {
    const { auCreditCheckComponents } = this.props;
    if (auCreditCheckComponents.length > 0) {
      return (
        <div className={styles.section}>
          <div className={styles.subTitle}>
            {' '}
            {FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_TRUST_REPORTING ? (
              <Fragment>
                Company / Business Credit Checks Australia
                <br />
                <span>
                  This applies to Company, Trust, and Partnership entity types.
                </span>
              </Fragment>
            ) : (
              <Fragment>
                Company Credit Checks Australia
                <br />
              </Fragment>
            )}
          </div>
          {auCreditCheckComponents}
        </div>
      );
    }
    return '';
  },

  renderAUPersonal() {
    const { auPersonalCreditCheckComponents, isAuEquifaxBusinessEnabled } =
      this.props;
    if (auPersonalCreditCheckComponents.length > 0) {
      return (
        <div className={styles.section}>
          <div className={styles.subTitle}>
            {FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_CONSUMER &&
            isAuEquifaxBusinessEnabled ? (
              <Fragment>
                Business Credit Checks Australia
                <br />
                <span>
                  This applies to Sole trader entity type. This is the business
                  credit check for Sole trader entities.
                </span>
              </Fragment>
            ) : (
              <Fragment>
                Personal Credit Checks Australia
                <br />
              </Fragment>
            )}
          </div>
          {auPersonalCreditCheckComponents}
        </div>
      );
    }
    return '';
  },

  renderAUInidividual() {
    if (!FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_CONSUMER) return null;

    const { auIndividualCreditCheckComponents } = this.props;
    if (auIndividualCreditCheckComponents.length > 0) {
      return (
        <div className={styles.section}>
          <div className={styles.subTitle}>
            Personal Credit Checks Australia
          </div>
          {auIndividualCreditCheckComponents}
        </div>
      );
    }
    return '';
  },

  renderAccountTypeSelect() {
    const { credit_check_application_types, dispatch } = this.props;
    const accountTypeOptions = getAccountTypeOptions();

    const attributes = (newValue) => {
      const payload = newValue.length === 0 ? ['credit'] : newValue;
      dispatch(setCreditCheckAccountTypes(payload));
    };

    return (
      <div className={styles.section}>
        <div className={styles.subTitle}>Account types</div>
        <span className={styles.account_type_label}>
          {'Select the account type(s) you wish to apply to credit checks'}
        </span>
        <div className={styles.account_type}>
          <AccountTypeSelectDropdown
            handleChange={(target) => attributes(target.value)}
            value={credit_check_application_types || []}
            multiple
            bulkSelect
            options={accountTypeOptions}
            disabled={!isFeatureEditEnabled('Credit')}
          />
        </div>
      </div>
    );
  },

  renderNZCompany() {
    const { nzCreditCheckComponents } = this.props;
    if (nzCreditCheckComponents.length > 0) {
      return (
        <div className={styles.section}>
          <div className={styles.subTitle}>
            Company Credit Checks New Zealand
          </div>
          {nzCreditCheckComponents}
        </div>
      );
    }
    return '';
  },

  renderNZPersonal() {
    const { nzPersonalCreditCheckComponents } = this.props;
    if (nzPersonalCreditCheckComponents.length > 0) {
      return (
        <div className={styles.section}>
          <div className={styles.subTitle}>
            Personal Credit Checks New Zealand
          </div>
          {nzPersonalCreditCheckComponents}
        </div>
      );
    }
    return '';
  },
});

module.exports = connect((state) => {
  return {
    card: get(state, 'manage_profile.current_entity_card', null),
    credit_check_application_types: get(
      state,
      'current_user.current_entity.attributes.credit_check_application_types',
      []
    ),
    entity_region: get(
      state,
      'current_user.current_entity.attributes.region',
      ''
    ),
    role_types: get(
      state,
      'current_user.current_user_entity_link.attributes.role_types',
      []
    ),
  };
})(Modal);
