import { IconButton, Typography } from '@material-ui/core';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import React from 'react';
import styled from 'styled-components';
import HelpIcon from '@material-ui/icons/Help';

import { onSetActiveQuestionAndAnswer } from 'modules/shared/components/top/Overlay/redux/actions';
import { TRUSTEE_TYPE } from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/constants/questionAndAnswers';

const TRUSTEE_TYPE_OPTIONS = [
  {
    label: 'Individual trustee',
    brief: 'Individual trustee',
    value: 'individual',
  },
  {
    label: 'Corporate trustee',
    brief: 'Corporate trustee',
    value: 'corporate',
  },
  {
    label: 'Independent trustee',
    brief: 'Independent trustee',
    value: 'independent',
  },
];

const Description = styled(Typography)`
  width: auto;
  @media screen and (min-width: 991px) {
    width: max-content;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: relative;
  top: -2px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

type TrusteeTypeProps = {
  onChange: () => void;
  value?: string;
  error?: string;
  dispatch: any;
};

export const TrusteeType = (props: TrusteeTypeProps) => {
  const { onChange, value, error, dispatch } = props;

  const handlClickHelp = () =>
    dispatch(onSetActiveQuestionAndAnswer(TRUSTEE_TYPE));

  return (
    <Container>
      <Description>
        A trustee could be an individual trustee, corporate trustee, or
        independent trustee. Please select the type of the trustee and complete
        required fields.
        <StyledIconButton onClick={handlClickHelp}>
          <HelpIcon color="primary" fontSize="small" />
        </StyledIconButton>
      </Description>
      <BorderedSelect
        name="trustee_type"
        options={TRUSTEE_TYPE_OPTIONS}
        onChange={onChange}
        placeholder="Trustee type"
        value={value || ''}
        error={!!error}
        helperText={error}
      />
    </Container>
  );
};
