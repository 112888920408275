import React from 'react';
import { Paper, Tab, Tabs, Typography } from '@material-ui/core';
// @ts-ignore
import { browserHistory } from 'react-router';

import WatchtowerAlerts from './Alerts';
import AccountMonitoring from './AccountMonitoring';
import { FEATURE_FLAGS } from 'conf';

const isAccountMonitoringEnabled =
  FEATURE_FLAGS.FEATURE_FLAG_ACCOUNT_MONITORING;

type CurrentUser = {
  accessToken: string;
  currentEntity: {
    id: string;
  };
  id: string;
};

type Application = any;

export type WatchtowerAlertProps = {
  application: Application;
  currentUser: CurrentUser;
  onFetchApplicationRecord: () => void;
  watchtowerExclusions: string[];
};

export default function Watchtower(props: WatchtowerAlertProps) {
  const queryParams = new URLSearchParams(window.location.search);
  const tab = queryParams.get('tab') || 'alerts';

  const onChangeTab = (_, newTab) => {
    if (tab === newTab) {
      return;
    }
    queryParams.set('tab', newTab);
    browserHistory.push(`${window.location.pathname}?${queryParams}`);
  };

  return (
    <Paper square elevation={0}>
      <Typography variant="h6">Watchtower</Typography>
      <Tabs
        style={{ textTransform: 'none' }}
        indicatorColor="primary"
        textColor="primary"
        value={tab}
        onChange={onChangeTab}
        scrollButtons="auto"
        variant="scrollable"
        aria-label="watch tower tabs"
      >
        <Tab
          label={<span>Alerts</span>}
          value="alerts"
          style={{ textTransform: 'none' }}
        />
        <Tab
          disabled={!isAccountMonitoringEnabled}
          label={
            <span>
              {isAccountMonitoringEnabled
                ? 'Account monitoring'
                : 'Future tasks'}
            </span>
          }
          value="account-monitoring"
          style={{ textTransform: 'none' }}
        />
      </Tabs>
      <div className="pt-4">
        {tab === 'alerts' && <WatchtowerAlerts {...props} />}
        {tab === 'account-monitoring' && (
          <AccountMonitoring
            application={props.application}
            currentUser={props.currentUser}
            onFetchApplicationRecord={props.onFetchApplicationRecord}
          />
        )}
      </div>
    </Paper>
  );
}
