/* eslint-disable max-lines */
import { InputAdornment } from '@material-ui/core';
import api from 'api';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import ReviewModel, { isReviewerLevel } from 'models/ReviewModel';
import { MobileScreenContext } from 'modules/new-applications/components/Application';
import ApprovalBlockedByIUF from 'modules/new-applications/components/application-sections/LimitAndApprovals/ApprovalBlockedByIUF';
import ButtonWithTooltip from 'modules/new-applications/components/application-sections/LimitAndApprovals/ButtonWithTooltip';
import GenericApproverForm from 'modules/new-applications/components/application-sections/LimitAndApprovals/GenericApproverForm';
import ReadOnlyTaggedApprover from 'modules/new-applications/components/application-sections/LimitAndApprovals/ReadOnlyTaggedApprover';
import ReviewContent from 'modules/new-applications/components/application-sections/LimitAndApprovals/ReviewContent';
import RecordHistory from 'modules/new-applications/components/RecordHistory';
import commonStyles from 'modules/new-applications/css/common.css';
import styles from 'modules/new-applications/css/LimitAndApprovals.css';
import useApplicationApproversState from 'modules/new-applications/hooks/useApplicationApproversState';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import {
  createPreviousEscalationData,
  getCurrentReviewLevelEscalation,
  getEscalationDataFromReview,
  getEscalationsEscalatedByUser,
  hasActiveEscalations,
  hasReviewEscalations,
  taggedApproverEscalationData,
} from 'modules/new-applications/utils/escalationHelpers';
import { isUserInvolvedInEscalation } from 'modules/new-applications/utils/escalationHelpers';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import BorderedTextArea from 'modules/shared/components/inputs/BorderedTextArea';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import Modal from 'modules/shared/components/v2/Modal';
import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import LabeledInputContent from 'modules/shared/components/widgets/static/LabeledInputContent';
import {
  isBranchOrRegion,
  isDecentralize1CAHBranch,
  isDifferentBranchApplication,
} from 'modules/shared/helpers/headquarterDetect';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { sendSurvey } from 'utils/askNicely';
import { setChurnZeroAttributesWithoutRedux } from 'utils/churnZero';
import { formatMoney } from 'utils/formatting';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import sortByDate from 'utils/sortByDate';
import * as yup from 'yup';

const MAX_DISCOUNT = 75;

const tagApproverSchema = (level) =>
  yup.object().shape({
    taggedApproverId: yup
      .string()
      .required(() => getTaggedAPproverIdValidationMessage(level)),
    taggedNotes: yup.string(),
  });

const acceptCashReviewSchema = yup.object().shape({
  notes: yup.string(),
});

const getTaggedAPproverIdValidationMessage = (level) => {
  if (isReviewerLevel(level)) {
    return 'Please select a new reviewer';
  }

  return 'Please select a new approver';
};

const getIsCurrentApproverStillValid = (approvers, taggedApproverId) =>
  approvers.some((approver) => approver.value === taggedApproverId);

const headerByLevel = ({ creditLimit, level, minimumApprovers }) => {
  if (isReviewerLevel(level)) {
    return 'Application reviewer';
  }

  const formattedLimit = formatMoney(creditLimit || 0);
  return `Level ${level} ($${formattedLimit}) - ${minimumApprovers} approvers required`;
};

const getApprovalLevel = (application) => {
  const {
    applicationType,
    cashApplicationDerivedCurrentApprovalLevel,
    derivedCurrentApprovalLevel,
  } = application;
  return applicationType === 'cash'
    ? cashApplicationDerivedCurrentApprovalLevel
    : derivedCurrentApprovalLevel;
};

function sanitizeApprovers({ approvers, review, reviews }) {
  const selectedApproverIds = reviews
    .filter((r) => r.id !== review.id)
    .map((r) => r.taggedApproverId)
    .filter((r) => isPresent(r));

  return approvers.filter(
    (approver) => !selectedApproverIds.includes(approver.value)
  );
}

function filterByLevel({
  approvers,
  review,
  reviews,
  level,
  derivedCurrentApprovalLevel,
}) {
  const userLevelCheck =
    level === 0 || level ? level : derivedCurrentApprovalLevel;
  return sanitizeApprovers({
    approvers,
    review,
    reviews,
  }).filter((approver) => approver.level === userLevelCheck);
}

function filterByEscalation({
  approvers,
  review,
  reviews,
  currentUser,
  level,
  sameLevelEscalatedByUserIds,
}) {
  return sanitizeApprovers({
    approvers,
    review,
    reviews,
  }).filter(
    (approver) =>
      approver.level >= level &&
      currentUser.id !== approver.value &&
      !sameLevelEscalatedByUserIds.includes(approver.value)
  );
}

function tagLabel(review) {
  if (isPresent(review.id)) {
    return 'Retag';
  }

  return 'Tag';
}

const getTagApproverFieldLabel = (review, index) => {
  const labelPrefix = tagLabel(review);

  if (review.isReviewerLevel) {
    return `${labelPrefix} reviewer`;
  }

  return `${labelPrefix} approver ${index}`;
};

const getReviewActionButtonsMessage = (isReviewerLevel) => {
  if (isReviewerLevel) {
    return 'Would you like to review this application?';
  }

  return 'Would you like to approve this application?';
};

function TaggingNotes(props) {
  const { isDisabled, isVisible, register } = props;

  if (isVisible) {
    return (
      <BorderedTextArea
        disabled={isDisabled}
        label="Tagging notes"
        name="taggedNotes"
        inputRef={register}
        placeholder="Notes"
      />
    );
  }

  return null;
}

function useSelectedApproverState(props) {
  const { approvers, taggedApproverId } = props;

  const [selectedApproverId, setSelectedApproverId] =
    useState(taggedApproverId);
  const selectedOption =
    approvers.find((approver) => approver.value === selectedApproverId) || {};

  return { selectedApproverId, selectedOption, setSelectedApproverId };
}

function DesktopTagApproverForm(props) {
  const { isMobileScreen } = useContext(MobileScreenContext);

  if (isMobileScreen) {
    return null;
  }

  const {
    approvers,
    errors,
    isDisabled,
    isLoading,
    isTaggingNotesVisible,
    label,
    onSelectApprover,
    register,
    review,
    selectedOption,
  } = props;

  return (
    <div className={styles.desktop_tag_approver_form}>
      <GridContent gridColumnTemplate="two_thirds">
        <div>
          <LabeledInputContent label={label}>
            <BorderedAutocomplete
              textFieldProps={{
                error: Boolean(errors.taggedApproverId),
                helperText: get(errors, 'taggedApproverId.message', ' '),
                label: '',
                name: 'taggedApproverId',
              }}
              options={approvers}
              value={selectedOption}
              withBottomMargin={false}
              disabled={isDisabled}
              onChange={onSelectApprover}
            />
          </LabeledInputContent>
          <TaggingNotes
            register={register}
            isVisible={isTaggingNotesVisible}
            isDisabled={isDisabled}
          />
        </div>
        <div>
          <Button
            disabled={isDisabled}
            loading={isLoading}
            type="submit"
            text={tagLabel(review)}
          />
        </div>
      </GridContent>
    </div>
  );
}

function MobileTagApproverForm(props) {
  const { isMobileScreen } = useContext(MobileScreenContext);

  if (!isMobileScreen) {
    return null;
  }

  const {
    approvers,
    errors,
    isDisabled,
    isLoading,
    isTaggingNotesVisible,
    label,
    onSelectApprover,
    register,
    review,
    selectedOption,
  } = props;

  return (
    <div className={styles.mobile_tag_approver_form}>
      <GridContent mobileGridColumnTemplate="two_thirds">
        <LabeledContent label={label} withBottomMargin={false}>
          <BorderedAutocomplete
            textFieldProps={{
              error: Boolean(errors.taggedApproverId),
              helperText: get(errors, 'taggedApproverId.message', ' '),
              label: '',
              name: 'taggedApproverId',
            }}
            options={approvers}
            value={selectedOption}
            withBottomMargin={false}
            disabled={isDisabled}
            onChange={onSelectApprover}
          />
        </LabeledContent>
        <div className={styles.mobile_submit_button_container}>
          <Button
            disabled={isDisabled}
            loading={isLoading}
            type="submit"
            text={tagLabel(review)}
          />
        </div>
      </GridContent>
      <GridContent>
        <TaggingNotes
          register={register}
          isVisible={isTaggingNotesVisible}
          isDisabled={isDisabled}
        />
      </GridContent>
    </div>
  );
}

const getAlertErrorMessage = (error) => {
  const applicationErrors = get(error, 'response.data.errors', []);

  if (isBlank(applicationErrors)) {
    return 'Something went wrong. Please refresh and try again.';
  }

  const errorMessages = applicationErrors.map((applicationError, index) => (
    <p key={`error-message-${index}`}>{applicationError.title}</p>
  ));

  errorMessages.push(
    <p key="error-message-generic">
      Refresh the page to get an up to date version of the application.
    </p>
  );

  return errorMessages;
};

function TagApproverForm(props) {
  const {
    application,
    approvers,
    currentUser,
    index,
    review,
    onFetchApplicationRecord,
    onSetAlert,
    escalations,
    escalatedForId,
    hasEscalatedReview,
  } = props;

  const { selectedApproverId, selectedOption, setSelectedApproverId } =
    useSelectedApproverState({
      approvers,
      taggedApproverId: review.taggedApproverId,
    });

  useEffect(() => {
    if (escalatedForId !== selectedApproverId) {
      setSelectedApproverId(review.taggedApproverId);
    }
  }, [review.taggedApproverId]);

  const {
    clearError,
    errors,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm({
    mode: 'onBlur',
    validationSchema: tagApproverSchema(review.level),
  });

  const { isLoading, setIsLoading } = useIsLoadingState();
  const isDisabled = isBlank(approvers);
  const [isApprovalFormVisible, setIsApprovalFormVisible] = useState(false);

  const onSuccessCallback = () => {
    setIsLoading(false);
    reset();
    onFetchApplicationRecord();
  };

  const onErrorCallback = (error) => {
    setIsLoading(false);
    setSelectedApproverId(null);
    onSetAlert({ message: getAlertErrorMessage(error), type: 'error' });
  };

  const isTaggedApproverChanged =
    review.taggedApproverId !== selectedApproverId &&
    escalatedForId !== selectedApproverId;
  const onSubmit = (event) => {
    event.preventDefault();

    if (isTaggedApproverChanged) {
      handleSubmit((data) => {
        setIsLoading(true);

        review.save({
          application,
          attributes: data,
          currentUser,
          onErrorCallback,
          onSuccessCallback,
        });
      })();
    } else {
      setError(
        'taggedApproverId',
        'required',
        getTaggedAPproverIdValidationMessage(review.level)
      );
    }
  };

  const onSelectApprover = (_, value) => {
    const selectedValue = (value || {}).value;

    clearError('taggedApproverId');

    setSelectedApproverId(selectedValue);
    setValue('taggedApproverId', selectedValue);
  };

  useEffect(() => {
    register({ name: 'taggedApproverId' });
  }, [register]);

  let taggingContent = null;
  if (!isApprovalFormVisible) {
    const label = getTagApproverFieldLabel(review, index);
    const isTaggingNotesVisible =
      isTaggedApproverChanged && isPresent(selectedOption);

    taggingContent = (
      <form onSubmit={onSubmit}>
        <DesktopTagApproverForm
          approvers={approvers}
          errors={errors}
          index={index}
          isDisabled={isDisabled || hasEscalatedReview}
          isLoading={isLoading}
          isTaggedApproverChanged={isTaggedApproverChanged}
          isTaggingNotesVisible={isTaggingNotesVisible}
          label={label}
          onSelectApprover={onSelectApprover}
          register={register}
          review={review}
          selectedOption={selectedOption}
        />
        <MobileTagApproverForm
          approvers={approvers}
          errors={errors}
          index={index}
          isDisabled={isDisabled || hasEscalatedReview}
          isLoading={isLoading}
          isTaggedApproverChanged={isTaggedApproverChanged}
          isTaggingNotesVisible={isTaggingNotesVisible}
          label={label}
          onSelectApprover={onSelectApprover}
          register={register}
          review={review}
          selectedOption={selectedOption}
        />
      </form>
    );
  }

  return (
    <FixedContent withBottomSeparator={false}>
      {taggingContent}
      <ApprovalBlockedByIUF application={application} />
      <CurrentApprover
        index={index}
        setIsApprovalFormVisible={setIsApprovalFormVisible}
        escalations={escalations}
        escalatedForId={escalatedForId}
        {...props}
      />
    </FixedContent>
  );
}

function TagApproversByApprovalHierarchy(props) {
  const {
    application,
    currentUser,
    formattedUsers,
    hierarchy,
    onFetchApplicationRecord,
    onSetAlert,
  } = props;
  const { autoDecisioningState, reviews, derivedCurrentApprovalLevel } =
    application;

  if (autoDecisioningState === 'approved') {
    return null;
  }

  const {
    credit_limit: creditLimit,
    level,
    minimum_approvers: minimumApprovers,
  } = hierarchy;

  // Review new escalations start
  const { reviewEscalations } = application;
  const hasEscalation = hasReviewEscalations(reviewEscalations);
  const hasUnactionedEscalations = hasActiveEscalations(
    reviewEscalations,
    reviews
  );
  let reviewDisplay = reviews;
  // Create cloned previous review escalations for display
  if (hasEscalation) {
    reviewDisplay = [
      ...createPreviousEscalationData({ reviewEscalations, reviews }),
      ...reviews,
    ];
  }
  // Review new escalations end
  const reviewsByLevel = reviewDisplay.filter(
    (review) => review.level === level
  );
  const hasUnactionedTaggedApprover = reviewsByLevel.some((review) => {
    return review.taggedApproverId && !review.isActioned;
  });
  const actionedReviews = sortByDate({
    dateAttribute: 'reviewedAt',
    direction: 'ascending',
    records: reviewsByLevel.filter((review) => review.isActioned),
  });
  const pendingReviewsLevel = hasUnactionedTaggedApprover
    ? derivedCurrentApprovalLevel - 1
    : derivedCurrentApprovalLevel;
  const pendingReviews = sortByDate({
    dateAttribute: 'createdAt',
    direction: 'ascending',
    records: reviewsByLevel.filter(
      (review) => !review.isActioned && !(review.level < pendingReviewsLevel)
    ),
  });
  const sortedReviews = actionedReviews.concat(pendingReviews);
  const approvers = formattedUsers.filter((user) => !user.isStandard);
  const approverComponents = sortedReviews.map((review, i) => {
    const index = i + 1;

    // Escalation check
    const { taggedApproverId } = review;
    const currentUserIsTaggedApprover = taggedApproverId === currentUser.id;
    const currentReviewEscalation = getCurrentReviewLevelEscalation({
      currentUserIsTaggedApprover,
      level,
      reviewEscalations,
      reviewId: review.id,
    });
    const { attributes: currentReviewEscalationAttributes, id: escalationId } =
      currentReviewEscalation || {};
    const {
      escalated_for_id,
      escalated_to_level,
      escalated_from_level,
      escalated_by_id,
    } = currentReviewEscalationAttributes || {};
    const isNonEscalatedDecision = review.decision !== 'escalated';
    const sameLevelEscalation = escalated_to_level === escalated_from_level;
    const currentUserEscalatedReview = escalated_by_id === currentUser.id;

    if (
      (review.isActioned && isNonEscalatedDecision) ||
      (review.isCloned && !isNonEscalatedDecision) ||
      (sameLevelEscalation && currentUserEscalatedReview && !review.isCloned)
    ) {
      return (
        <ReviewContent
          key={`review-content-${level}-${index}`}
          index={index}
          application={application}
          currentUser={currentUser}
          review={review}
          onRevertDecisionCallback={onFetchApplicationRecord}
          onSetAlert={onSetAlert}
        />
      );
    }

    const specificLevelApprovers = filterByLevel({
      approvers,
      level,
      review,
      reviews,
    });
    const sameLevelEscalatedByUserIds = getEscalationsEscalatedByUser(
      reviewEscalations,
      level
    );
    const escalationApprovers = filterByEscalation({
      approvers,
      currentUser,
      level,
      review,
      reviews,
      sameLevelEscalatedByUserIds,
    });

    const reviewEscalated = application.isReviewLevelEscalated(review.level);
    const escalatedToLevel = review.level === escalated_to_level;
    const escalatedFromLevel = review.level === escalated_from_level;
    if (
      ((reviewEscalated && !currentUserIsTaggedApprover && escalatedToLevel) ||
        (currentUserIsTaggedApprover && escalatedFromLevel)) &&
      !sameLevelEscalation
    ) {
      return <ReadOnlyTaggedApprover review={review} />;
    }
    return (
      <TagApproverForm
        key={`tag-approver-form-${level}-${index}`}
        index={index}
        approvers={specificLevelApprovers}
        escalations={escalationApprovers}
        review={review}
        level={level}
        hasEscalatedReview={hasUnactionedEscalations}
        escalatedForId={escalated_for_id}
        escalationId={escalationId}
        {...props}
      />
    );
  });

  const missingApproverCount = minimumApprovers - approverComponents.length;
  const existingReviewsInLevel = reviewsByLevel.filter((review) => review.id);
  if (
    missingApproverCount > 0 &&
    (!existingReviewsInLevel.length ||
      existingReviewsInLevel.length < minimumApprovers)
  ) {
    for (let i = 0; i < missingApproverCount; i++) {
      const newReview = new ReviewModel({ attributes: { level } });
      const index = reviewsByLevel.length + i + 1;

      application.addReview(newReview);

      if (!application.isReviewLevelEscalated(newReview.level)) {
        const sanitizedApprovers = filterByLevel({
          approvers,
          level,
          review: newReview,
          reviews,
        });

        const formComponent = (
          <TagApproverForm
            key={`tag-approver-form-${level}-${index}`}
            index={index}
            level={level}
            approvers={sanitizedApprovers}
            review={newReview}
            hasEscalatedReview={hasUnactionedEscalations}
            {...props}
          />
        );

        approverComponents.push(formComponent);
      }
    }
  }
  // no reviews or pending to display
  if (!approverComponents.length) return null;

  const header = headerByLevel({ creditLimit, level, minimumApprovers });
  return <FixedContent header={header}>{approverComponents}</FixedContent>;
}

function getApprovalHierarchyToDisplay(
  approvalHierarchy,
  hasReviewerEscalation
) {
  const approvalHierarchyLevelsArray = approvalHierarchy.map(
    (hierarchy) => hierarchy.level
  );
  let approvalHierarchyToDisplay = approvalHierarchy;
  if (hasReviewerEscalation && !approvalHierarchyLevelsArray.includes(0)) {
    approvalHierarchyToDisplay = [{ level: 0 }, ...approvalHierarchy];
  }
  return approvalHierarchyToDisplay;
}

function TagApprovers(props) {
  const { application, confirmModalState } = props;
  const { approvalHierarchyLevels, hasReviewerEscalation } = application;
  const approvalHierarchyDisplay = getApprovalHierarchyToDisplay(
    approvalHierarchyLevels,
    hasReviewerEscalation
  );
  const approvalLevel = getApprovalLevel(application);

  return approvalHierarchyDisplay
    .filter((hierarchy) => hierarchy.level <= approvalLevel)
    .map((hierarchy, i) => (
      <TagApproversByApprovalHierarchy
        key={`tag-approvers-by-approval-hierarchy-${i + 1}`}
        confirmModalState={confirmModalState}
        hierarchy={hierarchy}
        {...props}
      />
    ));
}

const getWaitingForReviewMessage = (level) => {
  if (isReviewerLevel(level)) {
    return 'Waiting for reviewer to action this account.';
  }

  return `Waiting for level ${level} managers to review this account.`;
};

function ReadonlyViewByApprovalHierarchy(props) {
  const {
    application,
    currentUser,
    hierarchy,
    onFetchApplicationRecord,
    onSetAlert,
    reviews,
  } = props;
  const {
    credit_limit: creditLimit,
    level,
    minimum_approvers: minimumApprovers,
  } = hierarchy;

  const header = headerByLevel({ creditLimit, level, minimumApprovers });

  if (reviews.length === 0) {
    return (
      <FixedContent header={header}>
        {getWaitingForReviewMessage(level)}
      </FixedContent>
    );
  }

  const actionedReviews = reviews.filter((review) => review.isActioned);
  let reviewComponents = actionedReviews.map((review, i) => {
    const escalationData = getEscalationDataFromReview({
      review,
      reviewEscalations: application.reviewEscalations,
    });
    // check if escalated to other user and wait for action
    if (
      escalationData &&
      currentUser.id !== review.taggedApproverId &&
      review.decision === 'escalated'
    ) {
      return (
        <FixedContent>
          {getWaitingForReviewMessage(
            escalationData.attributes.escalated_to_level
          )}
        </FixedContent>
      );
    }

    return (
      <ReviewContent
        key={`review-content-${level}-${i + 1}`}
        index={i + 1}
        application={application}
        currentUser={currentUser}
        review={review}
        onRevertDecisionCallback={onFetchApplicationRecord}
        onSetAlert={onSetAlert}
      />
    );
  });

  const unActionedReviews = reviews.filter((review) => !review.isActioned);
  if (application.isReviewLevelEscalated(level)) {
    const unActionReviewsComponents = unActionedReviews.map((review, i) => (
      <ReadOnlyTaggedApprover
        key={`read-only-tagged-approver-${level}-${i + 1}`}
        review={review}
        reviewEscalation={application.reviewEscalation}
      />
    ));

    reviewComponents = reviewComponents.concat(unActionReviewsComponents);
  } else if (isBlank(actionedReviews)) {
    reviewComponents = reviewComponents.concat(
      getWaitingForReviewMessage(level)
    );
  }

  return <FixedContent header={header}>{reviewComponents}</FixedContent>;
}

function StandardUserView(props) {
  const { application, currentUser } = props;
  const { approvalHierarchy, reviews } = application;
  const approvalLevel = getApprovalLevel(application);

  return approvalHierarchy
    .filter((hierarchy) => hierarchy.level <= approvalLevel)
    .map((hierarchy, i) => {
      const actionedReviews = reviews.filter(
        (review) => review.isActioned && review.level === hierarchy.level
      );

      return (
        <ReadonlyViewByApprovalHierarchy
          key={`standard-user-view-${i + 1}`}
          application={application}
          hierarchy={hierarchy}
          reviews={actionedReviews}
          currentUser={currentUser}
        />
      );
    });
}

function ApproverUserViewByApprovalHierarchy(props) {
  const {
    application,
    currentUser,
    formattedUsers,
    hierarchy,
    onFetchApplicationRecord,
    onSetAlert,
    reviews,
  } = props;
  const reviewer = formattedUsers.find(
    (approver) => approver.value === currentUser.id
  );

  if (isReviewerLevel(hierarchy.level) && isPresent(reviewer)) {
    return <TagApproversByApprovalHierarchy {...props} />;
  }

  if (
    (isBlank(reviewer) || reviewer.level !== hierarchy.level) &&
    !application.isReviewLevelEscalated(hierarchy.level)
  ) {
    return (
      <ReadonlyViewByApprovalHierarchy
        application={application}
        currentUser={currentUser}
        hierarchy={hierarchy}
        reviews={reviews}
        onFetchApplicationRecord={onFetchApplicationRecord}
        onSetAlert={onSetAlert}
      />
    );
  }

  return <TagApproversByApprovalHierarchy {...props} />;
}

function ApproverUserView(props) {
  const {
    application,
    confirmModalState,
    currentUser,
    formattedUsers,
    onFetchApplicationRecord,
    onSetAlert,
    userEntityLink,
  } = props;
  const {
    approvalHierarchyLevels,
    reviews,
    reviewEscalations,
    hasReviewerEscalation,
  } = application;
  const approvalLevel = getApprovalLevel(application);

  const approvalHierarchyDisplay = getApprovalHierarchyToDisplay(
    approvalHierarchyLevels,
    hasReviewerEscalation
  );

  const hasEscalation = hasReviewEscalations(reviewEscalations);
  let reviewDisplay = reviews;
  if (hasEscalation) {
    reviewDisplay = [
      ...createPreviousEscalationData({ reviewEscalations, reviews }),
      ...reviews,
    ];
  }

  return approvalHierarchyDisplay
    .filter((hierarchy) => hierarchy.level <= approvalLevel)
    .map((hierarchy, i) => {
      const reviewsByLevel = reviewDisplay.filter(
        (review) => review.level === hierarchy.level
      );
      return (
        <ApproverUserViewByApprovalHierarchy
          key={`approver-user-view-${i + 1}`}
          application={application}
          confirmModalState={confirmModalState}
          currentUser={currentUser}
          formattedUsers={formattedUsers}
          hierarchy={hierarchy}
          reviews={reviewsByLevel}
          userEntityLink={userEntityLink}
          onFetchApplicationRecord={onFetchApplicationRecord}
          onSetAlert={onSetAlert}
        />
      );
    });
}

const getIsApproverViewVisible = ({ currentUser, formattedUsers }) =>
  currentUser.superAdmin ||
  formattedUsers.some((user) => user.value === currentUser.id);

const NonApproverMessage = ({ application }) => {
  if (application.isDecentralizedApproval) {
    return (
      <p>
        You are not authorized to approve this application. Check with your
        admin if you are an approver of {application.supplierName}.
      </p>
    );
  }

  return (
    <p>
      You are not authorized to approve this application. Check with your admin
      if you are an approver of tier 1.
    </p>
  );
};

function ViewByUser(props) {
  const { application, currentUser, formattedUsers } = props;

  if (isBlank(application.approvalHierarchy)) {
    return null;
  }

  if (currentUser.isStandard) {
    return <StandardUserView {...props} />;
  }

  if (application.isDecentralizedApproval) {
    // Check if user is involved in escalation;

    const currentUserInvolvedInEscalation = isUserInvolvedInEscalation({
      currentUserId: currentUser.id,
      reviewEscalations: application.reviewEscalations,
    });

    if (
      (application.applicationAdminIds || []).includes(currentUser.id) ||
      currentUserInvolvedInEscalation ||
      currentUser.superAdmin ||
      (currentUser.isApprover && currentUser.hasApproverLevel)||
      currentUser.isReviewer
    ) {
      return <TagApprovers {...props} />;
    }

    const isApproverViewVisible = getIsApproverViewVisible({
      currentUser,
      formattedUsers,
    });

    if (isApproverViewVisible) {
      return <ApproverUserView {...props} />;
    }
  } else {
    // centralized can approve/tag in T1 only
    if (
      currentUser.isAdmin ||
      currentUser.isReviewer ||
      (currentUser.isApprover && currentUser.hasApproverLevel)||
      currentUser.superAdmin
    ) {
      return <TagApprovers {...props} />;
    }

    console.error('should not be arrived here for centralize mode...');
  }

  return <NonApproverMessage application={application} />;
}

function CreditApplicationFields(props) {
  const {
    application,
    clearError,
    errors,
    register,
    review,
    setValue,
    userEntityLink,
    values,
  } = props;

  if (application.applicationType !== 'credit') {
    return null;
  }

  const isLimitEnabled = (userEntityLink || { canOverrideLimit: false })
    .canOverrideLimit;
  const [tradeAccountLimit, setTradeAccountLimit] = useState(
    values.tradeAccountLimit
  );

  const [tradeAccountDiscountPercentage, setTradeAccountDiscountPercentage] =
    useState(values.tradeAccountDiscountPercentage);

  return (
    <Fragment>
      {!isLimitEnabled && (
        <div className="mb-5">
          For access to the limit override function please contact your Admin
          user. They are required to give you permission on your user profile,
          accessed on the team tab.
        </div>
      )}
      <LabeledInputContent label="Override requested limit">
        <BorderedTextField
          label={`Confirm a trade account limit of $${formatMoney(
            parseInt(tradeAccountLimit) || review.tradeAccountLimit || 0
          )}`}
          placeholder="0"
          name="tradeAccountLimit"
          onFocus={() => clearError('tradeAccountLimit')}
          onChange={(event) => {
            setTradeAccountLimit(parseFloat(get(event, 'target.value')) || 0);
          }}
          onBlur={() => {
            setValue('tradeAccountLimit', parseFloat(tradeAccountLimit) || 0);
          }}
          value={tradeAccountLimit}
          disabled={!isLimitEnabled}
          inputRef={register}
          error={Boolean(errors.tradeAccountLimit)}
          helperText={get(errors, 'tradeAccountLimit.message', ' ')}
          customProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </LabeledInputContent>

      <LabeledInputContent label="Suggested trade discount">
        <BorderedTextField
          label={`Confirm a trade account discount of ${
            parseInt(tradeAccountDiscountPercentage) ||
            review.tradeAccountDiscountPercentage ||
            0
          }%`}
          placeholder="0"
          name="tradeAccountDiscountPercentage"
          onFocus={() => clearError('tradeAccountDiscountPercentage')}
          onChange={(event) => {
            setTradeAccountDiscountPercentage(
              parseInt(get(event, 'target.value') || 0)
            );
          }}
          onBlur={() => {
            setValue(
              'tradeAccountDiscountPercentage',
              parseInt(tradeAccountDiscountPercentage) || 0
            );
          }}
          value={tradeAccountDiscountPercentage}
          inputRef={register}
          error={Boolean(errors.tradeAccountDiscountPercentage)}
          helperText={get(
            errors,
            'tradeAccountDiscountPercentage.message',
            ' '
          )}
          customProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </LabeledInputContent>
    </Fragment>
  );
}

function getAcceptCreditReviewSchema(application) {
  if (application.applicationType === 'cash') {
    return acceptCashReviewSchema;
  }

  return yup.object().shape({
    notes: yup.string(),
    tradeAccountDiscountPercentage: yup
      .number()
      .integer()
      .min(0, 'Trade discount cannot be less than 0%')
      .max(MAX_DISCOUNT, `Max trade discount is ${MAX_DISCOUNT}%`),
    tradeAccountLimit: yup
      .number()
      .required('Credit limit cannot be empty')
      .integer('Credit limit must be a number')
      .min(0)
      .max(
        application.tradeAccountLimit,
        `Override requested limit cannot be greater than ${application.formattedTradeAccountLimit}`
      ),
  });
}

function ApproveForm(props) {
  const {
    application,
    confirmModalState,
    currentUser,
    hierarchy,
    index,
    onCancel,
    onFetchApplicationRecord,
    review,
    userEntityLink,
  } = props;
  const { isLoading, setIsLoading } = useIsLoadingState();

  const validationSchema = getAcceptCreditReviewSchema(application);

  const { clearError, errors, getValues, handleSubmit, register, setValue } =
    useForm({
      defaultValues: {
        tradeAccountDiscountPercentage:
          review.tradeAccountDiscountPercentage || 0,
        tradeAccountLimit:
          review.tradeAccountLimit || application.tradeAccountLimit,
      },
      mode: 'onBlur',
      submitFocusError: false,
      validationSchema,
    });

  const values = getValues();

  const onSuccessCallback = (review) => {
    sendSurvey({
      access_token: currentUser.accessToken,
      application_id: null,
      consumer_id: null,
      consumer_name: null,
      email: currentUser.email,
      event_flow: 'Review - Accepted',
      name: currentUser.fullName,
      supplier: true,
      supplier_id: get(currentUser, 'currentEntity.id'),
      supplier_name: get(currentUser, 'currentEntity.companyName'),
      websitebutton: application.websitebuttonApplication,
    });

    mixpanel.track('Review Accepted', {
      'Account discount': review.tradeAccountDiscountPercentage,
      'Account limit': review.tradeAccountLimit,
      Application: application.id,
      'Entity ID': get(currentUser, 'currentEntity.id'),
      Outcome: 'Accepted',
      distinct_id: currentUser.id,
    });

    setChurnZeroAttributesWithoutRedux(currentUser);
    onFetchApplicationRecord(() => {
      setIsLoading(false);
      onCancel();
    });
  };

  const onSaveReview = (data) => {
    review.save({
      application,
      attributes: data,
      currentUser,
      index,
      onSuccessCallback,
    });
  };

  async function onSubmit(data) {
    setIsLoading(true);
    data.decision = 'accepted';

    const reviewsAPI = api(
      'reviews',
      currentUser.accessToken,
      get(currentUser, 'currentEntity.id')
    );

    if (application.applicationType === 'cash') {
      onSaveReview(data);
      return;
    }

    const lastReviewCheckResponse = await reviewsAPI.lastReviewCheck({
      applicationId: application.id,
      creditLimit: hierarchy.credit_limit,
      level: hierarchy.level,
      tradeAccountLimit: data.tradeAccountLimit,
    });

    if (get(lastReviewCheckResponse, 'data.decider')) {
      const formattedTradeAccountLimit = `$${formatMoney(
        data.tradeAccountLimit
      )}`;

      confirmModalState.onShowConfirmationModal({
        confirmButtonText: 'Send acceptance',
        confirmCallback: () => onSaveReview(data),
        confirmDescription: `You are the final approver. Please confirm you want to accept this application with a trade account limit of ${formattedTradeAccountLimit}`,
        hideCallback: () => setIsLoading(false),
      });
    } else {
      onSaveReview(data);
    }
  }

  return (
    <GridContent gridColumnTemplate="two_thirds">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CreditApplicationFields
          application={application}
          clearError={clearError}
          currentUser={currentUser}
          errors={errors}
          register={register}
          review={review}
          setValue={setValue}
          userEntityLink={userEntityLink}
          values={values}
        />

        <BorderedTextArea
          label="Approver notes"
          placeholder="Notes shared with your team (optional)"
          name="notes"
          inputRef={register}
        />
        <div className={commonStyles.buttons}>
          <Button
            loading={isLoading}
            text="Send acceptance"
            type="submit"
            style={{ marginRight: 60 }}
          />
          <Button grey text="Cancel" handleClick={onCancel} />
        </div>
      </form>
    </GridContent>
  );
}

const getEscalatedFromLowerLevelMessage = (application, review) => {
  const reviewerEscalationString =
    'This application has been escalated by the application reviewer please action.';

  const escalationData = taggedApproverEscalationData({
    review,
    reviewEscalations: application.reviewEscalations,
  });

  if (escalationData) {
    const { escalated_from_level } = escalationData.attributes;
    if (escalated_from_level === 0) {
      return reviewerEscalationString;
    }
    return `This application has been escalated by level ${escalationData.attributes.escalated_from_level} please action.`;
  }

  if (!application.isReviewLevelEscalated(review.level - 1)) {
    return getReviewActionButtonsMessage(review.isReviewerLevel);
  }

  if (review.level - 1 >= 1) {
    return `This application has been escalated by level ${
      review.level - 1
    } please action.`;
  }

  return reviewerEscalationString;
};

function CurrentApprover(props) {
  const {
    approvers,
    application,
    confirmModalState,
    currentUser,
    index,
    review,
    setIsApprovalFormVisible,
    escalatedForId,
  } = props;

  if (review.isNewRecord) {
    return null;
  }

  if (application.isApprovalBlockedByIUF) {
    return null;
  }

  // When an approver has been tagged at a certain level but changed their
  // approval level afterwards.
  if (review.isCloned) {
    return null;
  }

  if (
    review.taggedApproverId === currentUser.id &&
    !getIsCurrentApproverStillValid(approvers, review.taggedApproverId)
  ) {
    return null;
  }

  if (review.taggedApproverId !== currentUser.id) {
    return null;
  }

  if (review.isActioned && review.taggedApproverId !== currentUser.id) {
    return null;
  }

  const [visibleForm, setVisibleForm] = useState();

  const onClickReviewActionButton = (reviewAction) => {
    setIsApprovalFormVisible(true);
    setVisibleForm(reviewAction);
  };

  const onCancelForm = () => {
    setIsApprovalFormVisible(false);
    setVisibleForm(null);
  };

  if (visibleForm === 'approve') {
    return (
      <ApproveForm
        index={index}
        confirmModalState={confirmModalState}
        onCancel={onCancelForm}
        review={review}
        {...props}
      />
    );
  }

  if (['decline', 'deescalate', 'escalate', 'review'].includes(visibleForm)) {
    return (
      <GenericApproverForm
        action={visibleForm}
        review={review}
        onCancel={onCancelForm}
        {...props}
      />
    );
  }

  const reviewMessage = getEscalatedFromLowerLevelMessage(application, review);

  return (
    <GridContent gridColumnTemplate="two_thirds">
      <FixedContent>
        <div className={styles.current_approver_label}>{reviewMessage}</div>
        <div className={commonStyles.buttons}>
          {application.canDecline(review.level) && (
            <Button
              red
              text="Decline"
              handleClick={() => onClickReviewActionButton('decline')}
            />
          )}
          {application.canApprove(review.level) && (
            <Button
              text="Approve"
              handleClick={() => onClickReviewActionButton('approve')}
            />
          )}
          {application.canReview(review.level) && (
            <Button
              text="Review"
              handleClick={() => onClickReviewActionButton('review')}
            />
          )}
          {application.canEscalate(review, approvers) && (
            <ButtonWithTooltip
              buttonText="Escalate"
              onClick={() => onClickReviewActionButton('escalate')}
              tooltip={
                <span>
                  You can escalate the approval to any other approvers in the
                  same or higher levels. When they&apos;ve reviewed, they can
                  de-escalate with response or approve themselves.
                </span>
              }
            />
          )}
          {(application.canDeescalate(review.level) || escalatedForId) && (
            <ButtonWithTooltip
              buttonText="De-escalate"
              onClick={() => onClickReviewActionButton('deescalate')}
              tooltip={
                <span>
                  You can de-escalate with comments. This will go back to the
                  person who originally escalated the approval.
                </span>
              }
            />
          )}
        </div>
      </FixedContent>
    </GridContent>
  );
}

function AutoDecisionContent(props) {
  const { application } = props;
  const { autoDecisioningState } = application;

  const sections = [
    'guarantors',
    'credit checks',
    'ID checks',
    'trade reference checks',
    'Watchtower alerts',
  ];

  if (application.isIUFApprovalRequired) {
    sections.push('IUF approval');
  }

  function getSectionNames() {
    return sections.reduce((prev, current, index) => {
      const lastItem = index === sections.length - 1;
      const separator = lastItem ? ' or ' : ', ';

      return `${prev}${separator}${current}`;
    });
  }

  if (autoDecisioningState === 'deferred') {
    return (
      <FixedContent header="Auto decisioning">
        <div>{application.autoDecisionDeferredReason}</div>
      </FixedContent>
    );
  }

  if (autoDecisioningState === 'approved') {
    const { autoDecisionedBy } = application;
    const href = `/dashboard/addons?task=edit-auto&id=${autoDecisionedBy.id}`;
    return (
      <FixedContent header="Auto decisioning">
        <div>
          This account was automatically approved by autodecisioning{' '}
          <a href={href}>
            #{autoDecisionedBy.serial_number} {autoDecisionedBy.name}
          </a>{' '}
          (Version {autoDecisionedBy.version}) {application.formattedApprovedAt}
        </div>
      </FixedContent>
    );
  }

  if (autoDecisioningState === 'waiting') {
    return (
      <FixedContent header="Auto decisioning">
        <div>
          Auto decision system is awaiting response from {getSectionNames()}.
        </div>
      </FixedContent>
    );
  }

  return null;
}

function ContentByRules(props) {
  const { application, currentUser } = props;
  const { isAutoDecisioned, autoDecisioningState } = application;
  const applicationTierIds = application.applicationTierIds || [];

  if (application.archived) {
    return <p>This application has already been archived.</p>;
  }

  if (application.isDeleted) {
    return <p>This application has already been deleted.</p>;
  }

  if (isBranchOrRegion()) {
    if (isDecentralize1CAHBranch()) {
      // not the users of this entity
      // but needs to consider one case which T3 which belongs to current entity T2
      const isT3Parent =
        applicationTierIds.length === 3 &&
        [applicationTierIds[1], applicationTierIds[2]].includes(
          currentUser.currentEntity.id
        );

      const isT2Parent =
        applicationTierIds.length === 2 &&
        applicationTierIds[1] === currentUser.currentEntity.id;

      if (
        isDifferentBranchApplication({ application, currentUser }) &&
        !isT3Parent &&
        !isT2Parent
      ) {
        return <p>You are not authorized to approve this application.</p>;
      }
    } else {
      // centralized
      return (
        <p>
          This application can ONLY be approved in headquarter due to
          centralised strategy.
        </p>
      );
    }
  }

  // T1 or the users of this entity for decentralise CAH

  const isAutoDecisionedAndAccepted =
    isAutoDecisioned && autoDecisioningState === 'approved';

  if (!isAutoDecisionedAndAccepted) {
    return <ViewByUser {...props} />;
  }

  return null;
}

function LimitContent(props) {
  const { application } = props;

  return (
    <FixedContent>
      <FixedContent header="Requested limit" withBottomSeparator={false}>
        <div>{application.formattedTradeAccountLimit}</div>
      </FixedContent>
      <ApprovedLimit application={application} />
    </FixedContent>
  );
}

function BrokerContent(props) {
  const { application, currentUser } = props;

  const isInsuranceBrokerEnabled = get(
    currentUser,
    'currentEntity.brokerConfig.onOff',
    false
  );

  if (!isInsuranceBrokerEnabled || isBlank(application.brokerEmail)) {
    return null;
  }

  const updateAppStatus = (status) => {
    const applicationApi = api(
      'applications',
      currentUser.accessToken,
      get(currentUser, 'currentEntity.id')
    );
    const data = { broker_status: status };

    applicationApi.updateApplication(application.id, data);
  };

  let content = (
    <div className={commonStyles.buttons}>
      <Button
        red
        text="Decline"
        handleClick={() => updateAppStatus('declined')}
      />
      <Button text="Approve" handleClick={() => updateAppStatus('approved')} />
    </div>
  );

  if (['approved', 'declined'].includes(application.brokerStatus)) {
    content = `Broker ${application.brokerStatus} at ${application.formattedBrokerStatusUpdatedAt}`;
  }

  return (
    <FixedContent>
      <FixedContent header="Broker approval" withBottomSeparator={false}>
        <div>
          Broker notification was sent at
          {` ${application.formattedBrokerEmailSentAt}`}
        </div>
      </FixedContent>
      {content}
    </FixedContent>
  );
}

function ApprovedLimit(props) {
  const { application } = props;

  if (!application.isAccepted) {
    return null;
  }

  return (
    <FixedContent
      header="Approved limit"
      withBottomSeparator={false}
      withBottomMargin={false}
    >
      <div>{application.formattedFinalLimit}</div>
    </FixedContent>
  );
}

function LimitAndApprovalContent(props) {
  const { application, currentUser } = props;

  return (
    <Fragment>
      <LimitContent application={application} />
      <BrokerContent application={application} currentUser={currentUser} />
      <AutoDecisionContent {...props} />
      <ContentByRules {...props} />
    </Fragment>
  );
}

function useConfirmModalState() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalConfirmCallback, setModalConfirmCallback] = useState(null);
  const [modalHideCallback, setModalHideCallback] = useState(null);
  const [modalConfirmButtonText, setModalConfirmButtonText] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const { isLoading, setIsLoading } = useIsLoadingState();

  const onShowConfirmationModal = ({
    confirmButtonText,
    confirmCallback,
    confirmDescription,
    hideCallback,
  }) => {
    setIsModalVisible(true);
    setModalConfirmButtonText(confirmButtonText);
    setModalConfirmCallback(() => confirmCallback);
    setModalDescription(confirmDescription);
    setModalHideCallback(() => hideCallback);
  };

  const onHideConfirmModal = () => {
    setIsLoading(false);
    setIsModalVisible(false);
    setModalConfirmCallback(null);
    setModalConfirmButtonText('');
    setModalDescription('');

    if (modalHideCallback) {
      modalHideCallback();
      setModalHideCallback(null);
    }
  };

  const onClickConfirm = () => {
    setIsLoading(true);

    if (modalConfirmCallback) {
      modalConfirmCallback();
    }

    onHideConfirmModal();
  };

  let modal = null;
  if (isModalVisible) {
    modal = (
      <Modal
        onClose={onHideConfirmModal}
        footer={
          <div className="is-flex is-full-width is-justify-content-center">
            <Button
              handleClick={onClickConfirm}
              text={modalConfirmButtonText}
              type="button"
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        }
      >
        <div className="has-text-weight-normal has-text-centered">
          {modalDescription}
        </div>
      </Modal>
    );
  }

  return { modal, onHideConfirmModal, onShowConfirmationModal };
}

export default function LimitAndApprovals(props) {
  const { application, currentUser } = props;
  const reviewHistories = application.reviewHistories;
  const { formattedUsers, onFetchApprovers } = useApplicationApproversState({
    applicationId: application.id,
    currentUser,
  });
  const confirmModalState = useConfirmModalState();

  useEffect(() => {
    if (application.id) {
      onFetchApprovers();
    }
  }, [application.id, application.derivedCurrentApprovalLevel]);

  useEffect(() => {
    if (application.id) {
      onFetchApprovers();
    }
  }, []);

  const content = (
    <Fragment>
      {confirmModalState.modal}
      <LimitAndApprovalContent
        formattedUsers={formattedUsers}
        confirmModalState={confirmModalState}
        {...props}
      />
    </Fragment>
  );

  if (reviewHistories.length > 0) {
    return (
      <ContentWithFooter footer={<RecordHistory histories={reviewHistories} />}>
        {content}
      </ContentWithFooter>
    );
  }

  return <ScrollableContent>{content}</ScrollableContent>;
}
