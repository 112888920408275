import { Typography } from '@material-ui/core';
import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';
import Agreements1Centre, {
  agreements1CYupSchema,
} from 'modules/card-management-shared/Agreements1Centre';
// @ts-ignore-next-line - Should be imported
import Checklist from 'modules/card-management-shared/Checklist';
import DigitalSignature from 'modules/card-management-shared/DigitalSignature';
import IdentityCapture from 'modules/identity/components/IdentityCapture/IdentityCapture';
import styles from 'modules/paperless-onboarding/css/Authorisation.css';
import commonStyles from 'modules/paperless-onboarding/css/Section.css';
import Button from 'modules/shared/components/inputs/Button';
import PdfReader from 'modules/shared/components/widgets/static/pdfReader';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { areStringsEqual } from 'utils';
import * as yup from 'yup';
import { Authorisation_deprecated } from './Authorisation_deprecated';

export function checkListItems(supplierName) {
  return [
    {
      label: 'I am authorised to sign this direct debit',
      name: 'authorised',
    },
    {
      label: `I have read and understood the terms and conditions for ${supplierName}`,
      name: 'read_terms',
    },
    {
      label: `I understand that ${supplierName} will not use, store or disclose my information except in accordance with ${supplierName} Privacy Policy and/or Terms and Conditions of Sale and/or Credit`,
      name: 'disclose_information',
    },
    {
      label: 'I am not less than 18 years of age',
      name: 'over18',
    },
  ];
}

function IDUpload({ application, errors }) {
  const { attributes } = application;
  const {
    image_64: imageUrl,
    user_information_id: distinctId,
    user_role_id: userRoleId,
  } = attributes;

  return (
    <div>
      <IdentityCapture
        apiType="user_roles"
        authorisationID={userRoleId}
        distinctId={distinctId}
        handleComplete={() => null}
        imageUrl={imageUrl}
        noInstantUpload={true}
        resourceType="user_role"
      />
      <div className={commonStyles.error}>
        {get(errors, 'image64.message', '')}
      </div>
    </div>
  );
}

function GeneralAcknowledgementChecklist(props) {
  const { application } = props;
  const checkList = checkListItems(application.attributes.supplier_name);

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        General Acknowledgement and Agreement
      </Typography>
      <div className={styles.checklist_container}>
        <Checklist
          checklist={checkList}
          inputRef={props.inputRef}
          errors={props.errors}
          clearError={props.clearError}
        />
      </div>
    </div>
  );
}

function TermsAndConditions(props) {
  const { application } = props;

  return (
    <div style={{ position: 'relative' }}>
      <hr className={styles.divider} />

      <div className={styles.tnc_title}>
        Supplier, paperless Terms and Conditions
      </div>

      <PdfReader urls={application.attributes.ts_and_cs} />

      <hr className={styles.divider} />

      <GeneralAcknowledgementChecklist
        application={application}
        inputRef={props.inputRef}
        errors={props.errors}
        clearError={props.clearError}
      />

      <hr className={styles.divider} />

      <Agreements1Centre
        application={application}
        inputRef={props.inputRef}
        errors={props.errors}
        clearError={props.clearError}
      />

      <hr className={styles.divider} />

      <Typography variant="h5" gutterBottom>
        Electronic Signature
      </Typography>

      <div className={styles.tnc_instruction}>
        {`By electronically signing this section you confirm and acknowledge that: You have read and understood this application and the information supplied by you is true and complete
        I authorise ${application.attributes.supplier_name}, until further notice in writing to debit my/our account.`}
      </div>
    </div>
  );
}

export function Authorisation(props) {
  if (!FEATURE_FLAGS.FEATURE_FLAG_UPDATED_TNCS) {
    return <Authorisation_deprecated {...props} />;
  }
  const { paperlessApplication } = props;
  const correctFirstName =
    paperlessApplication.attributes.first_name.toLowerCase();
  const correctLastName =
    paperlessApplication.attributes.last_name.toLowerCase();

  const authorisationSchema = yup.object().shape({
    authorised: yup
      .boolean()
      .test('authorised', 'Please check to agree.', (value) => value),
    disclose_information: yup
      .boolean()
      .test('disclose_information', 'Please check to agree', (value) => value),
    first_name: yup
      .string()
      .required('Please input first name.')
      .test('first_name_test', 'Incorrect first name.', (value) =>
        areStringsEqual(value, correctFirstName)
      ),
    image64: yup
      .string()
      .required('Please upload/take a photo of your ID.')
      .test(
        'images',
        'Please upload/take a photo of your ID.',
        (value) => value !== null
      ),
    last_name: yup
      .string()
      .required('Please input last name.')
      .test('last_name_test', 'Incorrect last name.', (value) =>
        areStringsEqual(value, correctLastName)
      ),
    over18: yup
      .boolean()
      .test('over18', 'Please check to agree.', (value) => value),
    read_terms: yup
      .boolean()
      .test('readTerms', 'Please check to agree.', (value) => value),
    ...agreements1CYupSchema,
  });

  const { errors, handleSubmit, clearError, setValue, register } = useForm({
    mode: 'onBlur',
    validationSchema: authorisationSchema,
  });

  setValue(
    'image64',
    props.identificationImage || paperlessApplication.attributes.image_64
  );
  if (errors.image64 && props.identificationImage) clearError('image64');

  useEffect(() => {
    register({ name: 'image64' });
  }, [register]);

  const onSubmit = (data) => {
    paperlessApplication.setAttributes(data);
    (async () => {
      const result = await paperlessApplication.saveIDphoto(props.supplierId);
      if (result.status === 200) {
        props.toNextSection();
      }
    })();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={commonStyles.container}>
          <section className={commonStyles.section}>
            <IDUpload errors={errors} application={paperlessApplication} />
            <TermsAndConditions
              application={paperlessApplication}
              inputRef={register}
              errors={errors}
              clearError={clearError}
            />
            <DigitalSignature inputRef={register} errors={errors} />
            <div className={commonStyles.flow_buttons}>
              <Button text="Back" onClick={props.toPrevSection} />
              <Button
                text="Submit"
                type="submit"
                loading={paperlessApplication.isLoading}
              />
            </div>
          </section>
        </div>
      </form>
    </div>
  );
}

export default connect((state) => {
  return {
    identificationImage: state.identity.image_64,
  };
})(Authorisation);
