import React from 'react';
import EquifaxReport from './shared/EquifaxReport';

const IndividualReport = (props) => {
  return (
    <EquifaxReport
      reportType="individual"
      mainHeader="Equifax Apply"
      {...props}
    />
  );
};

export default IndividualReport;
