import React from 'react';
import {
  TableCell,
  TableRow as MuiTableRow,
  Collapse,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { Add, ChevronRight, Phone, Remove } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
// @ts-ignore
import { browserHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'modules/shared/components/inputs/Button';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import ContentPaste from 'images/svgs/icon-content-paste.svg';
import { COLORS } from 'variables/theme';
import { FEATURE_FLAGS } from 'conf';

const isWatchtowerEnabled = FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER;

const StyledRow = styled(MuiTableRow)<{ $bg?: string }>`
  background-color: ${(props) => props.$bg || 'initial'};
  td {
    border-bottom: none;
  }
`;

const CustomerCell = styled(TableCell)`
  cursor: pointer;
  :hover {
    background-color: ${COLORS.lightGrey};
  }
  transition: background-color 200ms;

  .icon {
    color: var(--main-color);
    position: relative;
    top: 4.5px;
  }

  @media screen and (min-width: 992px) {
    width: 40%;
  }
`;

const RapidTransferIconContainer = styled.div`
  display: inline-block;
  position: relative;
  left: 4px;
  .rapid-transfer-icon {
    color: var(--main-color);
    position: relative;
    left: 4px;
  }
`;

const PhoneIconContainer = styled.div`
  display: inline-block;
  position: relative;
  left: 10px;
  top: 5px;
`;

const CollapseRow = styled(StyledRow)`
  td {
    padding: 0 !important;
  }
  .collapse-inner {
    padding: 15px 24px;
    background: #f6f6f6;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ViewGroupWrapper = styled.span`
  @media screen and (max-width: 991px) {
    display: none !important;
  }
`;

const AccountMonitoringIconContainer = styled.div`
  display: inline-block;
  position: relative;
  left: 10px;
  top: 4px;
`;

const AccountMonitoringIcon = styled(ContentPaste)`
  width: 16px;
  height: 16px;
`;

const WatchtowerAlertNames = ({ children }: { children: string }) => (
  <Typography color="error">{children}</Typography>
);

type TableRowProps = {
  id: string;
  expandable?: boolean;
  expanded?: boolean;
  toggleExpand: () => void;
  customerName: string;
  approvedLimit: string;
  date: string;
  tier: string;
  rowColor?: string;
  isFromBulkUpload: boolean;
  showViewGroup: boolean;
  internalWatchtowerAlertNames?: string;
  externalWatchtowerAlertNames?: string;
  callCentreActivity: boolean;
  hasAccountMonitoring: boolean;
};

export const TableRow = (props: TableRowProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    id,
    expanded = false,
    expandable = false,
    toggleExpand,
    customerName,
    approvedLimit,
    date,
    tier,
    isFromBulkUpload,
    rowColor,
    showViewGroup,
    internalWatchtowerAlertNames,
    externalWatchtowerAlertNames,
    callCentreActivity,
    hasAccountMonitoring,
  } = props;

  const handleClickName = () => {
    expandable
      ? toggleExpand()
      : browserHistory.push(`/dashboard/applications/${id}`);
  };

  let Icon = ChevronRight;
  if (expandable) {
    Icon = expanded ? Remove : Add;
  }

  const getWatchtowerUrl = () => {
    const encodedCustomerName = encodeURIComponent(customerName);
    return `/dashboard/watchtower?key_word=${encodedCustomerName}&page=1&per=25&default_open`;
  };

  return (
    <>
      <StyledRow $bg={rowColor}>
        <CustomerCell onClick={handleClickName}>
          <Icon className="icon" /> {customerName}
          {isFromBulkUpload && (
            <PopperTooltip placement="top" title="Rapid transfer">
              <RapidTransferIconContainer>
                <FontAwesomeIcon
                  className="rapid-transfer-icon"
                  icon="arrow-alt-circle-up"
                />
              </RapidTransferIconContainer>
            </PopperTooltip>
          )}
          {callCentreActivity && (
            <PhoneIconContainer>
              <Phone color="primary" />
            </PhoneIconContainer>
          )}
          {hasAccountMonitoring && (
            <PopperTooltip
              placement="top"
              title="Account monitoring (Account status/Review date)  apply. Go to VCF for more information"
            >
              <AccountMonitoringIconContainer>
                <AccountMonitoringIcon />
              </AccountMonitoringIconContainer>
            </PopperTooltip>
          )}
        </CustomerCell>
        <TableCell>{approvedLimit}</TableCell>
        {isDesktop && (
          <>
            <TableCell>{date}</TableCell>
            <TableCell>{tier}</TableCell>
          </>
        )}
      </StyledRow>
      {expandable && (
        <CollapseRow>
          <TableCell colSpan={4}>
            <Collapse
              classes={{ wrapperInner: 'collapse-inner' }}
              in={expanded}
            >
              {isWatchtowerEnabled && (
                <>
                  {externalWatchtowerAlertNames && (
                    <WatchtowerAlertNames>
                      {externalWatchtowerAlertNames}
                    </WatchtowerAlertNames>
                  )}
                  {internalWatchtowerAlertNames && (
                    <WatchtowerAlertNames>
                      {internalWatchtowerAlertNames}
                    </WatchtowerAlertNames>
                  )}
                </>
              )}
              <ButtonsContainer>
                <Button
                  text="Manage account"
                  link={`/dashboard/applications/${id}`}
                />
                {showViewGroup && (
                  <ViewGroupWrapper>
                    <Button white text="View group" link={getWatchtowerUrl()} />
                  </ViewGroupWrapper>
                )}
              </ButtonsContainer>
            </Collapse>
          </TableCell>
        </CollapseRow>
      )}
    </>
  );
};
