import axios from './axios';

let CompanySearch = function (config) {
  config.timeout = 300000;
  this.axios = axios(config);
};

CompanySearch.prototype.companySearch = function (success, error, options) {
  return this.axios.get('/company_search', options).then(success).catch(error);
};

CompanySearch.prototype.companyDetails = function (
  business_number,
  success,
  error,
  options
) {
  return this.axios
    .get(`/company_details/${business_number}`, options)
    .then(success)
    .catch(error);
};

CompanySearch.prototype.companyDetailsAlternative = function (
  business_number,
  options
) {
  return this.axios.get(`/company_details/${business_number}`, options);
};

CompanySearch.prototype.getAsicDetails = function (acn) {
  return this.axios.get(`/company_details/asic_details/${acn}`);
};

export default CompanySearch;
