import React from 'react';
import styles from './report-styles.css';

const ReportBusinessRelationships = ({ characteristic }) => {
  let currentDirectorships =
      characteristic.find((obj) => obj['@id'] === 'NP8500') || {},
    prevDirectorships =
      characteristic.find((obj) => obj['@id'] === 'NP8509') || {},
    externalAdminCompanies =
      characteristic.find((obj) => obj['@id'] === 'NP8506') || {},
    businessHeld = characteristic.find((obj) => obj['@id'] === 'NP8508') || {},
    disqDirectorships =
      characteristic.find((obj) => obj['@id'] === 'NP8503') || {},
    noRelationships =
      parseInt(currentDirectorships.value) +
      parseInt(prevDirectorships.value) +
      parseInt(externalAdminCompanies.value) +
      parseInt(businessHeld.value);

  return (
    <div>
      <p className="is-pulled-right">
        No. of relationships: {!noRelationships ? 0 : noRelationships}
        &emsp; Disqualified directorships:
        {!disqDirectorships || disqDirectorships.value < 0
          ? '-'
          : disqDirectorships.value}
      </p>
      <br />
      <div className={styles.table + ' mt-2'}>
        <div className={styles.row}>
          <div className={styles.th + ' ' + styles.col_8}>Data</div>
          <div className={styles.th + ' ' + styles.col_4}>
            No. of relationships
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Current directorships
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!currentDirectorships || currentDirectorships.value < 0
              ? '-'
              : currentDirectorships.value}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Previous directorships in the last 10 years
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!prevDirectorships || prevDirectorships.value < 0
              ? '-'
              : prevDirectorships.value}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Number of external administration companies for director, or
            disqualified directorships
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!externalAdminCompanies || externalAdminCompanies.value < 0
              ? '-'
              : externalAdminCompanies.value}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Business names held
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!businessHeld || businessHeld.value < 0 ? '-' : businessHeld.value}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportBusinessRelationships;
