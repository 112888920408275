/* eslint-disable max-lines */
/* eslint-disable max-len */
import humps from 'humps';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import { loadAdditionalAnswers } from 'modules/consumer-onboarding/actions/additional';
import { loadCardholders } from 'modules/consumer-onboarding/actions/cards';
import { loadFinancialsAnswers } from 'modules/consumer-onboarding/actions/financials';
import { setEntityFormValue } from 'modules/consumer-onboarding/actions/onboarding';
import { loadPaperlessAnswers } from 'modules/consumer-onboarding/actions/paperless';
import { signatureDataFormatter } from 'modules/shared/helpers/signatureDataFormatter';
import moment from 'moment';
import { browserHistory } from 'react-router';
import { sendSurvey } from 'utils/askNicely';
import extractAttachmentUrl from 'utils/extractAttachmentUrl';
import isPresent from 'utils/isPresent';

import api from '../../../api';
import {
  getUserData,
  updateUserFirstNameLastNameFromSignature,
  userLogin,
} from '../../user/actions';
import { USER_LOGIN_CREDENTIALS_ERROR } from '../../user/constants';
import {
  COB_REVIEW_ADDRESS_LOOKUP_CLEAR,
  COB_REVIEW_ADDRESS_LOOKUP_ERROR,
  COB_REVIEW_ADDRESS_LOOKUP_START,
  COB_REVIEW_ADDRESS_LOOKUP_SUCCESS,
  COB_REVIEW_REDIRECT,
  COB_REVIEW_SELECT_ADDRESS,
  COB_REVIEW_SET_ADDRESS,
  COB_REVIEW_SET_COMPLETE,
  COB_REVIEW_SET_DECLARATION_DOB,
  COB_REVIEW_SET_DECLARATION_EMAIL,
  COB_REVIEW_SET_DECLARATION_FIRST_NAME,
  COB_REVIEW_SET_REVIEW_INDEX,
  COB_REVIEW_SET_TERMS_REJECT_REASON_TEXT,
  COB_REVIEW_SUBMIT_CLEAR_START,
  COB_REVIEW_SUBMIT_DATA_ERROR,
  COB_REVIEW_SUBMIT_WATCHTOWER_ERROR,
  COB_REVIEW_SUBMIT_DATA_START,
  COB_REVIEW_SUBMIT_DATA_SUCCESS,
  COB_REVIEW_SUBMIT_NETWORK_BREAK,
  COB_REVIEW_SUPPLIER_TERMS_REDIRECT,
  COB_REVIEW_UPDATE_AUTHORISATION_ERROR,
  COB_REVIEW_UPDATE_AUTHORISATION_START,
  COB_REVIEW_UPDATE_AUTHORISATION_SUCCESS,
  COB_REVIEW_UPDATE_TRADING_NAMES_ERROR,
  COB_REVIEW_UPDATE_TRADING_NAMES_START,
  COB_REVIEW_UPDATE_TRADING_NAMES_SUCCESS,
  COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_ERROR,
  COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_START,
  COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_SUCCESS,
  ENTITY_UPDATE_ERROR,
  ENTITY_UPDATE_START,
  ENTITY_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_UPDATE_IN_START_APPLICATION_ERROR,
  USER_UPDATE_IN_START_APPLICATION_START,
  USER_UPDATE_IN_START_APPLICATION_SUCCESS,
  USER_UPDATE_START,
  USER_UPDATE_SUCCESS,
} from '../constants/review';
import { preSetGuarantorData } from './guarantors';
import {
  clearAffectedPages,
  loadApplicationAddonAnswers,
  loadApplicationAddonRules,
  loadCurrentPeople,
  setStartComplete,
} from './section';
import { preSetRefereeData } from './trade-reference';
import * as Sentry from '@sentry/browser';
import { FEATURE_FLAGS } from 'conf';
import { findWatchtowerBlockedEmails, isWatchtowerBlock } from 'utils';
import { COB_PAPERLESS_SET_BLOCKED_EMAILS } from '../constants/paperless';
import { COB_GUARANTOR_SET_BLOCKED_EMAILS } from '../constants/guarantors';
import { getCorporateTrusteeSignatories } from '../helpers';

export function reviewComponentComplete(section, state) {
  return (dispatch) => {
    dispatch({
      payload: {
        section,
        state,
      },
      type: COB_REVIEW_SET_COMPLETE,
    });
  };
}

export function readyForReview() {
  return (dispatch, getState) => {
    dispatch({
      meta: {
        mixpanel: {
          event: 'Redirect to review',
          props: {
            Flow: 'Consumer onboarding',
            distinct_id: getState().current_user.data.data.id,
          },
        },
      },
      type: COB_REVIEW_REDIRECT,
    });
  };
}

export function readyForSupplierTerms() {
  return (dispatch, getState) => {
    dispatch({
      meta: {
        mixpanel: {
          event: 'Redirect to supplier terms',
          props: {
            Flow: 'Consumer onboarding',
            distinct_id: getState().current_user.data.data.id,
          },
        },
      },
      type: COB_REVIEW_SUPPLIER_TERMS_REDIRECT,
    });
  };
}

export function setDeclarationFormValue(key, value) {
  let type;
  switch (key) {
    case 'declaration_first_name':
      type = COB_REVIEW_SET_DECLARATION_FIRST_NAME;
      break;
    case 'declaration_last_name':
      type = COB_REVIEW_SET_DECLARATION_EMAIL;
      break;
    case 'declaration_dob':
      type = COB_REVIEW_SET_DECLARATION_DOB;
      break;
  }

  return {
    payload: value,
    type,
  };
}

export function setReviewIndex(index) {
  return {
    payload: index,
    type: COB_REVIEW_SET_REVIEW_INDEX,
  };
}

export function setTermsRejectReasonFormValue(value) {
  return {
    payload: value,
    type: COB_REVIEW_SET_TERMS_REJECT_REASON_TEXT,
  };
}

export function consumerOnboardingDataSubmissionStarted() {
  return {
    type: COB_REVIEW_SUBMIT_DATA_START,
  };
}

export function consumerOnboardingDataSubmitted(dispatch, getState, eAttrs) {
  mixpanel.people.set({
    Onboarded: true,
  });

  dispatch({
    meta: {
      mixpanel: {
        event: 'Consumer onboarded',
        props: {
          Supplier: eAttrs.supplier_name,
          'Trading name': eAttrs.trading_name,
          distinct_id: getState().current_user.data.data.id,
        },
      },
    },
    payload: eAttrs,
    type: COB_REVIEW_SUBMIT_DATA_SUCCESS,
  });
}

export function consumerOnboardingDataFailure(error) {
  Sentry.captureException(error);
  if (isWatchtowerBlock(error)) {
    return {
      payload: true,
      type: COB_REVIEW_SUBMIT_WATCHTOWER_ERROR,
    };
  } else {
    return {
      payload: error,
      type: COB_REVIEW_SUBMIT_DATA_ERROR,
    };
  }

  return null;
}

export function submitOnboardingAndApplicationData(redirect) {
  return (dispatch, getState) => {
    dispatch(consumerOnboardingDataSubmissionStarted());

    const businessState = getState().cob_business;
    //
    // Save addresses
    //
    const physicalAddress = businessState.physical_address;
    let postalAddress = null;

    if (
      Object.prototype.hasOwnProperty.call(
        businessState.postal_address,
        'api_id'
      ) &&
      businessState.postal_address.api_id
    ) {
      postalAddress = businessState.postal_address;
    }

    const addresses = api('addresses', getState().current_user.access_token);
    addresses.createAddress(
      physicalAddress,
      (success) => {
        const physicalAddressId = success.data.data.id;
        let eAttrs = getEntity(getState);

        eAttrs = { ...eAttrs, physical_address_id: physicalAddressId };

        if (postalAddress) {
          addresses.createAddress(postalAddress, (success) => {
            const postalAddressId = success.data.data.id;

            eAttrs = { ...eAttrs, postal_address_id: postalAddressId };

            createEntity(dispatch, getState, redirect, eAttrs);
          });
        } else {
          createEntity(dispatch, getState, redirect, eAttrs);
        }
      },
      (error) => {
        dispatch(consumerOnboardingDataFailure(error));
      }
    );
  };
}

function createEntity(dispatch, getState, redirect, eAttrs) {
  const entities = api('entities', getState().current_user.access_token);

  entities.createEntity(
    eAttrs,
    (success) => {
      const entityId = success.data.data.id;

      const submissionPromises = [
        submitPeople(getState, entityId),
        submitApplicationData(getState, dispatch, entityId),
      ];

      return Promise.all(submissionPromises)
        .then(() => {
          // Load the user and redirect to the correct place
          dispatch({
            type: COB_REVIEW_SUBMIT_DATA_SUCCESS,
          });
          dispatch(getUserData(null, redirect));
        })
        .catch((error) => {
          dispatch(consumerOnboardingDataFailure(error));
        });
    },
    (error) => {
      dispatch(consumerOnboardingDataFailure(error));
    }
  );
}

export function updateApplicationData(
  applicationId,
  redirect,
  consumerAccount
) {
  return (dispatch, getState) => {
    dispatch({
      type: COB_REVIEW_SUBMIT_DATA_START,
    });
    dispatch(consumerOnboardingDataSubmissionStarted());

    const applications = api(
      'applications',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    const data = {};
    data.entity = getEntity(getState, consumerAccount);

    const entityAttributes = getEntity(getState, false); // Using false for consumerAccount to be able to get legal_type
    data.application = {
      business_number: entityAttributes.business_number,
      company_name: entityAttributes.company_name,
      company_number: entityAttributes.company_number,
      contact_phone_number: entityAttributes.contact_phone_number,
      delivery_instruction: entityAttributes.delivery_instruction,
      incorporated_number: entityAttributes.incorporated_number,
      legal_type: entityAttributes.legal_type,
      region: entityAttributes.region,
      registered_at: entityAttributes.registered_at,
      staff_count: entityAttributes.staff_count,
      state: entityAttributes.state,
      trade_account_limit: getState().cob_money.requested_limit || 0,
    };

    if (entityAttributes.legal_type === 'company') {
      const selectedTradingNameIds =
        getState().cob_section.selected_trading_names;
      const tradingNameOptions = getState().cob_section.trading_names;
      data.trading_names = selectedTradingNameIds.map(
        (id) => tradingNameOptions[id]
      );
    } else {
      data.application.trading_name = entityAttributes.trading_name;
    }

    data.entity.physical_address = getState().cob_business.physical_address;
    data.entity.physical_address.id =
      getState().current_user.current_entity.attributes.physical_address_id;

    const hasPostalAddress =
      Object.prototype.hasOwnProperty.call(
        getState().cob_business.postal_address,
        'api_id'
      ) && getState().cob_business.postal_address.api_id;

    if (hasPostalAddress) {
      data.entity.postal_address = getState().cob_business.postal_address;
      data.entity.postal_address.id =
        getState().current_user.current_entity.attributes.postal_address_id;
    }

    data.people = [
      ...getState().cob_section.people,
      ...getCorporateTrusteeSignatories(getState()),
    ];

    data.signature = {};

    const identityState = getState().identity;
    const dob = identityState.dob;
    const formattedDate = moment(dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const expDate = identityState.identification_exp_date;
    const formattedIdExpDate = moment(expDate, 'DD/MM/YYYY').format(
      'YYYY-MM-DD'
    );

    let proofOfAddress = null;
    if (identityState.proof_of_address) {
      proofOfAddress = identityState.proof_of_address.url;
    }

    data.signature = {
      dob: formattedDate,
      first_name: identityState.first_name,
      identification_expiry_date: !identityState.noIdentification
        ? formattedIdExpDate
        : null,
      // identification_image: identityState.image_64,
      identification_state: identityState.identification_state,
      identification_number: identityState.noIdentification
        ? null
        : identityState.number,
      identification_type: identityState.noIdentification
        ? 'no_identification'
        : identityState.type,
      identification_version:
        !identityState.noIdentification &&
        identityState.type === 'driver_licence'
          ? identityState.driver_licence_version
          : null,
      last_name: identityState.last_name,
      no_identification_reason: identityState.image_64
        ? null
        : identityState.no_identification_reason,
      other_region: identityState.other_region,
      proof_of_address: proofOfAddress,
      region: identityState.region,
    };

    data.signature.address = getState().identity.address;
    if (getState().cob_section.signature.attributes) {
      data.signature.address.id =
        getState().cob_section.signature.attributes.address_id;
    }

    data.guarantors = [];
    // TODO: This can potentially be changed to a `map` function
    getState().cob_guarantors.form_values.forEach((guarantor) => {
      data.guarantors.push({
        email: guarantor.email,
        first_name: guarantor.first_name,
        id: guarantor.id,
        last_name: guarantor.last_name,
        middle_name: guarantor.middle_name || null,
        percentage_share: guarantor.percentage_share,
        position: guarantor.position,
        associated_entity_id: guarantor.associated_entity_id,
      });
    });

    data.trade_references = [];
    // TODO: This can potentially be changed to a `map` function
    getState().cob_traderef.referees.forEach((ref) => {
      data.trade_references.push({
        address: ref.company_address,
        business_name: ref.business_name,
        contact_country_code: ref.contact_country_code,
        contact_phone_number: ref.contact_phone,
        email: ref.email,
        first_name: ref.first_name,
        id: ref.id,
        last_name: ref.last_name,
        notes: ref.notes,
        notified: ref.informed === 'yes',
        position: ref.referee_position,
        previous_business_name: ref.previous_business_name,
        relationship_length: ref.relationship_length,
        trade_frequency: ref.trade_frequency,
      });
    });

    const moduleNames = ['additional', 'financials', 'paperless'];
    data.addon_answers = [];
    moduleNames.forEach((moduleName) => {
      const moduleState = getState()[`cob_${moduleName}`];
      if (moduleState.addon_rule_id) {
        data.addon_answers.push({
          addon_rule_id: moduleState.addon_rule_id,
          answers: _paperlessAnswersFilter(moduleState.answers),
          file: moduleState.file,
          id: moduleState.id,
        });
      }
    });

    data.cardholders = getState().cob_cards.cardholders.map((cardholder) => {
      const attributes = humps.decamelizeKeys(cardholder.attributes);
      return { id: cardholder.id, ...attributes };
    });

    applications.businessConsumerSubmission(
      applicationId,
      () => {
        dispatch({ type: COB_REVIEW_SUBMIT_DATA_SUCCESS });
        browserHistory.push(redirect);
      },
      (error) => {
        dispatch(consumerOnboardingDataFailure(error));
        dispatch({ type: COB_REVIEW_SUBMIT_DATA_ERROR });
      },
      data
    );
  };
}

function _updateApplicationBusinessDetail({
  applicationId,
  dispatch,
  entityId,
  getState,
  resetSubmitting = true,
}) {
  return new Promise((resolve, reject) => {
    dispatch(consumerOnboardingDataSubmissionStarted());

    // Using false for consumer_account to be able to get legal_type
    const entityAttributes = getEntity(getState, false);

    const data = {
      business_number: entityAttributes.business_number,
      company_name: entityAttributes.company_name,
      company_number: entityAttributes.company_number,
      contact_phone_number: entityAttributes.contact_phone_number,
      delivery_instruction: entityAttributes.delivery_instruction,
      incorporated_number: entityAttributes.incorporated_number,
      legal_entity_agreement: (
        getState().cob_business.legal_entity_agreement || {}
      ).url,
      legal_type: entityAttributes.legal_type,
      region: entityAttributes.region,
      registered_at: entityAttributes.registered_at,
      staff_count: entityAttributes.staff_count,
      state: entityAttributes.state,
      trading_name: entityAttributes.trading_name,
      business_api_details: entityAttributes.business_api_details,
    };
    const applicationAPI = api(
      'applications',
      getState().current_user.access_token,
      entityId
    );

    applicationAPI.updateApplication(
      applicationId,
      data,
      (response) => {
        const legalEntityAgreement = extractAttachmentUrl(
          get(
            response,
            'data.data.attributes.legal_entity_agreement',
            'legal_entity_agreement'
          )
        );

        if (isPresent(legalEntityAgreement)) {
          dispatch(
            setEntityFormValue('legal_entity_agreement', legalEntityAgreement)
          );
        }

        if (data.legal_type === 'personal') {
          resetSubmitting
            ? dispatch({ type: COB_REVIEW_SUBMIT_DATA_SUCCESS })
            : null;
          resolve();
        } else {
          _updateApplicationAddresses({
            applicationId,
            dispatch,
            entityId,
            getState,
            resetSubmitting,
          })
            .then(() => resolve())
            .catch((error) => reject(error));
        }
      },
      (error) => {
        dispatch(consumerOnboardingDataFailure(error));
        reject(error);
      }
    );
  });
}

function updateEntityAddresses(
  dispatch,
  getState,
  entityId,
  resetSubmitting = true
) {
  const addressAPI = api(
    'addresses',
    getState().current_user.access_token,
    entityId
  );
  const entityAPI = api(
    'entities',
    getState().current_user.access_token,
    entityId
  );

  entityAPI.getEntity(
    entityId,
    (success) => {
      const entity = success.data.data;

      _updateEntityPhysicalAddress(
        dispatch,
        getState,
        entity,
        entityAPI,
        addressAPI,
        resetSubmitting
      );

      _updateEntityPostalAddress(
        dispatch,
        getState,
        entity,
        entityAPI,
        addressAPI,
        resetSubmitting
      );
    },
    (error) => {
      dispatch(consumerOnboardingDataFailure(error));
    }
  );
}

// eslint-disable-next-line max-params
function _updateEntityPhysicalAddress(
  dispatch,
  getState,
  entity,
  entityAPI,
  addressAPI,
  resetSubmitting = true
) {
  const businessState = getState().cob_business;
  const physicalAddress = businessState.physical_address;

  addressAPI.createAddress(
    physicalAddress,
    (success) => {
      entityAPI.updateEntity(
        entity.id,
        { physical_address_id: success.data.data.id },
        () => {
          resetSubmitting
            ? dispatch({ type: COB_REVIEW_SUBMIT_DATA_SUCCESS })
            : null;
        },
        (error) => {
          dispatch(consumerOnboardingDataFailure(error));
        }
      );
    },
    (error) => {
      dispatch(consumerOnboardingDataFailure(error));
    }
  );
}

// eslint-disable-next-line max-params
function _updateEntityPostalAddress(
  dispatch,
  getState,
  entity,
  entityAPI,
  addressAPI,
  resetSubmitting = true
) {
  const businessState = getState().cob_business;
  let postalAddress = null;
  // const postalAddressId = entity.attributes.postal_address_id;
  const hasPostalAddress =
    Object.prototype.hasOwnProperty.call(
      businessState.postal_address,
      'api_id'
    ) && businessState.postal_address.api_id;

  if (hasPostalAddress) postalAddress = businessState.postal_address;

  if (postalAddress) {
    addressAPI.createAddress(
      postalAddress,
      (success) => {
        entityAPI.updateEntity(
          entity.id,
          { postal_address_id: success.data.data.id },
          () => {
            resetSubmitting
              ? dispatch({ type: COB_REVIEW_SUBMIT_DATA_SUCCESS })
              : null;
          },
          (error) => {
            dispatch(consumerOnboardingDataFailure(error));
          }
        );
      },
      (error) => {
        dispatch(consumerOnboardingDataFailure(error));
      }
    );
  }
}

// eslint-disable-next-line max-params
function _updateApplicationAddresses({
  dispatch,
  getState,
  applicationId,
  entityId,
  resetSubmitting = true,
}) {
  return new Promise((resolve, reject) => {
    const addressAPI = api(
      'addresses',
      getState().current_user.access_token,
      entityId
    );
    const applicationAPI = api(
      'applications',
      getState().current_user.access_token,
      entityId
    );

    applicationAPI.getApplication(
      applicationId,
      (success) => {
        const application = success.data.data;

        Promise.all([
          _updateApplicationPhysicalAddress({
            addressAPI,
            application,
            applicationAPI,
            dispatch,
            getState,
            resetSubmitting,
          }),
          _updateApplicationPostalAddress({
            addressAPI,
            application,
            applicationAPI,
            dispatch,
            getState,
            resetSubmitting,
          }),
        ])
          .then(() => resolve())
          .catch((error) => reject(error));
      },
      (error) => {
        dispatch(consumerOnboardingDataFailure(error));
        reject(error);
      }
    );
  });
}

// eslint-disable-next-line max-params
function _updateApplicationPhysicalAddress({
  addressAPI,
  application,
  applicationAPI,
  dispatch,
  getState,
  resetSubmitting = true,
}) {
  return new Promise((resolve, reject) => {
    const businessState = getState().cob_business;
    const physicalAddress = businessState.physical_address;

    addressAPI.createAddress(
      physicalAddress,
      (success) => {
        applicationAPI.updateApplication(
          application.id,
          { physical_address_id: success.data.data.id },
          () => {
            if (resetSubmitting) {
              dispatch({ type: COB_REVIEW_SUBMIT_DATA_SUCCESS });
            }
            resolve();
          },
          (error) => {
            dispatch(consumerOnboardingDataFailure(error));
            reject(error);
          }
        );
      },
      (error) => {
        dispatch(consumerOnboardingDataFailure(error));
        reject(error);
      }
    );
  });
}

// eslint-disable-next-line max-params
function _updateApplicationPostalAddress({
  addressAPI,
  application,
  applicationAPI,
  dispatch,
  getState,
  resetSubmitting = true,
}) {
  return new Promise((resolve, reject) => {
    const businessState = getState().cob_business;
    let postalAddress = null;
    // const postalAddressId = application.attributes.postal_address_id;
    const hasPostalAddress =
      Object.prototype.hasOwnProperty.call(
        businessState.postal_address,
        'api_id'
      ) && businessState.postal_address.api_id;

    if (hasPostalAddress) postalAddress = businessState.postal_address;

    if (postalAddress) {
      addressAPI.createAddress(
        postalAddress,
        (success) => {
          applicationAPI.updateApplication(
            application.id,
            { postal_address_id: success.data.data.id },
            () => {
              if (resetSubmitting) {
                dispatch({ type: COB_REVIEW_SUBMIT_DATA_SUCCESS });
              }
              resolve();
            },
            (error) => {
              dispatch(consumerOnboardingDataFailure(error));
              reject(error);
            }
          );
        },
        (error) => {
          dispatch(consumerOnboardingDataFailure(error));
          reject(error);
        }
      );
    }

    resolve();
  });
}

function getEntity(getState, consumerAccount) {
  const sectionState = getState().cob_business;

  const data = {
    entity: {},
  };

  let registrationDate;
  if (
    sectionState.entity_type !== 'company' &&
    sectionState.entity_type !== 'personal'
  ) {
    const date = (sectionState.company_details || {}).registered_at;
    registrationDate = moment(
      `${date.month}/${date.day}/${date.year}`,
      'MM/DD/YYYY'
    ).format('YYYY-MM-DD');
  } else if (sectionState.entity_type === 'company') {
    registrationDate = (sectionState.company_details || {}).registration_date;
  }

  data.entity.company_name =
    sectionState.entity_type === 'personal'
      ? null
      : sectionState.company_details.name;
  data.entity.trading_name = sectionState.entity_name;

  data.entity.contact_phone_number = sectionState.entity_phone;
  data.entity.staff_count = sectionState.entity_staff_count;
  //data.entity.age = sectionState.company_details.age;
  if (registrationDate) {
    data.entity.registered_at = registrationDate;
  }
  data.entity.region = sectionState.entity_region;
  data.entity.state = sectionState.entity_state;

  data.entity.delivery_instruction = sectionState.delivery_instruction;
  data.entity.incorporated_number = sectionState.incorporated_number;

  if (!consumerAccount) {
    data.entity.legal_type = sectionState.entity_type;
  }

  const companyNumber = get(sectionState, 'company_details.company_number');
  if (isPresent(companyNumber)) {
    data.entity.company_number = companyNumber;
  } else {
    data.entity.companyNumber = null;
  }

  const businessNumber = get(sectionState, 'company_details.business_number');
  if (isPresent(businessNumber)) {
    data.entity.business_number = businessNumber;
  } else {
    data.entity.business_number = null;
  }

  // if (
  //   sectionState.entity_type === "company" &&
  //   sectionState.entity_region === "NZ"
  // ) {
  //   data.entity.company_number = sectionState.company_details.company_number;
  //   data.entity.business_number = sectionState.company_details.business_number;
  // } else if (sectionState.entity_region === "AU") {
  //   data.entity.company_number =
  //     sectionState.company_details.company_number &&
  //     sectionState.company_details.company_number.length > 0
  //       ? sectionState.company_details.company_number
  //       : null;
  //   data.entity.business_number =
  //     sectionState.company_details.business_number &&
  //     sectionState.company_details.business_number.length > 0
  //       ? sectionState.company_details.business_number
  //       : null;
  // } else {
  //   data.entity.company_number = null;
  //   data.entity.business_number = null;
  // }

  data.entity.business_api_details = sectionState.businessApiDetails;

  return data.entity;
}

function submitPeople(getState, entityId) {
  const entity = api(
    'entities',
    getState().current_user.access_token,
    entityId
  );
  const people = getState().cob_section.people;
  const peoplePromises = [];
  const creditApplication =
    getState().cob_section.application.attributes.application_type === 'credit';
  if (creditApplication) {
    peoplePromises.push(
      entity.createOrUpdatePeople(
        entityId,
        () => {},
        (error) => {
          throw error;
        },
        { people }
      )
    );
  }

  return Promise.all(peoplePromises);
}

function deletePeople(getState, entityId) {
  const entity = api(
    'entities',
    getState().current_user.access_token,
    entityId,
    { headers: { 'Content-Type': 'application/json' }, version: '/v2' }
  );
  return entity.deletePeople(
    entityId,
    () => {
      // do nothing
    },
    () => Promise.reject("Can't delete people")
  );
}

export function submitApplicationData(
  getState,
  dispatch,
  entityId,
  entityType
) {
  const applications = api(
    'applications',
    getState().current_user.access_token,
    entityId
  );
  const sectionState = getState().cob_section;
  const moneyState = getState().cob_money;
  const extrasState = getState().cob_extras;

  let tradeReferenceChecks;

  const data = {
    trade_account_limit: moneyState.requested_limit || 0,
  };

  return applications.updateApplication(sectionState.application.id, data).then(
    () =>
      applications.getApplication(
        sectionState.application.id,
        (result) => {
          dispatch({
            payload: result.data.included,
            type: COB_REVIEW_SUBMIT_CLEAR_START,
          });

          if (result.data.included) {
            tradeReferenceChecks = result.data.included.filter(
              (inc) => inc.type === 'trade_reference_checks'
            );
          }

          let promises = [];

          if (entityType === 'personal') {
            promises = [
              submitIdentification(getState, dispatch),
              submitAuthorityDeclaration(
                getState,
                sectionState.application.id,
                entityId
              ),
              saveGuarantors(
                getState,
                sectionState.application.id,
                entityId,
                dispatch
              ),
              deleteTradeReferences(
                getState,
                sectionState.application.id,
                entityId,
                dispatch,
                tradeReferenceChecks
              ),
            ];
          } else {
            promises = [
              submitIdentification(getState, dispatch),
              submitAuthorityDeclaration(
                getState,
                sectionState.application.id,
                entityId
              ),
              saveGuarantors(
                getState,
                sectionState.application.id,
                entityId,
                dispatch
              ),
              saveTradeReferences(
                getState,
                sectionState.application.id,
                entityId,
                dispatch,
                tradeReferenceChecks
              ),
            ];
          }

          return Promise.all(promises).catch(() =>
            Promise.reject('Promise List Failed')
          );
        },
        (error) => {
          dispatch(consumerOnboardingDataFailure(error));
          return Promise.reject('GetApplication Failed');
        },
        {
          params: {
            include: 'trade_references.trade_reference_check',
          },
        }
      ),
    (error) => {
      dispatch(consumerOnboardingDataFailure(error));
      return Promise.reject('UpdateApplication Failed');
    }
  );
}

function submitIdentification(getState, dispatch) {
  return new Promise((resolve, reject) => {
    const authorisationAPI = api(
      'authorisations',
      getState().current_user.access_token
    );

    const signature = signatureDataFormatter();

    if (
      getState().identity.address &&
      getState().identity.address.full_address
    ) {
      signature.address = getState().identity.address;
    }

    const attributes = { signature };
    const authorisationId = getState().cob_section.authorisation.id;

    authorisationAPI
      .updateAuthorisation(authorisationId, attributes)
      .then(() => {
        resolve('Success');
      })
      .catch((error) => {
        dispatch(consumerOnboardingDataFailure(error));
        reject(error);
      });
  });
}

export function updateUser(userAttr) {
  return (dispatch, getState) => {
    const users = api(
      'users',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    dispatch({
      type: USER_UPDATE_START,
    });

    users.updateUser(
      getState().current_user.data.data.id,
      userAttr,
      (success) => {
        dispatch({
          meta: {
            mixpanel: {
              event: 'Update User',
              props: {
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: USER_UPDATE_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          payload: error,
          type: USER_UPDATE_ERROR,
        });
      }
    );
  };
}

export function createAuthorityDeclaration(applicationId) {
  return (dispatch, getState) => {
    const ad = api(
      'authority_declarations',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    const dob = getState().cob_review.declaration_dob;
    const formattedDate = moment(
      `${dob.month}/${dob.day}/${dob.year}`,
      'MM/DD/YYYY'
    ).format('YYYY-MM-DD');
    ad.createAuthorityDeclarationForApplication(applicationId, {
      authoriser_dob: formattedDate,
      authoriser_dpid: getState().cob_review.declaration_dpid,
      authoriser_first_name: getState().cob_review.declaration_first_name,
      authoriser_last_name: getState().cob_review.declaration_last_name,
    });
  };
}

function submitAuthorityDeclaration(getState, applicationId, entityId) {
  const ad = api(
    'authority_declarations',
    getState().current_user.access_token,
    entityId
  );

  const dob = getState().cob_review.declaration_dob;
  const formattedDate = moment(
    `${dob.month}/${dob.day}/${dob.year}`,
    'MM/DD/YYYY'
  ).format('YYYY-MM-DD');
  return ad.createAuthorityDeclarationForApplication(applicationId, {
    authoriser_dob: formattedDate,
    authoriser_dpid: getState().cob_review.declaration_dpid,
    authoriser_first_name: getState().cob_review.declaration_first_name,
    authoriser_last_name: getState().cob_review.declaration_last_name,
  });
}

// eslint-disable-next-line max-params
function saveTradeReferences(
  getState,
  applicationId,
  entityId,
  dispatch,
  tradeReferenceChecks
) {
  const traderefPromises = [];
  const traderefState = getState().cob_traderef;
  const tradeReferences = traderefState.referees;

  if (tradeReferences.length > 0) {
    tradeReferences.forEach((item, index) => {
      const tradeReferencesApi = api(
        'trade_references',
        getState().current_user.access_token,
        entityId
      );
      const data = {
        address: item.company_address,
        business_name: item.business_name,
        contact_country_code: item.contact_country_code,
        contact_phone_number: item.contact_phone,
        email: item.email ? item.email : null,
        first_name: item.first_name,
        last_name: item.last_name,
        notes: item.notes,
        notified: item.informed === 'yes',
        position: item.referee_position,
        previous_business_name: item.previous_business_name,
        relationship_length: item.relationship_length,
        trade_frequency: item.trade_frequency,
      };
      let isTradeReferenceCheckExisted;

      if (item.id) {
        if (tradeReferenceChecks.length > 0) {
          isTradeReferenceCheckExisted = tradeReferenceChecks.find(
            (trc) => trc.attributes.trade_reference_id === item.id
          );
        }
        if (
          traderefState.origin_referees[index].first_name !== item.first_name &&
          traderefState.origin_referees[index].last_name !== item.last_name &&
          traderefState.origin_referees[index].contact_phone !==
            item.contact_phone &&
          getState().cob_section.application.attributes
            .requires_trade_reference_check &&
          isTradeReferenceCheckExisted
        ) {
          /*update existed ref with archived */
          traderefPromises.push(
            tradeReferencesApi.updateTradeRef(
              item.id,
              { archived: true },
              () => {
                // do nothing
              },
              () => {
                // do nothing
              }
            )
          );

          traderefPromises.push(
            tradeReferencesApi.createTradeRef(
              applicationId,
              data,
              (success) => {
                item.id = success.data.data.id;
              },
              () => {
                // do nothing
              }
            )
          );
        } else {
          /*update existed ref */
          traderefPromises.push(
            tradeReferencesApi.updateTradeRef(
              item.id,
              data,
              () => {
                // do nothing
              },
              () => {
                // do nothing
              }
            )
          );
        }
      } else {
        /*create a new ref */
        traderefPromises.push(
          tradeReferencesApi.createTradeRef(
            applicationId,
            data,
            (success) => {
              item.id = success.data.data.id;
            },
            () => {
              // do nothing
            }
          )
        );
      }
    });
    traderefPromises.push(dispatch(preSetRefereeData(tradeReferences)));
    return Promise.all(traderefPromises);
  }
}

function saveGuarantors(getState, applicationId, entityId, dispatch) {
  const guarantorPromises = [];
  const guarantors = getState().cob_guarantors.form_values;
  guarantors.forEach((item) => {
    const guarantorsApi = api(
      'guarantors',
      getState().current_user.access_token,
      entityId
    );
    if (item.id) {
      guarantorPromises.push(
        guarantorsApi.updateGuarantor(
          item.id,
          {
            email: item.email,
            first_name: item.first_name,
            is_applicant: item.is_applicant,
            last_name: item.last_name,
            middle_name: item.middle_name || null,
            percentage_share: item.percentage_share,
            position: item.position,
            associated_entity_id: item.associated_entity_id,
          },
          () => {
            // do nothing
          },
          () => {
            // do nothing
          }
        )
      );
    } else {
      guarantorPromises.push(
        guarantorsApi.createGuarantor(
          applicationId,
          {
            email: item.email,
            first_name: item.first_name,
            is_applicant: item.is_applicant,
            last_name: item.last_name,
            middle_name: item.middle_name,
            percentage_share: item.percentage_share,
            position: item.position,
            associated_entity_id: item.associated_entity_id,
          },
          (success) => {
            item.id = success.data.data.id;
          },
          () => {
            // do nothing
          }
        )
      );
    }
  });
  guarantorPromises.push(dispatch(preSetGuarantorData(guarantors)));
  return Promise.all(guarantorPromises);
}

export function setAddress(value) {
  return {
    payload: value,
    type: COB_REVIEW_SET_ADDRESS,
  };
}

export function selectAddress(dpid) {
  return {
    payload: dpid,
    type: COB_REVIEW_SELECT_ADDRESS,
  };
}

export function lookupAddress(string) {
  return (dispatch, getState) => {
    // If the string is empty, then zero out the lookup list.
    if (string.length === 0) {
      dispatch({
        type: COB_REVIEW_ADDRESS_LOOKUP_CLEAR,
      });
    } else {
      dispatch({
        payload: string,
        type: COB_REVIEW_ADDRESS_LOOKUP_START,
      });

      const addressSearch = api(
        'address_search',
        getState().current_user.address_token
      );
      const data = {};

      addressSearch.addressSearch(
        (result) => {
          for (let i = 0; i < result.data.addresses.length; i += 1) {
            data[result.data.addresses[i].DPID] =
              result.data.addresses[i].FullAddress;
          }

          dispatch({
            payload: data,
            type: COB_REVIEW_ADDRESS_LOOKUP_SUCCESS,
          });
        },
        (error) => {
          dispatch({
            payload: error,
            type: COB_REVIEW_ADDRESS_LOOKUP_ERROR,
          });
        },
        {
          params: {
            address: string,
            region: 'New Zealand', // TODO: Replace with data from region drop down
            type: 'All',
          },
        }
      );
    }
  };
}

export function getWebsitebuttonEntity(getState) {
  const sectionState = getState().cob_business;

  const data = {
    entity: {},
  };

  data.entity.key_contact_first_name = sectionState.key_contact_first_name;
  data.entity.key_contact_last_name = sectionState.key_contact_last_name;
  data.entity.region = sectionState.entity_region;
  data.entity.state = sectionState.entity_state;
  data.entity.key_contact_phone_number = sectionState.key_contact_phone;
  data.entity.key_contact_country_code = sectionState.key_contact_country_code;
  data.entity.key_contact_email = sectionState.key_contact_email;
  data.entity.key_contact_password = sectionState.key_contact_password;
  data.entity.consumer_id = sectionState.entity_id;
  data.entity.application_type = sectionState.application_type;

  return data.entity;
}

export function updateEntityContactAndRegionInfo(
  entityId,
  eAttrs,
  applicationId,
  redirect
) {
  return (dispatch, getState) => {
    dispatch(setStartComplete(applicationId));

    dispatch({
      type: ENTITY_UPDATE_START,
    });

    const entities = api(
      'entities',
      getState().current_user.access_token,
      entityId
    );

    entities.updateEntity(
      entityId,
      eAttrs,
      (success) => {
        dispatch({
          meta: {
            mixpanel: {
              event: 'Update Entity Contact And Region Information',
              props: {
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: ENTITY_UPDATE_SUCCESS,
        });

        if (redirect) {
          browserHistory.push(redirect);
        }
      },
      (error) => {
        dispatch({
          payload: error,
          type: ENTITY_UPDATE_ERROR,
        });
      },
      { consumer_flow: true }
    );
  };
}

export function updateBusinessSection({
  applicationId,
  consumerAccount,
  entityId,
  entityType,
  successCallback,
}) {
  return (dispatch, getState) => {
    Promise.all([
      _updateEntityData({
        consumerAccount,
        dispatch,
        entityId,
        entityType,
        getState,
        resetSubmitting: false,
      }),
      _updateApplicationBusinessDetail({
        applicationId,
        dispatch,
        entityId,
        getState,
        resetSubmitting: false,
      }),
      _updateTradingNames({
        applicationId,
        dispatch,
        entityType,
        getState,
      }),
    ])
      .then(() => {
        dispatch(clearAffectedPages());
        dispatch({ type: COB_REVIEW_SUBMIT_DATA_SUCCESS });
        successCallback();
      })
      .catch((error) => {
        console.error({ error });
      });
  };
}

export function _updateEntityData({
  consumerAccount,
  dispatch,
  entityId,
  entityType,
  getState,
  resetSubmitting,
}) {
  return new Promise((resolve, reject) => {
    dispatch(consumerOnboardingDataSubmissionStarted());

    const entities = api(
      'entities',
      getState().current_user.access_token,
      entityId
    );
    const eAttrs = getEntity(getState, consumerAccount);

    entities.updateEntity(
      entityId,
      eAttrs,
      () => {
        let submissionPromises = [];

        if (entityType === 'personal') {
          submissionPromises = [submitPeople(getState, entityId)];
        } else {
          submissionPromises = [
            submitPeople(getState, entityId),
            updateEntityAddresses(dispatch, getState, entityId, false),
          ];
        }

        return Promise.all(submissionPromises)
          .then(() => {
            dispatch(loadCurrentPeople(entityId));
            resetSubmitting
              ? dispatch({ type: COB_REVIEW_SUBMIT_DATA_SUCCESS })
              : null;
            resolve();
          })
          .catch((error) => {
            dispatch(consumerOnboardingDataFailure(error));
            reject(error);
          });
      },
      (error) => {
        dispatch(consumerOnboardingDataFailure(error));
        reject(error);
      },
      { consumer_flow: true }
    );
  });
}

export function updateTradeReferencesData(entityId, successCallback) {
  return (dispatch, getState) => {
    const applications = api(
      'applications',
      getState().current_user.access_token,
      entityId
    );
    const sectionState = getState().cob_section;
    let tradeReferenceChecks;

    return applications.getApplication(
      sectionState.application.id,
      (result) => {
        dispatch({
          payload: result.data.included,
          type: COB_REVIEW_SUBMIT_CLEAR_START,
        });
        if (result.data.included) {
          tradeReferenceChecks = result.data.included.filter(
            (inc) => inc.type === 'trade_reference_checks'
          );
        }
        const promises = [
          saveTradeReferences(
            getState,
            sectionState.application.id,
            entityId,
            dispatch,
            tradeReferenceChecks
          ),
        ];

        return Promise.all(promises)
          .then(() => {
            if (successCallback) {
              dispatch(successCallback);
            }
          })
          .catch((error) => {
            dispatch(consumerOnboardingDataFailure(error));
          });
      },
      (error) => {
        dispatch(consumerOnboardingDataFailure(error));
      },
      {
        params: {
          include: 'trade_references.trade_reference_check',
        },
      }
    );
  };
}

export function updateGuarantorsData(entityId, successCallback) {
  return (dispatch, getState) => {
    const applications = api(
      'applications',
      getState().current_user.access_token,
      entityId
    );
    const sectionState = getState().cob_section;

    return applications.getApplication(
      sectionState.application.id,
      async (result) => {
        dispatch({
          payload: result.data.included,
          type: COB_REVIEW_SUBMIT_CLEAR_START,
        });

        if (FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER) {
          // Watchtower is enabled. Before we save the guarantor data,
          // we need to check if the email entered for any of the guarantor
          // is a blocked one.

          // Collect the emails.
          const emails = getState().cob_guarantors.form_values.map(
            (guarantor) => guarantor.email
          );

          // We need to pass the ids of the cosumer and supplier
          // entities to the function that checks whether any of the emails are
          // blocked.
          const consumerId = getState().cob_section.current_entity.id;
          const supplierId = getState().cob_section.supplier.id;

          // We will get the result of the check to this variable.
          let result = null;

          try {
            // Call the function to check if any of the emails are blocked.
            result = await findWatchtowerBlockedEmails({
              emails,
              consumerId,
              supplierId,
            });
          } catch (error) {
            // The function ran into an error.
            // Since we couldn't verify the emails, we should not save the data.
            dispatch(consumerOnboardingDataFailure(error));
          }

          if (result) {
            // Either there are some blocked emails, or we ran into an error
            // while checking for blocked emails. We should not save the data.

            if (result.blockedEmails && result.blockedEmails.length) {
              // We found some blocked emails

              // Send the blocked emails to store
              dispatch({
                type: COB_GUARANTOR_SET_BLOCKED_EMAILS,
                payload: result.blockedEmails,
              });

              // Dispatch the normal error handler action
              dispatch(consumerOnboardingDataFailure(result.error));

              // Returning becuase we shouldn't save the data
              return;
            }

            if (result.hasOtherError) {
              // There were no blocked emails, but we ran into some other error.
              dispatch(consumerOnboardingDataFailure(result.error));
              return;
            }
          }
        }

        const promises = [
          saveGuarantors(
            getState,
            sectionState.application.id,
            entityId,
            dispatch
          ),
        ];

        return Promise.all(promises)
          .then(() => {
            dispatch(clearAffectedPages());
            if (successCallback) {
              dispatch(successCallback);
            }
          })
          .catch((error) => {
            dispatch(consumerOnboardingDataFailure(error));
          });
      },
      (error) => {
        dispatch(consumerOnboardingDataFailure(error));
      },
      {
        params: {
          include: 'guarantors',
        },
      }
    );
  };
}

export function updateApplicationAddonRules(entityId, callback) {
  return async (dispatch, getState) => {
    dispatch(consumerOnboardingDataSubmissionStarted());
    const applications = api(
      'applications',
      getState().current_user.access_token,
      entityId
    );
    const sectionState = getState().cob_section;

    try {
      const response = await applications.updateApplicationAndGetAddonRules(
        sectionState.application.id
      );
      dispatch({
        type: COB_REVIEW_SUBMIT_DATA_SUCCESS,
      });
      dispatch(loadApplicationAddonRules(response.data.rules.data));
      dispatch(loadApplicationAddonAnswers(response.data.answers.data));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(consumerOnboardingDataFailure(error));
    }
  };
}

export function updateApplicationExtraData(entityId, callback) {
  return async (dispatch, getState) => {
    dispatch(consumerOnboardingDataSubmissionStarted());
    const applications = api(
      'applications',
      getState().current_user.access_token,
      entityId
    );
    const sectionState = getState().cob_section;
    const moneyState = getState().cob_money;

    const data = {
      trade_account_limit: moneyState.requested_limit,
    };

    try {
      const response = await applications.updateApplicationAndGetAddonRules(
        sectionState.application.id,
        data
      );
      dispatch({
        type: COB_REVIEW_SUBMIT_DATA_SUCCESS,
      });
      dispatch(loadApplicationAddonRules(response.data.rules.data));
      dispatch(loadApplicationAddonAnswers(response.data.answers.data));
      dispatch(loadCardholders(sectionState.application.id));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(consumerOnboardingDataFailure(error));
    }
  };
}

export function updateIdentificationData(entityId, callback) {
  return (dispatch, getState) => {
    dispatch(consumerOnboardingDataSubmissionStarted());
    const sectionState = getState().cob_section;

    let promises = [
      submitIdentification(getState, dispatch),
      _updateUserFirstNameLastNameFromSignaturePage(dispatch, getState),
    ];

    if (
      !get(sectionState, 'current_entity.attributes.key_contact_first_name')
    ) {
      promises += _updateEntityKeyContactFirstNameLastNameFromSignaturePage(
        getState,
        entityId
      );
    }

    return Promise.all(promises)
      .then(() => {
        consumerOnboardingDataSubmitted(
          dispatch,
          getState,
          sectionState.application.attributes
        );
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(consumerOnboardingDataFailure(error));
      });
  };
}

function _updateUserFirstNameLastNameFromSignaturePage(dispatch, getState) {
  return new Promise((resolve, reject) => {
    const users = api(
      'users',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    const params = {
      first_name: getState().identity.first_name,
      last_name: getState().identity.last_name,
    };
    users.updateUser(
      getState().current_user.data.data.id,
      params,
      (success) => {
        resolve('Success');
        dispatch(updateUserFirstNameLastNameFromSignature(success.data.data));
      },
      (error) => reject(error)
    );
  });
}

function _updateEntityKeyContactFirstNameLastNameFromSignaturePage(
  getState,
  entityId
) {
  return new Promise((resolve, reject) => {
    const entities = api(
      'entities',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    const params = {
      key_contact_first_name: getState().identity.first_name,
      key_contact_last_name: getState().identity.last_name,
    };
    entities.updateEntity(
      entityId,
      params,
      () => resolve('Success'),
      (error) => reject(error)
    );
  });
}

export function updateApplicationComplete(entityId, redirect) {
  return (dispatch, getState) => {
    dispatch({
      payload: false,
      type: COB_REVIEW_SUBMIT_WATCHTOWER_ERROR,
    });
    dispatch(consumerOnboardingDataSubmissionStarted());
    const applications = api(
      'applications',
      getState().current_user.access_token,
      entityId
    );
    const sectionState = getState().cob_section;

    const applicationId = sectionState.application.id;

    applications
      .isIpBlocked(applicationId)
      .then(() => {
        const data = {
          submission_status: 'complete',
        };

        applications.updateApplication(
          applicationId,
          data,
          () => {
            consumerOnboardingDataSubmitted(
              dispatch,
              getState,
              sectionState.application.attributes
            );

            const name =
              getState().current_user.data.data.attributes.full_name || '';
            const email =
              getState().current_user.data.data.attributes.email || '';
            const supplierName =
              sectionState.application.attributes.supplier_name || '';
            const websitebutton =
              sectionState.application.attributes.websitebutton_application;

            sendSurvey({
              name,
              email,
              supplier: false,
              event_flow: 'Consumer onboarding',
              supplier_name: supplierName,
              access_token: getState().current_user.access_token,
              websitebutton: websitebutton,
              application_id: sectionState.application.id,
              supplier_id: sectionState.application.attributes.supplier_id,
              consumer_id: sectionState.application.attributes.consumer_id,
              consumer_name: null,
            });

            browserHistory.push(redirect);
          },
          (error) => {
            dispatch(consumerOnboardingDataFailure(error));
          }
        );
      })
      .catch((error) => {
        dispatch(consumerOnboardingDataFailure(error));
      });
  };
}

// eslint-disable-next-line max-params
export function updateStartInfoAndLoginUser(
  userAttr,
  redirect,
  entityId,
  eAttrs,
  applicationId
) {
  return (dispatch, getState) => {
    const users = api(
      'users',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    dispatch({
      type: USER_UPDATE_IN_START_APPLICATION_START,
    });

    users.updateUserAndEntityRegion(
      getState().current_user.data.data.id,
      { ...userAttr, ...eAttrs },
      (success) => {
        dispatch(
          userLogin(
            userAttr.email,
            userAttr.password,
            redirect,
            setStartComplete(applicationId)
            // updateEntityContactAndRegionInfo(entityId, eAttrs, applicationId),
          )
        );

        dispatch({
          meta: {
            mixpanel: {
              event: 'Update User',
              props: {
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: USER_UPDATE_IN_START_APPLICATION_SUCCESS,
        });

        const authorisationId = get(getState(), 'cob_section.authorisation.id');

        if (authorisationId) {
          const authorisationsAPI = api(
            'authorisations',
            getState().current_user.access_token
          );
          authorisationsAPI.updateInProgressState(authorisationId);
        }
      },
      (error) => {
        dispatch({
          payload: error,
          type: USER_UPDATE_IN_START_APPLICATION_ERROR,
        });
      }
    );
  };
}

// eslint-disable-next-line max-params
function deleteTradeReferences(
  getState,
  applicationId,
  entityId,
  dispatch,
  tradeReferenceChecks
) {
  const traderefPromises = [];
  const traderefState = getState().cob_traderef;
  const tradeReferences = traderefState.referees;

  if (tradeReferences.length > 0) {
    tradeReferences.forEach((item) => {
      const tradeReferencesApi = api(
        'trade_references',
        getState().current_user.access_token,
        entityId
      );
      let isTradeReferenceCheckExisted;

      if (item.id) {
        if (tradeReferenceChecks.length > 0) {
          isTradeReferenceCheckExisted = tradeReferenceChecks.find(
            (trc) => trc.attributes.trade_reference_id === item.id
          );
        }
        if (isTradeReferenceCheckExisted) {
          /*update existed ref with archived */
          traderefPromises.push(
            tradeReferencesApi.updateTradeRef(
              item.id,
              { archived: true },
              () => {
                // do nothing
              },
              () => {
                // do nothing
              }
            )
          );
        } else {
          /*update existed ref */
          traderefPromises.push(
            tradeReferencesApi.delete(
              item.id,
              () => {
                // do nothing
              },
              () => {
                // do nothing
              }
            )
          );
        }
      }
    });
    traderefPromises.push(dispatch(preSetRefereeData(tradeReferences)));
    return Promise.all(traderefPromises);
  }
}

const _paperlessAnswersFilter = (answers) =>
  answers.multiSignature
    ? answers
    : {
        ...answers,
        jointSignatories: [],
      };

export function updateAddonAnswerData(
  applicationId,
  addonRuleId,
  moduleName,
  successCallback
) {
  return async (dispatch, getState) => {
    dispatch(consumerOnboardingDataSubmissionStarted());
    const addonAnswers = api('addon_answers');
    const moduleState = getState()[`cob_${moduleName}`];

    const isWatchtowerEnabled = FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER;
    const isMultiSignatory = moduleState.answers.multiSignature;

    if (isWatchtowerEnabled && moduleName === 'paperless' && isMultiSignatory) {
      // For paperless direct debit section, we need to check if the emails are
      // blocked or not, when watchtower is enabled, and when multiple signatures
      // are enabled

      // Array of emails to be checked
      const emails = moduleState.answers.jointSignatories.map(
        (signatory) => signatory.email
      );

      const consumerId = getState().cob_section.current_entity.id;
      const supplierId = getState().cob_section.supplier.id;

      let result = null;

      try {
        result = await findWatchtowerBlockedEmails({
          emails,
          consumerId,
          supplierId,
        });
      } catch (error) {
        // The function ran into an error.
        // Since we couldn't verify the emails, we should not save the data.
        dispatch(consumerOnboardingDataFailure(error));
      }

      if (result) {
        // Either there are some blocked emails, or we ran into an error
        // while checking for blocked emails. We should not save the data.

        if (result.blockedEmails && result.blockedEmails.length) {
          // We found some blocked emails

          // Send the blocked emails to store
          dispatch({
            payload: result.blockedEmails,
            type: COB_PAPERLESS_SET_BLOCKED_EMAILS,
          });

          // Dispatch the normal error handler action
          dispatch(consumerOnboardingDataFailure(result.error));

          // Returning becuase we shouldn't save the data
          return;
        }

        if (result.hasOtherError) {
          // There were no blocked emails, but we ran into some other error.
          dispatch(consumerOnboardingDataFailure(result.error));
          return;
        }
      }
    }

    const data = {
      addon_rule_id: addonRuleId,
      answers: { ..._paperlessAnswersFilter(moduleState.answers) },
      file: moduleState.file ? moduleState.file.url : null,
      owner_id: applicationId,
      owner_type: 'Application',
    };

    try {
      if (moduleState.id) {
        await addonAnswers.updateAddonAnswer(moduleState.id, data);
        if (successCallback) {
          dispatch(successCallback);
        }
      } else {
        const response = await addonAnswers.createAddonAnswer(data);

        switch (moduleName) {
          case 'additional':
            dispatch(loadAdditionalAnswers(response.data.data));
            break;
          case 'financials':
            dispatch(loadFinancialsAnswers(response.data.data));
            break;
          case 'paperless':
            dispatch(loadPaperlessAnswers(response.data.data));
            break;
        }
        if (successCallback) {
          dispatch(successCallback);
        }
      }
      dispatch({
        type: COB_REVIEW_SUBMIT_DATA_SUCCESS,
      });
    } catch (error) {
      dispatch(consumerOnboardingDataFailure(error));
    }
  };
}

export function updateAuthorisation(id, attributes, callback) {
  return async (dispatch, getState) => {
    dispatch({ type: COB_REVIEW_UPDATE_AUTHORISATION_START });

    const accessToken = getState().current_user.access_token;

    try {
      const authorisationAPI = api('authorisations', accessToken);

      await authorisationAPI.updateAuthorisation(id, attributes);

      dispatch({ type: COB_REVIEW_UPDATE_AUTHORISATION_SUCCESS });

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({ type: COB_REVIEW_UPDATE_AUTHORISATION_ERROR });
    }
  };
}

async function _updateTradingNames({
  applicationId,
  dispatch,
  entityType,
  getState,
}) {
  if (entityType !== 'company') {
    return Promise.resolve();
  }

  dispatch({ type: COB_REVIEW_UPDATE_TRADING_NAMES_START });

  const accessToken = getState().current_user.access_token;
  const selectedTradingNameIds = getState().cob_section.selected_trading_names;
  const tradingNames = getState().cob_section.trading_names;
  const selectedTradingNames = selectedTradingNameIds.map((id) => {
    return { id, ...tradingNames[id] };
  });

  try {
    const applicationAPI = api('applications', accessToken);
    await applicationAPI.patchConsumerTradingNames(applicationId, {
      data: selectedTradingNames,
    });
    dispatch({ type: COB_REVIEW_UPDATE_TRADING_NAMES_SUCCESS });
    return Promise.resolve();
  } catch (error) {
    dispatch({ type: COB_REVIEW_UPDATE_TRADING_NAMES_ERROR });
    return Promise.reject(error);
  }
}
